import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/main_text_editor_panel.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfMainTextEditorPanel', [
    '$injector',

    () => ({
        restrict: 'E',
        scope: {
            textEditorViewModel: '<',
        },
        templateUrl,
        link(scope) {
            Object.defineProperty(scope, 'frameViewModel', {
                get() {
                    return this.textEditorViewModel?.frameViewModel;
                },
            });
        },
    }),
]);
