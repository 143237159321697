import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/challenges/challenges_panel_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfChallengesPanelEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            scope: {
                editorViewModel: '<',
                frameViewModel: '<',
                overlayEditorViewModel: '<',
            },
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                Object.defineProperty(scope, 'challengesEditorViewModels', {
                    get() {
                        const challengeEditorViewModels = scope.editorViewModel.editorViewModelsFor(
                            scope.editorViewModel.model.challenges,
                        );
                        if (scope.overlayEditorViewModel) {
                            // This filter is to do an array intersection...this way, we use the order of the
                            // main challenges array, but filtered to the set of challenges in the current overlay
                            return challengeEditorViewModels.filter(e =>
                                scope.overlayEditorViewModel.challengesEditorViewModels.includes(e),
                            );
                        }
                        return challengeEditorViewModels;
                    },
                });

                Object.defineProperty(scope, 'interactiveCardIndex', {
                    get() {
                        if (scope.overlayEditorViewModel) {
                            return scope.editorViewModel.interactiveCards.overlays.indexOf(
                                scope.overlayEditorViewModel.model,
                            );
                        }
                        return 0;
                    },
                });

                Object.defineProperty(scope, 'canAddChallenge', {
                    get() {
                        if (scope.editorViewModel.config.disallowAddAndReorderChallenges) {
                            return false;
                        }

                        return true;
                    },
                });

                function _indexOfChallengeEditorViewModel(challengeEditorViewModel) {
                    const editorViewModels = [];
                    scope.editorViewModel.model.challenges.forEach(challengeModel => {
                        editorViewModels.push(challengeModel.editorViewModel);
                    });
                    return editorViewModels.indexOf(challengeEditorViewModel);
                }

                function _firstChallengeEditorViewModel() {
                    return scope.editorViewModel.model.challenges.length >= 1
                        ? scope.editorViewModel.model.challenges[0].editorViewModel
                        : undefined;
                }

                function _lastChallengeEditorViewModel() {
                    return scope.editorViewModel.model.challenges.length >= 1
                        ? scope.editorViewModel.model.challenges[scope.editorViewModel.model.challenges.length - 1]
                              .editorViewModel
                        : undefined;
                }

                Object.defineProperty(scope, 'firstChallengeIndex', {
                    get() {
                        return _indexOfChallengeEditorViewModel(_firstChallengeEditorViewModel());
                    },
                });
                Object.defineProperty(scope, 'lastChallengeIndex', {
                    get() {
                        return _indexOfChallengeEditorViewModel(_lastChallengeEditorViewModel());
                    },
                });

                function _indexToSpliceChallenge() {
                    const lastChallengeEditorViewModel = _lastChallengeEditorViewModel();

                    if (scope.overlayEditorViewModel) {
                        if (lastChallengeEditorViewModel) {
                            return _indexOfChallengeEditorViewModel(lastChallengeEditorViewModel) + 1;
                        }

                        let lastIndex = -1;
                        let done = false;
                        scope.editorViewModel.interactiveCards.editorViewModel.model.overlays.forEach(overlayModel => {
                            const oevm = overlayModel.editorViewModel;
                            if (done) {
                                return;
                            }
                            if (oevm.challenges && oevm.challenges.length > 0) {
                                const challenge = oevm.model.challenges[oevm.challenges.length - 1].editorViewModel;
                                lastIndex = _indexOfChallengeEditorViewModel(challenge);
                            }
                            if (oevm === scope.overlayEditorViewModel) {
                                done = true;
                            }
                        });

                        return lastIndex + 1;
                    }
                    return lastChallengeEditorViewModel
                        ? _indexOfChallengeEditorViewModel(lastChallengeEditorViewModel) + 1
                        : 0;
                }

                scope.addChallenge = function () {
                    // Insert new challenge at the bottom of existing challenges for this card
                    // If no challenges exist for this card, insert after the last challenge of a previous card's last challenge
                    const index = _indexToSpliceChallenge();
                    this.editorViewModel.addChallenge(index, scope.overlayEditorViewModel);
                };

                // If a new challenge panel has been added to a MCMC frame, automatically add a new challenge card overlay to go with it.
                // We should always have a 1-to-1 relationship between MCMC Interactive Cards and Challenge Panels/Overlays.
                scope.$watch('overlayEditorViewModel.model.overlayComponents', () => {
                    if (
                        scope.overlayEditorViewModel?.model?.overlayComponents?.length === 0 &&
                        scope.frame.mainUiComponent.editor_template === 'multiple_card_multiple_choice'
                    ) {
                        scope.addChallenge();
                    }
                });
            },
        }),
]);
