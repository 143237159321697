import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('ContentTopic', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('content_topics');
            this.alias('ContentTopic');

            Object.defineProperty(this.prototype, 'streamTitles', {
                get() {
                    return this.stream_titles || [];
                },
            });

            Object.defineProperty(this.prototype, 'streamTitlesToS', {
                get() {
                    return this.streamTitles.join(' ');
                },
            });

            Object.defineProperty(this.prototype, 'localizedLabelsToS', {
                get() {
                    if (!this.locales) {
                        return '';
                    }
                    return _.map(this.locales, value => value).join(' ');
                },
            });
        });
    },
]);
