import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/stream/edit_stream.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import comingSoonIcon from 'images/coming_soon_icon.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editStream', [
    '$injector',

    function factory($injector) {
        const Chapter = $injector.get('Lesson.Stream.Chapter');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const contentItemEditorLists = $injector.get('contentItemEditorLists');
        const scopeTimeout = $injector.get('scopeTimeout');
        const safeApply = $injector.get('safeApply');
        const $rootScope = $injector.get('$rootScope');
        const Stream = $injector.get('Lesson.Stream');
        const $location = $injector.get('$location');
        const UnsavedChangesWarning = $injector.get('UnsavedChangesWarning');
        const FileTypeIcon = $injector.get('FileTypeIcon');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const DialogModal = $injector.get('DialogModal');
        const TranslatableLessonExportSet = $injector.get('TranslatableLessonExportSet');
        const LESSON_TRANSLATION_EXPORT_FORMATS = $injector.get('LESSON_TRANSLATION_EXPORT_FORMATS');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                streamId: '@',
            },
            link(scope) {
                scope.comingSoonIcon = comingSoonIcon;

                UnsavedChangesWarning.warnOnUnsavedChanges(scope, 'stream');
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.setTitleHTML('EDIT<br>COURSE');
                $injector.get('scrollHelper').scrollToTop();
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //---------------------------
                // Data Loading
                //---------------------------

                scope.$watch('streamId', streamId => {
                    // see line 162 of content_item_editor_lists.js for why we can't rely on caching here
                    // effectively, we might a published version already cached and need to puncture the cache for this stream
                    Stream.resetCache();
                    Stream.getCachedOrShow(streamId, {
                        filters: {
                            published: false,
                            in_users_locale_or_en: null,
                            user_can_see: null,
                        },
                        'except[]': ['lesson_streams_progress'],
                        include_progress: false, // we need to override getCachedOrShow
                    }).then(stream => {
                        scope.stream = stream;
                        scope.chaptersCompletedCount = stream.chaptersCompletedCount;
                        scope.lessonsCompletedCount = stream.lessonsCompletedCount;
                    });
                });

                // Get a list of all the lessons
                scope.$watch('stream.locale', locale => {
                    if (locale) {
                        // load the streams before the lessons because the lessons take much longer
                        loadStreams();

                        // load the lessons
                        scope.lessons = undefined;
                        scope.filters = {}; // on scope for specs

                        if (scope.stream.assessmentEditingLocked) {
                            scope.filters.assessment = false;
                        }

                        contentItemEditorLists.load('Lesson', locale, scope.filters).onLoad(lessons => {
                            scope.lessons = lessons;
                        });
                    }
                });

                function loadStreams() {
                    scope.streams = undefined;
                    // FIXME: see https://trello.com/c/wVMI5Wyx/553-chore-convert-related-recommended-courses-to-be-a-list-of-locale-pack-ids
                    contentItemEditorLists.load('Lesson.Stream', scope.stream.locale).onLoad(streams => {
                        scope.streams = streams;
                    });
                }

                //---------------------------
                // Navigation
                //---------------------------

                scope.goBack = () => {
                    $location.url('/editor?section=courses');
                };

                //---------------------------
                // Images Support
                //---------------------------

                scope.imageUploaded = s3Asset => {
                    scope.stream.image = s3Asset;
                };

                scope.certificateImageUploaded = s3Asset => {
                    scope.stream.certificate_image = s3Asset;
                };

                scope.imageEdited = s3Asset => {
                    scope.stream.image = s3Asset;
                };

                //-----------------------------
                // Chapter Support
                //-----------------------------

                scope.addChapter = () => {
                    const chapter = Chapter.new();
                    chapter.$$embeddedIn = scope.stream;
                    scope.stream.chapters.push(chapter);
                };

                // convenience lookup for stream lessons guid => lesson
                scope.streamLessonLookupById = {};

                // rebuilds the lesson lookup map and selectize dataprovider
                function updateStreamLessonsLookup() {
                    const streamLessonLookupById = {};
                    const streamLessons = [];
                    scope.stream.lessons.forEach(lesson => {
                        streamLessonLookupById[lesson.id] = lesson;
                        streamLessons.push({
                            value: lesson.id,
                            text: lesson.titleWithTag,
                        });
                    });
                    scope.streamLessonLookupById = streamLessonLookupById;
                    scope.streamLessons = streamLessons;
                }

                // updates the otherLessons array
                function setOtherLessons() {
                    const otherLessons = [];
                    angular.forEach(scope.lessons, lesson => {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!scope.streamLessonLookupById.hasOwnProperty(lesson.id)) {
                            otherLessons.push(lesson);
                        }
                    });

                    // NOTE: updating otherLessons immediately can cause issues with selectize or
                    // other renderers that may still be acting on present list / selection
                    scopeTimeout(scope, () => {
                        scope.otherLessons = otherLessons;
                    });
                }

                function rebuildChapterDataProviders() {
                    if (!scope.stream || !scope.lessons) {
                        return;
                    }
                    updateStreamLessonsLookup();
                    setOtherLessons();
                }

                scope.$watchCollection('lessons', () => {
                    rebuildChapterDataProviders();
                });

                scope.$watchCollection('stream.lessons', () => {
                    rebuildChapterDataProviders();
                });

                //-----------------------------
                // Key Terms Support
                //-----------------------------

                scope.reviewKeyTerms = () => {
                    const keyTerms = scope.stream.getKeyTerms();

                    const counts = keyTerms.reduce((acc, curr) => {
                        const key = curr.text.toLowerCase();
                        if (typeof acc[key] === 'undefined') {
                            acc[key] = 1;
                        } else {
                            acc[key] += 1;
                        }

                        return acc;
                    }, {});

                    const duplicates = Object.keys(counts).filter(key => counts[key.toLowerCase()] > 1);

                    DialogModal.alert({
                        content:
                            // eslint-disable-next-line no-multi-str
                            '<table class="table table-hover table-condensed"> \
                                <p class="help-block">Use the list of key terms below to review. You may jump into a lesson to edit it using the button on the far right column.</p> \
                                <p ng-if="::duplicates.length > 0">Auto-detected duplicates (exact matches only; be sure to look at complete list):</p> \
                                <ul ng-if="::duplicates.length > 0"> \
                                    <li ng-repeat="dup in ::duplicates">{{::dup}}</li> \
                                </ul> \
                                <tr> \
                                    <th>Term</th> \
                                    <th>Chapter</th> \
                                    <th>Lesson</th> \
                                    <th>Edit</th> \
                                </tr> \
                                <tr ng-repeat="term in ::terms | orderBy:\'text\':false"> \
                                    <td>{{::term.text}}</td> \
                                    <td>{{::term.lesson.chapter().index + 1}}</td> \
                                    <td>{{::term.lesson.chapterLessonsIndex() + 1}}</td> \
                                    <td><button class="btn btn-xs btn-warning" ng-click="editLesson(term.lesson)"><i class="fa fa-edit"></i></button></td> \
                                </tr> \
                            </table>',
                        title: 'Review Key Terms',
                        scope: {
                            terms: keyTerms,
                            duplicates,
                            editLesson(lesson) {
                                const path = `/editor/lesson/${lesson.id}/edit`;
                                $location.url(path);
                            },
                        },
                    });
                };

                //-----------------------------
                // What You'll Learn Support
                //-----------------------------

                scope.addLearning = () => {
                    scope.stream.what_you_will_learn.push('');
                };

                scope.removeLearning = index => {
                    if (scope.stream.what_you_will_learn.length > index && index > -1) {
                        scope.stream.what_you_will_learn.splice(index, 1);
                    }
                };

                scope.updateLearning = (index, data) => {
                    if (scope.stream.what_you_will_learn.length > index && index > -1) {
                        scope.stream.what_you_will_learn[index] = data;
                    }
                };

                //-----------------------------
                // Resource Links Support
                //-----------------------------

                scope.addResourceLink = () => {
                    scope.stream.resource_links.push({});
                };

                scope.removeResourceLink = index => {
                    if (scope.stream.resource_links.length > index && index > -1) {
                        scope.stream.resource_links.splice(index, 1);
                    }
                };

                scope.updateResourceLink = (index, data) => {
                    if (scope.stream.resource_links.length > index && index > -1) {
                        scope.stream.resource_links[index] = data;
                    }
                };

                //-----------------------------
                // Resources / Summaries Support
                //-----------------------------

                scope.onSelectAssetFiles = (collection, files, errFiles) => {
                    if (files.length > 0) {
                        scope.uploadingAssets = collection;
                    }

                    frontRoyalUpload
                        .handleNgfSelect(files, errFiles, selectedFiles => ({
                            url: `${window.ENDPOINT_ROOT}/api/assets.json`,
                            data: {
                                files: selectedFiles,
                            },
                        }))
                        .then(assetsUploaded.bind(null, collection))
                        .catch(err => {
                            const message = err && err.message;
                            DialogModal.alert({
                                content: message || 'Something went wrong',
                            });
                        })
                        .finally(() => {
                            scope.uploadingAssets = null;
                        });
                };

                function assetsUploaded(collection, response) {
                    response.data.contents.s3_assets?.forEach(s3Asset => {
                        const url = s3Asset.formats.original.url;

                        const item = {
                            id: s3Asset.id,
                            url,
                            title: s3Asset.file_file_name,
                            type: scope.getFileTypeForURL(url),
                        };

                        if (collection === scope.stream.summaries) {
                            item.lessons = [];
                        }
                        collection.push(item);
                    });

                    safeApply(scope);
                }

                scope.removeFromCollection = (collection, index) => {
                    if (collection.length > index && index > -1) {
                        collection.splice(index, 1);
                    }
                };

                scope.getFileTypeForURL = url => {
                    const regexp = /\.(.{3,4})$|\.(.{3,4})(\?|#)/i;
                    const matches = regexp.exec(url);
                    if (matches && matches.length > 1) {
                        return matches[1];
                    }
                    return '';
                };

                scope.downloadTypeClasses = downloadType => FileTypeIcon.getFontAwesomeIconClass(downloadType);

                function onItemAddCallback(lessonId) {
                    const selectize = this; // see generateSummarySelectizeConfigs()
                    const summaryId = selectize.settings.summaryId;

                    // add the lesson the the appropriate summary
                    scope.stream.summaries.forEach(summary => {
                        if (summary.id === summaryId && !_.includes(summary.lessons, lessonId)) {
                            summary.lessons.push(lessonId);
                        }
                    });
                    selectize.clear();
                }

                // it was either generate custom configs for selectize,
                // or generate custom DPs. this seemed smaller.
                function generateSummarySelectizeConfigs() {
                    const summarySelectizeConfigs = {};
                    scope.stream.summaries.forEach(summary => {
                        summarySelectizeConfigs[summary.id] = {
                            maxItems: 1,
                            summaryId: summary.id,
                            onItemAdd: onItemAddCallback,
                        };
                    });
                    scope.summarySelectizeConfigs = summarySelectizeConfigs;
                }

                scope.$watchCollection('stream.summaries', () => {
                    if (scope.stream && scope.stream.summaries) {
                        generateSummarySelectizeConfigs();
                    }
                });

                //-----------------------------
                // Streams Map Support
                //-----------------------------

                scope.streamMap = {};

                function updateStreamMap() {
                    scope.streamMap = {};
                    if (scope.stream && scope.streams) {
                        scope.streams.forEach(stream => {
                            scope.streamMap[stream.id] = stream;
                        });
                    }
                }
                scope.$watch('streams', updateStreamMap);

                scope.streamTitleForStreamID = streamId => {
                    if (!scope.streamMap) {
                        return undefined;
                    }
                    const stream = scope.streamMap[streamId];
                    if (angular.isDefined(stream)) {
                        return stream.titleWithTag;
                    }
                    return '☢☢☢ STREAM NOT FOUND ☢☢☢ Probably deleted?';
                };

                //-----------------------------
                // Recommended Streams Support
                //-----------------------------

                function updateFilteredRecommendedStreams() {
                    const filtered = [];

                    if (scope.stream && scope.streams) {
                        updateStreamMap();

                        scope.streams.forEach(stream => {
                            if (!scope.stream.recommended_stream_ids.includes(stream.id)) {
                                filtered.push({
                                    value: stream.id,
                                    text: stream.titleWithTag,
                                });
                            }
                        });
                    }
                    scope.filteredRecommendedStreams = filtered;
                }

                scope.$watchGroup(['streams', 'stream'], updateFilteredRecommendedStreams);

                scope.$watch('addRecommendedStream', newVal => {
                    const streamId = newVal || undefined;
                    if (!streamId || !scope.stream) {
                        return;
                    }

                    const stream = scope.streamMap[streamId];

                    scope.stream.recommended_stream_ids.push(stream.id);
                    updateFilteredRecommendedStreams();

                    scope.addRecommendedStream = undefined;
                });

                scope.removeRecommendedStream = index => {
                    if (index < 0 || index >= scope.stream.recommended_stream_ids.length) {
                        return;
                    }
                    scope.stream.recommended_stream_ids.splice(index, 1);
                    updateFilteredRecommendedStreams();
                };

                //-----------------------------
                // Related Streams Support
                //-----------------------------

                function updateFilteredRelatedStreams() {
                    const filtered = [];

                    if (scope.stream && scope.streams) {
                        updateStreamMap();

                        scope.streams.forEach(stream => {
                            if (!scope.stream.related_stream_ids.includes(stream.id)) {
                                filtered.push({
                                    value: stream.id,
                                    text: stream.titleWithTag,
                                });
                            }
                        });
                    }
                    scope.filteredRelatedStreams = filtered;
                }

                scope.$watchGroup(['streams', 'stream'], updateFilteredRelatedStreams);

                scope.$watch('addRelatedStream', newVal => {
                    const streamId = newVal || undefined;
                    if (!streamId || !scope.stream) {
                        return;
                    }

                    const stream = scope.streamMap[streamId];

                    scope.stream.related_stream_ids.push(stream.id);
                    updateFilteredRelatedStreams();

                    scope.addRelatedStream = undefined;
                });

                scope.removeRelatedStream = index => {
                    if (index < 0 || index >= scope.stream.related_stream_ids.length) {
                        return;
                    }
                    scope.stream.related_stream_ids.splice(index, 1);
                    updateFilteredRelatedStreams();
                };

                scope.createTranslatableExportForAllLessons = () => {
                    scope.translatableLessonExportSet = new TranslatableLessonExportSet(
                        scope.stream.lessonIds,
                        LESSON_TRANSLATION_EXPORT_FORMATS,
                        scope.stream.zipFilenameForTranslatableLessonExportSet,
                        scope.stream,
                    );
                };

                scope.duplicate = () => {
                    if (scope.stream) {
                        DialogModal.alert({
                            content: '<duplicate-content-item content-item="contentItem"></duplicate-content-item>',
                            scope: {
                                contentItem: scope.stream,
                            },
                            classes: ['overflow-visible'],
                            size: 'small',
                        });
                    }
                };

                //-----------------------------
                // Metadata export / import
                //-----------------------------

                scope.exportMetadata = stream => {
                    const exportableMetadata = stream.getExportableMetadata();

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    const url = window.URL.createObjectURL(exportableMetadata.blob);
                    a.href = url;
                    a.download = exportableMetadata.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                };

                scope.importMetadata = file => {
                    const reader = new window.FileReader();
                    reader.onload = e => {
                        scope.stream.importMetadata(e.target.result);
                        safeApply(scope);
                    };

                    // read as text to ensure we properly load the data as UTF-8
                    // https://trello.com/c/Mgl6NaIN we saw some errors thrown in this function with Arabic
                    // files, even though the onload was still successfully called. Catch and silence them.
                    try {
                        reader.readAsText(file);
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                };

                //-----------------------------
                // Cleanup
                //-----------------------------

                scope.$on('$destroy', () => {
                    DialogModal.hideAlerts();
                });
            },
        };
    },
]);
