import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.HasInteractiveCards',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const InteractiveCardsModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsModel',
            );
            const ChallengeOverlayBlankModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
            );

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'interactiveCards', {
                        get() {
                            if (
                                this.model.sharedContentForInteractiveImage &&
                                this.model.sharedContentForInteractiveImage.isA(InteractiveCardsModel)
                            ) {
                                return this.model.sharedContentForInteractiveImage;
                            }
                        },
                    });

                    ChallengesEditorViewModel.include({
                        findOrCreateBlankForChallenge(challenge, overlayEditorViewModel) {
                            if (!this.interactiveCards) {
                                throw new Error('interactiveCards is undefined');
                            }

                            if (!overlayEditorViewModel) {
                                overlayEditorViewModel = this.interactiveCards.overlays[0].editorViewModel;
                            }

                            let blank;

                            // Find the associated blank
                            this.interactiveCards.overlays.forEach(overlay => {
                                if (blank) {
                                    return;
                                }
                                overlay.overlayComponents.forEach(_blank => {
                                    if (_blank.challenge === challenge) {
                                        blank = _blank;
                                    }
                                });
                            });

                            if (!blank) {
                                blank = this._createBlankForChallenge(challenge, overlayEditorViewModel);
                            }

                            challenge.editorViewModel.proxyEditorConfig = {
                                label: 'Blank',
                                editorViewModel: blank.editorViewModel,
                            };

                            return blank;
                        },

                        _createBlankForChallenge(challenge, overlayEditorViewModel) {
                            // Create blank on frame
                            const blank = ChallengeOverlayBlankModel.EditorViewModel.addComponentTo(this.frame).setup()
                                .model;

                            // Set up blank and add to the overlay components on the current overlay
                            blank.challenge = challenge;
                            overlayEditorViewModel.model.overlayComponents.push(blank);

                            return blank;
                        },
                    });
                },
            });
        },
    ],
);
