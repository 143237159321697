import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/next_frame_selector.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfNextFrameSelector', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    () => ({
        restrict: 'E',

        // overriding defaults in EditorDirHelper. I wanted to
        // make it so you just had to pass in the frame, but that led
        // to conflicts with EditorDirHelper
        scope: {
            frame: '<',
            defaultFrame: '<?',
        },

        // get a hold of NgModelController
        require: 'ngModel',

        templateUrl,

        link(scope, _element, _attrs, ngModelCtrl) {
            Object.defineProperty(scope, 'frames', {
                get() {
                    return this.frame.lesson().frames;
                },
            });

            Object.defineProperty(scope, 'nextFrameId', {
                get() {
                    return ngModelCtrl.$modelValue;
                },
                set(val) {
                    ngModelCtrl.$setViewValue(val);
                    ngModelCtrl.$render();
                },
            });

            function resetNextFrameOptions() {
                const frames = scope.frames;
                const defaultLabel = scope.defaultFrame ? scope.defaultFrame.label : 'Next frame';
                const options = [
                    {
                        frameId: undefined,
                        label: ` --- ${defaultLabel} --- `,
                    },
                ];
                const thisFrame = scope.frame;
                const thisFrameLabel = ['*** ', thisFrame.label].join('');
                frames.forEach(frame => {
                    const label = frame === thisFrame ? thisFrameLabel : frame.label;
                    options.push({
                        frameId: frame.id,
                        label,
                    });
                });
                scope.nextFrameOptions = options;
            }

            scope.$watchCollection('frames', resetNextFrameOptions);
            scope.$watch('defaultFrame', resetNextFrameOptions);
        },
    }),
]);
