import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',
        'MaxTextLengthConfig',
        'DeepExtend',

        (
            ComponentEditorViewModel,
            SelectableAnswerEditorViewModel,
            TextEditorViewModel,
            MaxTextLengthConfig,
            DeepExtend,
        ) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel');

                this.supportConfigOption('disallowAddAnswers');

                return {
                    directiveName: 'cf-answer-list-editor',

                    initialize($super, model) {
                        $super(model);
                        this.disallowSwitchContentTypeOnAnswers = false;
                    },

                    setup() {
                        this.model.answer_ids = [];
                        this.model.skin = 'buttons';
                        return this;
                    },

                    trimAnswers(length) {
                        this.model.answers.splice(length);
                    },

                    addAnswer() {
                        const EditorViewModel = SelectableAnswerEditorViewModel;
                        const helper = EditorViewModel.addComponentTo(this.frame).setup();

                        this.model.answers.push(helper.model);

                        return helper;
                    },

                    // Accepts a list of identifiers which map to available
                    // user defined options.  Allowed identifiers are
                    //
                    // * RandomizeAnswerOrder
                    // * forceSingleColumn
                    //
                    // If the last argument is an object, it will be deep-merged
                    // with the default options.  For example:
                    //
                    //     answerListEditorViewModel.setUserDefinedOptions('RandomizeAnswerOrder', {
                    //         RandomizeAnswerOrder: {
                    //             defaultValue: {}
                    //         }
                    //     });
                    setUserDefinedOptions(...args) {
                        const identifiers = Array.prototype.slice.call(args, 0);
                        let overrides = {};
                        if (typeof _.last(identifiers) !== 'string') {
                            overrides = identifiers.pop();
                        }
                        let availableOptions = {
                            RandomizeAnswerOrder: {
                                type: 'toggleBehavior',
                                behavior: 'RandomizeAnswerOrder',
                                title: 'Show answers in random order.',
                                valueWhenOn: {},
                            },
                            forceSingleColumn: {
                                type: 'radioSetProperty',
                                title: 'Use wide buttons',
                                radios: [
                                    [undefined, 'Auto'],
                                    [true, 'Yes'],
                                    [false, 'No'],
                                ],
                                get: () => this.model.force_single_column,
                                set: val => {
                                    this.model.force_single_column = val;
                                    return val;
                                },
                            },
                        };

                        availableOptions = DeepExtend.extend(availableOptions, overrides);

                        const userDefinedOptions = [];
                        identifiers.forEach(identifier => {
                            const entry = availableOptions[identifier];
                            if (!entry) {
                                throw new Error(`"${identifier}" is not an available option.`);
                            }
                            userDefinedOptions.push(entry);
                        });

                        return this.setConfig({
                            userDefinedOptions,
                        });
                    },
                };
            }),
    ],
);
