import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/answer/selectable_answer/selectable_answer_editor_inline.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfSelectableAnswerEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                Object.defineProperty(scope, 'showRelatedBlankStuff', {
                    get() {
                        return this.editorViewModel.relatedBlankLabel && this.editorViewModel.relatedBlankChallenge;
                    },
                });

                Object.defineProperty(scope, 'locked', {
                    get() {
                        if (!this.editorViewModel.relatedBlankChallenge) {
                            return false;
                        }
                        if (this.editorViewModel.relatedBlankChallenge.unlink_blank_from_answer) {
                            return false;
                        }
                        return true;
                    },
                });

                Object.defineProperty(scope, 'lockedClass', {
                    get() {
                        if (this.locked) {
                            return 'lock';
                        }
                        return 'unlock';
                    },
                });
            },
        }),
]);
