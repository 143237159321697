import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/pin_and_publish_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('pinAndPublishLesson', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lesson: '<',
                shouldPublish: '<',
                saveLesson: '<',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.metadata = {
                    should_publish: scope.shouldPublish,
                    should_pin: true,
                };

                scope.cancel = () => {
                    DialogModal.hideAlerts();
                };

                scope.save = () => {
                    scope.submitClicked = true;
                    scope.saveLesson(scope.metadata).then(
                        () => {
                            DialogModal.hideAlerts();
                        },
                        () => {
                            DialogModal.hideAlerts();
                        },
                    );
                };
            },
        };
    },
]);
