import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/edit_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editLesson', [
    '$injector',
    function factory($injector) {
        const Lesson = $injector.get('Lesson');
        const $location = $injector.get('$location');
        const RecentlyEditedLessons = $injector.get('RecentlyEditedLessons');
        const $timeout = $injector.get('$timeout');
        const EventLogger = $injector.get('EventLogger');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lessonId: '@',
            },
            link(scope, elem) {
                const params = {
                    filters: {
                        published: false,
                    },
                    'except[]': ['lesson_progress'],
                };

                const version = $location.search().version;
                if (version) {
                    params.filters.version_id = version;
                }

                /*
                    For the editor lesson sessions report, we want to know how
                    long someone was active in the editor, but lesson:frame:unload events
                    might be too infrequent for that and click events are too frequent
                    to read efficiently, so we log a ping event every minute.
                */
                // listen for keydown in case someone is typing in a text box

                function ping() {
                    if (scope.playerViewModel) {
                        EventLogger.log('lesson:editor:ping', scope.playerViewModel.logInfo());
                    }
                }

                let pingTimeout;

                function onActivity() {
                    if (!pingTimeout && scope.playerViewModel) {
                        ping();
                        pingTimeout = $timeout(() => {
                            ping();
                            pingTimeout = undefined;
                        }, 60 * 1000);
                    }
                }

                // log an initial ping so that we can start the clock on the editor session
                scope.$watch('playerViewModel', onActivity);
                $(elem).on('mousedown.editLesson keydown.editLesson', onActivity);

                Lesson.show(scope.lessonId, params).then(response => {
                    const lesson = response.result;

                    ensureAllTextFormatted(lesson);

                    new RecentlyEditedLessons().add(lesson);

                    scope.playerViewModel = lesson.createPlayerViewModel({
                        editorMode: true,
                    });
                });

                function ensureAllTextFormatted(lesson) {
                    if (!lesson) {
                        return;
                    }
                    /*
                        Since we have frames in the wild that existed before we implemented
                        pre-rendered text formatting, we need to make sure that loading up
                        a lesson automagically formats all the text in that lesson, so that
                        if it is saved (and published), it will have formatted text.
                    */
                    _.chain(lesson.frames).invokeMap('reify').invokeMap('formatAllText', false).value(); // force disabled
                }

                scope.$on('$destroy', () => {
                    // ping one more time on destroy so the user gets credit
                    // for the last minute they were in the lesson
                    ping();
                    $timeout.cancel(pingTimeout);
                    $(elem).off('.editLesson');
                });
            },
        };
    },
]);
