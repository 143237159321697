import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/edit_frame_panel.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editFramePanel', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const DialogModal = $injector.get('DialogModal');
        const Frame = $injector.get('Lesson.FrameList.Frame');
        const Lesson = $injector.get('Lesson');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                frameViewModel: '<',
            },
            link(scope, elem) {
                Object.defineProperty(scope, 'frame', {
                    get() {
                        return this.frameViewModel?.frame;
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return this.frame?.lesson();
                    },
                });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'playerViewModel', {
                    get() {
                        return this.frameViewModel?.playerViewModel;
                    },
                });

                // mainUiComponentEditorViewModel will be undefined for any frames other than FlexibleComponentized.
                // It's just used to display userDefinedOptions, so once we need those with any other kinds of frame,
                // we'll have to think about how to make that work.
                Object.defineProperty(scope, 'mainUiComponentEditorViewModel', {
                    get() {
                        return this.frame?.mainUiComponentEditorViewModel;
                    },
                });

                Object.defineProperty(scope, 'showNextFrameSelector', {
                    get() {
                        if (!this.lesson) return false;

                        if (!this.lesson.supportsFrameNavigator) return false;

                        // When hasBranching is true, different answer choices are assigned to different target frames. In that case,
                        // a separate "Branching" panel appears to support setting up the branching rules. So, in that case
                        // we do not show the next frame selector.
                        if (this.frame?.hasBranching) return false;

                        return true;
                    },
                });

                scope.showJson = () => {
                    const frameJson = angular.toJson(scope.frame, true).replace(/</g, '&lt;').replace(/>/g, '&gt;');
                    DialogModal.alert({
                        content: `<pre>${frameJson}</pre>`,
                        size: 'large',
                    });
                };

                scope.$watch('jsonImport', value => {
                    if (value) {
                        // regenerate ids on import
                        delete value.id;
                        const newFrame = Frame.new(value);

                        scope.frameViewModel.playerViewModel.activeFrame = undefined;
                        scope.frameViewModel.playerViewModel.swapFrame(scope.frameViewModel.frame, newFrame);
                        scope.frameViewModel.playerViewModel.activeFrame = newFrame;
                    }
                    scope.jsonImport = undefined;
                });

                scope.importJsonText = () => {
                    const json = scope.sharedProps.pastedJson;
                    const attrs = angular.fromJson(json);
                    scope.jsonImport = attrs;
                };

                scope.expandAll = () => {
                    elem.find('.panel-collapse').collapse('show');
                };

                scope.collapseAll = () => {
                    elem.find('.panel-collapse').collapse('hide');
                };

                scope.showEditorHints = () => {
                    DialogModal.alert({
                        content: '<editor-hints></editor-hints>',
                        classes: ['editor-hints'],
                    });
                };

                scope.showGrade = () => {
                    const lesson = scope.frameViewModel.frame.lesson();

                    DialogModal.alert({
                        title: lesson.title,
                        scope: {
                            lesson,
                        },
                        classes: ['lesson-grader'],
                        content: '<grade-frame-list lesson="lesson"></grade-frame-list>',
                    });
                };

                let englishVersionPromise;

                function loadEnglishVersion() {
                    if (!englishVersionPromise) {
                        englishVersionPromise = Lesson.index({
                            filters: {
                                locale_pack_id: scope.lesson.localePackId,
                                locale: 'en',
                                published: false,
                                in_users_locale_or_en: false,
                            },
                            'fields[]': ['frames', 'lesson_type'],
                        }).then(response => response.result[0]);
                    }

                    return englishVersionPromise;
                }

                scope.revertToEnglish = () => {
                    if (
                        $window.confirm(
                            'Are you sure you want to revert this frame back to looking like the current English version?',
                        )
                    ) {
                        const lesson = scope.lesson;
                        const frame = scope.frame;
                        const playerViewModel = scope.frameViewModel.playerViewModel;
                        playerViewModel.activeFrame = undefined;
                        loadEnglishVersion().then(enLesson => {
                            const index = frame.index();

                            if (!enLesson) {
                                $window.alert('No English lesson found.');
                                return;
                            }
                            // See the logic in the next block. I don't know how we determine that it's the "same" frame
                            // when there is no mainUiComponent
                            if (!frame.mainUiComponent) {
                                $window.alert('This frame type cannot be reverted to English.');
                                return;
                            }

                            const enFrame = enLesson.frames[index].reify();
                            if (!enFrame || enFrame.mainUiComponent.id !== frame.mainUiComponent.id) {
                                $window.alert('Frame not found in same position in English lesson.');
                                return;
                            }

                            const frameJson = _.extend(enFrame.asJson(), {
                                id: frame.id,
                            });
                            const newFrame = Frame.new(frameJson);
                            lesson.frames[index] = newFrame;
                            newFrame.$$embeddedIn = lesson;
                            playerViewModel.activeFrame = newFrame;
                        });
                    }
                };
            },
        };
    },
]);
