import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.SequentialOrConsumableMixin',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
        (AModuleAbove, AnswerListModel) =>
            new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    ChallengesEditorViewModel.supportConfigOption('supportsSequentialAndConsumable');

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'sequentialOrConsumable', {
                        get() {
                            if (!this.config.supportsSequentialAndConsumable) {
                                return undefined;
                            }

                            // eslint-disable-next-line no-unused-vars
                            let sequential = true;
                            let consumable = true;

                            /*
                        Sequential means that each time a new challenge is activated,
                        all answers are reset (i.e. correct styling removed and all
                        answers enabled).  Sequential mode must have a shared
                        answer list. (although we probably want to change this so sequential mode
                        can possibly have a shared answer list)

                        Consumable means that there is a shared answer list and answers
                        are not reset when a challenge is activated.
                    */
                            this.model.challenges.forEach(challenge => {
                                if (!challenge.includesBehavior('ResetAnswersOnActivated')) {
                                    sequential = false;
                                } else if (challenge.includesBehavior('ResetAnswersOnActivated')) {
                                    consumable = false;
                                }
                            });

                            if (!this.sharedAnswerList) {
                                consumable = false;
                            }

                            if (consumable) {
                                return 'consumable';
                            }
                            return 'sequential';
                        },

                        set(val) {
                            if (!this.config.supportsSequentialAndConsumable) {
                                throw new Error(
                                    'Cannot set sequential/consumable without config.sequentialOrConsumable',
                                );
                            }

                            if (val === this.sequentialOrConsumable) {
                                return;
                            }

                            // keep track of all of the current correct answers and all of the current
                            // confusers
                            const correctAnswersForChallenges = {};
                            const confusers = [];

                            this.model.challenges.forEach(challengeModel => {
                                const challengeEditorViewModel = challengeModel.editorViewModel;
                                challengeEditorViewModel.model.answers.forEach(answer => {
                                    if (answer === challengeEditorViewModel.correctAnswer) {
                                        correctAnswersForChallenges[challengeModel.id] =
                                            challengeEditorViewModel.correctAnswer;
                                    } else {
                                        confusers.push(answer);
                                    }
                                });
                            });

                            if (val === 'consumable' && !this.sharedAnswerList) {
                                this.sharedAnswerList = AnswerListModel.EditorViewModel.addComponentTo(
                                    this.frame,
                                ).setup().model;
                            } else if (val === 'sequential' && this.sharedAnswerList) {
                                this.sharedAnswerList = undefined;
                            }

                            // Now that we've moved answerLists around, reset all correctAnswers
                            this.model.challenges.forEach(challengeModel => {
                                challengeModel.editorViewModel.correctAnswer =
                                    correctAnswersForChallenges[challengeModel.id];
                            });

                            // If there's a shared answerList (consumable mode), we can also put the confusers on there.
                            // If not, we can't know where the confusers belong, so we just throw them away
                            if (this.sharedAnswerList) {
                                confusers.forEach(confuser => {
                                    // if something is a confuser and a correct answer, do not add it again
                                    if (!this.sharedAnswerList.answers.includes(confuser)) {
                                        this.sharedAnswerList.answers.push(confuser);
                                    }
                                });
                            }

                            this._applySequentialOrConsumableToChallenges(val);
                            this.model.triggerCallbacks('set:sequentialOrConsumable', val);
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'sequential', {
                        get() {
                            return this.sequentialOrConsumable === 'sequential';
                        },
                        set(val) {
                            if (val) {
                                this.sequentialOrConsumable = 'sequential';
                                return;
                            }
                            throw new Error(
                                'sequential setter can only set sequential to true.  Use `consumable = true` instead.',
                            );
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'consumable', {
                        get() {
                            return this.sequentialOrConsumable === 'consumable';
                        },
                        set(val) {
                            if (val) {
                                this.sequentialOrConsumable = 'consumable';
                                return;
                            }
                            throw new Error(
                                'consumable setter can only set sequential to true.  Use `sequential = true` instead.',
                            );
                        },
                    });

                    ChallengesEditorViewModel.onConfigChange('supportsSequentialAndConsumable', function (configValue) {
                        if (configValue) {
                            if (!this.config.supportsSharedAnswerList) {
                                throw new Error(
                                    'config.supportsSequentialAndConsumable requires config.supportsSharedAnswerList',
                                );
                            }

                            this._applySequentialOrConsumableToChallenges = sequentialOrConsumable => {
                                if (this._sequentialConsumableListener) {
                                    this._sequentialConsumableListener.cancel();
                                    this._sequentialConsumableListener = undefined;
                                }

                                this._sequentialConsumableListener = this.model.challenges.on(
                                    'childAdded',
                                    challenge => {
                                        if (sequentialOrConsumable === 'sequential') {
                                            challenge.behaviors.ResetAnswersOnActivated = {};
                                        } else if (sequentialOrConsumable === 'consumable') {
                                            if (!this.sharedAnswerList) {
                                                throw new Error(
                                                    'Cannot set challenge to consumable because there is no shared answer list.',
                                                );
                                            }
                                            challenge.behaviors.ResetAnswersOnActivated = undefined;
                                        }
                                    },
                                );
                            };

                            this._applySequentialOrConsumableToChallenges(this.sequentialOrConsumable);
                        } else if (this._sequentialConsumableListener) {
                            this._sequentialConsumableListener.cancel();
                            this._sequentialConsumableListener = undefined;
                        }
                    });
                },
            }),
    ],
);
