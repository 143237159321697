import angularModule from 'Editor/angularModule/scripts/editor_module';

function imagesByLabel(ImageModel, frame) {
    const map = {};
    frame.componentsForType(ImageModel).forEach(image => {
        if (image.label) {
            map[image.label] = image;
        }
    });

    return map;
}

function replaceInlineImagesInText(injector, frame, text, fn) {
    const ImageModel = injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
    const imageMap = imagesByLabel(ImageModel, frame);

    return text.replace(/(!!?)\[(.*?)\]({[^}]*})?/g, (_, prefix, imageLabel, styleBlock) => {
        const image = imageMap[imageLabel];

        // When the image is defined with `![label]`, add the `inline` class.
        // When `!![label]`, add the `display` class. This class is added
        // to any others that are defined explicitly by the user with `!![label]{someClass}
        const styleFromPrefix = {
            '!': 'inline',
            '!!': 'display',
        }[prefix];
        styleBlock = styleBlock || '{}';
        styleBlock = styleBlock.replace(/^{/, `{${styleFromPrefix},`);

        // Determine which size image file we need to load.  We use smaller
        // images in the `inline` case.
        const context = styleBlock.match(/inline/) ? 'inline' : 'display';
        return fn(context, image, styleBlock);
    });
}

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesInlineImagesEditor', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');

        // Before markdown processing, we convert something like
        //
        //      `!![label]{left,height:32}`
        // to
        //
        //      `![http://path/to/image]{display,left,height:32}`
        function processInlineImagesPreMarkdown(text) {
            const splitOnSpecialBlock = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.TextHelpers',
            ).splitOnSpecialBlock;

            // ProcessesInlineImages requires that markdown runs after it, so we know that it
            // has not run yet
            const isMarkdownFormatted = false;

            text = splitOnSpecialBlock(text, isMarkdownFormatted, (textBlock, inSpecialBlock) => {
                if (inSpecialBlock) {
                    return textBlock;
                }

                return replaceInlineImagesInText($injector, this.frame, textBlock, (context, image, styleBlock) => {
                    if (image) {
                        /*
                            This is going to be called when the editor is writing the lesson, so we don't want
                            urlForContext to rely on the current pixel ratio of the screen that the editor is working on.
                            We want to optimize for the screens that students will later be looking at the lesson on.
                            We are going to err on the side of large file sizes and better quality images by optimizing
                            for the highest pixel ratio.
                        */
                        const imageUrl = image.urlForContext(context, { forceMaxPixelRatio: true });
                        return `![](${imageUrl})${styleBlock}`;
                    }
                    return '';
                });
            });

            return text;
        }

        // After markdown processing, we convert something like
        //
        //      `<img alt="" src="http://someimage.png">{display,left,height:32}`
        //
        // to
        //
        //      `<img alt="" storable-image="http://someimage.png" class="display left" style="max-height:32px;" />`
        function processInlineImagesPostMarkdown(text) {
            const replaced = text.replace(
                /(<img )([^src]+) src="([^"]+)"([^>]*>)({[^}]*})/g,
                (_s, pre1, pre2, url, closeTag, styleBlock) => {
                    let styleAttr = '';

                    // NOTE: We did not used to add any classes to inline image,.
                    // and we have not migrated all the old content, so there are
                    // still old inline images in content that do not include any
                    // classes on them at all.  For more details, see this comment:
                    // https://trello.com/c/jb0NQLmf/4168-feat-fix-in-line-image-bug-add-new-display-image-feature#comment-5f5be3aeea982f619e512531

                    // `height` is a special thing in the styleBlock.  If it's
                    // there, then extract the height in pixels and build a
                    // style attribute to add to the img tag
                    const styleString = styleBlock.replace(/height:([\d.]+),?/g, (_, height) => {
                        styleAttr = `style="max-height: ${height}px"`;
                        return '';
                    });

                    // Support a whitelist of custom classes.  Throw
                    // away any unsupported classes.
                    const classesString = styleString
                        .replace(/[{}]/g, '')
                        .split(',')
                        .map(e => e.trim())
                        .filter(e => ['inline', 'display', 'left', 'right', 'short'].includes(e))
                        .join(' ');

                    const classesAttr = `class="${classesString}"`;

                    return `${pre1}${pre2} storable-image="${url}" ${classesAttr} ${styleAttr}${closeTag}`;
                },
            );
            return replaced;
        }

        return new AModuleAbove({
            included(TextEditorViewModel) {
                // set up a callback to be run whenever a TextModel is initialized
                TextEditorViewModel.setCallback('after', 'initialize', function afterInit() {
                    const editorViewModel = this;

                    this.model.on('behavior_added:ProcessesInlineImages', () => {
                        if (!editorViewModel.model.includesBehavior('ProcessesMarkdown')) {
                            throw new Error('ProcessesInlineImages requires ProcessesMarkdown');
                        }

                        editorViewModel.formatters.add('inlineImages', processInlineImagesPreMarkdown);
                        editorViewModel.formatters.add('inlineImagesPostMarkdown', processInlineImagesPostMarkdown);
                    });
                });
            },
        });
    },
]);
