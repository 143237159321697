import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/archive_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('archiveLesson', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lesson: '<',
            },
            link(scope) {
                scope.close = () => {
                    $injector.get('DialogModal').hideAlerts();
                };

                scope.archive = () => {
                    scope.submitting = true;
                    scope.lesson.archived = true;
                    scope.lesson.save().then(() => {
                        ngToast.create({
                            content: 'Lesson archived successfully.',
                            className: 'success',
                        });
                    });
                    scope.close();
                };
            },
        };
    },
]);
