import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',

        ComponentEditorViewModel =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorModel',
                );

                return {
                    setup() {
                        this.model.expectedAnswerMatchers = [];
                        return this;
                    },

                    removeExpectedAnswersFor(answer) {
                        this.model.expectedAnswerMatchers.forEach(answerMatcherModel => {
                            if (answerMatcherModel.matches(answer)) {
                                answerMatcherModel.editorViewModel.remove();
                            }
                        });
                    },
                };
            }),
    ],
);
