import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/seo_description_hints.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('seoDescriptionHints', [
    function factory() {
        return {
            templateUrl,
            scope: {},
            restrict: 'E',
            link(scope) {
                scope.currentTab = 'formatting';
            },
        };
    },
]);
