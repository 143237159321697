import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/reorder_list_item_buttons.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfReorderListItemButtons', [
    () => ({
        restrict: 'E',

        scope: {
            item: '<',
            onReordered: '&',
            collection: '<',
        },

        templateUrl,

        link(scope, element, attrs) {
            Object.defineProperty(scope, 'currentIndex', {
                get() {
                    const index = scope.collection ? scope.collection.indexOf(scope.item) : -1;
                    return index > -1 ? index : undefined;
                },
            });

            Object.defineProperty(scope, 'maxIndex', {
                get() {
                    if (angular.isDefined(attrs.maxIndex)) {
                        return scope.$parent.$eval(attrs.maxIndex);
                    }
                    return scope.collection.length - 1;
                },
            });

            Object.defineProperty(scope, 'minIndex', {
                get() {
                    if (angular.isDefined(attrs.minIndex)) {
                        return scope.$parent.$eval(attrs.minIndex);
                    }
                    return 0;
                },
            });

            scope.move = offset => {
                // sanity check. can't re-order something which doesn't exist
                const currentIndex = scope.currentIndex;
                if (angular.isUndefined(scope.currentIndex)) {
                    throw new Error(`Unable to find item ${scope.item} in collection for re-ordering.`);
                }

                // constrain to collection bounds
                let targetIndex = currentIndex + offset;
                if (targetIndex < scope.minIndex) {
                    targetIndex = scope.minIndex;
                } else if (targetIndex > scope.maxIndex) {
                    targetIndex = scope.maxIndex;
                }

                // remove existing element
                scope.collection.splice(currentIndex, 1);

                // update collection
                scope.collection.splice(targetIndex, 0, scope.item);

                if (scope.onReordered) {
                    scope.onReordered();
                }
            };

            scope.canMoveUp = () => {
                if (angular.isUndefined(scope.currentIndex)) {
                    return false;
                }
                return scope.currentIndex > scope.minIndex;
            };

            scope.canMoveDown = () => {
                if (angular.isUndefined(scope.currentIndex)) {
                    return false;
                }
                return scope.currentIndex !== -1 && scope.currentIndex < scope.maxIndex;
            };
        },
    }),
]);
