import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.ooDirective('editLessonTypeBase', [
    '$injector',

    function factory($injector) {
        const DirectiveBase = $injector.get('DirectiveBase');
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const DialogModal = $injector.get('DialogModal');
        const $rootScope = $injector.get('$rootScope');
        const ngToast = $injector.get('ngToast');
        const $filter = $injector.get('$filter');
        const Lesson = $injector.get('Lesson');
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const UnsavedChangesWarning = $injector.get('UnsavedChangesWarning');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return DirectiveBase.subclass({
            restrict: 'E',
            scope: {
                // since scope.playerViewModel gets inside of lessonLoaded,
                // two-way binding seems to make sense here.  It didn't seem
                // like anything broke when we tried one-way though.
                playerViewModel: '=',
            },
            link(scope) {
                scope.previewMode = scope.playerViewModel.previewMode;

                scope.playerViewModel.started = true;

                // we require negative-lookbehind regexes, which only chrome has for now.
                // this is required for for some regexes in text_editor_view_model.js#addTransN
                try {
                    const regexWithNegativeLookBehind = /(?<!foobar)testing/;
                    'testing'.match(regexWithNegativeLookBehind);
                    scope.enableAdvancedTools = true;
                } catch (err) {
                    scope.enableAdvancedTools = false;
                }

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.maxVersionsToShow = {
                    n: 6,
                };

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return scope.playerViewModel && scope.playerViewModel.lesson;
                    },
                });

                // prevent submit either because we are currently submitting OR the user doesnt have perms to duplicate
                Object.defineProperty(scope, 'preventDuplication', {
                    get() {
                        if (!scope.currentUser) {
                            return true;
                        }

                        if (!scope.currentUser.canEditLesson(scope.lesson)) {
                            return true;
                        }

                        if (scope.duplicatedLesson && scope.duplicatedLesson.blockSaving) {
                            return true;
                        }

                        if (scope.lesson.blockSaving) {
                            return true;
                        }

                        return false;
                    },
                });

                // prevent submit either because we are currently submitting OR the user doesnt have perms to delete
                Object.defineProperty(scope, 'preventDelete', {
                    get() {
                        return scope.$preventDelete || !scope.currentUser.canEditLesson(scope.lesson);
                    },
                    set(val) {
                        scope.$preventDelete = val;
                    },
                });

                scope.preview = () => {
                    scope.previewMode = !scope.previewMode;
                    scope.lesson.resetKeyTerms();

                    // Destroy the current lesson play (a new one
                    // will be created automatically by the watcher
                    // in playerViewModel.destroyed watcher in this file).  This ensures that the state
                    // of the hexagons is reset correctly, and that if the
                    // first frame is a no_interaction frame, it
                    // still fires finish after we start updating the
                    // hexagons
                    scope.playerViewModel.destroy();
                };

                const stopWatchingForExitPreviewMode = scope.$on('editLesson:exitPreviewMode', () => {
                    if (scope.previewMode) {
                        scope.preview();
                    }
                });

                function onSaved(response) {
                    // save_warnings is either undefined or an array with
                    // at least one element
                    if (response.meta && response.meta.save_warnings) {
                        const issuesText = response.meta.save_warnings
                            .reduce((p, c) => {
                                if (!p.includes(c)) {
                                    p.push(c);
                                }
                                return p;
                            }, [])
                            .map(warning => `<li>${warning}</li>`)
                            .join('');
                        DialogModal.alert({
                            title: 'Lesson Warnings',
                            content: `<p>Your lesson has been saved, but you cannot pin until the following issues are fixed:</p><ul>${issuesText}</ul>`,
                        });
                    }

                    return scope.lesson.entity_metadata.save().then(() => {
                        // display a brief update message
                        ngToast.create({
                            content: `Lesson successfully saved at ${$filter('amDateFormat')(
                                response.result.updated_at * 1000,
                                'h:mm:ss a',
                            )}`,
                        });

                        return response;
                    });
                }

                scope.save = function save(metadata = {}) {
                    if (scope.lesson) {
                        // allow multiple attempts to save batch in a single follow-up request
                        if (this.lesson.$$saving && !this._subsequentSavePromise) {
                            this._subsequentSavePromise = this.lesson.$$savePromise.then(() => {
                                this._subsequentSavePromise = false;
                                return this.save().then(onSaved);
                            });
                        }

                        // we're already saving, return the promise
                        if (this.lesson.$$saving) {
                            return this._subsequentSavePromise;
                        }

                        // save the lesson, and do not allow default timeout
                        return this.lesson.save(metadata, { timeout: undefined }).then(onSaved);
                    }

                    return $q.when();
                };

                scope.showPinAndPublishDialog = shouldPublish => {
                    DialogModal.alert({
                        content:
                            '<pin-and-publish-lesson lesson="lesson" should-publish="shouldPublish" save-lesson="saveLesson"></pin-and-publish-lesson>',
                        scope: {
                            lesson: scope.lesson,
                            shouldPublish: shouldPublish || false,
                            saveLesson: scope.save,
                        },
                        classes: ['pin-and-publish-modal'],
                    });
                };

                scope.showUnpublishDialog = () => {
                    DialogModal.alert({
                        content: '<unpublish-lesson lesson="lesson"></unpublish-lesson>',
                        scope: {
                            lesson: scope.lesson,
                        },
                        size: 'small',
                    });
                };

                scope.duplicate = () => {
                    if (scope.lesson) {
                        DialogModal.alert({
                            content: '<duplicate-content-item content-item="contentItem"></duplicate-content-item>',
                            scope: {
                                contentItem: scope.lesson,
                            },
                            classes: ['overflow-visible'],
                            size: 'small',
                        });
                    }
                };

                scope.$on('$destroy', () => {
                    stopWatchingForExitPreviewMode();
                });

                scope.launchVersion = version => {
                    if (version.isMostRecentVersion) {
                        return;
                    }
                    NavigationHelperMixin.loadUrl(
                        `/editor/lesson/${scope.lesson.id}/edit?version=${version.version_id}`,
                        '_blank',
                    );
                };

                scope.restoreVersion = function restoreVersion(version) {
                    if (
                        !$window.confirm(
                            `Are you sure you want to save a new version using the version from ${scope.lesson.formatDate(
                                version.updated_at,
                            )}?`,
                        )
                    ) {
                        return;
                    }

                    function lessonLoaded(response) {
                        // retrieve old lesson
                        const oldLesson = response.result;

                        // save over the new lesson with the old one
                        oldLesson.updated_at = scope.lesson.updated_at;
                        oldLesson.old_version = false; // prevent UI from changing during save
                        const newPlayerViewModel = oldLesson.createPlayerViewModel({
                            editorMode: true,
                            previewMode: false,
                        });
                        scope.playerViewModel = newPlayerViewModel;

                        // save the current lesson
                        scope.save();
                    }

                    function onLessonLoadError() {
                        // DialogModal.alert('There was an issue trying to load the target lesson version. Please try again.');
                    }

                    // Load data from this old version, then save a new version using it.
                    Lesson.show(scope.lesson.id, {
                        filters: {
                            published: false,
                            version_id: version.version_id,
                        },
                        'except[]': ['lesson_progress'],
                    }).then(lessonLoaded.bind(this), onLessonLoadError);
                };

                scope.versionHistoryFilter = {
                    criteria: 'all',
                };
                scope.filterVersions = criteria => item => {
                    if (criteria === 'all') {
                        return true;
                    }
                    if (criteria === 'pinned') {
                        return item.pinned;
                    }
                    if (criteria === 'published') {
                        return item.was_published;
                    }
                    return false;
                };

                // This is probably a hack, but preview mode uses the show-lesson directive.  When that directive
                // is destroyed, it calls playerViewModel.destroy.  But we don't want to keep using a destroyed playerViewModel
                // (the practical problem is that it has been delinked from the window).  So we watch for the playerViewModel
                // to get destroyed and, when it is, we create a new one.
                //
                // Maybe it would be better if we didn't destroy the playerViewModel at all when leaving previewMode, but I
                // don't have a good way to do that while still making sure it happens in the player.
                scope.$watch('playerViewModel.destroyed', val => {
                    if (val) {
                        const activeFrame = scope.playerViewModel.frameThatWasActiveWhenDestroyed;
                        const lesson = scope.playerViewModel.lesson;
                        scope.playerViewModel = lesson.createPlayerViewModel({
                            editorMode: !scope.previewMode,
                            previewMode: scope.previewMode,
                        });

                        if (activeFrame) {
                            scope.playerViewModel.activeFrame = activeFrame;
                        }

                        if (
                            scope.playerViewModel &&
                            scope.playerViewModel.editorMode &&
                            !scope.playerViewModel.previewMode
                        ) {
                            scope.playerViewModel.linkToWindow($injector);
                        }
                    }
                });

                // When we enter previewMode, we want to delink the playerViewModel from the header.
                // When we are in editorMode, we re-link it.
                scope.$watchGroup(
                    ['playerViewModel', 'playerViewModel.previewMode', 'playerViewModel.editorMode'],
                    () => {
                        const playerViewModel = scope.playerViewModel;
                        if (playerViewModel && playerViewModel.editorMode && !playerViewModel.previewMode) {
                            playerViewModel.linkToWindow($injector);
                        } else if (playerViewModel) {
                            playerViewModel.delinkFromWindow($injector);
                        }
                    },
                );

                UnsavedChangesWarning.warnOnUnsavedChanges(scope, 'lesson');

                scope.showReviewToolHints = $event => {
                    $event.stopPropagation();
                    DialogModal.alert({
                        title: 'Review Tool',
                        content:
                            '<p><strong>To Open in Numbers (Mac OS X):</strong>' +
                            ' To import into Numbers on Mac OS X, rename the file to have a <code>.txt</code> extension.' +
                            ' Then drag and drop the file onto the Numbers icon.</p>' +
                            ' <p><strong>To Open in Excel (Mac OS X):</strong>' +
                            ' To import into Excel on Mac OS X, drag and drop the file onto the Excel icon.' +
                            ' Note that Excel for Mac has problems with character encodings, ' +
                            ' so things like em-dashes (&mdash;) will likely not display correctly.</p>',
                    });
                };

                scope.exportToTSV = () => {
                    // Generate arrays of values to convert to TSV
                    const lessonInfoColumns = ['Lesson Title', 'Tag', 'Lesson ID'].join('\t');
                    const lessonInfo = [scope.lesson.title, scope.lesson.tag, scope.lesson.id].join('\t');

                    const framesColumns = [
                        'Frame',
                        'Frame Type',
                        'Component Type',
                        'Text ID',
                        'Text (en-US)',
                        'Translation',
                    ].join('\t');
                    const frames = [];
                    scope.lesson.frames.forEach(frame => {
                        const frameIndex = frame.displayIndex();

                        // Build hash of ID to referencing component(s) types
                        const idToReferencingComponents = {};
                        frame.components.forEach(component => {
                            const references = component.referencedComponents();
                            let type = component.type;
                            // Make the type prettier for output
                            type = type.replace(/Model/, '').replace(/TextImageInteractive|Challenges/, 'Main Text');
                            references.forEach(reference => {
                                const referencing = idToReferencingComponents[reference.id] || [];
                                referencing.push(type);
                                idToReferencingComponents[reference.id] = referencing;
                            });
                        });

                        // Iterate over all text components and create entries in tsv
                        const textComponents = frame.componentsForType(TextModel);
                        textComponents.forEach(textModel => {
                            frames.push(
                                [
                                    frameIndex,
                                    frame.editor_template,
                                    idToReferencingComponents[textModel.id].join(','),
                                    textModel.id,
                                    textModel.text.replace(/\n/g, '\\n'),
                                    '',
                                ].join('\t'),
                            );
                        });
                    });

                    const data = [lessonInfoColumns, lessonInfo, framesColumns].concat(frames).join('\n');

                    const tsvContent = `data:text/tab-separated-values;charset=utf-8,${data}`;
                    const encodedUri = encodeURI(tsvContent);
                    NavigationHelperMixin.loadUrl(encodedUri, '_blank');
                };

                scope.$watch('lesson', lesson => {
                    scope.selectedVersionIds = {};
                    const history = lesson.version_history || [];
                    history.slice(0, 2).forEach(version => {
                        scope.selectedVersionIds[version.version_id] = true;
                    });
                    scope.loadedVersions = {};
                });

                scope.$watch('lesson.$$saving', () => {
                    if (!scope.lesson) {
                        return;
                    }

                    // Each time the lesson is saved, we have to unselect
                    // any lessons that have been removed from the lesson, because
                    // otherwise there is no way to unselect them and get only
                    // two versions checked again.
                    _.forEach(scope.selectedVersionIds, (val, key) => {
                        // if the version has been removed from the lesson
                        // (because of pinning), then unselect it
                        if (!_.chain(scope.lesson.version_history).map('version_id').includes(key).value()) {
                            delete scope.selectedVersionIds[key];
                        }

                        // delete everything set to false, just to make it
                        // easier to count once we're done
                        if (!val) {
                            delete scope.selectedVersionIds[key];
                        }
                    });

                    scope.selectedVersionCount = _.size(scope.selectedVersionIds);
                });

                scope.$watchCollection('selectedVersionIds', selectedVersionIds => {
                    scope.selectedVersionCount = 0;
                    angular.forEach(selectedVersionIds || [], val => {
                        if (val) {
                            scope.selectedVersionCount += 1;
                        }
                    });
                });

                scope.showVersionDiff = () => {
                    scope.selectedVersions = [];

                    const version_ids = [];
                    angular.forEach(scope.selectedVersionIds, (val, id) => {
                        if (val) {
                            version_ids.push(id);
                        }
                    });

                    if (version_ids.length !== 2) {
                        throw new Error(`Trying to show a version diff with ${version_ids.length} versions selected.`);
                    }

                    const path = ['', 'editor', 'lesson', scope.lesson.id, 'diff', version_ids[0], version_ids[1]].join(
                        '/',
                    );

                    scope.diffWindow = NavigationHelperMixin.loadUrl(path, '_blank');
                };
            },
        });
    },
]);
