import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeEditorViewModel',
    [
        '$injector',
        $injector => {
            const ChallengeEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeEditorViewModel',
            );
            const ThisOrThatChallengeEditorTemplate = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.ThisOrThat',
            );
            const BasicMultipleChoiceEditorTemplate = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BasicMultipleChoice',
            );
            const FillInTheBlanksEditorTemplate = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.FillInTheBlanks',
            );
            const BlanksOnImageEditorTemplate = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksOnImage',
            );
            const MatchingEditorTemplate = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.Matching',
            );
            const AnswerListEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListEditorViewModel',
            );
            const SelectableAnswerModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel',
            );
            const SimilarToSelectableAnswerModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerModel',
            );
            const MultipleChoiceMessageModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.MultipleChoiceMessage.MultipleChoiceMessageModel',
            );
            const NoIncorrectAnswersMixin = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeEditorViewModel.NoIncorrectAnswers',
            );

            return ChallengeEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
                );
                this.include(ThisOrThatChallengeEditorTemplate);
                this.include(FillInTheBlanksEditorTemplate);
                this.include(BlanksOnImageEditorTemplate);
                this.include(BasicMultipleChoiceEditorTemplate);
                this.include(MatchingEditorTemplate);
                this.include(NoIncorrectAnswersMixin);

                this.supportConfigOption('disableCorrectAnswerSelect');

                // Note: this only returns answers that have been specifically
                // assigned to this challenge.  Other answers with the same text
                // or image may also be acceptable, but will not be included here.
                Object.defineProperty(this.prototype, 'correctAnswer', {
                    get() {
                        return this.model.correctAnswer;
                    },
                    set(answer) {
                        this.correctAnswers = answer ? [answer] : [];
                    },
                });

                // Note: this only returns answers that have been specifically
                // assigned to this challenge.  Other answers with the same text
                // or image may also be acceptable, but will not be included here.
                Object.defineProperty(this.prototype, 'correctAnswers', {
                    get() {
                        return this.model.correctAnswers;
                    },
                    set(answers) {
                        const oldValue = this.correctAnswers.slice(0);
                        if (_.isEqual(answers, oldValue)) {
                            return;
                        }

                        const matchers = this.model.validator.expectedAnswerMatchers.clone();
                        matchers.forEach(matcher => {
                            matcher.remove();
                        });

                        answers.forEach(answer => {
                            if (!answer.isA(SelectableAnswerModel)) {
                                throw new Error(`unexpected answer type ${answer.type}`);
                            }

                            if (!this.model.answerList) {
                                throw new Error('Cannot set correctAnswers with no answerList');
                            }

                            if (!this.model.answerList.answers.includes(answer)) {
                                this.model.answerList.answers.push(answer);
                            }

                            const matcher = SimilarToSelectableAnswerModel.EditorViewModel.addComponentTo(
                                this.frame,
                            ).setup().model;
                            matcher.answer = answer;
                            this.model.validator.expectedAnswerMatchers.push(matcher);
                        });

                        this.model.triggerCallbacks('set:correctAnswers', answers, oldValue);
                    },
                });

                Object.defineProperty(this.prototype, 'correctAnswerIds', {
                    get() {
                        return this.correctAnswers.map(answer => answer.id);
                    },
                });

                // ProcessesChallengeBlanks (or blanks_on_image editor template) may try to set text
                // on a challenge, in which case we will
                // put that text on the correct answer
                Object.defineProperty(this.prototype, 'correctAnswerText', {
                    get() {
                        return this.correctAnswer && this.correctAnswer.text && this.correctAnswer.text.text;
                    },
                    set(val) {
                        if (!this.correctAnswer) {
                            if (!this.model.answerList.editorViewModel) {
                                throw new Error(
                                    'Cannot set correctAnswer on a challenge that does not have an answerList.',
                                );
                            }
                            this.correctAnswer = this.model.answerList.editorViewModel.addAnswer().model;
                        }
                        this.correctAnswer.editorViewModel.setContentType('text');
                        this.correctAnswer.text.text = val;
                    },
                });

                // blanks_on_image editor template may try to set the image
                // on a challenge, in which case we will
                // put that image on the correct answer
                Object.defineProperty(this.prototype, 'correctAnswerImage', {
                    get() {
                        return this.correctAnswer && this.correctAnswer.image;
                    },
                    set(val) {
                        if (!this.correctAnswer) {
                            if (!this.model.answerList) {
                                throw new Error(
                                    'Cannot set correctAnswer on a challenge that does not have an answerList.',
                                );
                            }
                            this.correctAnswer = this.model.answerList.editorViewModel.addAnswer().model;
                        }
                        this.correctAnswer.image = val;
                    },
                });

                Object.defineProperty(this.prototype, 'messageEvent', {
                    get() {
                        // If the local variable has not been set, check if
                        // all messages have the same event (there is no case right
                        // now where they won't, but ...)
                        if (angular.isUndefined(this._messageEvent)) {
                            let events = {};
                            this.model.messages.forEach(message => {
                                events[message.event] = true;
                            });
                            events = Object.keys(events);
                            if (events.length > 1) {
                                throw new Error('Not expecting more than one message event');
                            }
                            this._messageEvent = events[0] || 'validated';
                        }
                        return this._messageEvent;
                    },
                    set(val) {
                        (this.model.messages || []).forEach(message => {
                            message.event = val;
                        });
                        this._messageEvent = val;
                    },
                });

                return {
                    directiveName: 'cf-multiple-choice-challenge-editor',

                    setup($super) {
                        $super();
                        this.addAnswerList();
                        return this;
                    },

                    initialize($super, model) {
                        $super(model);
                        this.model.messages = this.model.messages || [];
                    },

                    addAnswerList() {
                        if (!this.model.answerList) {
                            const answerListHelper = AnswerListEditorViewModel.addComponentTo(this.frame).setup();
                            this.model.answerList = answerListHelper.model;
                        }
                    },

                    hasMessageFor(answer, event) {
                        return !!this.messageComponentFor(answer, event);
                    },

                    messageComponentFor(answer, event) {
                        return this.model.messageComponentFor(answer, event);
                    },

                    messageEditorViewModelFor(answer, event) {
                        const message = this.messageComponentFor(answer, event);
                        if (message) {
                            return this.editorViewModelFor(message);
                        }
                        return undefined;
                    },

                    addMessageFor(answer) {
                        const event = this.messageEvent;
                        if (!answer || !answer.isA || !answer.isA(SelectableAnswerModel)) {
                            throw new Error('addMessageFor requires a selectable answer');
                        }
                        if (!event || typeof event !== 'string') {
                            throw new Error('addMessageFor requires event to be a string');
                        }
                        const messageHelper = MultipleChoiceMessageModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup();
                        const message = messageHelper.model;

                        message.challenge = this.model;
                        message.event = event;
                        const matcher = SimilarToSelectableAnswerModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                        matcher.answer = answer;
                        message.answerMatcher = matcher;
                        this.model.messages = this.model.messages || [];
                        this.model.messages.push(message);
                        return messageHelper;
                    },

                    wrapTransN() {
                        const correctAnswers = this.correctAnswers;
                        _.forEach(correctAnswers, correctAnswer => {
                            const oldExpectedText = correctAnswer && correctAnswer.text && correctAnswer.text.text;

                            if (!oldExpectedText || !correctAnswer.isA(SelectableAnswerModel)) {
                                return;
                            }

                            correctAnswer.editorViewModel.wrapTransN();

                            const newExpectedText = correctAnswer.text.text;

                            if (oldExpectedText !== newExpectedText) {
                                this.model.unlink_blank_from_answer = true;
                            }
                        });
                    },
                };
            });
        },
    ],
);
