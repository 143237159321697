import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/playlist/list_playlists.html';
import customTitleTemplate from 'Editor/angularModule/views/playlist/list_playlists_custom_title.html';
import customActionsTemplate from 'Editor/angularModule/views/playlist/list_playlists_custom_actions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listPlaylists', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const SiteMetadata = $injector.get('SiteMetadata');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const Playlist = $injector.get('Playlist');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.Locale = $injector.get('Locale');
                scope.clientFilters = {};
                scope.customTip = '<i class="fa fa-star"></i> = Available to SMARTER users';
                scope.apiFields = Playlist.fieldsForEditorList;

                //-------------------------
                // Editing
                //-------------------------

                scope.editPlaylist = playlist => {
                    $location.url(`/editor/playlist/${playlist.id}/edit`);
                };

                scope.createCallback = playlist => {
                    // now that we have the server-generated playlist id, embed it in the seo url and save
                    const seoCanonicalUrl = SiteMetadata.seoCanonicalUrlFromTitle(
                        'playlist',
                        playlist.title,
                        playlist.id,
                    );
                    playlist.entity_metadata.canonical_url = seoCanonicalUrl;
                    playlist.entity_metadata.save().then(() => {
                        scope.editPlaylist(playlist);
                    });
                };

                //-------------------------
                // Columns
                //-------------------------

                scope.displayColumns = [
                    {
                        prop: 'tag',
                        type: 'text',
                        label: 'Tag',
                    },
                    {
                        prop: 'title',
                        type: 'custom',
                        label: 'Title',
                        template: customTitleTemplate,
                        callbacks: {
                            editCallback: scope.editPlaylist,
                        },
                    },
                    {
                        prop: 'authorName',
                        type: 'text',
                        label: 'Author',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'streamCount',
                        type: 'number',
                        label: '# Stream(s)',
                        classes: ['hidden-xs', 'hidden-sm', 'hidden-md'],
                    },
                    {
                        prop: 'sortablePublishedAt',
                        type: 'time',
                        label: 'Published',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'sortableModifiedAt',
                        type: 'time',
                        label: 'Modified',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'relatedCohortsToS',
                        type: 'text',
                        label: 'Cohort(s)',
                        classes: ['hidden-xs', 'hidden-sm', 'hidden-md'],
                    },
                    {
                        prop: 'editorAbilities',
                        type: 'custom',
                        label: 'Actions',
                        template: customActionsTemplate,
                        callbacks: {
                            editCallback: scope.editPlaylist,
                        },
                    },
                ];

                //-------------------------
                // Filtering
                //-------------------------

                scope.quickFilterProperties = ['tag', 'title', 'authorName'];
                const booleanProperties = ['modifiedAfterPublished', 'hasPublishedVersion'];

                const defaultFilters = [
                    {
                        server: true,
                        default: true,
                        value: {
                            published: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            locale: 'en',
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            in_users_locale_or_en: false,
                        },
                    },
                ];

                scope.modifiedAfterPublishedOptions = [
                    {
                        label: 'Modified after published',
                        value: 'true',
                    },
                    {
                        label: 'Not modified after published',
                        value: 'false',
                    },
                ];

                scope.hasPublishedVersionOptions = [
                    {
                        label: 'Published',
                        value: 'true',
                    },
                    {
                        label: 'Not published',
                        value: 'false',
                    },
                ];

                // wire up filtering support
                editContentItemListMixin.onLink(scope, 'listPlaylists', defaultFilters, booleanProperties);
            },
        };
    },
]);
