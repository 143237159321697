import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BasicMultipleChoice',
    [
        'AModuleAbove',

        AModuleAbove =>
            new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    function applyTemplate(model, isMultipleAnswerMode) {
                        model.behaviors.CompleteOnCorrect = {};
                        model.behaviors.ResetAnswersOnActivated = {};
                        model.behaviors.FlashIncorrectStyling = {};
                        model.behaviors.ClearMessagesOnAnswerSelect = {};
                        // ImmediateValidation and ShowCorrectStyling are handled by the
                        // NoIncorrectAnswers mixin

                        if (isMultipleAnswerMode) {
                            model.behaviors.DisallowMultipleSelect = undefined;
                        } else {
                            model.behaviors.DisallowMultipleSelect = {};
                        }

                        // validator behaviors (i.e. HasAllExpectedAnswers) are handled
                        // by NoIncorrectAnswers mixin
                    }

                    /*
                        The setup of two templates, one for check_many and one for
                        a single correct answer, is duplicated in
                        componentized/component/challenge/multiple_choice_challenge/editor_templates/blanks_on_image_editor_template.js
                    */

                    MultipleChoiceChallengeEditorViewModel.addTemplate('basic_multiple_choice', function () {
                        applyTemplate(this.model, false);

                        // make sure there is at most one correct answer
                        this.correctAnswers = this.correctAnswers.length > 0 ? [this.correctAnswers[0]] : [];
                    });

                    MultipleChoiceChallengeEditorViewModel.addTemplate('check_many', function () {
                        applyTemplate(this.model, true);
                        // FIXME: if we decide we want to support checkMany frames with
                        // no correct answers (i.e. any answer is correct), then remove
                        // the validator completely.  Code will have to change in challenges
                        // and the challenges continue button to support that.
                    });
                },
            }),
    ],
);
