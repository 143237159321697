import angularModule from 'Editor/angularModule/scripts/editor_module';
import jsondiffpatch from 'FrontRoyalJsonDiffPatch';

angularModule.factory('JsonDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const $sce = $injector.get('$sce');
        const dmp = $injector.get('dmp'); // angular-diff-patch

        const jsonDiff = jsondiffpatch.create({
            objectHash(obj, index) {
                // try to find an id property, otherwise just use the index in the array
                return obj.id || `$$index:${index}`;
            },
            arrays: {
                detectMove: true,
                includeValueOnMove: false,
            },
            textDiff: {
                minLength: 350,
            },
        });

        const JsonDiff = SuperModel.subclass(function () {
            /*
                    NOTE: this will not reliably show all unchanged values:
                    https://github.com/benjamine/jsondiffpatch/issues/95
                */
            Object.defineProperty(this.prototype, 'showUnchanged', {
                get() {
                    return this._showUnchanged || false;
                },
                set(val) {
                    if (val === this.showUnchanged) {
                        return val;
                    }
                    this._showUnchanged = val;
                    this._setHtml();
                    return val;
                },
            });

            return {
                isJsonDiff: true,
                initialize(oldJson, newJson) {
                    const types = _.uniq([typeof oldJson, typeof newJson]);

                    const hasNonStringValue = _.chain(types).difference(['undefined', 'string']).some().value();

                    // if there are only string values, use angular-diff,
                    // otherwise use json-diff-path
                    if (!hasNonStringValue && (oldJson || newJson) && oldJson !== newJson) {
                        this._setStringDiff(oldJson, newJson);
                    } else {
                        this._setJsonDiff(oldJson, newJson);
                    }
                },

                _setJsonDiff(oldJson, newJson) {
                    let delta;
                    try {
                        delta = jsonDiff.diff(oldJson, newJson);
                    } catch (e) {
                        // The diff-match-patch library has issues with UTF characters outside of the
                        // UTF-16 space (known as astral plane characters). Those characters have to be
                        // represented by two code points (e.g. \uD83D\uDE00 is a smiley face). However,
                        // a lot of the string functions in JavaScript aren't aware of this. So I think
                        // diff-match-patch might be trying to only use the first code point somewhere in
                        // the logic, which creates an invalid character and causes diff-match-patch code
                        // to throw an error. Let's just catch this situation when it happens and replace
                        // the offending unicode characters with a stub so that at least the diff does not break.
                        // The library only seems to break if the diff actually involves the astral character,
                        // so rather than just always stripping we are trying to diff first, then catching on the
                        // URIError: URI malformed error that is thrown by diff-match-patch.
                        // See https://github.com/google/diff-match-patch/pull/13
                        //
                        // I highly recommend reading this article if you are in this code!
                        // See https://dmitripavlutin.com/what-every-javascript-developer-should-know-about-unicode/

                        const replacementCharacter = '\uFFFD';
                        const oldJsonSanitizedString = JSON.stringify(oldJson).replace(
                            /[\u{10000}-\u{10FFFF}]/gu,
                            () => replacementCharacter,
                        );
                        const newJsonSanitizedString = JSON.stringify(newJson).replace(
                            /[\u{10000}-\u{10FFFF}]/gu,
                            () => replacementCharacter,
                        );

                        oldJson = JSON.parse(oldJsonSanitizedString);
                        newJson = JSON.parse(newJsonSanitizedString);

                        delta = jsonDiff.diff(oldJson, newJson);
                    }

                    this.edited = !!delta;

                    // ensure we aren't triggering diff alerts on newly added `formatted_text`
                    if (delta && delta._t === 'a') {
                        // 'a' = expected 'array' datatype

                        // get all the deltas for the components
                        for (const deltaKey in delta) {
                            // will be numeric property name keyed off occurence?
                            let allFormattedText = true;

                            // check if the diff includes newly created introduced `formatted_text`
                            for (const propName in delta[deltaKey]) {
                                if (propName !== 'formatted_text' || delta[deltaKey][propName].length !== 1) {
                                    // we found something else, so w're definitely edited
                                    allFormattedText = false;
                                    break;
                                }
                            }

                            // otherwise, it's okay to display as unchanged.
                            if (allFormattedText) {
                                this.edited = false;
                                break;
                            }
                        }
                    }

                    if (this.edited) {
                        const diffHTML = jsondiffpatch.formatters.html.format(delta, oldJson);
                        this._el = $(diffHTML);
                    }

                    this._showUnchanged = false;
                    this._setHtml();
                },

                _setStringDiff(oldText, newText) {
                    this.edited = true;
                    this.html = dmp.createSemanticDiffHtml(oldText || '', newText || '', {});
                },

                _setHtml() {
                    // _el will be undefined if
                    //  - there are no changes, or
                    //  - we are using diffFilter to handle a string diff (in which case the html can never change so we don't need to set it again anyway)
                    if (!this._el) {
                        return undefined;
                    }
                    jsondiffpatch.formatters.html.showUnchanged(this.showUnchanged, this._el[0]);
                    this.html = $sce.trustAsHtml(this._el[0].outerHTML);
                },
            };
        });

        return JsonDiff;
    },
]);
