import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    ***************** Notes on blanks frame types

    see fill_in_the_blanks_editor_template.js


*/

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ComposeBlanks', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',

    (AModuleAbove, ChallengesEditorTemplateHelper, UserInputChallengeModel) =>
        new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('compose_blanks', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);
                    this.model.behaviors.GotoNextOnChallengeComplete = {
                        delay: 0,
                    };

                    this.setConfig({
                        newChallengeType: UserInputChallengeModel,
                        disallowAddAndReorderChallenges: true,
                        allowSetContextImages: true,
                        userDefinedOptions: templateHelper.getComposeBlanksUserOptions(),
                    });

                    templateHelper.setChallengeTemplate('basic_user_input');

                    // since we're using ProcessesChallengeBlanks, the main text component
                    // needs a reference to the challenges component
                    const sharedTextEditorViewModel = templateHelper.setupSharedText();
                    sharedTextEditorViewModel.model.challengesComponent = this.model;
                    sharedTextEditorViewModel.model.behaviors.ProcessesChallengeBlanks = {};
                });
            },
        }),
]);
