import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherEditorViewModel',

        AnswerMatcherEditorViewModel =>
            AnswerMatcherEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerModel',
                );

                return {
                    initialize($super, model) {
                        $super(model);

                        // remove this whenever the associated answer is removed
                        this.model.on('set:answer', function (answer) {
                            if (!answer) {
                                this.remove();
                            }
                        });
                    },
                };
            }),
    ],
);
