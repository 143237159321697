import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksOnImage',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksMixin',

        (AModuleAbove, BlanksMixin) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    /*
                        The setup of two templates, one for check_many and one for
                        a single correct answer, is duplicated in
                        componentized/component/challenge/multiple_choice_challenge/editor_templates/basic_multiple_choice_editor_template.js
                    */

                    MultipleChoiceChallengeEditorViewModel.addTemplate('blanks_on_image', function () {
                        BlanksMixin.applyToEditorTemplate(this);

                        // make sure there is at most one correct answer
                        this.correctAnswers = this.correctAnswers.length > 0 ? [this.correctAnswers[0]] : [];
                    });

                    MultipleChoiceChallengeEditorViewModel.addTemplate('blanks_on_image_check_many', function () {
                        BlanksMixin.applyToEditorTemplate(this, true);
                        // See comment about supporting no incorrect answers near similar code in
                        // componentized/component/challenge/multiple_choice_challenge/editor_templates/basic_multiple_choice_editor_template.js
                    });
                },
            }),
    ],
);
