import angularModule from 'Editor/angularModule/scripts/editor_module';
import JSZip from 'jszip';

angularModule.factory('TranslatableLessonExportSet', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const SuperModel = $injector.get('SuperModel');
        const $q = $injector.get('$q');
        const $timeout = $injector.get('$timeout');
        const TranslatableLessonExport = $injector.get('TranslatableLessonExport');
        const Lesson = $injector.get('Lesson');
        const sequence = $injector.get('sequence');

        const TranslatableLessonExportSet = SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'lessons', {
                get() {
                    return this.$$lessons;
                },
                set(lessons) {
                    const self = this;
                    self.$$lessons = lessons;
                    self.translatableLessonExports = [];
                    lessons.forEach(lesson => {
                        self.formats.forEach(format => {
                            const translatableLessonExport = new TranslatableLessonExport(lesson, format);
                            self.translatableLessonExports.push(translatableLessonExport);
                        });
                    });
                    return lessons;
                },
            });

            Object.defineProperty(this.prototype, 'fileCount', {
                get() {
                    return this.translatableLessonExports.length;
                },
            });

            return {
                initialize(lessonsOrIds, formats, zipFilename, stream) {
                    const self = this;
                    self.translatableLessonExports = [];
                    self.formats = formats;
                    self.preparing = false;
                    self.prepared = false;
                    self.statusMessage = 'Not yet prepared';

                    if (typeof lessonsOrIds[0] === 'string') {
                        self.lessonIds = lessonsOrIds;
                        self.lessons = [];
                    } else {
                        self.lessons = lessonsOrIds;
                    }

                    if (self.lessons.length === 1 && !zipFilename) {
                        const lesson = self.lessons[0];
                        zipFilename = `${[lesson.parameterizedTag, lesson.id].join('_')}.zip`;
                    }

                    if (!zipFilename) {
                        throw new Error('When exporting multiple lessons, a filename must be provided.');
                    }
                    self.zipFilename = zipFilename;

                    // Optional stream for the lesson set
                    self.stream = stream;
                },

                prepare() {
                    const self = this;
                    self.preparing = true;
                    self.prepared = false;
                    self.statusMessage = 'Initializing ...';
                    return self._prepare().then(
                        () => {
                            self.preparing = false;
                            self.prepared = true;
                        },
                        err => {
                            $window.alert('An error occurred building the export.');
                            throw err;
                        },
                    );
                },

                downloadTranslatableArtifacts() {
                    function handleDownload(file) {
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.style = 'display: none';
                        const url = window.URL.createObjectURL(file.blob);
                        a.href = url;
                        a.download = file.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }

                    if (this.fileCount > 1) {
                        this._zipAllFormats().then(handleDownload, err => {
                            $injector.get('DialogModal').alert({
                                content: 'An error occurred.  Smartly engineers have been notified.',
                            });

                            // It seems like we should be able to just throw the error here and it
                            // should bubble up, but it doesn't.  Either I'm confused about how this
                            // should work or JsZip's custom promise implementation is doing
                            // the wrong thing.  So we send it directly to the error log service
                            const errorLogService = $injector.get('ErrorLogService');
                            errorLogService(err);
                        });
                    } else {
                        const file = this.translatableLessonExports[0];
                        handleDownload(file);
                    }
                },

                // separate method that can be mocked in specs
                _prepare() {
                    const self = this;

                    // add a timeout so the UI has a little time to respond,
                    // show a spinner, whatever
                    return $timeout()
                        .then(() => self._ensureLessonsLoaded())
                        .then(() => {
                            let i = 0;
                            return sequence(self.translatableLessonExports, translatableLessonExport => {
                                self.statusMessage = `Preparing file ${i + 1} of ${
                                    self.translatableLessonExports.length
                                } ...`;
                                i += 1;
                                return translatableLessonExport.prepare();
                            });
                        });
                },

                _zipAllFormats() {
                    // see also: https://stuk.github.io/jszip/
                    // NOTE: it is also possible to organize files in folders
                    // NOTE: docs suggest using FileSaver, but that didn't work
                    //          I think maybe because of html5
                    const zip = new JSZip();

                    _.forEach(this.translatableLessonExports, translatableLessonExport => {
                        zip.file(translatableLessonExport.filename, translatableLessonExport.content);

                        zip.file(
                            translatableLessonExport.filename.replace('.html', '.txt'),
                            translatableLessonExport.getPlainText(),
                        );
                    });

                    // Add the stream's metadata (if sent)
                    if (this.stream) {
                        const exportableMetadata = this.stream.getExportableMetadata();
                        zip.file(exportableMetadata.filename, exportableMetadata.content);
                    }

                    const self = this;

                    // jszip only generates in async now (3.0.0+)
                    return zip
                        .generateAsync({
                            type: 'blob',
                        })
                        .then(content => ({
                            filename: self.zipFilename,
                            blob: content,
                        }));
                },

                _ensureLessonsLoaded() {
                    const self = this;
                    if (_.some(self.lessons)) {
                        return $q.when();
                    }
                    self.statusMessage = 'Loading lessons ...';
                    return Lesson.index(
                        {
                            filters: {
                                published: false,
                                id: self.lessonIds,
                            },
                            fields: 'ALL',
                            'except[]': ['lesson_progress', 'version_history', 'practice_frames'],
                        },
                        {
                            // this request could take a long time. Do not time it out
                            timeout: undefined,
                        },
                    ).then(response => {
                        const lessons = response.result;
                        _.invokeMap(lessons, 'reifyAllFrames');
                        self.lessons = lessons;
                    });
                },
            };
        });

        return TranslatableLessonExportSet;
    },
]);
