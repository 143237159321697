import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',

        ComponentEditorViewModel =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ContinueButtonModel');

                return {
                    setup() {
                        return this;
                    },
                };
            }),
    ],
);
