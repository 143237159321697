import angularModule from 'Editor/angularModule/scripts/editor_module';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/componentized_info_panel.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('componentizedInfoPanel', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                frameViewModel: '<',
            },
            link(scope) {
                // needed because this gets set inside of an ng-if,
                // which creates an inherited scope
                scope.sharedProps = {};

                Object.defineProperty(scope, 'frame', {
                    get() {
                        return this.frameViewModel.frame;
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return this.frame.lesson();
                    },
                });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'mainUiComponentEditorViewModel', {
                    get() {
                        return this.frame.mainUiComponentEditorViewModel;
                    },
                });

                scope.frame.initializeEditorViewModels();
            },
        };
    },
]);
