import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.BasicMultipleChoice', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',

    (AModuleAbove, ChallengesEditorTemplateHelper, MultipleChoiceChallengeModel) =>
        new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('basic_multiple_choice', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    this.supportsNoIncorrectAnswers = {
                        ImmediateValidation: false,
                    };
                    this.supportsHasBranching = true;

                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                        disallowAddAndReorderChallenges: true,
                        allowSetContextImages: true,
                        supportsCheckMany: {
                            answerListSkin: {
                                check_one_selection_mode: 'buttons',
                                check_many_selection_mode: 'checkboxes',
                                check_all_selection_mode: 'checkboxes',
                            },
                            challengeEditorTemplates: ['check_many', 'basic_multiple_choice'],
                        },
                        userDefinedOptions: [
                            {
                                type: 'radioSetProperty',
                                property: 'selectionMode',
                                radios: [
                                    ['check_one_selection_mode', 'Check One'],
                                    ['check_many_selection_mode', 'Check Many'],
                                    ['check_all_selection_mode', 'Check All'],
                                ],
                            },
                            {
                                type: 'checkboxSetProperty',
                                property: 'hasBranching',
                                title: 'Branching',
                                disabled: () =>
                                    this.lesson.isPractice || this.selectionMode !== 'check_one_selection_mode',
                            },
                            {
                                type: 'checkboxSetProperty',
                                property: 'noIncorrectAnswers',
                                title: 'No Incorrect Answers',
                                disabled: () => this.selectionMode === 'check_all_selection_mode' || this.hasBranching,
                            },
                        ],
                    });

                    const answerListEditorViewModel = templateHelper.setupSharedAnswerList(
                        this.config.supportsCheckMany.answerListSkin[this.selectionMode],
                        2,
                    );
                    answerListEditorViewModel.setUserDefinedOptions('RandomizeAnswerOrder', 'forceSingleColumn');

                    templateHelper.setChallengeTemplate('basic_multiple_choice');
                    templateHelper.setupSharedText();

                    templateHelper.setupExactlyOneChallenge();
                });
            },
        }),
]);
