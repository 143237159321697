import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.MultipleChoicePoll', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',

    (AModuleAbove, ChallengesEditorTemplateHelper, MultipleChoiceChallengeModel) =>
        new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('multiple_choice_poll', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    this.supportsNoIncorrectAnswers = {
                        ImmediateValidation: true,
                    };
                    this.supportsHasBranching = true;

                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                        disallowAddAndReorderChallenges: true,
                        allowSetContextImages: true,
                        userDefinedOptions: [
                            {
                                type: 'checkboxSetProperty',
                                property: 'hasBranching',
                                title: 'Branching',
                                disabled: () => this.lesson.isPractice,
                            },
                        ],
                    });

                    const answerListEditorViewModel = templateHelper.setupSharedAnswerList('poll', 2);
                    templateHelper.setChallengeTemplate('basic_multiple_choice');
                    templateHelper.setupSharedText();

                    // FIXME: rmove forceSingleColumn?
                    answerListEditorViewModel.setUserDefinedOptions('RandomizeAnswerOrder');
                    answerListEditorViewModel.disallowSwitchContentTypeOnAnswers = true;

                    templateHelper.setupExactlyOneChallenge();

                    // has to be set after challenge is added, which is probably a mistake
                    this.noIncorrectAnswers = true;

                    // cleanup old poll image if there is one
                    if (this.model.layout.content_for_header_id) {
                        try {
                            this.frame.dereference(this.model.layout.content_for_header_id).remove();
                            this.model.layout.content_for_header_id = undefined;
                        } catch (e) {
                            // no-op
                        }
                    }
                });
            },
        }),
]);
