import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.Matching',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksMixin',

        (AModuleAbove, BlanksMixin) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    MultipleChoiceChallengeEditorViewModel.addTemplate('matching', function () {
                        BlanksMixin.applyToEditorTemplate(this);
                    });
                },
            }),
    ],
);
