import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/challenge/multiple_choice_challenge/multiple_choice_challenge_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfMultipleChoiceChallengeEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                //--------------------------
                // Answer Options Updates
                //--------------------------

                // Note: We should possibly move this into has_text_or_image in the future

                let _answerOptions;
                scope.model.on(
                    '.answerList.answers[].text:set:text',
                    () => {
                        _answerOptions = undefined;
                    },
                    true,
                );

                scope.model.on(
                    '.answerList.answers[].image:set:image',
                    () => {
                        _answerOptions = undefined;
                    },
                    true,
                );

                scope.model.on(
                    '.answerList.answers[].image:set:label',
                    () => {
                        _answerOptions = undefined;
                    },
                    true,
                );

                Object.defineProperty(scope, 'answerOptions', {
                    get() {
                        if (!_answerOptions) {
                            _answerOptions = this.model.answers.map((answer, i) => ({
                                answer,
                                label: `${i + 1}: ${answer.label || 'BLANK'}`,
                            }));
                        }
                        return _answerOptions;
                    },
                });

                scope.isCorrectAnswer = function (answer) {
                    let foundIt = false;
                    this.model.editorViewModel.correctAnswers.forEach(a => {
                        if (a.id === answer.id) {
                            foundIt = true;
                        }
                    });
                    return foundIt;
                };

                scope.toggleSelection = function (answer) {
                    const currentCorrectAnswers = this.model.editorViewModel.correctAnswers.concat();
                    for (var index = 0; index < currentCorrectAnswers.length; index++) {
                        if (currentCorrectAnswers[index].id === answer.id) {
                            break;
                        }
                    }

                    // is currently selected
                    if (index > -1 && index < currentCorrectAnswers.length) {
                        currentCorrectAnswers.splice(index, 1);
                        this.model.editorViewModel.correctAnswers = currentCorrectAnswers;
                    }
                    // is newly selected
                    else {
                        currentCorrectAnswers.push(answer);
                        this.model.editorViewModel.correctAnswers = currentCorrectAnswers;
                    }
                };
            },
        }),
]);
