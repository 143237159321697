import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardEditorViewModel',

    [
        '$injector',

        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const TilePromptModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptModel',
            );
            const HasTextOrImageEditorViewModelMixin = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',
            );

            return ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardModel');

                this.defineCallbacks('initialize');

                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    setup() {
                        if (!this.model.tilePrompts) {
                            this.model.tilePrompts = [];
                        }

                        return this;
                    },

                    initialize(model) {
                        this.model = model;
                        this.removedPrompts = {};

                        if (this.model.challengesComponent) {
                            this.setupChallengeListeners();
                        } else {
                            const listener = this.model.on('set:challengesComponent', () => {
                                this.setupChallengeListeners();
                                listener.cancel();
                            });
                        }
                    },

                    setupChallengeListeners() {
                        const tilePromptChallengeListeners = [];
                        const that = this; // save on bind() overhead

                        // add challenge listeners for addition / removal of challenges
                        tilePromptChallengeListeners.push(
                            this.model.challengesComponent.challenges.on('childAdded', challenge => {
                                that.addTilePromptToChallenge(challenge);
                            }),
                        );

                        tilePromptChallengeListeners.push(
                            this.model.challengesComponent.challenges.on('childRemoved', challenge => {
                                that.removeTilePromptForChallenge(challenge);
                            }),
                        );
                    },

                    addTilePromptToChallenge(challenge) {
                        const currentTilesByChallenge = {};
                        this.model.tilePrompts.forEach(tile => {
                            currentTilesByChallenge[tile.challenge.id] = tile;
                        });

                        const updatedTilePrompts = [];
                        let tile;
                        this.model.challengesComponent.challenges.forEach(curChallenge => {
                            if (currentTilesByChallenge[curChallenge.id]) {
                                // use the existing collection tile
                                tile = currentTilesByChallenge[curChallenge.id];
                            } else if (this.removedPrompts[curChallenge.id]) {
                                // use the removed collection tile
                                tile = this.removedPrompts[curChallenge.id];
                                this.removedPrompts[curChallenge.id] = undefined;
                            } else {
                                // build out a new challenge tile
                                tile = TilePromptModel.EditorViewModel.addComponentTo(this.model.frame()).setup().model;
                                tile.challenge = challenge;
                            }

                            curChallenge.editorViewModel.proxyEditorConfig = {
                                label: 'Tile',
                                editorViewModel: tile.editorViewModel,
                            };
                            updatedTilePrompts.push(tile);
                        });

                        this.model.tilePrompts = updatedTilePrompts;

                        return tile;
                    },

                    removeTilePromptForChallenge(challenge) {
                        let indexToRemove = -1;
                        let tilePromptToRemove;
                        for (let i = this.model.tilePrompts.length - 1; i >= 0; i--) {
                            const tilePrompt = this.model.tilePrompts[i];
                            if (tilePrompt.challenge === challenge) {
                                tilePromptToRemove = tilePrompt;
                                indexToRemove = i;
                                break;
                            }
                        }
                        if (indexToRemove > -1) {
                            this.removedPrompts[tilePromptToRemove.challenge.id] = tilePromptToRemove;
                            this.model.tilePrompts.splice(indexToRemove, 1);
                        }
                    },
                };
            });
        },
    ],
);
