import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    The UiComponentDirHelper is used to help create the directives associated with UiComponents.

    Example usage:

        angularModule.directive('cfSomeComponent', [
            'Lesson.FrameList.Frame.Componentized.Component.UiComponent.UiComponentDirHelper',

            function(UiComponentDirHelper) {

                return UiComponentDirHelper.getOptions({
                    template: '<div>Some component</div>',
                    link: function(scope, elem) {
                        UiComponentDirHelper.link(scope);
                        ...
                    }
                });

            }
        ]);
*/
angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper', [
    () => {
        const EditorDirHelper = {
            link(scope, element) {
                scope.options = scope.options || {};

                element.get(0).classList.add('component_editor');

                Object.defineProperty(scope, 'model', {
                    get() {
                        return this.editorViewModel.model;
                    },
                });

                Object.defineProperty(scope, 'frame', {
                    get() {
                        return this.model.frame();
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return this.frame.lesson();
                    },
                });
                Object.defineProperty(scope, 'viewModel', {
                    get() {
                        if (!this.frameViewModel) {
                            throw new Error(
                                `Editor directive for ${this.editorViewModel.type} requires frame-view-model to be passed in`,
                            );
                        }
                        return this.frameViewModel.viewModelFor(this.model);
                    },
                });
            },

            getOptions(overrides) {
                // FIXME remove override stuff?
                return angular.extend(
                    {
                        restrict: 'E',
                        scope: {
                            editorViewModel: '<',
                            frameViewModel: '<',
                            options: '<?',
                            disabled: '<?',

                            // skin cannot go in options because it needs to be
                            // accessible to templateUrl, which is called before
                            // the scope is built
                            skin: '@',
                        },
                        link: EditorDirHelper.link,
                    },
                    overrides,
                );
            },
        };

        return EditorDirHelper;
    },
]);
