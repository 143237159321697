// Any time this file is updated (an existing annotation type is edited/removed or a new annotation type is added),
// please make sure to update the correlated Metabase query that finds all frames that contain an annotation:
// https://metabase.pedago-tools.com/question/1855-annotated-frames

const frameAnnotationTypes = [
    {
        type: 'time_sensitive',
        label: 'Time Sensitive',
        description: 'Current events/articles in this slide may need to be updated in the future.',
    },
    {
        type: 'needs_sme_attention',
        label: 'Needs SME Attention',
        description: 'This slide needs review from a Subject Matter Expert.',
    },
    {
        type: 'revisit_and_update_later',
        label: 'Revisit and Update Later',
        description: 'This slide should be revisited later depending on later content decisions.',
    },
    {
        type: 'update_translation',
        label: 'Update Translation',
        description: 'This slide needs its translations updated.',
    },
];

export default { frameAnnotationTypes };
