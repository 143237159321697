import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('RecentlyEditedLessons', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const ClientStorage = $injector.get('ClientStorage');
        const Lesson = $injector.get('Lesson');

        const RecentlyEditedLessons = SuperModel.subclass(function () {
            this.extend({
                CSKEY: 'recentlyEditedLessons',
                AGE_LIMIT: 7 * 24 * 60 * 60 * 1000,
                MAX_LENGTH: 10,
            });

            Object.defineProperty(this.prototype, 'lessons', {
                get() {
                    if (!this.$$lessons) {
                        this.$$lessons = _.map(this.$$lessonEntries, 'lesson');
                    }
                    return this.$$lessons;
                },
                configurable: true, // tests
            });

            return {
                initialize() {
                    const json = ClientStorage.getItem(RecentlyEditedLessons.CSKEY) || '[]';
                    const list = JSON.parse(json);
                    this.$$lessonEntries = _.chain(list)
                        .map(obj => {
                            if (obj.lastOpened + RecentlyEditedLessons.AGE_LIMIT < new Date()) {
                                return undefined;
                            }
                            return {
                                lastOpened: obj.lastOpened,
                                lesson: Lesson.new(obj.lesson),
                            };
                        })
                        .compact()
                        .value();
                },

                update(locale, lessons) {
                    if (typeof locale !== 'string') {
                        throw new Error('First argument should be a string');
                    }

                    // give a list of newly loaded lessons, replace the
                    // lessons in the cache with the new ones, which have more
                    // updated data
                    const lessonsById = _.keyBy(lessons, 'id');

                    // any lesson from this locale that is not
                    // in the ones passed in will be removed from this.lessons
                    this.$$lessonEntries = _.chain(this.$$lessonEntries)
                        .map(entry => {
                            const updatedLesson = lessonsById[entry.lesson.id];
                            if (updatedLesson) {
                                entry.lesson = updatedLesson;
                                return entry;
                            }
                            if (entry.lesson.locale !== locale) {
                                return entry;
                            }
                            return undefined;
                        })
                        .compact()
                        .value();

                    this._save();
                },

                add(lesson) {
                    this.$$lessonEntries = _.reject(this.$$lessonEntries, entry => entry.lesson.id === lesson.id);

                    this.$$lessonEntries.unshift({
                        lesson,
                        lastOpened: new Date().getTime(),
                    });

                    while (this.$$lessonEntries.length > RecentlyEditedLessons.MAX_LENGTH) {
                        this.$$lessonEntries.pop();
                    }
                    this._save();
                },

                _save() {
                    // unset $$lessons so it will be re-built from updated
                    // $$lessonEntries next time
                    this.$$lessons = undefined;
                    if (this.$$lessonEntries) {
                        const picked = _.map(this.$$lessonEntries, entry => ({
                            lastOpened: entry.lastOpened,
                            lesson: _.pick(entry.lesson.asJson(), Lesson.fieldsForEditorList),
                        }));
                        ClientStorage.setItem(RecentlyEditedLessons.CSKEY, angular.toJson(picked));
                    }
                },
            };
        });

        return RecentlyEditedLessons;
    },
]);
