import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/edit_frame_list_thumbnails.html';
import basicThumbnailTemplate from 'Editor/angularModule/views/lesson_types/frame_list/basic_thumbnail.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import EditorConstants from 'Editor/angularModule/scripts/constants';

import branch from 'vectors/branch.svg';
import merge from 'vectors/merge.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

cacheAngularTemplate(angularModule, 'Editor/basic_thumbnail.html', basicThumbnailTemplate);

angularModule.directive('editFrameListThumbnails', [
    '$injector',
    '$rootScope',

    function factory($injector, $rootScope) {
        const scopeTimeout = $injector.get('scopeTimeout');
        const DialogModal = $injector.get('DialogModal');
        const getInteractionTypeAbbreviationForFrame =
            $injector.get('frameInteractionTypes').getInteractionTypeAbbreviationForFrame;

        return {
            templateUrl,
            scope: {
                playerViewModel: '<',
                showActions: '<',
            },
            restrict: 'E',
            require: '?^form',
            link(scope, elem, _attrs, formController) {
                scope.branch = branch;
                scope.merge = merge;
                scope.getInteractionTypeAbbreviationForFrame = getInteractionTypeAbbreviationForFrame;

                //----------------------------
                // Scope Properties
                //----------------------------

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return this.playerViewModel && this.playerViewModel.lesson;
                    },
                });

                Object.defineProperty(scope, 'navigationPointers', {
                    get() {
                        if (!scope._navigationPointers) {
                            scope._navigationPointers = scope.lesson.createNavigationPointers();
                        }
                        return scope._navigationPointers;
                    },
                });

                //----------------------------
                // Watches
                //----------------------------

                // ensure that all frames are reified, so that they can display appropriately
                scope.$watch('lesson.frames', frames => {
                    frames.forEach(frame => {
                        frame.reify();
                    });
                });

                scope.$watch('playerViewModel.activeFrame', frame => {
                    scopeTimeout(
                        scope,
                        () => {
                            if (!frame) {
                                return;
                            }
                            const framesEl = elem.find('.frames');
                            const thumbnails = framesEl.find('.frame_thumbnail');
                            const thumbnail = thumbnails.eq(frame.index());
                            if (thumbnail.length === 0) {
                                return;
                            }
                            const topPosition = thumbnail.position().top + framesEl[0].scrollTop;
                            const bottomPosition = topPosition + thumbnail.height();

                            if (bottomPosition > framesEl[0].scrollTop + framesEl.height()) {
                                framesEl[0].scrollTop = bottomPosition;
                            }
                        },
                        0,
                    );
                });

                // refresh the navigation pointer list on every digest (way easier than listening for
                // every frame add, remove, reorder, etc)
                scope.$watch(() => {
                    scope._navigationPointers = undefined;
                });

                scope.$watchGroup(['lesson.frames.length', 'playerViewModel.activeFrameIndex'], () => {
                    scope.lesson.frames.forEach(frame => {
                        if (frame.validateTextLengths) {
                            const result = frame.validateTextLengths();
                            scope._textLimitMap[frame.id] = !result.valid;
                        }
                    });
                });

                //----------------------------
                // Frame Interaction
                //----------------------------

                scope.setFormDirty = () => {
                    if (formController) {
                        formController.$setDirty();
                    }
                };

                // called when the addFrame button is pressed; adds a frame after the currently selected frame
                scope.addFrame = () => {
                    const index = scope.playerViewModel.activeFrame ? scope.playerViewModel.activeFrameIndex + 1 : 0;
                    const frame = scope.lesson.addFrameWithDefaultType(index);
                    scope.playerViewModel.gotoFrame(frame);
                    frame.expandExtraPanelsInitially();
                    scope.setFormDirty();
                };

                scope.duplicateFrame = frame => {
                    scope.lesson.duplicateFrame(frame);
                    scope.setFormDirty();
                };

                scope.showSendFramesMenu = () => {
                    DialogModal.alert({
                        content: '<send-frames-to-lesson player-view-model="playerViewModel"></send-frames-to-lesson>',
                        scope: {
                            playerViewModel: scope.playerViewModel,
                        },
                        classes: ['overflow-visible'],
                    });
                };

                // called when the removeFrame button is pressed (with the $$activeFrame); removes the provided frame
                scope.removeFrame = frame => {
                    DialogModal.confirm({
                        text: `Are you sure you want to delete this frame?`,
                        confirmCallback: () => {
                            scope.onConfirmRemoveFrame(frame);
                        },
                    });
                };

                scope.onConfirmRemoveFrame = frame => {
                    scope.playerViewModel.removeFrame(frame);
                    scope.setFormDirty();
                };

                //----------------------------
                // Text Limit Enforcement
                //----------------------------

                scope._textLimitMap = {};

                scope.exceedsTextLimit = frame => scope._textLimitMap[frame.id];

                //----------------------------
                // Branching Support
                //----------------------------

                scope.hasBranching = frame => {
                    if (
                        scope.navigationPointers &&
                        scope.navigationPointers[frame.id] &&
                        scope.navigationPointers[frame.id].to
                    ) {
                        return scope.navigationPointers[frame.id].to.length > 0;
                    }
                    return false;
                };

                scope.hasMerging = frame => {
                    if (
                        scope.navigationPointers &&
                        scope.navigationPointers[frame.id] &&
                        scope.navigationPointers[frame.id].from
                    ) {
                        return scope.navigationPointers[frame.id].from.length > 0;
                    }
                    return false;
                };

                scope.hasAnnotations = frame => frame.annotations && Object.keys(frame.annotations).length > 0;

                scope.annotationTooltipContent = frame => {
                    const annotationElements = EditorConstants.frameAnnotationTypes
                        .filter(annotationConfig => Object.keys(frame.annotations).includes(annotationConfig.type))
                        .map(annotationConfig => {
                            const { notes } = frame.annotations[annotationConfig.type];

                            return `<li>
                                ${annotationConfig.label}
                                ${notes ? `<ul><li>Notes: "${notes}"</li></ul>` : ''}
                            </li>`;
                        })
                        .join(' ');
                    return `<h5>Annotations</h5> <ul>${annotationElements}</ul>`;
                };

                scope.annotationTooltipsOpen = [];
                scope.isAnnotationTooltipOpen = frameId => scope.annotationTooltipsOpen.includes(frameId);
                scope.openAnnotationTooltip = frameId => {
                    if (scope.annotationTooltipsOpen.indexOf(frameId) === -1) {
                        scope.annotationTooltipsOpen.push(frameId);
                    }
                };
                scope.closeAnnotationTooltip = frameId => {
                    const annotationIndex = scope.annotationTooltipsOpen.indexOf(frameId);
                    if (annotationIndex > -1) {
                        scope.annotationTooltipsOpen.splice(annotationIndex, 1);
                    }
                };

                scope.branchingFrames = frameId => {
                    const uniqFrames = [];
                    const guids = {};
                    scope.navigationPointers[frameId].to.forEach(guid => {
                        if (!guids[guid]) {
                            const frame = scope.lesson.frameForId(guid, true);
                            if (frame) {
                                uniqFrames.push(frame);
                                guids[guid] = true;
                            }
                        }
                    });
                    return uniqFrames;
                };

                scope.mergingFrames = frameId => {
                    const uniqFrames = [];
                    const guids = {};
                    scope.navigationPointers[frameId].from.forEach(guid => {
                        if (!guids[guid]) {
                            const frame = scope.lesson.frameForId(guid, true);
                            if (frame) {
                                uniqFrames.push(frame);
                                guids[guid] = true;
                            }
                        }
                    });
                    return uniqFrames;
                };

                scope.mergesFrom = frame => {
                    if (scope.hasMerging(frame)) {
                        return scope.navigationPointers[frame.id].from[0];
                    }
                    return undefined;
                };

                // draw lines between frames using canvas. hard to test.
                const canvas = elem.find('canvas')[0];
                const ctx = canvas.getContext('2d');

                function drawLine(i, j) {
                    const framesOffset = 75;
                    const thumbnailContainers = elem.find('.frame_thumbnail');
                    const frame1 = thumbnailContainers.eq(i);
                    const frame2 = thumbnailContainers.eq(j);
                    const yStart = framesOffset + frame1.offset().top - 85;
                    const yFinish = framesOffset + frame2.offset().top - 140;
                    const distance = yFinish - yStart;

                    ctx.beginPath();
                    ctx.moveTo(0, yStart);

                    ctx.bezierCurveTo(30, yStart + 0.25 * distance, 30, yStart + 0.75 * distance, 0, yFinish);
                    ctx.stroke();
                }

                function clearCanvas() {
                    $(canvas).hide();
                    canvas.width = window.innerWidth;
                    canvas.height = window.innerHeight;
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#FF4D63'; // $COLOR_V3_CORAL
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }

                scope.drawLinesFromFrame = (fromFrame, clear) => {
                    if (clear) {
                        clearCanvas();
                    }
                    if (!fromFrame) {
                        return;
                    }
                    const fromIndex = fromFrame.index();
                    const entry = scope.navigationPointers[fromFrame.id];

                    if (entry && entry.to) {
                        entry.to.forEach(frameId => {
                            const frame = scope.playerViewModel.lesson.frameForId(frameId, true);
                            if (frame) {
                                const toIndex = frame.index();
                                drawLine(fromIndex, toIndex);
                            }
                        });
                    }
                    $(canvas).show();
                };

                scope.drawLinesToFrame = (toFrame, clear) => {
                    if (clear) {
                        clearCanvas();
                    }
                    if (!toFrame) {
                        return;
                    }
                    const toIndex = toFrame.index();
                    const entry = scope.navigationPointers[toFrame.id];

                    if (entry && entry.from) {
                        entry.from.forEach(frameId => {
                            const frame = scope.playerViewModel.lesson.frameForId(frameId, true);
                            if (frame) {
                                const fromIndex = frame.index();
                                drawLine(fromIndex, toIndex);
                            }
                        });
                    }
                    $(canvas).show();
                };

                scope.removeLines = () => {
                    clearCanvas();
                };

                // setup initial display
                scope.showLines = {};
                clearCanvas();
            },
        };
    },
]);
