import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
    [
        'SuperModel',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonModel',
        'Lesson.FrameList.Frame.Componentized.Component.Text.TextModel',
        'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
        'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsModel',

        (
            SuperModel,
            MultipleChoiceChallengeModel,
            ChallengesContinueButtonModel,
            TextModel,
            AnswerListModel,
            ComponentOverlayModel,
            ChallengeOverlayBlankModel,
            InteractiveCardsModel,
        ) =>
            SuperModel.subclass({
                initialize(editorViewModel) {
                    this.editorViewModel = editorViewModel;
                    this.model = editorViewModel.model;
                    this.frame = this.editorViewModel.frame;

                    this.setupContinueButton();
                    this.model.behaviors.GotoNextOnChallengeComplete = {};
                    this.model.behaviors.CompleteOnAllChallengesComplete = {};
                },

                setChallengeTemplate(templateIdentifier) {
                    const challengeTemplateListener = this.model.challenges.on('childAdded', challenge => {
                        // We only want to set the template on a challenge if it is not yet set. Otherwise the
                        // toggling of challenge templates in the CheckManyMixin will be overridden
                        if (!challenge.editor_template) {
                            this.editorViewModel.editorViewModelFor(challenge).applyTemplate(templateIdentifier);
                        }
                    });

                    this.model.on('set:editor_template', () => {
                        challengeTemplateListener.cancel();

                        this.editorViewModel.model.challenges.forEach(challengeModel => {
                            challengeModel.editorViewModel.applyTemplate(undefined);
                        });
                    });
                },

                setupSharedText() {
                    // remove the sharedContentForText if it is not a text component
                    if (this.model.sharedContentForText && !this.model.sharedContentForText.isA(TextModel)) {
                        this.model.sharedContentForText.remove();
                    }

                    // if there is no sharedContentForText (or if it was just removed),
                    // then create a text component and assign it there
                    if (!this.model.sharedContentForText) {
                        this.model.sharedContentForText = TextModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }

                    return this.editorViewModel.editorViewModelFor(this.model.sharedContentForText);
                },

                // eslint-disable-next-line default-param-last
                setupSharedAnswerList(skin = 'buttons', minAnswers = 0, target, autoCleanup) {
                    if (angular.isUndefined(autoCleanup)) {
                        autoCleanup = true;
                    }

                    this.editorViewModel.setConfig({
                        supportsSharedAnswerList: {
                            target,
                        },
                    });

                    if (!this.editorViewModel.sharedAnswerList) {
                        this.editorViewModel.sharedAnswerList = AnswerListModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }

                    this.editorViewModel.sharedAnswerList.skin = skin;

                    function ensureMinAnswers(answerList) {
                        if (answerList) {
                            while (answerList && answerList.answers.length < minAnswers) {
                                answerList.editorViewModel.addAnswer();
                            }
                        }
                    }
                    this.sharedAnswerListListener = this.model.on('set:sharedContentForInteractive', ensureMinAnswers);
                    ensureMinAnswers(this.editorViewModel.sharedAnswerList);

                    // sometimes we don't want to do this automatically,
                    // because a template may need to refer to the shared answer list
                    // in it's teardown
                    if (autoCleanup) {
                        this.sharedAnswerListTeardownListener = this.model.on(
                            'set:editor_template',
                            this.teardownSharedAnswerList.bind(this),
                        );
                    }

                    // return reference to shared answer list
                    return this.editorViewModel.editorViewModelFor(this.editorViewModel.sharedAnswerList);
                },

                teardownSharedAnswerList() {
                    this.sharedAnswerListListener.cancel();
                    if (this.sharedAnswerListTeardownListener) {
                        this.sharedAnswerListTeardownListener.cancel();
                    }
                    this.editorViewModel.setConfig({
                        supportsSharedAnswerList: false,
                    });
                },

                setupAtLeastOneChallenge(overlayEditorViewModel) {
                    // overlayEditorViewModel can be undefined
                    if (this.model.challenges.length === 0) {
                        this.editorViewModel.addChallenge(0, overlayEditorViewModel);
                    }
                },

                setupExactlyOneChallenge() {
                    this.setupAtLeastOneChallenge();
                    if (this.model.challenges.length > 1) {
                        this.model.challenges.splice(0, 1);
                    }
                    return this.model.challenges[0].editorViewModel;
                },

                setupContinueButton() {
                    if (!this.frame.continueButton || !this.frame.continueButton.isA(ChallengesContinueButtonModel)) {
                        this.frame.continueButton = ChallengesContinueButtonModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }
                    this.frame.continueButton.targetComponent = this.model;
                },

                setupSharedComponentOverlay() {
                    // if there is no sharedContentForInteractiveImage,
                    // then create a ComponentOverlay component and assign it there
                    if (
                        !this.model.sharedContentForInteractiveImage ||
                        !this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel)
                    ) {
                        this.model.sharedContentForInteractiveImage =
                            ComponentOverlayModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                    }
                },

                teardownSharedComponentOverlay() {
                    if (
                        this.model.sharedContentForInteractiveImage &&
                        this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel)
                    ) {
                        this.model.sharedContentForInteractiveImage.remove();
                    }
                },

                setupSharedInteractiveCards() {
                    // if there is no sharedContentForInteractiveImage,
                    // then create an InteractiveCards component and assign it there
                    if (
                        !this.model.sharedContentForInteractiveImage ||
                        !this.model.sharedContentForInteractiveImage.isA(InteractiveCardsModel)
                    ) {
                        // Create InteractiveCards component
                        this.model.sharedContentForInteractiveImage =
                            InteractiveCardsModel.EditorViewModel.addComponentTo(this.frame).setup().model;

                        this.model.sharedContentForInteractiveImage.challengesComponent = this.model;
                        // Setup initial overlay component
                        const overlay = ComponentOverlayModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                        this.model.sharedContentForInteractiveImage.overlays.push(overlay);
                    }

                    return this.model.sharedContentForInteractiveImage.editorViewModel;
                },

                teardownSharedInteractiveCards() {
                    if (
                        this.model.sharedContentForInteractiveImage &&
                        this.model.sharedContentForInteractiveImage.isA(InteractiveCardsModel)
                    ) {
                        this.model.sharedContentForInteractiveImage.remove();
                    }
                },

                teardownSharedContentForImage() {
                    this.model.sharedContentForFirstImage = undefined;
                    this.model.sharedContentForSecondImage = undefined;
                },

                setupSharedImageOverlayBlanks() {
                    // remove anything that is not a ChallengeOverlayBlankModel from
                    // the overlay components
                    const componentsToRemove = [];
                    this.model.sharedContentForInteractiveImage.overlays.forEach(overlay => {
                        overlay.overlayComponents.forEach(component => {
                            if (!component.isA(ChallengeOverlayBlankModel)) {
                                componentsToRemove.push(component);
                            }
                        });
                        componentsToRemove.forEach(component => {
                            overlay.overlayComponents.remove(component);
                        });
                    });
                },

                setupBlankAndChallenge(challengesModel, challenge) {
                    let blank;
                    // Find the associated blank
                    challengesModel.sharedContentForInteractiveImage.overlays.forEach(overlay => {
                        if (blank) {
                            return;
                        }
                        overlay.overlayComponents.forEach(_blank => {
                            if (_blank.challenge === challenge) {
                                blank = _blank;
                            }
                        });
                    });

                    if (!blank) {
                        throw new Error('No blank found for challenge.');
                    }

                    challenge.editorViewModel.proxyEditorConfig = {
                        label: 'Blank',
                        editorViewModel: blank.editorViewModel,
                    };

                    return blank;
                },

                getComposeBlanksUserOptions() {
                    // Tricky: return an array of objects, with 'this' bound to the editorViewModel
                    // eslint-disable-next-line func-names
                    return function () {
                        return [
                            {
                                type: 'inputGetterSetter',
                                title: '% Correct Threshold',
                                disabled: () => !this.model.challenges[0],
                                get: () => {
                                    if (!this.model.challenges[0]) {
                                        return undefined;
                                    }
                                    return this.model.challenges[0].editorViewModel.correctThreshold;
                                },
                                set: val => {
                                    if (!this.model.challenges[0]) {
                                        return undefined;
                                    }
                                    this.model.challenges.forEach(challenge => {
                                        challenge.editorViewModel.correctThreshold = val;
                                    });
                                    return val;
                                },
                                inputType: 'number',
                                min: '0',
                                max: '100',
                                step: '1',
                            },
                            {
                                type: 'inputGetterSetter',
                                title: 'Case Sensitive',
                                disabled: () => !this.model.challenges[0],
                                get: () => {
                                    if (!this.model.challenges[0]) {
                                        return undefined;
                                    }
                                    return this.model.challenges[0].editorViewModel.caseSensitive;
                                },
                                set: val => {
                                    if (!this.model.challenges[0]) {
                                        return undefined;
                                    }
                                    this.model.challenges.forEach(challenge => {
                                        challenge.editorViewModel.caseSensitive = val;
                                    });
                                    return val;
                                },
                                inputType: 'checkbox',
                            },
                        ];
                    }.bind(this.editorViewModel)();
                },
            }),
    ],
);
