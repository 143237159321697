import angularModule from 'Editor/angularModule/scripts/editor_module';
import { isEqual, clone } from 'lodash/fp';

class SingleChangeError extends Error {
    constructor(message) {
        super(message);
        this.name = 'SingleChangeError';
    }
}

const createCacheKey = (modalKey, index) => JSON.stringify({ label: modalKey, index });

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesModalsEditor', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const ErrorLogService = $injector.get('ErrorLogService');

        function processModals(text) {
            let currentIndex = 0;

            const modals = this.model.modals;
            if (modals && modals.length > 0) {
                text = text.replace(/\[\[(.*?)\]\]/g, (_, contents) => {
                    // ensure we have a matching modal entry
                    const modalContent = modals[currentIndex];
                    if (modalContent === undefined) {
                        return undefined;
                    }

                    // moving the popover outside of the frame and into the root to eliminate
                    // stacking context issues which were invalidating z-index settings
                    const str = `<modal-popup modal-container="body" popover-class="frame-popover" index="${currentIndex}" event-type="lesson:modal-click"><span label>${contents}</span><span content dir="${this.lesson.localeDirection}"><cf-ui-component view-model="modalViewModel(${currentIndex})"></cf-ui-component></span></modal-popup>`;

                    currentIndex += 1;
                    return str;
                });
            }
            return text;
        }

        return new AModuleAbove({
            included(TextEditorViewModel) {
                // set up a callback to be run whenever a TextModel is initialized
                TextEditorViewModel.setCallback('after', 'initialize', function afterInit() {
                    const editorViewModel = this;
                    if (!this.model.modals) {
                        this.model.modals = [];
                    }
                    this.model.on('behavior_added:ProcessesModals', () => {
                        editorViewModel.$$modalKeys = editorViewModel.getModalKeysFromText();

                        editorViewModel.model.on('set:text', (newText, oldText) => {
                            editorViewModel.updateModals.bind(editorViewModel)();

                            // log if we detect a duplicate modal_id anomaly
                            // see: https://trello.com/c/DpBqezxE
                            if (
                                oldText &&
                                [...new Set(editorViewModel.$$modalKeys)].length < editorViewModel.$$modalKeys.length
                            ) {
                                ErrorLogService.notify('Text change generated duplicate modals.', null, {
                                    textDetails: {
                                        oldText,
                                        newText,
                                        modalKeys: editorViewModel.$$modalKeys,
                                        recentFormats: editorViewModel.$$recentFormats,
                                    },
                                });
                            }
                        });

                        editorViewModel.formatters.add('modals', processModals);
                    });

                    // right now, there's no way in the editor to remove this. Can add
                    // this (and test it) later if necessary
                    // this.on('behavior_removed:ProcessesModals', function() {
                    //                     this.off('set:text', onSetText);
                    //                 });
                });
            },

            updateModals() {
                this.updateModalKeys(this.getModalKeysFromText());
            },

            getModalKeysFromText() {
                const modalKeys = (this.model.text ? this.model.text.match(/\[\[(.*?)\]\]/g) : undefined) || [];

                return modalKeys.map((key, i) => createCacheKey(key.replace(/[[\]]/g, ''), i));
            },

            addModal() {
                const TextEditorViewModel = this.constructor;
                // We have to make sure to call setup() first before
                // setting the text on the new modal.  Otherwise the
                // textEditorViewModel will try to format the text before
                // all the behaviors are setup.
                const modal = TextEditorViewModel.addComponentTo(this.frame).setup().model;
                return modal;
            },

            updateModalKeys(newModalKeys) {
                // don't worry if this isn't immediately supplied. build off initial text parsing.
                if (!this.$$modalKeys) {
                    this.$$modalKeys = newModalKeys;
                }

                const origModalKeys = [...this.$$modalKeys]; // clone modalKeys array

                // This should never happen, but we have some bug causing us to
                // get messed up modals.  This should hopefully let editors
                // at least recover when in that state by ensuring there is
                // one modal for each of the original modal keys when we start this process.
                // See https://trello.com/c/6piQl5WG/969-bug-modal-will-not-process#
                if (origModalKeys.length !== this.model.modals?.length || 0) {
                    ErrorLogService.notify('modal keys do not agree with modals', null, {
                        origModalKeys: clone(origModalKeys),
                        modals: this.model.modals?.map(modal => modal.asJson()),
                        recentFormats: clone(this.$$recentFormats) || null,
                    });
                    while (origModalKeys.length > this.model.modals.length) {
                        origModalKeys.pop();
                    }

                    while (origModalKeys.length < this.model.modals.length) {
                        this.addModal();
                    }
                }

                if (isEqual(newModalKeys, origModalKeys)) return;

                // cache data
                this._saveDataForModals();

                // In most cases, only one modal will change at a time, either an
                // addition, a removal, or a change.  In those cases, we can be smart
                // about preserving the existing confusers, messages, etc.
                try {
                    // we can add a single modal
                    if (newModalKeys.length > origModalKeys.length) {
                        this._addSingleModal(newModalKeys, origModalKeys);
                    }

                    // we can remove a single modal
                    else if (newModalKeys.length < origModalKeys.length) {
                        this._removeSingleModal(newModalKeys, origModalKeys);
                    }

                    // we can change a single modal
                    else {
                        this._changeSingleModal(newModalKeys, origModalKeys);
                    }

                    // If there is more than once change, then an error will be thrown. In
                    // that case we have to blow away existing questions entirely and
                    // start over from scratch.
                } catch (e) {
                    if (e.name === 'SingleChangeError') {
                        this.$$modalKeys = [];
                        this.model.modals = [];
                        const oneByOne = [];
                        newModalKeys.forEach(modalKey => {
                            oneByOne.push(modalKey);
                            this.updateModalKeys(oneByOne);
                        });
                    } else {
                        throw e;
                    }
                }

                this.$$modalKeys = [...newModalKeys];
            },

            _addSingleModal(newModalKeys, origModalKeys) {
                const additions = [];
                let originalClone = [...origModalKeys];
                let origIndex = 0;
                newModalKeys.forEach((newModalKey, i) => {
                    // use origIndex to follow along in the original array,
                    // checking that each newModalKey matches up with the existing modal
                    if (origModalKeys[origIndex] !== newModalKey) {
                        // when we find one that doesn't match up, we assume it needs
                        // to be added, and we step ahead by one in the original array
                        if (additions.length) return;
                        additions.push({
                            index: i,
                            modal: newModalKey,
                        });
                        // add the new modal to the clone of the original
                        originalClone.splice(i, 0, newModalKey);
                        const newModalKeyObj = JSON.parse(newModalKey);
                        // update the index for every modal after the addition
                        originalClone = originalClone.map(key => {
                            const { label, index } = JSON.parse(key);
                            if (label !== newModalKeyObj.label && index >= newModalKeyObj.index) {
                                return createCacheKey(label, index + 1);
                            }
                            return key;
                        });
                    } else {
                        origIndex += 1;
                    }
                });
                // if only one modal was correctly added the clone should match the newModalKeys
                const onlyOneModalAdded = newModalKeys.every((key, i) => key === originalClone[i]);
                if (!onlyOneModalAdded) {
                    throw new SingleChangeError('Expecting to add exactly one modal');
                }

                const newModalKey = additions[0].modal;
                const index = additions[0].index;

                let modal;

                const cachedModal = this.$$cachedModalData && this.$$cachedModalData[newModalKey];
                if (cachedModal) {
                    if (cachedModal.frame() !== this.frame) {
                        this.frame.addComponent(cachedModal);
                    }
                    modal = cachedModal;
                } else {
                    modal = this.addModal();
                    modal.text = JSON.parse(newModalKey).label;
                }
                if (!this.model.modals) {
                    this.model.modals = [];
                }

                this.model.modals.splice(index, 0, modal);
            },

            _removeSingleModal(newModalKeys, origModalKeys) {
                const removals = [];
                let originalClone = [...origModalKeys];
                let newIndex = 0;
                origModalKeys.forEach((origModal, i) => {
                    // use newIndex to follow along in the new array,
                    // checking that each origModal matches up with the new modal
                    if (newModalKeys[newIndex] !== origModal) {
                        // when we find one that doesn't match up, we assume it needs
                        // to be removed, and we do not step ahead our counter in the new array
                        if (removals.length) return;
                        removals.push({
                            index: i,
                        });
                        // remove the "removal" modal from the clone of the new modal keys
                        originalClone.splice(i, 1);
                        const keyObj = JSON.parse(origModal);
                        // update the index on every modal after the removal
                        originalClone = originalClone.map(key => {
                            const { label, index } = JSON.parse(key);
                            if (label !== keyObj.label && index >= keyObj.index) {
                                return createCacheKey(label, index - 1);
                            }
                            return key;
                        });
                    } else {
                        newIndex += 1;
                    }
                });
                // if only one modal was correctly removed, the clone of the original (minus the removal)
                // will match the newModalKeys
                const onlyOneModalRemoved = originalClone.every((key, i) => key === newModalKeys[i]);

                if (!onlyOneModalRemoved) {
                    throw new SingleChangeError('Expecting to remove exactly one modal');
                }

                this.model.modals.splice(removals[0].index, 1);
            },

            _changeSingleModal(newModalKeys, origModalKeys) {
                const changes = [];
                origModalKeys.forEach((origModal, i) => {
                    const newModalKey = newModalKeys[i];
                    if (newModalKey !== origModal) {
                        changes.push({
                            modal: newModalKey,
                            index: i,
                        });
                    }
                });

                if (changes.length !== 1) {
                    throw new SingleChangeError('Expecting to change exactly one modal');
                }

                const newModalKey = changes[0].modal;
                const i = changes[0].index;

                const cachedModal = this.$$cachedModalData && this.$$cachedModalData[newModalKey];
                if (cachedModal) {
                    if (cachedModal.frame() !== this.frame) {
                        this.frame.addComponent(cachedModal);
                    }
                    this.model.modals[i] = cachedModal;
                }
                // delete cache references to empty modals after a change
                // this prevents an issue where adding a new modal at the same index as a previous one
                // causes the to reference the same model
                const deleteKeys = Object.keys(this.$$cachedModalData).reduce((prev, curr) => {
                    const keyObj = JSON.parse(curr);
                    if (keyObj.label === '') prev.push(curr);
                    return prev;
                }, []);

                deleteKeys.forEach(key => {
                    delete this.$$cachedModalData[key];
                });
            },

            // save modal data in a cache for later re-use
            _saveDataForModals() {
                if (!this.$$cachedModalData) {
                    this.$$cachedModalData = {};
                }

                this.$$modalKeys.forEach((modalKey, i) => {
                    this.$$cachedModalData[modalKey] = this.model.modals[i];
                });
            },
        });
    },
]);
