import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Image.ImageEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',

        ComponentEditorViewModel =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');

                return {
                    directiveName: 'cf-image-editor',

                    setup() {
                        return this;
                    },
                };
            }),
    ],
);
