import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
        'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',

        (
            ComponentEditorViewModel,
            ChallengeOverlayBlankModel,
            TextEditorViewModel,
            HasTextOrImageEditorViewModelMixin,
        ) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel');

                // Returns the editor view models for challenges that are associated with this overlay component
                Object.defineProperty(this.prototype, 'challengesEditorViewModels', {
                    get() {
                        return this.model.overlayComponents.map(overlayModel => {
                            if (!overlayModel.isA(ChallengeOverlayBlankModel)) {
                                throw new Error(
                                    'challengesEditorViewModels can only be used when overlay components are challenge overlay blanks.',
                                );
                            }
                            return overlayModel.challenge.editorViewModel;
                        });
                    },
                });

                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    directiveName: 'cf-component-overlay-editor',

                    setup() {
                        const textHelper = TextEditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;

                        return this;
                    },

                    initialize($super, model) {
                        $super(model);

                        this.model.overlayComponents = this.model.overlayComponents || [];
                        this.model.overlayOptions = this.model.overlayOptions || {};

                        this.model.overlayComponents.on('childAdded', component => {
                            this.model.overlayOptions[component.id] = this.model.overlayOptions[component.id] || {
                                x: 0,
                                y: 0,
                                units: '%',
                            };

                            // the component needs a reference to the overlayOptions so it can include
                            // the editor widget in the right place in the info panel
                            component.editorViewModel.overlayOptions = this.model.overlayOptions[component.id];
                        });

                        this.model.overlayComponents.on('childRemoved', component => {
                            delete this.model.overlayOptions[component.id];
                        });

                        this.model.on('set:text', text => {
                            if (!text) {
                                return;
                            }

                            // Clear the background_color when making this component a text
                            this.model.background_color = 'beige';

                            // FIXME: Once all clients support Text or Image instead of MainComponent
                            // we can remove this
                            // See https://trello.com/c/e5vFpRcK
                            this.model.main_component_id = undefined;
                        });

                        this.model.on('set:image', image => {
                            if (!image) {
                                return;
                            }

                            // Clear the background_color when making this compoment an image
                            this.model.background_color = undefined;

                            // FIXME: Once all clients support Text or Image instead of MainComponent
                            // we can remove this
                            // See https://trello.com/c/e5vFpRcK
                            this.model.main_component_id = image && image.id;
                        });
                    },
                };
            }),
    ],
);
