import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('TextListDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const $sce = $injector.get('$sce');

        const TextListDiff = SuperModel.subclass(() => ({
            isTextListDiff: true,

            initialize(oldList, newList) {
                this.oldList = oldList || [];
                this.newList = newList || [];

                let html = '';
                html = this._addListToHtml(html, this.oldList, 'removed');
                html = `${html}<div class="text-list arrow"><i class="fa fa-arrow-right"></i></div>`;
                html = this._addListToHtml(html, this.newList, 'added');

                this.html = $sce.trustAsHtml(html);
            },

            _addListToHtml(html, list, _class) {
                html += '<div class="text-list">';
                if (!list) {
                    html = `${html}<div class="${_class}">()</div>`;
                } else {
                    html += _.chain(list)
                        .map(item => `<div class="${_class}">${item}</div>`)
                        .join('\n')
                        .value();
                }
                html += '</div>';
                return html;
            },
        }));

        return TextListDiff;
    },
]);
