(angular => {
    function ooDirective(name, injectablesAndFactory) {
        /* jshint validthis:true */

        // if just a factory was passed in, then convert to bracket notation
        if (Object.prototype.toString.call(injectablesAndFactory) !== '[object Array]') {
            injectablesAndFactory = [injectablesAndFactory];
        }

        // if nothing was injected in, then assume they really wanted DirectiveBase
        // as the super directive
        if (injectablesAndFactory.length === 1) {
            injectablesAndFactory.unshift('DirectiveBase');
        }

        // pull the factory off the end of the array
        const factory = injectablesAndFactory.pop();
        const injectables = injectablesAndFactory;

        // create a factory to hold the options
        this.factory(
            name,
            injectables.concat([
                (...args) => {
                    const result = factory(...args);
                    return result;
                },
            ]),
        );

        // create the directive
        this.directive(name, [
            name,
            directiveOptions => {
                if (!directiveOptions.generateOptions) {
                    throw new Error(`Directive used in ooDirective must inherit from DirectiveBase. ${name} does not.`);
                }
                return directiveOptions.generateOptions();
            },
        ]);
    }

    angular.moduleWithoutOoDirective = angular.module;
    angular.module = function (...args) {
        const module = angular.moduleWithoutOoDirective.apply(this, args);
        module.ooDirective = ooDirective;
        return module;
    };
})(angular);
