import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/editor_hints.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editorHints', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');

        return {
            templateUrl,
            scope: {},
            restrict: 'E',
            link(scope) {
                scope.currentTab = 'formatting';

                // Hack: reflect on the MathJax config to find all custom defined units
                const arabicDict = $window.MathJax.Hub.config.Arabic.dict;
                scope.customMathJaxArabic = Object.keys(arabicDict)
                    .map(
                        key =>
                            `<strong>\\${arabicDict[key][0]}</strong> is equivalent to <strong>${arabicDict[key][2][0]}</strong> in English and <strong>${arabicDict[key][2][1]}</strong> in Arabic`,
                    )
                    .sort();
            },
        };
    },
]);
