import angularModule from 'Editor/angularModule/scripts/editor_module';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/no_interaction/no_interaction_info_panel.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('noInteractionInfoPanel', [
    '$injector',
    _ => ({
        restrict: 'E',
        templateUrl,
        scope: {
            frameViewModel: '<',
        },
        link(scope) {
            Object.defineProperty(scope, 'frame', {
                get() {
                    return scope.frameViewModel.frame;
                },
            });

            scope.frame.initializeEditorViewModels();
        },
    }),
]);
