import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/tile_prompt/tile_prompt_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfTilePromptEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',
    'Lesson.FrameList.Frame.Componentized.Component.Image.ImageEditorViewModel',

    (EditorDirHelper, TextEditorViewModel, ImageEditorViewModel) =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                Object.defineProperty(scope, 'contentType', {
                    get() {
                        return this.model.contentType;
                    },
                    set(val) {
                        if (val === 'image') {
                            // FIXME: remove only if not used elsewhere
                            this.model.text.editorViewModel.remove();
                            const imageHelper = ImageEditorViewModel.addComponentTo(this.frame).setup();
                            this.editorViewModel.model.image = imageHelper.model;
                        } else if (val === 'text') {
                            // FIXME: remove only if not used elsewhere
                            this.model.image.editorViewModel.remove();
                            const textHelper = TextEditorViewModel.addComponentTo(this.frame).setup();
                            this.editorViewModel.model.text = textHelper.model;
                        }
                    },
                });
            },
        }),
]);
