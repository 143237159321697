import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/edit_lesson_preview_mode.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { storeProvider } from 'ReduxHelpers';

const templateUrl = cacheAngularTemplate(angularModule, template);

/*
    What is going on here:

    * The playerViewModel from the editor is passed down into this directive.
    * This directive puts two iframes on the screen and loads up /editor/lesson/preview in each one
    * Within each iframe, a previewLesson directive is created, and it creates
      a registerPreviewLessonMasterScope method on the iframe's window
    * Once this directive detects that the registerPreviewLessonMasterScope method
      has been created, it calls that method, passing the scope for this
      directive into the method.  The scope for this directive then becomes the
      'sharedPreviewScope' for the previewLesson directive in the iframe.
    * Now that this directive is the sharedPreviewScope for the directive in the iframe,
      they can share a playerViewModel, which means they share all the view models for
      the active frame, which means that (almost) everything that happens is
      kept in sync, since almost everything is stored in the view models.
*/
angularModule.directive('editLessonPreviewMode', [
    '$injector',
    function factory($injector) {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const $window = $injector.get('$window');
        const Capabilities = $injector.get('Capabilities');
        const $timeout = $injector.get('$timeout');
        const safeApply = $injector.get('safeApply');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope, elem) {
                const iframeTimeouts = {};

                function cancelIframeTimeouts() {
                    Object.values(iframeTimeouts).forEach(timeout => $timeout.cancel(timeout));
                }

                function listenForIframesToLoad() {
                    // loading up iframes and running animations at the
                    // same time is just too much for the browser to handle. Wait
                    // for app header animation to finish.
                    cancelIframeTimeouts();
                    scope.iframesReady = false;
                    scope.loadingSlowly = false;
                    iframeTimeouts.delayBeforeInjecting = $timeout(() => {
                        scope.showIframes = true;
                        $timeout(() => {
                            elem.find('iframe').each((_index, iframe) => {
                                const thisOne = iframe;
                                const id = $(iframe).attr('data-id');
                                $(thisOne).on('load', () => {
                                    scope.onIframeLoad(id);
                                });
                            });
                        });
                    }, 500);
                }
                listenForIframesToLoad();

                AppHeaderViewModel.toggleVisibility(false);

                const showLocalStorageKey = 'editLessonPreviewMode:show';
                const defaultShow = 'desktop';

                if (!Capabilities.localStorage) {
                    const modalOptions = {
                        title: 'Preview Error',
                        content:
                            'Preview mode will not work properly without local storage enabled. If using Safari, please disable Private Browsing mode.',
                        classes: ['server-error-modal'],
                    };
                    $injector.get('DialogModal').alert(modalOptions);
                }

                Object.defineProperty(scope, 'showScreen', {
                    get() {
                        const locaStorageVal = $window.localStorage.getItem(showLocalStorageKey);
                        const allowedValues = {
                            desktop: true,
                            iphone: true,
                            both: true,
                        };
                        if (allowedValues[locaStorageVal]) {
                            return locaStorageVal;
                        }
                        return defaultShow;
                    },
                    set(val) {
                        $window.localStorage.setItem(showLocalStorageKey, val);
                    },
                });

                let retryCount = 0;

                // We need to mock out the actual method in specs so
                // that we can setup registerShartedPreviewScope on the
                // content window.  Having this method allows us to replace
                // the whole iframe with a mock
                scope.getIframe = iframeId => elem.find(`iframe[data-id='${iframeId}']`)[0];

                scope.onIframeLoad = (iframeId, i) => {
                    if (scope.$$destroyed) {
                        return;
                    }

                    const iframe = scope.getIframe(iframeId);
                    const timeoutId = `waiting for registerSharedPreviewScope on ${iframeId}`;

                    i = i || 0;
                    if (!iframe) {
                        throw new Error(`No iframe for ${iframeId}`);
                    }
                    $timeout.cancel(iframeTimeouts[timeoutId]);
                    if (iframe.contentWindow?.registerSharedPreviewScope) {
                        // Share the main window's Redux store provider with the iframe so that
                        // all windows use the same store.
                        iframe.contentWindow.storeProvider = storeProvider;
                        iframe.contentWindow.registerSharedPreviewScope(
                            scope,
                            $rootScope.currentUser,
                            $injector.get('ConfigFactory'),
                        );
                        scope.iframesReady = true;
                        safeApply(scope);
                    } else {
                        if (i > 10 && !scope.loadingSlowly) {
                            scope.loadingSlowly = true;
                            if (retryCount === 1) {
                                $injector
                                    .get('ErrorLogService')
                                    .notify('Preview iframes failed to load twice in a row');
                            }
                            safeApply(scope);
                        }

                        iframeTimeouts[timeoutId] = $timeout(
                            () => {
                                scope.onIframeLoad(iframeId, i + 1);
                            },
                            100,
                            false,
                        );
                    }
                };

                scope.reloadIframes = () => {
                    retryCount += 1;
                    listenForIframesToLoad();
                    scope.reloadingIframes = true;
                    $timeout(() => {
                        scope.reloadingIframes = false;
                    });
                };

                // Any time one of the iframes is digested, digest this
                // as well, to make sure that changes are shared between
                // multiple windows.
                scope.onPreviewDigest = () => {
                    safeApply(scope);
                };

                scope.$on('$destroy', () => {
                    AppHeaderViewModel.toggleVisibility(true);
                    cancelIframeTimeouts();
                });

                scope.exitPreview = () => {
                    scope.$emit('editLesson:exitPreviewMode');
                };
            },
        };
    },
]);
