import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('FrameDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const AuthorCommentsDiff = $injector.get('AuthorCommentsDiff');
        const AnnotationsDiff = $injector.get('AnnotationsDiff');
        const JsonDiff = $injector.get('JsonDiff');
        const ComponentDiff = $injector.get('ComponentDiff');

        const FrameDiff = SuperModel.subclass(() => ({
            initialize(oldFrame, newFrame) {
                const self = this;
                this.oldFrame = oldFrame;
                this.newFrame = newFrame;

                const oldJson = oldFrame ? oldFrame.asJson() : {};
                const newJson = newFrame ? newFrame.asJson() : {};

                [oldJson, newJson].forEach(json => {
                    // author comments will be handled in the AuthorCommentsDiff,
                    // so do not bother to diff them twice
                    delete json.author_comments;
                });

                this.componentsDiff = new JsonDiff(oldJson.components, newJson.components);

                this.added = !oldFrame;
                this.removed = !newFrame;

                this.authorCommentsDiff = new AuthorCommentsDiff(oldFrame, newFrame);
                this.annotationsDiff = new AnnotationsDiff(oldFrame, newFrame);

                const componentIds = _.chain(oldFrame ? oldFrame.components : [])
                    .union(newFrame ? newFrame.components : [])
                    .map('id')
                    .uniq()
                    .value();

                this.textDiffs = [];
                this.imageChanges = [];
                this.otherDiffs = [];
                this.componentDiffs = _.map(componentIds, id => {
                    const oldComponent = oldFrame && oldFrame.getComponentById(id);
                    const newComponent = newFrame && newFrame.getComponentById(id);
                    const componentDiff = new ComponentDiff(oldComponent, newComponent);
                    self.textDiffs = self.textDiffs.concat(componentDiff.textDiffs);
                    self.imageChanges = self.imageChanges.concat(componentDiff.imageChanges);
                    self.otherDiffs = self.otherDiffs.concat(componentDiff.otherDiffs);
                });

                // show main text first
                this.textDiffs = _.sortBy(this.textDiffs, textDiff => {
                    if (textDiff.usage === 'Main Text') {
                        return 'AAAAAAA';
                    }
                    return textDiff.usage;
                });

                this.edited =
                    oldFrame &&
                    newFrame &&
                    (this.componentsDiff.edited ||
                        this.authorCommentsDiff.hasNewComments ||
                        this.annotationsDiff.changes.length > 0 ||
                        this.textDiffs.length > 0 ||
                        this.imageChanges.length > 0);
            },
            displayIndex() {
                return (this.newFrame && this.newFrame.displayIndex()) || this.oldFrame.displayIndex();
            },
            frameType() {
                return (this.newFrame && this.newFrame.editor_template) || this.oldFrame.editor_template;
            },
        }));

        return FrameDiff;
    },
]);
