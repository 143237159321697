import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/translatable_lesson_export/translatable_frame_export.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { watchFrameContainerSizeChanges } from 'Lessons';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('translatableFrameExport', [
    '$injector',

    function factory($injector) {
        const $timeout = $injector.get('$timeout');

        return {
            templateUrl,
            restrict: 'E',
            scope: {
                frame: '<',
                singleColumn: '<',
                showFrameContent: '<',
                showImagesInTable: '<',
            },
            link(scope, elem) {
                watchFrameContainerSizeChanges('frameContainerClasses', {
                    scope,
                    elem,
                    window: $injector.get('$window'),
                });

                scope.renderStarted = () => {
                    scope.$emit('frame:rendering', scope.$id);
                };

                scope.renderCompleted = () => {
                    scope.$emit('frame:rendered', scope.$id);
                };

                scope.renderStarted();

                scope.$watch('frame', frame => {
                    scope.frameViewModel = frame.createFrameViewModel();

                    // Build hash of ID to referencing component(s) types
                    const idToReferencingComponents = {};
                    frame.components.forEach(component => {
                        const references = component.referencedComponents();
                        let type = component.type;
                        // Make the type prettier for output
                        type = type.replace(/Model/, '').replace(/TextImageInteractive|Challenges/, 'Main Text');
                        references.forEach(reference => {
                            const referencing = idToReferencingComponents[reference.id] || [];
                            referencing.push(type);
                            idToReferencingComponents[reference.id] = referencing;
                        });
                    });

                    // Iterate over all text components and create entries in tsv
                    const textComponents = frame.componentsForType('Text.TextModel');
                    const texts = [];
                    textComponents.forEach(textModel => {
                        texts.push({
                            type: idToReferencingComponents[textModel.id].join(','),
                            id: textModel.id,
                            text: textModel.text,
                        });
                    });
                    scope.texts = texts;

                    // Other than text models, we also need to translate the expectedText property
                    // in cases where a challenge is unlinked from the blank.
                    scope.unlinkedAnswerMatchers = _.chain(
                        frame.componentsForType('Challenge.UserInputChallenge.UserInputChallengeModel'),
                    )
                        .map(challenge => {
                            if (challenge.unlink_blank_from_answer) {
                                const answerMatchers = challenge.validator.expectedAnswerMatchers;
                                const count = answerMatchers ? answerMatchers.length : 0;
                                if (count !== 1) {
                                    throw new Error('Expected exactly 1 answer matcher');
                                }
                                const answerMatcher = answerMatchers[0];
                                if (!answerMatcher.expectedText) {
                                    throw new Error('expectedText not defined');
                                }
                                return answerMatcher;
                            }
                            return undefined;
                        })
                        .compact()
                        .value();

                    // This is used for the copyright export
                    if (scope.showImagesInTable) {
                        // Iterate over all image components and create entries in tsv
                        const imageComponents = frame.componentsForType('Image.ImageModel');
                        const images = [];
                        imageComponents.forEach(imageModel => {
                            // skip unreferenced images
                            const referencingComponents = idToReferencingComponents[imageModel.id];
                            if (!referencingComponents) {
                                return;
                            }

                            images.push({
                                type: referencingComponents.join(', '),
                                id: imageModel.id,
                                src: imageModel.src,
                            });
                        });
                        scope.images = images;
                    }

                    $timeout(() => {
                        scope.renderCompleted();
                    }, 100);
                });
            },
        };
    },
]);
