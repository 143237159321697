import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/list_lessons_create.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listLessonsCreate', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');
        const FrameList = $injector.get('Lesson.FrameList');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lesson: '=thing',
                goBack: '&',
                created: '&',
            },
            link(scope) {
                scope.save = () =>
                    FrameList.create({
                        title: scope.newLessonTitle,
                    }).then(response => {
                        scope.created({
                            $thing: response.result,
                        });

                        ngToast.create({
                            content: 'Lesson saved',
                            className: 'success',
                        });
                    });
            },
        };
    },
]);
