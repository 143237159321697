import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/image_selector.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

/*
    There are two things defined in this file:

    1. cfImageSelectorImageOptions factory - generates all of the image
        options that should be available for a particular frame and makes
        that list available to share between all instances of <cf-image-selector>
    2. cfImageSelector directive - the directive that holds an
        actual dropdown list
*/
angularModule.factory('cfImageSelectorImageOptions', [
    '$injector',
    $injector => {
        /*
            cfImageSelectorImageOptions defines a global object that
            has a method ImageOptions.getImages(frame) which will return
            a list of all available images for that frame.  That method
            is optimized so that the list will be strategically re-generated
            only when things have changed.

            This factory defines two classes:
            * ImageOption represents a single option in the dropdown
            * ImageOptions represents all of the options in the list

            Notes:
            * the list includes all the images AVAILABLE for a frame,
                which is all the images from the entire lesson
            * if an image is duplicated across frames, then those images
                will all be folded into a single option in the list. In this
                sense, "duplicated" means that two images have the same label
                and url.  Since the url is generated as a SHA of the image content,
                that means the images have the same label and content.
        */

        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const SuperModel = $injector.get('SuperModel');
        let ImageOptions;

        /*
            As noted above, two image models with the same label
            and url will be folded into a single option.  This key
            is how we manage that */
        function optionKey(imageModel) {
            const src = (imageModel.src || '').split('?')[0]; // remove the alteredUrl key
            return src + imageModel.label;
        }

        /*
            ImageOption class represents a single option in the
            select dropdown */
        const ImageOption = SuperModel.subclass(function () {
            /* The label combines the frame index(es) and the
                image label.  Examples:
                * (1) image_label
                * (2,3) this_image_is_in_two_frames */
            Object.defineProperty(this.prototype, 'label', {
                get() {
                    return `(${this.frameIndexString}) ${this.modelLabel}` || 'UNLABELED';
                },
            });

            return {
                initialize(imageModel, action) {
                    /*
                        action is either
                        * 'select'
                            reserved for images in the target frame.  Selecting
                            this option from the dropdown will simply assign a
                            reference to the image in the desired component.

                        * 'copy'
                            reserved for images that only exist in other frames.
                            Selecting this option will copy the image into this
                            frame and assign a reference to the image in the desired component. */
                    this.action = action;

                    // since an image can exist in multiple frames, it can have multiple frame indexes
                    this.frameIndexes = {};
                    this.frameIndexString = '';
                    this.originalUrl = imageModel.src;
                    this.modelLabel = imageModel.label;
                    this.key = optionKey(imageModel);
                    this.imageModel = imageModel;
                    if (action === 'select') {
                        // we only have to listen for changes
                        // on images from this frame, since there is no way
                        // to change the labels from other frames
                        this.labelListeners = [
                            this.imageModel.on('set:label', ImageOptions.destroy.bind(ImageOptions)),
                            this.imageModel.on('set:image', ImageOptions.destroy.bind(ImageOptions)),
                        ];
                    }
                },

                // each time we find this image in a new frame, we call
                // addFrameIndex on it
                addFrameIndex(frameIndex) {
                    this.frameIndexes[frameIndex] = true;
                    this.frameIndexString = Object.keys(this.frameIndexes)
                        .sort()
                        .map(index => Number(index) + 1)
                        .join(',');
                },

                // check if a particular image model matches this option
                matches(imageModel) {
                    return optionKey(imageModel) === this.key;
                },

                destroy() {
                    if (this.labelListeners) {
                        this.labelListeners.forEach(listener => {
                            listener.cancel();
                        });
                    }
                },
            };
        });

        /*
            ImageOptions class represents all of the options in the dropdown.  It
            includes a list of ImageOption instances */
        ImageOptions = SuperModel.subclass(function () {
            /*
                ImageOptions is a singleton, since all of the dropdowns on the
                screen at one time can share the same list.  currentOptions,
                getImages, and destroy are class methods that handle the
                management of the singleton class.
            */
            this.extend({
                currentOptions: undefined,
                getImages(frame) {
                    if (this.currentOptions && this.currentOptions.frame !== frame) {
                        // See comment above about ImageOptions being a singleton.
                        // It is expected that old scopes using ImageOptions for a different frame have
                        // been destroyed before a new scope calls getImages for a new frame.
                        throw new Error(
                            'We do not support having two different instances of ImageOptions for different frames.',
                        );
                    }
                    if (!this.currentOptions) {
                        this.currentOptions = new ImageOptions(frame);
                    }
                    return this.currentOptions.options;
                },
                destroy() {
                    if (!this.currentOptions) {
                        return;
                    }
                    this.currentOptions.options.forEach(option => {
                        option.destroy();
                    });
                    this.currentOptions = undefined;
                },
            });

            /*
                'options' is the list of ImageOption instances.  It will
                be regenerated any time an image has been added or removed from
                the list, or any time an existing image has changed. */
            Object.defineProperty(this.prototype, 'options', {
                get() {
                    // comparing imagesForFrame by identity works because of the
                    // way componentized frames cached components to use in componentsForType
                    const imagesForFrame = this.frame.componentsForType(ImageModel);
                    const frameCount = this.frame.lesson().frames.length;
                    if (
                        !this.$$options ||
                        imagesForFrame !== this.prevImagesForFrame ||
                        frameCount !== this.prevFrameCount
                    ) {
                        this._generateOptions(imagesForFrame, frameCount);
                    }

                    return this.$$options;
                },
            });

            return {
                /*
                    Each instance of ImageOptions is specific to a frame,
                    since

                    1. the images for the frame are put at the top of the list
                    2. assumptions are made that only images from this frame can change
                    3. the action is different when selecting images from this frame
                        compared with selecting images from other frames (see 'action' above)
                */
                initialize(frame) {
                    this.frame = frame;
                },

                // generate the list of options from the list of images in the lesson
                _generateOptions(imagesForFrame, frameCount) {
                    this.$$options = [];
                    const frameIndex = this.frame.index();
                    this._addImages(this.frame, frameIndex, this.$$options, 'select');

                    this.frame.lesson().frames.forEach((_frame, index) => {
                        if (_frame === this.frame) {
                            return;
                        }

                        this._addImages(_frame, index, this.$$options, 'clone');
                    });

                    this.prevImagesForFrame = imagesForFrame;
                    this.prevFrameCount = frameCount;
                },

                // add a list of images from a particular frame to the
                // options list
                _addImages(frame, frameIndex, options, action) {
                    _.sortBy(frame.imageComponents, 'label').forEach(imageModel => {
                        const key = optionKey(imageModel);
                        if (!options[key]) {
                            const cacheEntry = new ImageOption(imageModel, action);
                            options.push(cacheEntry);
                            options[key] = cacheEntry;
                        }

                        options[key].addFrameIndex(frameIndex);
                    });
                },
            };
        });

        return ImageOptions;
    },
]);

angularModule.directive('cfImageSelector', [
    '$injector',

    $injector => {
        const $timeout = $injector.get('$timeout');
        const ImageOptions = $injector.get('cfImageSelectorImageOptions');

        return {
            restrict: 'E',
            scope: {
                frameViewModel: '<',
                imageModel: '=', // sends new value back up
                name: '@',
                disabled: '<',
            },
            templateUrl,
            link(scope) {
                const noneOption = {
                    label: '(No Image)',
                };

                Object.defineProperty(scope, 'imageOptions', {
                    get() {
                        return [noneOption].concat(ImageOptions.getImages(this.frameViewModel.frame));
                    },
                });

                Object.defineProperty(scope, 'selectedImageOption', {
                    get() {
                        if (!scope.imageModel) {
                            this.$$selectedImageOption = noneOption;
                        } else if (
                            !this.$$selectedImageOption ||
                            this.$$selectedImageOption.model !== scope.imageModel
                        ) {
                            this.$$selectedImageOption = noneOption;
                            ImageOptions.getImages(this.frameViewModel.frame).forEach(option => {
                                if (option.matches(scope.imageModel)) {
                                    this.$$selectedImageOption = option;
                                }
                            });
                        }

                        return this.$$selectedImageOption;
                    },
                    set(option) {
                        const oldValue = scope.imageModel;
                        let newValue;
                        if (option === noneOption || !option) {
                            newValue = undefined;
                        } else if (option.action === 'select') {
                            newValue = option.imageModel;
                        } else {
                            newValue = option.imageModel.clone(true);
                            scope.frameViewModel.frame.addComponent(newValue);
                        }

                        if (oldValue === newValue) {
                            return;
                        }

                        scope.imageModel = newValue;

                        // if the current image component does not have an
                        // image in it (i.e. none has been uploaded yet, then
                        // delete the component)
                        if (oldValue && !oldValue.image) {
                            // wait until the change to scope.imageModel has
                            // been applied before removing the old model.  Otherwise
                            // the content type on has-text-or-image will be toggled
                            // back to text (scope.$evalAsync does not work here)
                            $timeout(() => {
                                oldValue.remove();
                            });
                        }
                    },
                });

                scope.$watch('frameViewModel.frame.lesson().frames.$$sortableKey', (newValue, oldValue) => {
                    // any time the frames are re-ordered, regenerate all
                    // the labels (This will only catch changes to the frames array
                    // initiated by the sortable directive)
                    if (oldValue && oldValue !== newValue) {
                        ImageOptions.destroy();
                    }
                });

                scope.$on('$destroy', () => {
                    ImageOptions.destroy();
                });
            },
        };
    },
]);
