import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptEditorViewModel',

    [
        '$injector',

        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const TextEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',
            );
            const HasTextOrImageEditorViewModelMixin = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',
            );

            return ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.TilePrompt.TilePromptModel');

                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    directiveName: 'cf-tile-prompt-editor',

                    setup() {
                        const textHelper = TextEditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;

                        return this;
                    },
                };
            });
        },
    ],
);
