import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',

        (ComponentEditorViewModel, ComponentOverlayModel) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.InteractiveCards.InteractiveCardsModel');

                Object.defineProperty(this.prototype, 'firstImage', {
                    get() {
                        return _.chain(this.model.overlays).map('image').compact().first().value();
                    },
                    set(component) {
                        this.ensureFirstInteractiveCard().image = component;
                        return component;
                    },
                });

                return {
                    directiveName: 'cf-interactive-cards-editor',

                    setup() {
                        return this;
                    },

                    initialize($super, model) {
                        $super(model);

                        this.model.overlays = this.model.overlays || [];

                        this.model.overlays.on('childAdded', this._reorderChallenges.bind(this));
                    },

                    addInteractiveCard() {
                        const overlay = ComponentOverlayModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                        this.model.overlays.push(overlay);
                        return overlay;
                    },

                    removeInteractiveCard(overlayEditorViewModel) {
                        // Removing the challenge changes the `overlayEditorViewModel.model.overlayComponents` array,
                        // so we clone it first and iterate over the cloned array to avoid accidentally skipping elements.
                        const overlayComponents = overlayEditorViewModel.model.overlayComponents.clone();
                        overlayComponents.forEach(challengeOverlayBlank => {
                            challengeOverlayBlank.challenge.remove();
                        });
                        overlayEditorViewModel.model.remove();
                    },

                    ensureFirstInteractiveCard() {
                        if (!this.model.overlays[0]) {
                            this.addInteractiveCard();
                        }
                        return this.model.overlays[0];
                    },

                    _reorderChallenges() {
                        const origChallenges = _.map(this.model.challengesComponent.challenges, 'id');
                        const sortFunction = (challenge1, challenge2) => {
                            if (
                                this.model.cardIndexForChallenge(challenge1) !==
                                this.model.cardIndexForChallenge(challenge2)
                            ) {
                                return this.model.cardIndexForChallenge(challenge1) >
                                    this.model.cardIndexForChallenge(challenge2)
                                    ? 1
                                    : -1;
                            }
                            return origChallenges.indexOf(challenge1.id) > origChallenges.indexOf(challenge2.id)
                                ? 1
                                : -1;
                        };

                        this.model.challengesComponent.challenges =
                            this.model.challengesComponent.challenges.sort(sortFunction);
                    },
                };
            }),
    ],
);
