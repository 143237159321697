import angularModule from 'Editor/angularModule/scripts/editor_module';
// FIXME: does fill_in_the_blanks really need it's own editor template?  What's the right level here?
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.EditorTemplates.BasicUserInput',
    [
        'AModuleAbove',

        AModuleAbove =>
            new AModuleAbove({
                included(UserInputChallengeEditorViewModel) {
                    UserInputChallengeEditorViewModel.addTemplate('basic_user_input', function () {
                        this.model.behaviors = {
                            CompleteOnCorrect: {},
                        };

                        this.model.validator.behaviors.HasAllExpectedAnswers = {};
                        this.model.validator.behaviors.HasNoUnexpectedAnswers = {};
                    });
                },
            }),
    ],
);
