import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/translatable_lesson_export_set.html';

// Adds support for various translation export features
angularModule.directive('translatableLessonExportSet', [
    '$injector',

    () => ({
        restrict: 'E',
        template,

        scope: {
            translatableLessonExportSet: '<',
        },

        link(scope) {
            scope.$watch('translatableLessonExportSet', translatableLessonExportSet => {
                if (translatableLessonExportSet) {
                    translatableLessonExportSet.prepare();
                }
            });
        },
    }),
]);
