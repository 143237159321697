import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/send_frames_to_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('sendFramesToLesson', [
    '$injector',

    function factory($injector) {
        const Lesson = $injector.get('Lesson');
        const LessonsCtrlBase = $injector.get('LessonsCtrlBase');
        const DialogModal = $injector.get('DialogModal');

        return {
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            restrict: 'E',
            link(scope) {
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');

                let copiedFrames;

                // Get a list of all the lessons
                scope.published = false;
                new LessonsCtrlBase(scope);

                // defaults
                scope.mode = 'active';
                scope.pruneSource = false;

                //-----------------------------
                // targetLesson
                //-----------------------------

                function onSaveTargetLesson() {
                    if (scope.pruneSource) {
                        scope.playerViewModel.activeFrame = undefined; // FIXME: Remove once ng-animate / activeFrame issues have been squashed
                        scope.playerViewModel.removeFrames(copiedFrames);
                    }
                    scope.targetLesson = undefined;
                    scope.complete = true;
                }

                function onSaveTargetLessonError() {
                    scope.error =
                        'There was an error trying to save the target lesson. Please ensure your frames are valid prior to sending them to another lesson.';
                }

                function saveTargetLesson() {
                    scope.targetLesson.save().then(onSaveTargetLesson, onSaveTargetLessonError);
                }

                function buildTargetLesson(response) {
                    if (response && response.meta && response.meta.warning) {
                        DialogModal.alert(angular.toJson(response.meta.warning));
                    }
                    // set the target lesson
                    scope.targetLesson = response.result;
                }

                function onGetTargetLessonError() {
                    scope.error = 'There was an issue trying to load the target lesson. Please try again.';
                }

                function getTargetLesson(lessonId) {
                    Lesson.show(lessonId, {
                        filters: {
                            published: false,
                        },
                        'except[]': ['lesson_progress', 'version_history'],
                    }).then(buildTargetLesson.bind(this), onGetTargetLessonError);
                }

                //-----------------------------
                // Frame Copying
                //-----------------------------

                // copies user-selected frames to a new lesson
                scope.copyFrames = () => {
                    let frames;
                    const playerViewModel = scope.playerViewModel;

                    // support individual or range of frames
                    if (scope.mode === 'range') {
                        frames = playerViewModel.lesson.frames.slice(scope.startIndex, scope.endIndex + 1); // include the last frame
                    } else {
                        frames = [playerViewModel.activeFrame];
                    }

                    // copy and save the lessons
                    scope.targetLesson.importFrames(frames);

                    // save then remove frames from playerViewModel (optional)
                    saveTargetLesson();

                    // retain for pruning callbacks
                    copiedFrames = frames;
                };

                //-----------------------------
                // Watches
                //-----------------------------

                scope.$watch('lessons', () => {
                    scope.lessonOptions = [];
                    if (!scope.lessons || !scope.playerViewModel) {
                        return;
                    }

                    // if we're on the latest version of a lesson, it doesn't make
                    // sense to allow it to be a target in the operation. filter it out.
                    if (!scope.playerViewModel.lesson.old_version) {
                        let currentLessonIndex = -1;
                        for (let i = scope.lessons.length - 1; i >= 0; i--) {
                            const lesson = scope.lessons[i];
                            if (lesson.id === scope.playerViewModel.lesson.id) {
                                currentLessonIndex = i;
                                break;
                            }
                        }
                        scope.lessons.splice(currentLessonIndex, 1);
                    }

                    // build out selectize dataProvider
                    scope.lessons.forEach(lesson => {
                        scope.lessonOptions.push({
                            value: lesson.id,
                            text: lesson.titleWithTag,
                        });
                    });
                });

                scope.$watchCollection(
                    '[startIndex, endIndex]',
                    () => {
                        if (scope.startIndex > scope.endIndex) {
                            scope.error = 'Start frame is greater than end frame.';
                        } else {
                            scope.error = undefined;
                        }
                    },
                    true,
                );

                scope.$watch('targetLessonId', newVal => {
                    const targetLessonId = newVal || undefined;
                    if (!targetLessonId) {
                        return;
                    }
                    getTargetLesson(targetLessonId);
                    scope.targetLessonId = undefined; //
                });

                scope.$watch('targetLesson', () => {
                    if (!scope.targetLesson) {
                        return;
                    }

                    // build concenience list for select dropdowns
                    scope.frameNumbers = scope.playerViewModel.lesson.frames.map((frame, index) => ({
                        index,
                        label: index + 1,
                    }));

                    // set select dropdown values
                    scope.startIndex = scope.playerViewModel.activeFrameIndex;
                    scope.endIndex = scope.playerViewModel.activeFrameIndex;
                });
            }, // end link
        }; // end return
    },
]);
