import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ThisOrThat', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const ChallengesEditorTemplateHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
        );
        const TilePromptBoardModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardModel',
        );
        const MultipleChoiceChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        );

        return new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('this_or_that', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    this.model.sharedContentForImage = undefined;
                    this.model.sharedContentForInteractive = undefined;
                    if (
                        !this.model.sharedContentForInteractiveImage ||
                        !this.model.sharedContentForInteractiveImage.isA(TilePromptBoardModel)
                    ) {
                        this.model.sharedContentForInteractiveImage =
                            TilePromptBoardModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                    }

                    const tilePromptBoardModel = this.model.sharedContentForInteractiveImage;

                    this.setConfig({
                        allowSetContextImages: false,
                        newChallengeType: MultipleChoiceChallengeModel,
                        userDefinedOptions: [
                            {
                                type: 'toggleBehavior',
                                behavior: 'RandomizeChallengeOrder',
                                title: 'Show challenges in random order.',
                                valueWhenOn: {},
                            },
                        ],
                    });

                    // setup shared answer list
                    const answerListEditorViewModel = templateHelper.setupSharedAnswerList(
                        'buttons',
                        2,
                        'mainImageTilePromptBoard',
                    );
                    answerListEditorViewModel.model.answers.on('childAdded', answer => {
                        answer.editorViewModel.setConfig({
                            disallowDelete: true,
                        });
                    });
                    answerListEditorViewModel.trimAnswers(2);

                    answerListEditorViewModel.setConfig({
                        disallowAddAnswers: true,
                    });
                    answerListEditorViewModel.setUserDefinedOptions('RandomizeAnswerOrder', 'forceSingleColumn');

                    // This or that does not support alignment on answer buttons.  Alignment is
                    // always centered.
                    answerListEditorViewModel.model.on(
                        '.answers[]:set:text',
                        textModel => {
                            if (textModel) {
                                textModel.editorViewModel.setConfig({
                                    showFontStyleEditor: false,
                                });
                            }
                        },
                        {
                            runNowOnSet: true,
                        },
                    );

                    templateHelper.setChallengeTemplate('this_or_that');

                    // add challenge and pass to tile prompt board
                    templateHelper.setupAtLeastOneChallenge();
                    tilePromptBoardModel.challengesComponent = this.model;

                    // setup shared text
                    templateHelper.setupSharedText();
                });
            },
        });
    },
]);
