import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorModel',
        'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.SimilarToSelectableAnswer.SimilarToSelectableAnswerModel',

        (ComponentEditorViewModel, SelectableAnswerNavigatorModel, SimilarToSelectableAnswerModel) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.FrameNavigator.FrameNavigatorModel');

                Object.defineProperty(this.prototype, 'challenges', {
                    get() {
                        return this.challengesComponent ? this.challengesComponent.challenges : undefined;
                    },
                });

                return {
                    directiveName: 'cf-frame-navigator-editor',

                    initialize($super, model) {
                        $super(model);
                        if (!model.selectableAnswerNavigators) {
                            this.model.selectableAnswerNavigators = [];
                        }
                        this.challengesComponent = undefined; // might be set by hasBranching
                    },

                    setup() {
                        return this;
                    },

                    ensureSelectableAnswerNavigatorFor(challenge, answer, event) {
                        const existing = this.selectableAnswerNavigatorFor(challenge, answer, event);
                        if (!existing) {
                            return this.addSelectableAnswerNavigatorFor(challenge, answer, event);
                        }
                        return existing;
                    },

                    hasSelectableAnswerNavigatorFor(challenge, answer, event) {
                        return !!this.selectableAnswerNavigatorFor(challenge, answer, event);
                    },

                    // this is inefficient and called over and over
                    // cache the result and listen for the answer to change
                    // to invalidate the cache. But it doesn't seem to
                    // be a big deal for now.
                    selectableAnswerNavigatorFor(challenge, answer, event) {
                        if (!answer || !answer.isA || answer.type !== 'SelectableAnswerModel') {
                            throw new Error('selectableAnswerNavigatorFor requires a selectable answer');
                        }
                        if (!challenge || !challenge.isA || challenge.type !== 'MultipleChoiceChallengeModel') {
                            throw new Error('selectableAnswerNavigatorFor requires a challenge');
                        }
                        if (!event || typeof event !== 'string') {
                            throw new Error('selectableAnswerNavigatorFor requires event to be a string');
                        }

                        const navigators = [];

                        for (const selectableAnswerNavigator of this.model.selectableAnswerNavigators) {
                            if (
                                selectableAnswerNavigator.event === event &&
                                selectableAnswerNavigator.appliesToAnswer(answer) &&
                                challenge === selectableAnswerNavigator.challenge
                            ) {
                                navigators.push(selectableAnswerNavigator);
                            }
                        }

                        if (navigators.length > 1) {
                            throw new Error('Multiple navigators match a single answer');
                        }

                        return navigators[0];
                    },

                    selectableAnswerNavigatorEditorViewModelFor(challenge, answer, event) {
                        const selectableAnswerNavigator = this.selectableAnswerNavigatorFor(challenge, answer, event);
                        if (selectableAnswerNavigator) {
                            return selectableAnswerNavigator.editorViewModel;
                        }
                    },

                    addSelectableAnswerNavigatorFor(challenge, answer, event) {
                        if (!answer || !answer.isA || answer.type !== 'SelectableAnswerModel') {
                            throw new Error('addSelectableAnswerNavigatorFor requires a selectable answer');
                        }
                        if (!challenge || !challenge.isA || challenge.type !== 'MultipleChoiceChallengeModel') {
                            throw new Error('addSelectableAnswerNavigatorFor requires a challenge');
                        }
                        if (!event || typeof event !== 'string') {
                            throw new Error('addSelectableAnswerNavigatorFor requires event to be a string');
                        }
                        const selectableAnswerNavigator = SelectableAnswerNavigatorModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;

                        selectableAnswerNavigator.challenge = challenge;
                        selectableAnswerNavigator.frameNavigator = this.model;
                        selectableAnswerNavigator.event = event;
                        const matcher = SimilarToSelectableAnswerModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                        matcher.answer = answer;
                        selectableAnswerNavigator.answerMatcher = matcher;

                        // by default, the selectable answer navigator does not override next_frame_id,
                        // so it will default back to whatever is save here in the frameNavigator or just
                        // to the next frame in the list.
                        selectableAnswerNavigator.next_frame_id = undefined;
                        this.model.selectableAnswerNavigators = this.model.selectableAnswerNavigators || [];
                        this.model.selectableAnswerNavigators.push(selectableAnswerNavigator);
                        return selectableAnswerNavigator.editorViewModel;
                    },
                };
            }),
    ],
);
