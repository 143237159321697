import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.EditorTemplates.BasicUserInput',
        'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextModel',
        'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageModel',

        (ChallengeEditorViewModel, BasicUserInputEditorTemplate, MatchesExpectedTextModel, UserInputMessageModel) =>
            ChallengeEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
                );
                this.include(BasicUserInputEditorTemplate);

                // ProcessesChallengeBlanks may try to set text on a challenge, in which case we will
                // put that text on the correct answer
                Object.defineProperty(this.prototype, 'correctAnswerText', {
                    get() {
                        const matcher = this.model.validator.expectedAnswerMatchers[0];
                        if (!matcher) {
                            return undefined;
                        }
                        return matcher.expectedText;
                    },
                    set(val) {
                        if (val === this.correctAnswerText) {
                            return val;
                        }

                        this.model.validator.expectedAnswerMatchers[0].expectedText = val;
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'caseSensitive', {
                    get() {
                        return this.model.caseSensitive;
                    },
                    set(val) {
                        this.model.caseSensitive = val;
                        return val;
                    },
                });

                Object.defineProperty(this.prototype, 'correctThreshold', {
                    get() {
                        return this.model.correctThreshold;
                    },
                    set(val) {
                        this.model.correctThreshold = val;
                    },
                });

                Object.defineProperty(this.prototype, 'answerMatcherMode', {
                    get() {
                        return this.model.answerMatcherMode;
                    },
                    set(val) {
                        this.model.answerMatcherMode = val;
                    },
                });

                return {
                    directiveName: 'cf-user-input-challenge-editor',

                    answerMatcherModes: [
                        ['exact', 'Exact matching (default)'],
                        ['number', 'Numbers (ignore commas)'],
                        ['currency', 'Currency (ignore $, €, etc. and commas)'],
                        ['decimal', 'Decimal Numbers (ignore commas and leading zero)'],
                    ],

                    setup($super) {
                        $super();
                        const matcher = MatchesExpectedTextModel.EditorViewModel.addComponentTo(this.frame).setup()
                            .model;
                        this.model.validator.expectedAnswerMatchers = [];
                        this.model.validator.expectedAnswerMatchers.push(matcher);
                        return this;
                    },

                    initialize($super, model) {
                        $super(model);
                    },

                    hasMessage() {
                        return !!this.messageComponent();
                    },

                    messageComponent() {
                        return this.model.messageComponent();
                    },

                    messageEditorViewModel() {
                        const message = this.messageComponent();
                        if (message) {
                            return this.editorViewModelFor(message);
                        }
                    },

                    addMessage() {
                        const messageEditorViewModel = UserInputMessageModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup();
                        const message = messageEditorViewModel.model;

                        message.challenge = this.model;
                        message.show_on_correct_answer = true;
                        this.model.messages = this.model.messages || [];
                        this.model.messages.push(message);
                        return messageEditorViewModel;
                    },

                    removeTransN() {
                        const matcher = this.model.validator.expectedAnswerMatchers[0];
                        const oldExpectedText = matcher.expectedText;

                        matcher.editorViewModel.removeTransN();

                        if (oldExpectedText !== matcher.expectedText) {
                            this.model.unlink_blank_from_answer = true;
                        }
                    },
                };
            }),
    ],
);
