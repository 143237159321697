import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/playlist/edit_playlist.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import comingSoonIcon from 'images/coming_soon_icon.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editPlaylist', [
    '$injector',

    function factory($injector) {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const $rootScope = $injector.get('$rootScope');
        const Playlist = $injector.get('Playlist');
        const $location = $injector.get('$location');
        const UnsavedChangesWarning = $injector.get('UnsavedChangesWarning');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playlistId: '@',
            },
            link(scope) {
                scope.comingSoonIcon = comingSoonIcon;

                UnsavedChangesWarning.warnOnUnsavedChanges(scope, 'playlist');
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.setTitleHTML('EDIT<br>PLAYLIST');
                $injector.get('scrollHelper').scrollToTop();
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //---------------------------
                // Data Loading
                //---------------------------

                scope.$watch('playlistId', playlistId => {
                    if (playlistId) {
                        // see line 162 of content_item_editor_lists.js for why we can't rely on caching here
                        // effectively, we might a published version already cached and need to puncture the cache for this playlist
                        Playlist.resetCache();
                        Playlist.getCachedOrShow(playlistId, {
                            filters: {
                                published: false,
                                in_users_locale_or_en: null,
                            },
                            'fields[]': ['ADMIN_FIELDS'],
                            get_available_content_family_identifiers: true,
                        }).then(playlist => {
                            scope.playlist = playlist;
                            scope.availableContentFamilyIdentifiers =
                                scope.playlist.available_content_family_identifiers.map(identifier => ({
                                    label: identifier,
                                    value: identifier,
                                }));
                        });
                    }
                });

                scope.$watch('playlist', () => {
                    if (!scope.playlist) {
                        return;
                    }

                    if (scope.playlist.assessmentEditingLocked) {
                        scope.streamFilters = {
                            customFilter: stream =>
                                // See https://trello.com/c/ZdLPPi7z to understand why we
                                // exclude exams and streams with assessments in this context.
                                !stream.gradable ||
                                // Need to include streams associated with the playlist to prevent
                                // locale-pack-selectize from throwing an error because its ngModel
                                // value contains streams not in the list of selectable streams.
                                scope.playlist.streamLocalePackIds.includes(stream.localePackId),
                        };
                    } else {
                        scope.streamFilters = {};
                    }
                });

                //---------------------------
                // Navigation
                //---------------------------

                scope.goBack = () => {
                    $location.url('/editor?section=playlists');
                };

                //---------------------------
                // Images Support
                //---------------------------

                scope.imageUploaded = s3Asset => {
                    scope.playlist.image = s3Asset;
                };

                scope.imageEdited = s3Asset => {
                    scope.playlist.image = s3Asset;
                };

                //---------------------------
                // Stream Management
                //---------------------------

                scope.streamCannotBeRemoved = stream => {
                    if (scope.playlist.assessmentEditingLocked) {
                        return stream.gradable;
                    }
                    return false;
                };

                scope.duplicate = () => {
                    if (scope.playlist) {
                        DialogModal.alert({
                            content: '<duplicate-content-item content-item="contentItem"></duplicate-content-item>',
                            scope: {
                                contentItem: scope.playlist,
                            },
                            classes: ['overflow-visible'],
                            size: 'small',
                        });
                    }
                };

                //-----------------------------
                // Cleanup
                //-----------------------------

                scope.$on('$destroy', () => {
                    DialogModal.hideAlerts();
                });
            },
        };
    },
]);
