/* eslint-disable no-prototype-builtins */
import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ImageHotspot', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const ChallengesEditorTemplateHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
        );
        const MultipleChoiceChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        );

        return new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('image_hotspot', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    this.supportsNoIncorrectAnswers = {
                        ImmediateValidation: false,
                    };
                    this.supportsHasBranching = true;

                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                        disallowAddAndReorderChallenges: true,
                        allowSetContextImages: true,
                        supportsCheckMany: {
                            answerListSkin: {
                                check_one_selection_mode: 'image_hotspot',
                                check_many_selection_mode: 'image_hotspot',
                                check_all_selection_mode: 'image_hotspot',
                            },
                            challengeEditorTemplates: ['check_many', 'basic_multiple_choice'],
                        },
                        userDefinedOptions: [
                            {
                                type: 'radioSetProperty',
                                property: 'selectionMode',
                                radios: [
                                    ['check_one_selection_mode', 'Check One'],
                                    ['check_many_selection_mode', 'Check Many'],
                                    ['check_all_selection_mode', 'Check All'],
                                ],
                            },
                            {
                                type: 'checkboxSetProperty',
                                property: 'hasBranching',
                                title: 'Branching',
                                disabled: () =>
                                    this.lesson.isPractice || this.selectionMode !== 'check_one_selection_mode',
                            },
                            {
                                type: 'checkboxSetProperty',
                                property: 'noIncorrectAnswers',
                                title: 'No Incorrect Answers',
                                disabled: () => this.selectionMode === 'check_all_selection_mode' || this.hasBranching,
                            },
                        ],
                    });

                    templateHelper.setChallengeTemplate('basic_multiple_choice');
                    templateHelper.setupSharedText();
                    templateHelper.setupExactlyOneChallenge();
                    templateHelper.setupSharedComponentOverlay();

                    // Use image_hotspot for positionable regions
                    const answerListeners = [];

                    templateHelper.setupSharedAnswerList('image_hotspot', 1, 'overlayOnImage', false);

                    // unset the shared interactive content for interactive. Do not remove it
                    // from the frame because it may be the sharedAnswerList that is now on
                    // the ComponentOverlay.  For the same reason, this must be called after
                    // setupSharedAnswerList
                    if (this.model.sharedContentForInteractive) {
                        this.model.sharedContentForInteractive = undefined;
                    }

                    answerListeners.push(
                        this.sharedAnswerList.answers.on('childAdded', answer => {
                            answer.editorViewModel.setConfig({
                                supportHasBackground: true,
                                hideFontSizeEditor: false,
                            });

                            /*
                                when an answer is first created, give it a little width and
                                height and position it a bit randomly so they're not all
                                on top of each other
                            */
                            if (!answer.hasOwnProperty('width')) {
                                answer.width = 5;
                            }
                            if (!answer.hasOwnProperty('height')) {
                                answer.height = 5;
                            }
                            if (!answer.hasOwnProperty('x')) {
                                answer.x = 50 * Math.random();
                            }
                            if (!answer.hasOwnProperty('y')) {
                                answer.y = 50 * Math.random();
                            }

                            function setupText(textComponent) {
                                textComponent.editorViewModel.setConfig({
                                    showFontStyleEditor: true,
                                });

                                textComponent.allowBlank = true;

                                if (!textComponent.fontSize) {
                                    textComponent.fontSize = 14;
                                }
                            }

                            /* show fontSize editors on text components */
                            if (answer.text) {
                                setupText(answer.text);
                            }
                            answerListeners.push(
                                answer.on('set:text', textComponent => {
                                    if (textComponent) {
                                        setupText(textComponent);
                                    }
                                }),
                            );

                            /*
                                whenever the image on an answer changes, reset
                                the dimensions
                            */
                            answerListeners.push(
                                answer.on('set:image', image => {
                                    if (!image) {
                                        return;
                                    }

                                    if (image.image) {
                                        delete answer.width;
                                        delete answer.height;
                                    }

                                    answerListeners.push(
                                        image.on('set:image', () => {
                                            delete answer.width;
                                            delete answer.height;
                                        }),
                                    );
                                }),
                            );
                        }),
                    );

                    // listen for the template to be changed and clean up
                    const removeTemplateListener = this.model.on('set:editor_template', () => {
                        this.setConfig({
                            newChallengeType: undefined,
                            disallowAddAndReorderChallenges: false,
                            allowSetContextImages: false,
                            userDefinedOptions: undefined,
                        });

                        this.sharedAnswerList.answers.forEach(answer => {
                            answer.editorViewModel.setConfig({
                                supportHasBackground: false,
                            });

                            if (answer.text) {
                                answer.text.editorViewModel.setConfig({
                                    showFontStyleEditor: false,
                                });

                                answer.text.allowBlank = false;
                                answer.text.fontSize = undefined;
                            }

                            delete answer.x;
                            delete answer.y;
                            delete answer.width;
                            delete answer.height;
                        });

                        templateHelper.teardownSharedAnswerList();

                        removeTemplateListener.cancel();

                        angular.forEach(answerListeners, listener => {
                            listener.cancel();
                        });
                    });
                });
            },
        });
    },
]);
