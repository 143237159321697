import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    NoIncorrectAnswers gets turned on and off at the Challenges level, but this mixin
    is mostly just responsible for delegating to the individual challenges, which handle
    all the nittygritty.
*/

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.HasBranchingMixin',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'supportsHasBranching', {
                        get() {
                            return this._supportsHasBranching || false;
                        },
                        set(val) {
                            this._supportsHasBranching = val;
                            if (!val) {
                                this.frame.frameNavigator.has_branching = false;
                                this.frame.frameNavigator.editorViewModel.challenges = undefined;
                            }
                            if (!!val && !!this.frame.frameNavigator.has_branching) {
                                this.frame.frameNavigator.editorViewModel.challengesComponent = this.model;
                            }
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'hasBranching', {
                        get() {
                            return this.frame.hasBranching;
                        },
                        set(value) {
                            if (!this.supportsHasBranching) {
                                throw new Error('hasBranching not supported');
                            }
                            if (value) {
                                // cant be both branching and checkMany at the same time
                                if (this.config.supportsCheckMany) {
                                    this.selectionMode = 'check_one_selection_mode';
                                }
                                if (this.supportsNoIncorrectAnswers) {
                                    this.noIncorrectAnswers = true;
                                }
                                this.frame.frameNavigator.editorViewModel.challengesComponent = this.model;
                            } else {
                                this.frame.frameNavigator.editorViewModel.challengesComponent = undefined;
                                this.frame.frameNavigator.editorViewModel.model.selectableAnswerNavigators.forEach(
                                    selectableAnswerNavigator => {
                                        selectableAnswerNavigator.remove();
                                    },
                                );
                            }
                            this.frame.frameNavigator.has_branching = value;
                        },
                    });
                },
            });
        },
    ],
);
