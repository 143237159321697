import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/translatable_lesson_export/import_translatable_artifact.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('importTranslatableArtifact', [
    '$injector',

    $injector => {
        const DialogModal = $injector.get('DialogModal');
        const $window = $injector.get('$window');
        const TranslatableLessonExport = $injector.get('TranslatableLessonExport');

        return {
            restrict: 'E',
            scope: {
                playerViewModel: '<',
            },
            transclude: true,
            templateUrl,
            link(scope, elem) {
                Object.defineProperty(scope, 'showDefault', {
                    get() {
                        return elem.find('[custom-content]').html().length === 0;
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return scope.playerViewModel && scope.playerViewModel.lesson;
                    },
                });

                // separate method that can be mocked in tests
                scope.allowedType = file => ['text/html'].includes(file.type);

                scope.onFileSelect = files => {
                    if (!files || files.length === 0) {
                        return;
                    }

                    const maxSizeMb = 99999;
                    const maxSizeBytes = maxSizeMb * 1024 * 1024;
                    const errorText = [];

                    // determine files are allowed
                    files.forEach(file => {
                        if (!scope.allowedType(file)) {
                            errorText.push(`'${file.name}' is not an allowed image type (html is allowed).`);
                        }
                        if (file.size > maxSizeBytes) {
                            errorText.push(`'${file.name}' exceeds the maximum upload size of ${maxSizeMb} MB`);
                        }
                    });

                    // if so, error out
                    if (errorText.length > 0) {
                        const errorTextHTML = errorText.join('<br>');
                        DialogModal.alert({
                            content: errorTextHTML,
                            classes: ['server-error-modal'],
                            title: 'Invalid Image',
                        });

                        // clear the files array for re-use
                        elem.find('[ngf-file-select]').val('');
                        return;
                    }

                    function getBody(content) {
                        let x = content.indexOf('<body');
                        x = content.indexOf('>', x);
                        const y = content.lastIndexOf('</body>');
                        return content.slice(x + 1, y);
                    }

                    files.forEach(file => {
                        const reader = new $window.FileReader();
                        // Closure to capture the file information. (Nate doesn't quite
                        // understand why this is structured the way it is, but it was
                        // copied from the internet and it works, so ...)
                        reader.onload = (() => e => {
                            const text = e.target.result;

                            // We have to unset the active frame, because the import actually
                            // destroy and rebuilds frames that it changes
                            const activeFrameIndex = scope.playerViewModel.activeFrameIndex;
                            scope.playerViewModel.activeFrame = undefined;

                            // we have to extract the body, becaushe jquery cannot
                            // deal with anything more than that
                            new TranslatableLessonExport(scope.lesson).import($(getBody(text))).then(() => {
                                scope.playerViewModel.activeFrameIndex = activeFrameIndex;
                            });
                        })(file);
                        // read as text to ensure we properly load the data as UTF-8
                        reader.readAsText(file);
                    });
                };
            },
        };
    },
]);
