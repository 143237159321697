import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/image/image_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfImageEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                let saveBlock;

                scope.onImageUploaded = function (fileName, s3Asset) {
                    this.model.label = fileName;

                    if (saveBlock) {
                        saveBlock.unblock();
                    }

                    this.model.replaceDataUrlWithImage(s3Asset);
                };

                scope.onImageUploadStarted = function (fileName, dataUrl) {
                    if (!this.model.label || this.model.label === '') {
                        this.model.label = fileName;
                    }

                    if (saveBlock) {
                        saveBlock.unblock();
                    }

                    this.origImage = this.model.image;
                    this.model.image = undefined;
                    this.model.$$dataUrl = dataUrl;
                    saveBlock = this.lesson.blockSave();
                };

                scope.onUploadError = function () {
                    if (saveBlock) {
                        saveBlock.unblock();
                    }
                    this.model.image = this.origImage;
                };
            },
        }),
]);
