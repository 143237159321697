import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesStorableImagesEditor', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');

        /* eslint-enable new-cap */

        // This behavior has been deprecated and its functionality moved into
        // ProcessesInlineImages.  But it's too much work to go back and remove the
        // reference to the behavior in all existing content
        return new AModuleAbove({});
    },
]);
