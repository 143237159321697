import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/overlay_options_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfOverlayOptionsEditor', [
    '$injector',
    $injector => {
        const AspectRatioEnforcer = $injector.get('AspectRatioEnforcer');
        const $interval = $injector.get('$interval');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            scope: {
                overlayOptions: '<',
                restrictAspectRatio: '<',
                overlayComponentViewModel: '<',
                frameViewModel: '<',
            },
            templateUrl,
            link(scope) {
                // just-in-time round any preexisting unrounded overlay options so lesson can be resaved
                ['y', 'x', 'width', 'height'].forEach(prop => {
                    if (scope.overlayOptions && scope.overlayOptions[prop]) {
                        scope.overlayOptions[prop] = Math.round(1000000 * scope.overlayOptions[prop]) / 1000000;
                    }
                });

                function setDimensionsWithAspectRatioRestriction(targetWidth, targetHeight) {
                    const aspectRatioEnforcer = new AspectRatioEnforcer();
                    aspectRatioEnforcer.setInitialDimensions({
                        width: scope.overlayOptions.width,
                        height: scope.overlayOptions.height,
                    });
                    aspectRatioEnforcer.calculate(targetWidth, targetHeight);
                    scope.overlayOptions.width = aspectRatioEnforcer.width;
                    scope.overlayOptions.height = aspectRatioEnforcer.height;
                }

                // this will cause resize_handle_dir.js to reset
                // dimensions based on the size of the element.
                // see setDataWidthAndHeightFromElSizeOnceResizedInBrowser
                let prevPosition;
                let resetDimensionsIntervalPromise;
                scope.resetDimensions = () => {
                    // mkae sure the last one is done before doing another
                    if (!scope.overlayOptions.width || !scope.overlayOptions.height) {
                        return;
                    }
                    const overlayInFramePreview = $(
                        `.lesson-content-container [component-id="${scope.overlayComponentViewModel.model.id}"]`,
                    );
                    if (overlayInFramePreview.length === 0) {
                        throw new Error('Cannot find overlay in frame preview');
                    }

                    // If there is no content in the overlay, then
                    // we cannot reset the dimensions to the natural size.
                    // Just do nothing.  See https://trello.com/c/2dMdpLIl/85-reset-dimensions-image-hotspot-with-no-content
                    if (overlayInFramePreview.text() === '' && overlayInFramePreview.find('img').length === 0) {
                        return;
                    }
                    prevPosition = {
                        x: scope.overlayOptions.x,
                        y: scope.overlayOptions.y,
                    };
                    delete scope.overlayOptions.width;
                    delete scope.overlayOptions.height;

                    /*
                        This seems totally unnecessary, but for some reason, if the
                        element was running up against the right side of the parent
                        element, it would only grow until it hit the right side.  So
                        we move it to the left before resizing, which gives it plenty
                        of space to grow.  We move it really far
                        so that it will be invisible while it's being resized, which
                        looks a little bit less bad.
                    */
                    scope.overlayOptions.x = -99999;
                    scope.overlayOptions.y = -99999;

                    // once the image hhas finished resizing, move it
                    // back to its original position
                    resetDimensionsIntervalPromise = $interval(
                        () => {
                            if (scope.overlayOptions.width) {
                                angular.merge(scope.overlayOptions, prevPosition);
                                prevPosition = false;
                                $interval.cancel(resetDimensionsIntervalPromise);
                                safeApply(scope);
                            }
                        },
                        50,
                        20,
                        false,
                    );
                };

                function valFromNumberInput(val) {
                    // If we don't convert nulls to undefined,
                    // then it is impossible to type a negative number
                    val = val === null ? undefined : val;
                    return val;
                }

                // the number input will fail silently with too many decimal places
                Object.defineProperty(scope, 'x', {
                    get() {
                        return Math.round(1000000 * this.overlayOptions.x) / 1000000;
                    },
                    set(val) {
                        this.overlayOptions.x = valFromNumberInput(val);
                        return this.overlayOptions.x;
                    },
                });

                // the number input will fail silently with too many decimal places
                Object.defineProperty(scope, 'y', {
                    get() {
                        return Math.round(1000000 * this.overlayOptions.y) / 1000000;
                    },
                    set(val) {
                        this.overlayOptions.y = valFromNumberInput(val);
                        return this.overlayOptions.y;
                    },
                });

                Object.defineProperty(scope, 'targetWidth', {
                    get() {
                        return scope.overlayOptions.width;
                    },
                    set(val) {
                        if (scope.restrictAspectRatio) {
                            setDimensionsWithAspectRatioRestriction(val, undefined);
                        } else {
                            scope.overlayOptions.width = val;
                        }

                        return val;
                    },
                });

                Object.defineProperty(scope, 'targetHeight', {
                    get() {
                        return scope.overlayOptions.height;
                    },
                    set(val) {
                        if (scope.restrictAspectRatio) {
                            setDimensionsWithAspectRatioRestriction(undefined, val);
                        } else {
                            scope.overlayOptions.height = val;
                        }

                        return val;
                    },
                });

                scope.$watchCollection('[overlayOptions.width, overlayOptions.height]', () => {
                    /*
                        When there is a width and a height set on the overlay component, then we want
                        it's contents to stretch to fit.  During resize, however, we do not want the
                        contents to stretch to fit, we want to component to resize based on the contents.
                    */
                    if (
                        angular.isUndefined(scope.overlayOptions.width) &&
                        angular.isUndefined(scope.overlayOptions.height)
                    ) {
                        scope.overlayComponentViewModel.stretchToFit = false;
                    } else {
                        scope.overlayComponentViewModel.stretchToFit = true;
                    }
                });

                scope.$on('$destroy', () => {
                    $interval.cancel(resetDimensionsIntervalPromise);
                });
            },
        };
    },
]);
