import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Mixins.IsMessageEditorViewModel', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const MaxTextLengthConfig = $injector.get('MaxTextLengthConfig');

        return new AModuleAbove({
            onInitializeMessageEditorViewModel() {
                this._setRecommendedMaximumTextLength();

                this.model.on('.challenge:remove', () => {
                    this.model.remove();
                });
            },

            setup() {
                this.model.messageText = TextModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                return this;
            },

            _setRecommendedMaximumTextLength() {
                this.model.on(
                    'set:messageText',
                    textComponent => {
                        if (textComponent) {
                            textComponent.editorViewModel.setConfig({
                                maxRecommendedTextLength: MaxTextLengthConfig.MESSAGE,
                            });
                        }
                    },
                    true,
                );
            },
        });
    },
]);
