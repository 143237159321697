import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/edit_frame_list.html';
import editLessonActionsFormTemplate from 'Editor/angularModule/views/lesson_types/edit_lesson_actions_form.html';
import editLessonSEOFormTemplate from 'Editor/angularModule/views/lesson_types/edit_lesson_seo_form.html';
import editLessonMainFormTemplate from 'Editor/angularModule/views/lesson_types/edit_lesson_main_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { watchFrameContainerSizeChanges } from 'Lessons';

const templateUrl = cacheAngularTemplate(angularModule, template);

cacheAngularTemplate(angularModule, 'Editor/edit_lesson_actions_form.html', editLessonActionsFormTemplate);
cacheAngularTemplate(angularModule, 'Editor/edit_lesson_seo_form.html', editLessonSEOFormTemplate);
cacheAngularTemplate(angularModule, 'Editor/edit_lesson_main_form.html', editLessonMainFormTemplate);

angularModule.ooDirective('editFrameList', [
    '$injector',
    function factory($injector) {
        const editLessonTypeBase = $injector.get('editLessonTypeBase');
        const DialogModal = $injector.get('DialogModal');
        const ngToast = $injector.get('ngToast');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const SiteMetadata = $injector.get('SiteMetadata');
        const ShareService = $injector.get('Navigation.ShareService');
        const ClientStorage = $injector.get('ClientStorage');

        return editLessonTypeBase.subclass({
            templateUrl,

            link(scope, elem, attrs) {
                watchFrameContainerSizeChanges('frameContainerClasses', {
                    scope,
                    elem,
                    window: $injector.get('$window'),
                    selector: '.lesson-content-container',
                });

                scope.showAnswerMessageIcons = ClientStorage.getItem('showEditorMessageIcons') !== 'false';
                scope.showCorrectIncorrectIcons = ClientStorage.getItem('showCorrectIncorrectIcons') !== 'false';

                AppHeaderViewModel.setBodyBackground('white');
                AppHeaderViewModel.showAlternateHomeButton = true;
                // Don't animate back transitions for this directive to prevent conflicts
                // with unsaved changes conirmation.
                // https://trello.com/c/8ESBm7hR
                AppHeaderViewModel.animateBackTransition = false;

                editLessonTypeBase.link(scope, elem, attrs);

                Object.defineProperty(scope, 'activeFrameViewModel', {
                    get() {
                        return this.playerViewModel && this.playerViewModel.activeFrameViewModel;
                    },
                });

                // only display lesson editing frame if editor has permissions to change anything
                if (scope.currentUser.canEditLesson(scope.lesson)) {
                    scope.currentTab = 'frame';
                } else {
                    scope.currentTab = 'comments';
                }

                scope.$watch('playerViewModel', playerViewModel => {
                    playerViewModel.preloadAllImages();

                    playerViewModel.initializeLockedSeoFields();
                });

                scope.$watch('playerViewModel.lesson.title', changedTitle => {
                    // if the seo title is locked, update it with the changed value
                    if (scope.playerViewModel.seoTitleLocked) {
                        scope.lesson.entity_metadata.title = changedTitle;
                    }
                });

                scope.$watch('playerViewModel.lesson.entity_metadata.title', () => {
                    // if the canonical url is locked, also update it on title update
                    if (scope.playerViewModel.seoCanonicalUrlLocked) {
                        // NOTE: this needs to be kept in sync with the server side Lesson#default_canonical_url
                        scope.lesson.entity_metadata.canonical_url = SiteMetadata.seoCanonicalUrlFromTitle(
                            'lesson',
                            scope.lesson.entity_metadata.title,
                            scope.lesson.id,
                        );
                    }
                });

                scope.$watch('showAnswerMessageIcons', () => {
                    ClientStorage.setItem('showEditorMessageIcons', scope.showAnswerMessageIcons);
                });
                scope.$watch('showCorrectIncorrectIcons', () => {
                    ClientStorage.setItem('showCorrectIncorrectIcons', scope.showCorrectIncorrectIcons);
                });

                //-----------------------------
                // Description List Support
                //-----------------------------

                scope.addDescriptionItem = () => {
                    scope.lesson.description.push('');
                };

                scope.removeDescriptionItem = index => {
                    if (scope.lesson.description.length > index && index > -1) {
                        scope.lesson.description.splice(index, 1);
                    }
                };

                // todo: update this to serialize array into string
                scope.$watchCollection('playerViewModel.lesson.description', changedDescription => {
                    // if originalTitle is the same as seoTitle, this field is locked and update seoTitle
                    if (scope.playerViewModel.seoDescriptionLocked) {
                        scope.lesson.entity_metadata.description = changedDescription.join(' ');
                    }
                });

                scope.$on('saveLesson', (_evt, metadata) => {
                    scope.save(metadata);
                });

                scope.$on('$destroy', () => {
                    if (scope.playerViewModel) {
                        scope.playerViewModel.destroy();
                    }
                });

                scope.leftPanelMouseOver = () => {
                    scope.leftPanelHover = true;
                };

                scope.leftPanelMouseOut = () => {
                    scope.leftPanelHover = false;
                };

                scope.showSeoTitleHints = $event => {
                    $event.stopPropagation();
                    DialogModal.alert({
                        content: '<seo-title-hints></seo-title-hints>',
                    });
                };

                // seo metadata saved outside the context of normal lesson editing, so that
                // we can tweak values without having to re-save / re-publish
                scope.saveLessonMetadata = () => {
                    scope.lesson.entity_metadata.save().then(response => {
                        scope.lesson.entity_metadata = response.result;

                        ngToast.create({
                            content: 'Lesson metadata has been updated.',
                            className: 'success',
                        });
                    });
                };
                scope.showSeoDescriptionHints = $event => {
                    $event.stopPropagation();
                    DialogModal.alert({
                        content: '<seo-description-hints></seo-description-hints>',
                    });
                };

                scope.showSeoCanonicalUrlHints = $event => {
                    $event.stopPropagation();
                    DialogModal.alert({
                        content: '<seo-canonical-url-hints></seo-canonical-url-hints>',
                    });
                };

                scope.showSeoImageHints = $event => {
                    $event.stopPropagation();
                    DialogModal.alert({
                        content: '<seo-image-hints></seo-image-hints>',
                    });
                };

                function share(provider) {
                    ShareService.share(
                        'edit_lesson',
                        provider,
                        SiteMetadata.contentCompletedShareInfo(scope.currentUser, scope.lesson),
                        scope.lesson.logInfo(),
                    );
                }

                scope.generateTwitterIntentLink = () => {
                    share('twitter');
                };

                scope.generateFacebookIntentLink = () => {
                    share('facebook');
                };

                scope.generateLinkedinIntentLink = () => {
                    share('linkedin');
                };

                scope.seoImageUploaded = s3Asset => {
                    scope.lesson.entity_metadata.image = s3Asset;
                };
            },
        });
    },
]);
