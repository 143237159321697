import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/mixins/has_text_or_image/has_text_or_image_editor.html';

angularModule.directive('cfHasTextOrImageEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',
    EditorDirHelper =>
        EditorDirHelper.getOptions({
            template,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                Object.defineProperty(scope, 'contentType', {
                    get() {
                        return this.model.contentType;
                    },
                    set(val) {
                        if (val !== this.model.contentType) {
                            this.editorViewModel.toggleContentType();
                        }
                    },
                });
            },
        }),
]);

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const DialogModal = $injector.get('DialogModal');

        return new AModuleAbove({
            included(EditorViewModel) {
                EditorViewModel.setCallback('after', 'initialize', function () {
                    const model = this.model;
                    model.on('set:text', text => {
                        if (text) {
                            const image = model.image;
                            model.image = undefined;
                            // if the current image model has never been assigned
                            // an actual image file, then just remove it (see
                            // https://trello.com/c/n9FS4DN5/181-bug-image-hotspot)
                            if (image && !image.image) {
                                image.remove();
                            }

                            // we do not trigger the text_or_image_change when
                            // the text component is set to undefined, because
                            // that means the image component will be set to
                            // something, and it will trigger text_or_image_change
                            model.triggerCallbacks('text_or_image_change');
                        }
                    });
                    model.on('.text:set:text', () => {
                        model.triggerCallbacks('text_or_image_change');
                    });

                    model.on('set:image', image => {
                        if (image) {
                            model.text = undefined;

                            // see comment in set:text about why we do not
                            // trigger this when image is set to undefined
                            model.triggerCallbacks('text_or_image_change');
                        } else if (!model.text) {
                            const textHelper = TextModel.EditorViewModel.addComponentTo(model.frame()).setup();
                            model.text = textHelper.model;
                        }
                    });

                    model.on('.image:set:image', () => {
                        model.triggerCallbacks('text_or_image_change');
                    });
                });
            },

            toggleContentType() {
                const currentContentType = this.model.contentType;
                if (currentContentType === 'text') {
                    if (this.model.text?.$$___text !== '') {
                        DialogModal.confirm({
                            text: `Are you sure you want to change the answer type to an image? The current text will be removed by this change.`,
                            confirmCallback: () => {
                                this.setContentType('image');
                            },
                        });
                    } else {
                        this.setContentType('image');
                    }
                } else if (currentContentType === 'image') {
                    if (typeof this.model.textOrImage.image !== 'undefined') {
                        DialogModal.confirm({
                            text: `Are you sure you want to change the answer type to text? The current image will be removed by this change.`,
                            confirmCallback: () => {
                                this.setContentType('text');
                            },
                        });
                    } else {
                        this.setContentType('text');
                    }
                }
            },
            setContentType(contentType) {
                if (contentType !== this.model.contentType) {
                    if (contentType === 'image') {
                        const imageHelper = ImageModel.EditorViewModel.addComponentTo(this.frame).setup();
                        this.model.image = imageHelper.model;
                    } else {
                        const textHelper = TextModel.EditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;
                    }
                }
            },
        });
    },
]);
