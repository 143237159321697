import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    ***************** Notes on blanks frame types

    We have 4 blanks editor templates, which differ from each other
    on two dimensions, the challenge type and the kind of blank:

                                                    | UserInputChallenge        | MultipleChoiceChallenge   |
                                                     -------------------------------------------------------
                            ChallengeOverlayBlank   | compose_blanks_on_image   | blanks_on_image           |
    inline blank (using ProcessesChallengeBlanks)   | compose_blanks            | fill_in_the_blanks        |
                                                     -------------------------------------------------------

    We can analyze the different responsibilities by looking at those
    two dimensions:

    * Creating challenges and assigning blanks to them:
        * For editor_templates that use ChallengeOverlayBlank blanks, challenges are created when
            the user clicks an "Add Challenge" button in the editor UI.  The editor_template is
            responsible for listening for new challenges to be created and for assigning a blank
            to each new challenge.
        * For editor_templates that use ProcessesChallengeBlanks, the text component (through
            the ProcessesChallengeBlanks behavior) is responsible for parsing the text to identify
            which blanks exist, and then creating a challenge for each blank in the text.

    * Keeping the content of the blank in sync with the answer of the challenge:
        * For editor_templates that use ChallengeOverlayBlank blanks, the ChallengeOverlayBlankEditorViewModel
            is responsible for listening for changes on the blank and updating the correct answer
            accordingly.
        * For editor_templates that use ProcessesChallengeBlanks, the text component (through
            the ProcessesChallengeBlanks behavior) is responsible for paying attentinon to
            changes to the inline blanks and updating the correct answer accordingly.

    * Linking/linking the correct answer and the blank:
        * By default, the correct answer is kept in sync with the content of the blank, but ...
        * For editor_templates that use MultipleChoiceChallenges, the editor for the correct
            answer (an instance of SelectableAnswer) provides the UI for unlinking the
            the correct answer from the content of the blank.  In order to do so, the correct answer's
            editor view model needs a reference to the relevant challenge.  For fill_in_the_blanks,
            this is assigned by ProcessesChallengeBlanks.  For blanks_on_image, this is assigned
            by the ChallengeOverlayBlankEditorViewModel (search for relatedBlankChallenge to see more).
        * For editor_templates that use UserInputChallenges, the editor for the challenge
            provides the UI for unlinking the
            the correct answer from the content of the blank.


*/

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.FillInTheBlanks', [
    'AModuleAbove',
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',

    (AModuleAbove, ChallengesEditorTemplateHelper, MultipleChoiceChallengeModel) =>
        new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('fill_in_the_blanks', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    /*
                        This really sucks, but the order of config settings matters.

                        newChallengeType needs to come before supportsSharedAnswerList, in
                        case we are switching from compose_blanks, and the challenges do not
                        support answerLists.

                        supportsSharedAnswerList needs to come before supportsSequentialAndConsumable
                        so that supportsSequentialAndConsumable can determine whether this is initially
                        sequential or consumable
                    */
                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                    });

                    this.setConfig({
                        supportsSharedAnswerList: true,
                    });

                    this.setConfig({
                        disallowAddAndReorderChallenges: true,
                        allowSetContextImages: true,
                        supportsSequentialAndConsumable: true,
                        userDefinedOptions: [
                            {
                                type: 'radioSetProperty',
                                property: 'sequentialOrConsumable',
                                radios: [
                                    ['sequential', 'Each blank has its own set of answers.'],
                                    ['consumable', 'Blanks share one set of answers.'],
                                ],
                            },
                        ],
                    });

                    templateHelper.setChallengeTemplate('fill_in_the_blanks');
                    this.model.sharedContentForInteractiveImage = undefined;

                    // since we're using ProcessesChallengeBlanks, the main text component
                    // needs a reference to the challenges component
                    const sharedTextEditorViewModel = templateHelper.setupSharedText();
                    sharedTextEditorViewModel.model.challengesComponent = this.model;
                    sharedTextEditorViewModel.model.behaviors.ProcessesChallengeBlanks = {};

                    // setup shared answer list
                    if (this.consumable) {
                        templateHelper.setupSharedAnswerList('buttons');
                    }

                    // Tear down incompatible stuff
                    templateHelper.teardownSharedComponentOverlay();

                    // listen for a challenge to be added
                    this.model.challenges.on('childAdded', challenge => {
                        // and the listen for it's answer list to be set
                        function setAnswerListConfig(answerList) {
                            if (answerList) {
                                answerList.editorViewModel.setUserDefinedOptions(
                                    'RandomizeAnswerOrder',
                                    'forceSingleColumn',
                                );

                                answerList.skin = 'buttons';
                            }
                        }
                        setAnswerListConfig(challenge.answerList);
                        challenge.on('set:answerList', setAnswerListConfig);
                    });
                });
            },
        }),
]);
