import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/stream/edit_chapter.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editChapter', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '<',
                chapter: '<',
                lessons: '<',
                otherLessons: '<',
                streamLessonLookupById: '<',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                //----------------------------
                // Watches
                //----------------------------

                // lookup map
                let lessonsById = {};
                scope.$watch('lessons', () => {
                    lessonsById = {};
                    if (!scope.lessons) {
                        return;
                    }
                    scope.lessons.forEach(lesson => {
                        lessonsById[lesson.id] = lesson;
                    });
                });

                // watch for new lesson selection
                scope.$watch('addLesson', newVal => {
                    const lessonId = newVal || undefined;
                    if (!lessonId) {
                        return;
                    }
                    scope.chapter.addLesson(lessonsById[lessonId]);
                    scope.addLesson = undefined; // clear the selectize
                });

                // list of remaining lessons
                scope.$watch('otherLessons', () => {
                    scope.lessonOptions = [];
                    if (!scope.otherLessons) {
                        return;
                    }

                    scope.otherLessons.forEach(lesson => {
                        scope.lessonOptions.push({
                            value: lesson.id,
                            text: lesson.titleWithTag,
                        });
                    });
                });

                //----------------------------
                // Misc Scope Methods
                //----------------------------

                scope.chapterCannotBeRemoved = () => {
                    if (!scope.stream.assessmentEditingLocked) {
                        return false;
                    }

                    return !!scope.chapter.lessons.find(scope.lessonCannotBeRemoved);
                };

                // remove the chapter from the stream, along with this directive element
                scope.removeChapter = () => {
                    if (scope.chapterCannotBeRemoved()) {
                        return;
                    }

                    if (!$window.confirm(`You sure you want to delete "${scope.chapter.title}"?`)) {
                        return;
                    }

                    scope.chapter.remove();
                };

                // In the context of exam streams for the refund policy based on assessment completion percentage,
                // we don't really care about lessons marked as `test=true` because it's the stream and not the
                // lessons that will be counted in the refund policy. So, we could modify this logic to check if
                // the lesson is an assessment OR a test, but it wouldn't really provide any meaningful benefit.
                scope.lessonCannotBeRemoved = lesson => scope.stream.assessmentEditingLocked && lesson.assessment;

                // remove the lesson from the chapter
                scope.removeLesson = lesson => {
                    if (scope.lessonCannotBeRemoved(lesson)) {
                        return;
                    }

                    scope.chapter.removeLesson(lesson);
                    scope.addLesson = ''; // not sure selection is triggered without this
                };

                scope.openLessonEditor = lesson => {
                    scope.loadUrl(lesson.editorUrl, '_blank');
                };
            },
        };
    },
]);
