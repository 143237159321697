import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Text.TextModel',
        'Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel',
        'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.AlwaysReadyContinueButton.AlwaysReadyContinueButtonModel',
        'MaxTextLengthConfig',

        (ComponentEditorViewModel, TextModel, ImageModel, AlwaysReadyContinueButtonModel, MaxTextLengthConfig) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveModel',
                );

                this.addMainUiComponentTemplate('no_interaction', function () {
                    // If there is something other than text in the text section, remove it
                    if (this.model.staticContentForText && !this.model.staticContentForText.isA(TextModel)) {
                        this.model.staticContentForText.remove();
                    }

                    // If there is not a text component in contentForText, add one
                    if (!this.model.staticContentForText) {
                        this.model.staticContentForText = TextModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }

                    // If there is a contentForInteractive section, remove it
                    if (this.model.staticContentForInteractive) {
                        this.model.staticContentForInteractive.remove();
                    }

                    // Replace the continue button
                    if (!this.frame.continueButton || !this.frame.continueButton.isA(AlwaysReadyContinueButtonModel)) {
                        this.frame.continueButton = AlwaysReadyContinueButtonModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }
                });

                Object.defineProperty(this.prototype, 'mainImage', {
                    get() {
                        return this.firstContextImage;
                    },
                    set(val) {
                        this.firstContextImage = val;
                    },
                });

                Object.defineProperty(this.prototype, 'firstContextImage', {
                    get() {
                        if (
                            this.model.staticContentForFirstImage &&
                            this.model.staticContentForFirstImage.isA(ImageModel)
                        ) {
                            return this.model.staticContentForFirstImage;
                        }
                        return undefined;
                    },
                    set(val) {
                        if (angular.isUndefined(val)) {
                            this.model.staticContentForFirstImage = undefined;
                        } else if (val.isA && val.isA(ImageModel)) {
                            this.model.staticContentForFirstImage = val;
                        } else {
                            throw new Error('Cannot set firstContextImage to something that is not an imageModel.');
                        }
                    },
                });

                Object.defineProperty(this.prototype, 'secondContextImage', {
                    get() {
                        if (
                            this.model.staticContentForSecondImage &&
                            this.model.staticContentForSecondImage.isA(ImageModel)
                        ) {
                            return this.model.staticContentForSecondImage;
                        }
                        return undefined;
                    },
                    set(val) {
                        if (angular.isUndefined(val)) {
                            this.model.staticContentForSecondImage = undefined;
                        } else if (val.isA && val.isA(ImageModel)) {
                            this.model.staticContentForSecondImage = val;
                        } else {
                            throw new Error('Cannot set secondContextImage to something that is not an imageModel.');
                        }
                    },
                });

                return {
                    directiveName: 'cf-text-image-interactive-editor',

                    initialize($super, model) {
                        $super(model);

                        this._updateMainTextComponentConfig();
                    },

                    setup() {
                        return this;
                    },

                    setMainTextComponent(textComponent) {
                        // If there's already something here that is not text, you probably
                        // weren't trying to replace it.  So we'll throw.
                        if (this.model.staticContentForText && !this.model.staticContentForText.isA(TextModel)) {
                            throw new Error(
                                `Cannot replace existing ${this.model.staticContentForText.type} with setter on mainTextComponent.`,
                            );
                        }
                        this.model.staticContentForText = textComponent;
                    },

                    _updateMainTextComponentConfig() {
                        // this is a bit of a hack.  it assumes that the mainTextComponent is in
                        // staticContentForText.  If that ever changes, this will fail
                        // silently
                        this.model.on(
                            'set:staticContentForText',
                            () => {
                                if (this.mainTextComponent) {
                                    this.mainTextComponent.editorViewModel.setConfig({
                                        maxRecommendedTextLength: () => {
                                            if (this.firstContextImage || this.secondContextImage) {
                                                return MaxTextLengthConfig.TEXT_WITH_IMAGE;
                                            }
                                            return MaxTextLengthConfig.TEXT_WITHOUT_IMAGE;
                                        },
                                        showTextToolbar: true,
                                        autoRenderKey: 'renderMainAutomatically',
                                    });
                                }
                            },
                            true,
                        );
                    },
                };
            }),
    ],
);
