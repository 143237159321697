import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('frameInteractionTypes', [
    '$injector',
    $injector => {
        const FlexibleComponentized = $injector.get('Lesson.FrameList.Frame.FlexibleComponentized');
        const NoInteraction = $injector.get('Lesson.FrameList.Frame.NoInteraction');
        const TextImageInteractiveEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveEditorViewModel',
        );
        const ChallengesEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel',
        );
        const config = $injector.get('ConfigFactory').getSync();

        // This function is used to succinctly get the config for a frame type that is used as an
        // option in the frame type dropdown in the editor. `componentized` frames have a separate option
        // for each editor_template. The configs for those are defined differently (see the list below)
        function getInteractionTypeConfig(title, abbreviation, FrameKlass, opts) {
            const { isInteractive } = opts;
            if (isInteractive === undefined) throw new Error('isInteractive is required');
            return {
                title,
                abbreviation,
                matchesFrame: frame => frame.constructor === FrameKlass,
                FrameKlass,
                isInteractive,
            };
        }

        const frameInteractionTypeConfigsMap = {
            noInteraction: FlexibleComponentized.getInteractionTypeConfig(
                'No Interaction',
                'NI',
                TextImageInteractiveEditorViewModel.templates.no_interaction,
            ),
            noInteraction2: getInteractionTypeConfig('No Interaction 2', 'NI2', NoInteraction, {
                isInteractive: false,
            }),
            thisOrThat: FlexibleComponentized.getInteractionTypeConfig(
                'This or That?',
                'ToT',
                ChallengesEditorViewModel.templates.this_or_that,
            ),
            fillInTheBlanks: FlexibleComponentized.getInteractionTypeConfig(
                'Fill in the Blanks',
                'FIB',
                ChallengesEditorViewModel.templates.fill_in_the_blanks,
            ),
            blanksOnImage: FlexibleComponentized.getInteractionTypeConfig(
                'Blanks on Image',
                'BOI',
                ChallengesEditorViewModel.templates.blanks_on_image,
            ),
            matching: FlexibleComponentized.getInteractionTypeConfig(
                'Matching',
                'MA',
                ChallengesEditorViewModel.templates.matching,
            ),
            basicMultipleChoice: FlexibleComponentized.getInteractionTypeConfig(
                'Multiple Choice',
                'MC',
                ChallengesEditorViewModel.templates.basic_multiple_choice,
            ),
            multipleChoiceMultipleCards: FlexibleComponentized.getInteractionTypeConfig(
                'Multiple Choice w Multiple Cards',
                'MCMC',
                ChallengesEditorViewModel.templates.multiple_card_multiple_choice,
            ),
            multipleChoicePoll: FlexibleComponentized.getInteractionTypeConfig(
                'Poll',
                'PO',
                ChallengesEditorViewModel.templates.multiple_choice_poll,
            ),
            composeBlanks: FlexibleComponentized.getInteractionTypeConfig(
                'Compose Blanks',
                'CB',
                ChallengesEditorViewModel.templates.compose_blanks,
            ),
            composeBlanksOnImage: FlexibleComponentized.getInteractionTypeConfig(
                'Compose Blanks on Image',
                'CBOI',
                ChallengesEditorViewModel.templates.compose_blanks_on_image,
            ),
            imageHotspot: FlexibleComponentized.getInteractionTypeConfig(
                'Image Hotspot',
                'IH',
                ChallengesEditorViewModel.templates.image_hotspot,
            ),
        };

        // Non-componentized types are not ready for prime-time yet, so we only show them when configured to
        // do so in local development
        if (!config.showNonComponentizedFramesInEditor()) {
            delete frameInteractionTypeConfigsMap.noInteraction2;
        }

        // Within each config entry, add the key that points to it.
        Object.keys(frameInteractionTypeConfigsMap).forEach(key => {
            const interactionTypeConfig = frameInteractionTypeConfigsMap[key];
            interactionTypeConfig.identifier = key;
        });

        const availableFrameInteractionTypeConfigs = Object.keys(frameInteractionTypeConfigsMap)
            .map(interactionType => {
                const interactionTypeConfig = frameInteractionTypeConfigsMap[interactionType];
                if (
                    !interactionTypeConfig.showNonComponentizedFramesInEditor &&
                    interactionTypeConfig.FrameKlass !== FlexibleComponentized
                ) {
                    return null;
                }
                return interactionTypeConfig;
            })
            .filter(Boolean)
            .sort((entry1, entry2) => (entry1.title > entry2.title ? 1 : -1));

        const getInteractionTypeConfigForFrame = frame => {
            const configForFrame = Object.values(frameInteractionTypeConfigsMap).find(frameInteractionTypeConfig =>
                frameInteractionTypeConfig.matchesFrame(frame),
            );
            if (!configForFrame) {
                throw new Error('Cannot determine interaction type for frame');
            }
            return configForFrame;
        };

        const getInteractionTypeAbbreviationForFrame = frame => getInteractionTypeConfigForFrame(frame).abbreviation;
        const getInteractionTypeForFrame = frame => getInteractionTypeConfigForFrame(frame).identifier;
        const isInteractiveType = interactionType => frameInteractionTypeConfigsMap[interactionType].isInteractive;

        const getInteractionTypeTitle = interactionType => frameInteractionTypeConfigsMap[interactionType].title;

        const returnValue = {
            availableFrameInteractionTypeConfigs,
            getInteractionTypeAbbreviationForFrame,
            getInteractionTypeForFrame,
            getInteractionTypeTitle,
            isInteractiveType,
        };
        return returnValue;
    },
]);
