import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel', [
    'SuperModel',
    '$injector',
    (SuperModel, $injector) => {
        const $route = $injector.get('$route');

        const ComponentEditorViewModel = SuperModel.subclass(function () {
            this.extendableArray('supportedConfigOptions');

            this.defineCallbacks('initialize');

            this.extend({
                // we have to keep a reference to the name of the model and inject it later
                // in order to avoid circular reference errors
                setModel(path) {
                    if (typeof path === 'string') {
                        Object.defineProperty(this, 'Model', {
                            get() {
                                return $injector.get(path);
                            },
                            configurable: true, // needed for some tests
                        });
                    } else {
                        throw new Error("You shouldn't be using models anymore!");
                    }
                },

                addComponentTo(frame, options) {
                    if (!this.Model) {
                        throw new Error('No model set on EditorViewModel.  Must call setModel()');
                    }
                    options = angular.extend(
                        {
                            behaviors: {},
                        },
                        options || {},
                    );
                    const component = this.Model.new(options);

                    // If copying from one frame to another, addComponent
                    // will create a clone.
                    const clonedComponent = frame.addComponent(component);
                    return frame.editorViewModelFor(clonedComponent);
                },

                onConfigChange(key, callback) {
                    const callbackKey = this._configChangeCallbackKey(key);
                    if (!this[callbackKey]) {
                        this.extendableArray(callbackKey);
                    }

                    this[callbackKey]().push(callback);
                },

                triggerConfigChangeCallbacks(editorViewModel, key, value) {
                    const callbackKey = this._configChangeCallbackKey(key);
                    if (!this[callbackKey]) {
                        return;
                    }

                    const callbacks = this[callbackKey]();
                    callbacks.forEach(callback => {
                        callback.apply(editorViewModel, [value]);
                    });
                },

                supportsConfigOption(key) {
                    return this.supportedConfigOptions().includes(key);
                },

                supportConfigOption(key) {
                    this.supportedConfigOptions().push(key);
                },

                addMainUiComponentTemplate(identifier, factory) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!this.hasOwnProperty('templates')) {
                        this.templates = {};
                    }
                    const template = {
                        identifier,
                        MainUiComponentEditorViewModel: this,
                        EditorViewModel: this,
                        FrameKlass: $injector.get('Lesson.FrameList.Frame.FlexibleComponentized'),
                        factory,
                    };
                    this.templates[identifier] = template;
                    return template;
                },

                addTemplate(identifier, factory) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!this.hasOwnProperty('templates')) {
                        this.templates = {};
                    }
                    const template = {
                        identifier,
                        factory,
                        EditorViewModel: this,
                    };
                    this.templates[identifier] = template;
                    return template;
                },

                allowInstantiate(fn) {
                    this.overrideAllowInstantiate = true;
                    const result = fn();
                    this.overrideAllowInstantiate = false;
                    return result;
                },

                _configChangeCallbackKey(key) {
                    return `configChangeCallbacksFor_${key}`.camelize();
                },
            });

            this.supportConfigOption('userDefinedOptions');
            this.setModel('Lesson.FrameList.Frame.Componentized.Component.ComponentModel');

            Object.defineProperty(this.prototype, 'frame', {
                get() {
                    return this.model.frame();
                },
            });

            Object.defineProperty(this.prototype, 'lesson', {
                get() {
                    return this.frame.lesson();
                },
            });

            Object.defineProperty(this.prototype, 'componentName', {
                get() {
                    return this.model.componentName;
                },
            });

            Object.defineProperty(this.prototype, 'type', {
                get() {
                    return `${this.componentName}EditorViewModel`;
                },
            });

            Object.defineProperty(this.prototype, 'mainTextComponent', {
                get() {
                    return this.model.mainTextComponent;
                },
                set(val) {
                    this.setMainTextComponent(val);
                },
            });

            Object.defineProperty(this.prototype, 'mainImage', {
                get() {
                    throw new Error(
                        `Components that are used as mainUiComponents should define mainImage. ${this.type} does not`,
                    );
                },
                set() {
                    throw new Error(
                        `Components that are used as mainUiComponents should define mainImage. ${this.type} does not`,
                    );
                },
            });

            // see comment in componentized.js text_content getter/setter
            Object.defineProperty(this.prototype, 'text_content', {
                get() {
                    return this.mainTextComponent && this.mainTextComponent.text;
                },
                set(val) {
                    if (!this.mainTextComponent) {
                        const TextModel = $injector.get(
                            'Lesson.FrameList.Frame.Componentized.Component.Text.TextModel',
                        );
                        this.mainTextComponent = TextModel.EditorViewModel.addComponentTo(this.frame).setup().model;
                    }
                    this.mainTextComponent.text = val;
                },
                configurable: true, // overridden in tests
            });

            Object.defineProperty(this.prototype, 'activeTemplate', {
                get() {
                    const identifier = this.model.editor_template;
                    if (!identifier) {
                        return undefined;
                    }
                    const template = this.constructor.templates && this.constructor.templates[identifier];
                    if (template) {
                        return template;
                    }
                    throw new Error(`No template found for "${identifier}"`);
                },
            });

            return {
                initialize(model) {
                    this.model = model;
                    this.config = {};
                    this._configChangeListeners = {};
                    this.runCallbacks('initialize', () => {});

                    let allowInstantiate = false;
                    if (ComponentEditorViewModel.overrideAllowInstantiate) {
                        allowInstantiate = true;
                    }
                    if (
                        !$route.current ||
                        _.includes(['edit-lesson', 'lesson-diff', 'bot'], $route.current.directive)
                    ) {
                        allowInstantiate = true;
                    }

                    if (!allowInstantiate) {
                        const ErrorLogService = $injector.get('ErrorLogService');
                        ErrorLogService.notify(
                            new Error(
                                `EditorViewModel instantiated on screen other than edit-lesson: ${$route.current.directive}`,
                            ),
                        );
                    }
                },

                applyCurrentTemplate() {
                    if (this.model.editor_template) {
                        const template = this.constructor.templates[this.model.editor_template];
                        if (!template) {
                            throw new Error(`Template "unknownTemplate" is not defined on ${this.type}`);
                        }
                        try {
                            this.applyTemplate(template, true);
                        } catch (e) {
                            if (e instanceof RangeError) {
                                throw new RangeError(
                                    `Maximum call stack error in applyTemplate:${this.type}:${template.identifier}`,
                                );
                            } else {
                                throw e;
                            }
                        }
                    }
                },

                setup() {
                    return this;
                },

                setConfig(config) {
                    angular.forEach(config, (value, key) => {
                        if (this.model.isReference(key)) {
                            const referencedModel = this.model[key];
                            if (!referencedModel) {
                                throw new Error(`Cannot set editor config on "${key}" because it is not defined.`);
                            } else if (_.isArray(referencedModel)) {
                                // FIXME: should eventually support this
                                throw new Error(`Cannot set editor config on "${key}" because it is an Array.`);
                            }

                            // FIXME: also set this on the editor_config and apply it to
                            // references added subsequently
                            this.editorViewModelFor(referencedModel).setConfig(value);
                        } else {
                            const currentValue = this.config[key];
                            if (currentValue !== value) {
                                this.config[key] = value;
                                if (!this.constructor.supportsConfigOption(key)) {
                                    throw new Error(`Config option "${key}" not supported on ${this.model.type}.`);
                                }
                                this.constructor.triggerConfigChangeCallbacks(this, key, value);
                            }
                        }
                    });
                },

                // any component used as a mainUiComponent should define a
                // getter for mainTextComponent on it's model.  It's editorViewModel should
                // define setMainTextComponent
                setMainTextComponent() {
                    throw new Error(
                        `Components that are used as mainUiComponents should define mainTextComponent. ${this.type} does not`,
                    );
                },

                editorViewModelFor(model) {
                    return model ? this.frame.editorViewModelFor(model) : undefined;
                },

                editorViewModelsFor(models) {
                    return models ? this.frame.editorViewModelsFor(models) : [];
                },

                remove() {
                    this.model.remove();
                },

                removeReferencesTo(component) {
                    // for this things that are lists, we need to get the proxyList so
                    // we can call remove on it
                    this.model.constructor.referenceKeys().forEach(key => {
                        // grab the proxyList or component
                        const value = this.model[key];

                        // If it is a proxyList, then remove the component from it
                        if (_.isArray(value)) {
                            value.remove(component);
                        } else if (value === component) {
                            this.model[key] = undefined;
                        }
                    });
                },

                swapReferences(oldComponent, newComponent) {
                    // for this things that are lists, we need to get the proxyList so
                    // we can call remove on it
                    this.model.constructor.referenceKeys().forEach(key => {
                        // grab the proxyList or component
                        const value = this.model[key];

                        // If it is a proxyList, then swap the component in it
                        if (_.isArray(value)) {
                            const index = value.indexOf(oldComponent);
                            if (index > -1) {
                                value.splice(index, 1, newComponent);
                            }
                        } else if (value === oldComponent) {
                            this.model[key] = newComponent;
                        }
                    });
                },

                applyTemplate(template, force, oldFrame) {
                    if (!template) {
                        this.model.editor_template = undefined;
                        return;
                    }
                    if (typeof template === 'string') {
                        const identifier = template;
                        template = this.constructor.templates[identifier];
                        if (!template) {
                            throw new Error(`No template found for "${identifier}" on ${this.type}.`);
                        }
                    }
                    if (template.EditorViewModel !== this.constructor) {
                        throw new Error(`Cannot apply template from a different EditorViewModel to ${this.type}`);
                    }

                    if (template.identifier === this.model.editor_template && !force) {
                        return;
                    }
                    this.model.editor_template = template.identifier;
                    template.factory.apply(this, [oldFrame]);
                },
            };
        });

        return ComponentEditorViewModel;
    },
]);
