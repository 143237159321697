import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.Matching', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const ChallengesEditorTemplateHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
        );
        const MatchingBoardModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardModel',
        );
        const MultipleChoiceChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        );

        return new AModuleAbove({
            included(ChallengesEditorViewModel) {
                ChallengesEditorViewModel.addMainUiComponentTemplate('matching', function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    this.model.sharedContentForInteractive = undefined;
                    if (
                        !this.model.sharedContentForInteractiveImage ||
                        !this.model.sharedContentForInteractiveImage.isA(MatchingBoardModel)
                    ) {
                        this.model.sharedContentForInteractiveImage = MatchingBoardModel.EditorViewModel.addComponentTo(
                            this.frame,
                        ).setup().model;
                    }
                    const matchingBoardModel = this.model.sharedContentForInteractiveImage;

                    /*
                            This really sucks, but the order of config settings matters.

                            newChallengeType needs to come before supportsSharedAnswerList, in
                            case we are switching from compose_blanks, and the challenges do not
                            support answerLists.

                            supportsSharedAnswerList needs to come before supportsSequentialAndConsumable
                            so that supportsSequentialAndConsumable can determine whether this is initially
                            sequential or consumable
                        */
                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                    });

                    // setup shared answer list
                    const answerListEditorViewModel = templateHelper.setupSharedAnswerList(
                        'matching',
                        0,
                        'mainImageMatchingBoard',
                    );
                    answerListEditorViewModel.setConfig({
                        disallowAddAnswers: true,
                    });

                    // does not include forceSingleColumn because the answer list here
                    // is always single column
                    answerListEditorViewModel.setUserDefinedOptions('RandomizeAnswerOrder');

                    this.setConfig({
                        supportsSequentialAndConsumable: true,
                        allowSetContextImages: {
                            bottom: false,
                        },
                    });

                    this.sequentialOrConsumable = 'consumable';

                    templateHelper.setChallengeTemplate('matching');

                    templateHelper.setupAtLeastOneChallenge();
                    matchingBoardModel.challengesComponent = this.model;

                    // setup shared text
                    templateHelper.setupSharedText();
                });
            },
        });
    },
]);
