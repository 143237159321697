import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('LessonDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const FrameDiff = $injector.get('FrameDiff');
        const JsonDiff = $injector.get('JsonDiff');

        const LessonDiff = SuperModel.subclass(() => ({
            initialize(oldVersion, newVersion) {
                this.oldVersion = oldVersion;
                this.newVersion = newVersion;

                // instantiate editorViewModels for all components.
                // This was initially necessary to get the proxyEditorConfig
                // set up for use in ComponentDiff#_setChallengeListDiffs, but
                // could be needed for other stuff
                _.chain(this.oldVersion && this.oldVersion.frames)
                    .union(this.newVersion && this.newVersion.frames)
                    .invokeMap('reify')
                    .map('components')
                    .flattenDeep()
                    .map('editorViewModel')
                    .value();

                // FIXME: remove arrayDiff
                // scope.diff = {};
                // scope.diff.frameDiff = arrayDiff(oldVersion.frameIds, newVersion.frameIds);

                this.oldVersion.reifyAllFrames();
                this.newVersion.reifyAllFrames();

                // Create a map that includes the frame and the index
                // for the old and new version of the frame in each version.
                // This is not the most readable way to do this, but it
                // lets us do it in one loop through the frame lists.
                const frames = {};
                [oldVersion, newVersion].forEach((version, i) => {
                    version.frames.forEach((frame, j) => {
                        frames[frame.id] = frames[frame.id] || [];
                        frames[frame.id][i] = {
                            frame,
                            index: j,
                        };
                    });
                });

                const frameDiffsById = (this.frameDiffsById = {});
                this.frameDiffs = _.chain(frames)
                    .map((entry, frameId) => {
                        const frameDiff = new FrameDiff(entry[0] && entry[0].frame, entry[1] && entry[1].frame);
                        frameDiffsById[frameId] = frameDiff;
                        return frameDiff;
                    })
                    .value();

                this.titleDiff = new JsonDiff(oldVersion.title, newVersion.title);
                this.tagDiff = new JsonDiff(oldVersion.tag, newVersion.tag);
                this.descriptionDiff = new JsonDiff(oldVersion.description, newVersion.description);
                this.keyTermsDiff = new JsonDiff(oldVersion.key_terms, newVersion.key_terms);
                this.assessmentDiff = new JsonDiff(oldVersion.assessment, newVersion.assessment);
                this.unrestrictedDiff = new JsonDiff(oldVersion.unrestricted, newVersion.unrestricted);

                this.hasMetaDataChanges =
                    this.titleDiff.edited ||
                    this.descriptionDiff.edited ||
                    this.keyTermsDiff.edited ||
                    this.assessmentDiff.edited ||
                    this.unrestrictedDiff.edited;
            },

            frameDiffsForVersion(version) {
                const self = this;
                return _.map(version.frames, frame => self.frameDiffsById[frame.id]);
            },
        }));

        return LessonDiff;
    },
]);
