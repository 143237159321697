import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/interactive_cards/interactive_cards_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfInteractiveCardsEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',
    EditorDirHelper =>
        EditorDirHelper.getOptions({
            scope: {
                editorViewModel: '<',
                frameViewModel: '<',
                options: '<?',
                setInteractiveImageOn: '<',
            },
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                scope.addInteractiveCard = () => {
                    scope.editorViewModel.addInteractiveCard();
                };

                scope.removeInteractiveCard = overlayEditorViewModel => {
                    scope.editorViewModel.removeInteractiveCard(overlayEditorViewModel);
                };
            },
        }),
]);
