import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/content_topic/content_topics_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('contentTopicsEditor', [
    '$injector',

    function factory($injector) {
        const ContentTopic = $injector.get('ContentTopic');
        const guid = $injector.get('guid');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                item: '<',
            },
            link(scope) {
                scope.EDITOR_FORM_CLASSES = $injector.get('EDITOR_FORM_CLASSES');

                // load up all the available content topics for this lesson's locale
                // and add them to the selectize input
                ContentTopic.index().then(response => {
                    scope.contentTopics = response.result;

                    scope.contentTopicsMap = _.fromPairs(
                        _.map(scope.contentTopics, contentTopic => [contentTopic.id, contentTopic]),
                    );

                    scope.topicOptions = _.chain(scope.contentTopics)
                        .filter(contentTopic => contentTopic.locales[scope.item.locale])
                        .map(contentTopic => ({
                            value: contentTopic.id,
                            text: contentTopic.locales[scope.item.locale],
                        }))
                        .value();
                });

                //--------------------------
                // Topic Updates
                //--------------------------

                // when text for a new topic is typed into the selectize box,
                // create a new topic option and add it to the list of options as well
                // as the contentTopicsMap
                scope.addTopic = (text, callback) => {
                    const option = {
                        text,
                        value: guid.generate(),
                    };
                    scope.topicOptions.push(option);

                    // Add the new topic to the contentTopicsMap
                    scope.contentTopicsMap[option.value] = {
                        id: option.value,
                        locales: {
                            en: option.text,
                        },
                        name: option.text, // FIXME: remove when 'name' column is dropped in the future
                    };

                    callback(option);
                };

                // Any time a new topic is selected or typed into
                // the selectize input, update the content item's locale_pack
                scope.$watchCollection('proxy.selectedTopicOptions', val => {
                    if (val && scope.topicOptions) {
                        // var availableTopicsMap = getAvailableTopicsMap();

                        scope.item.locale_pack.content_topics = val.map(id => scope.contentTopicsMap[id]);
                    }
                });

                //--------------------------
                // Initialization
                //--------------------------

                // put the selectedTopicOptions inside an object
                // so that ng-if does not mess up the references
                scope.proxy = {
                    selectedTopicOptions: scope.item.locale_pack.content_topics.map(contentTopic => contentTopic.id),
                };
            },
        };
    },
]);
