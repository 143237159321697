import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardEditorViewModel',

    [
        '$injector',

        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const MatchingChallengeButtonModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonModel',
            );

            return ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardModel');

                return {
                    setup() {
                        if (!this.model.matchingChallengeButtons) {
                            this.model.matchingChallengeButtons = [];
                        }

                        return this;
                    },

                    initialize(model) {
                        this.model = model;
                        this.removedChallengeButtons = {};

                        if (this.model.challengesComponent) {
                            this.setupChallengeListeners();
                        } else {
                            const listener = this.model.on('set:challengesComponent', () => {
                                this.setupChallengeListeners();
                                listener.cancel();
                            });
                        }
                    },

                    setupChallengeListeners() {
                        const matchingChallengeButtonsChallengeListeners = [];
                        const that = this; // save on bind() overhead

                        // add challenge listeners for addition / removal of challenges
                        matchingChallengeButtonsChallengeListeners.push(
                            this.model.challengesComponent.challenges.on('childAdded', challenge => {
                                that.addMatchingChallengeButtonToChallenge(challenge);
                            }),
                        );

                        matchingChallengeButtonsChallengeListeners.push(
                            this.model.challengesComponent.challenges.on('childRemoved', challenge => {
                                that.removeMatchingChallengeButtonFromChallenge(challenge);
                            }),
                        );
                    },

                    addMatchingChallengeButtonToChallenge(challenge) {
                        const currentButtonsByChallenge = {};
                        this.model.matchingChallengeButtons.forEach(currentButton => {
                            currentButtonsByChallenge[currentButton.challenge.id] = currentButton;
                        });

                        const updatedMatchingButtons = [];
                        let button;
                        this.model.challengesComponent.challenges.forEach(curChallenge => {
                            if (currentButtonsByChallenge[curChallenge.id]) {
                                // use the existing collection button
                                button = currentButtonsByChallenge[curChallenge.id];
                            } else if (this.removedChallengeButtons[curChallenge.id]) {
                                // use the removed collection button
                                button = this.removedChallengeButtons[curChallenge.id];
                                this.removedChallengeButtons[curChallenge.id] = undefined;
                            } else {
                                // build out a new challenge button
                                button = MatchingChallengeButtonModel.EditorViewModel.addComponentTo(
                                    this.model.frame(),
                                ).setup().model;
                                button.challenge = curChallenge;
                            }

                            curChallenge.editorViewModel.proxyEditorConfig = {
                                label: 'Button',
                                editorViewModel: button.editorViewModel,
                            };
                            updatedMatchingButtons.push(button);
                        });

                        this.model.matchingChallengeButtons = updatedMatchingButtons;

                        if (
                            !challenge.editorViewModel.correctAnswerText &&
                            !challenge.editorViewModel.correctAnswerImage
                        ) {
                            const i = this.model.challengesComponent.challenges.indexOf(challenge);
                            challenge.editorViewModel.correctAnswerText = `Match for challenge ${i + 1}`;
                        }

                        return button;
                    },

                    removeMatchingChallengeButtonFromChallenge(challenge) {
                        let indexToRemove = -1;
                        let buttonToRemove;
                        for (let i = this.model.matchingChallengeButtons.length - 1; i >= 0; i--) {
                            const button = this.model.matchingChallengeButtons[i];
                            if (button.challenge === challenge) {
                                buttonToRemove = button;
                                indexToRemove = i;
                                break;
                            }
                        }
                        if (indexToRemove > -1) {
                            this.removedChallengeButtons[buttonToRemove.challenge.id] = buttonToRemove;
                            this.model.matchingChallengeButtons.splice(indexToRemove, 1);
                        }
                    },
                };
            });
        },
    ],
);
