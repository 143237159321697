import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    Despite the name, note that this mixin is also used for the "Matching" and for the "Multiple Choice Multiple Card" editor templates

    It seems like what this is really for is any situation where each challenge is associated with a single correct answer, which should not be deletable (although
        that doesn't really describe the Check Many case, so who knows what this thing really is at this point?)
    It also kind of assumes that you're using sequential or consumable mixin, and that that is handling the usage of ResetAnswersOnActivated
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksMixin',
    [
        () => ({
            applyToEditorTemplate(editorViewModel, checkMany) {
                angular.forEach(
                    {
                        CompleteOnCorrect: {},
                        ClearMessagesOnAnswerSelect: {},
                        FlashIncorrectStyling: {},
                        ImmediateValidation: {},
                        ShowCorrectStyling: {},
                    },
                    (val, key) => {
                        editorViewModel.model.behaviors[key] = val;
                    },
                );

                // When we have a single answer, the correct answer
                // is always the one that corresponds to the blank, so
                // we don't need to allow the editor the set the correct answers.
                if (!checkMany) {
                    editorViewModel.model.behaviors.DisallowMultipleSelect = {};

                    editorViewModel.setConfig({
                        disableCorrectAnswerSelect: true,
                    });
                }

                // In Multiple Choice Multiple Cards, we support a check many option.
                // Even though it's kind of odd, since there are no visible blanks,
                // that frame type is implemented as a blanks on image. When the check many
                // option is selected, the editor does need to be able to mark which answers
                // are correct.
                else {
                    editorViewModel.model.behaviors.DisallowMultipleSelect = undefined;

                    editorViewModel.setConfig({
                        disableCorrectAnswerSelect: false,
                    });
                }

                editorViewModel.model.validator.behaviors.HasAllExpectedAnswers = {};
                editorViewModel.model.validator.behaviors.HasNoUnexpectedAnswers = {};

                // make sure any answerLists, whether shared or not, show the randomize
                // answer order option and the listen for it's answer list to be set
                function setAnswerListConfig(answerList) {
                    if (answerList) {
                        answerList.editorViewModel.setUserDefinedOptions('RandomizeAnswerOrder', 'forceSingleColumn');
                    }
                }
                setAnswerListConfig(editorViewModel.model.answerList);
                editorViewModel.model.on('set:answerList', setAnswerListConfig);

                function disallowCorrectAnswerDelete(newCorrectAnswers, oldCorrectAnswers) {
                    oldCorrectAnswers.forEach(answer => {
                        answer.editorViewModel.setConfig({
                            disallowDelete: false,
                        });
                    });
                    newCorrectAnswers.forEach(answer => {
                        answer.editorViewModel.setConfig({
                            disallowDelete: true,
                        });
                    });
                }

                disallowCorrectAnswerDelete(editorViewModel.correctAnswers, []);
                editorViewModel.model.on('set:correctAnswers', disallowCorrectAnswerDelete);

                editorViewModel.model.on('remove', function () {
                    // Be defensive in case there's no correct answers to remove (incomplete frame perhaps, already removed?)
                    if (this.editorViewModel?.correctAnswers?.length > 0) {
                        this.editorViewModel.correctAnswers.forEach(answer => {
                            answer.remove();
                        });
                    }
                });
            },
        }),
    ],
);
