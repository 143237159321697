import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesMathjaxEditor', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const FormatsText = $injector.get('FormatsText');
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const MathJax = $window.MathJax;

        // we need to do this lazily to make sure mathjax is loaded
        let initialized;

        /* eslint-disable new-cap */
        function init() {
            // skip this in tests
            if (!MathJax || !MathJax.ElementJax || !MathJax.ElementJax.mml) {
                return;
            }

            if (initialized) {
                return;
            }
            initialized = true;

            /*
                    We first extend MathJax's internal CHTMLcreateNode method.  MathJax
                    allows us to add extensions, but as far as I can tell, the internals
                    of mathjax provide very little flexibility as to what those extensions
                    can do.  Only changes to certain attributes are supported, for example.

                    So it seems like we have to extend this internal method in order to
                    give ourselves the ability to add the cf-challenge-blank directive to
                    a block in the mathjax.

                    The original method can be found at https://github.com/mathjax/MathJax/blob/master/unpacked/jax/output/CommonHTML/jax.js
                */
            const origCHTMLcreateNode = MathJax.ElementJax.mml.mbase.prototype.CHTMLcreateNode;

            MathJax.ElementJax.mml.mbase.prototype.CHTMLcreateNode = function (...args) {
                // call the original function
                const node = origCHTMLcreateNode.apply(this, args);

                // If the \Blank extension (see below) has added a cf-challenge-blank
                // property to this object, then add the cf-challenge-blank directive.
                // (Note this functionality is duplicated in ProcessesChallengeBlanks#processRegularBlank,
                // which does the same thing for blanks outside of mathjax.  Changes here may need to
                // be implemented there as well.)
                if (node && this['cf-challenge-blank']) {
                    const index = this['cf-challenge-blank'].index;
                    node.setAttribute('cf-challenge-blank', 'true');
                    node.setAttribute(
                        'view-model',
                        `viewModel.challengesComponentViewModel.challengesViewModels[${index}]`,
                    );
                    node.setAttribute('within-mathjax', 'true');
                    node.setAttribute('style', 'position: relative; font-family: MJXc-TeX-main-R,MJXc-TeX-main-Rw');

                    // If these Z-indexes ever change, see show_frame_player.scss#$overlayIndex
                    node.setAttribute('ng-style', `{zIndex: 100-${index}}`);
                    node.classList.add('within-mathjax');
                }

                return node;
            };

            /*
                    This is the \Blank extension to mathjax, which looks for
                    the text \BLANK[index]{math} inside of a mathjax block and
                    tells the HTMLcreateSpan method above to add the cf-challenge-blank
                    directive to the generated span.  This extension is based on the HREF
                    extension, which can be found at https://github.com/mathjax/MathJax/blob/master/unpacked/extensions/TeX/HTML.js
                */
            MathJax.Callback.Queue(
                MathJax.Hub.Register.StartupHook('TeX Jax Ready', () => {
                    const TEX = MathJax.InputJax.TeX;
                    const TEXDEF = TEX.Definitions;

                    TEXDEF.Add(
                        {
                            macros: {
                                href: 'HREF_attribute',
                                Blank: 'BLANK',
                            },
                        },
                        null,
                        true,
                    );

                    TEX.Parse.Augment({
                        //
                        //  Implements \BLANK[index]{math}
                        //
                        BLANK(name) {
                            const index = this.GetBrackets(name);
                            const arg = this.GetArgumentMML(name);
                            this.Push(
                                arg.With({
                                    'cf-challenge-blank': {
                                        index,
                                    },
                                }),
                            );
                        },

                        //
                        //  returns an argument that is a single MathML element
                        //  (in an mrow if necessary)
                        //
                        GetArgumentMML(name) {
                            let arg = this.ParseArg(name);
                            if (arg.inferred && arg.data.length === 1) {
                                arg = arg.data[0];
                            } else {
                                delete arg.inferred;
                            }
                            return arg;
                        },
                    });

                    MathJax.Hub.Startup.signal.Post('TeX HTML Ready');
                }),
            );
        }

        function processMathjax(text) {
            init();

            if (!initialized && !window.RUNNING_IN_TEST_MODE) {
                return undefined;
            }

            const self = this; // eslint-disable-line

            const mathMatches = text.match(/[%%|$$]/g); // using mathDelim var fails due to weird encoding issues?
            if (mathMatches && mathMatches.length > 1) {
                // if this is an Arabic lesson, wrap the mathjax in \alwaysar{ ... }
                if (self.model.lesson && self.model.lesson.locale === 'ar') {
                    text = FormatsText.findMathjaxAndReplace(
                        text,
                        originalMathJax => `\\alwaysar{ ${originalMathJax} }`,
                    );
                }

                // MathJax expects HTMLElements
                const element = $('<div>').html(text);
                $('#mathjax-hidden-div').append(element);

                return $q(resolve => {
                    const htmlElement = element[0];

                    // reprocess the marked up text area by queueing up a typeset event in MathJax's rendering engine
                    $window.MathJax.Hub.Queue([
                        'Typeset',
                        $window.MathJax.Hub,
                        htmlElement,
                        self.replaceMathJaxElement.bind(self, element, htmlElement, resolve),
                    ]);
                });
            }
            return text;
        }

        /* eslint-enable new-cap */

        return new AModuleAbove({
            included(TextEditorViewModel) {
                // set up a callback to be run whenever a TextModel is initialized
                TextEditorViewModel.setCallback('after', 'initialize', function () {
                    const editorViewModel = this;

                    this.model.on('behavior_added:ProcessesMathjax', () => {
                        editorViewModel.formatters.add(
                            'removeMathJax',
                            FormatsText.removeMathjaxBeforeMarkdown.bind(FormatsText),
                        );
                        editorViewModel.formatters.add(
                            'replaceMathJax',
                            FormatsText.addMathjaxAfterMarkdown.bind(FormatsText),
                        );
                        editorViewModel.formatters.add('processMathjax', processMathjax);
                    });
                });
            },

            replaceMathJaxElement(element, htmlElement, resolve) {
                let processedText = htmlElement.outerHTML;

                // replace any intentionally escaped MathJax delimeters
                processedText = processedText.replace(/\\([%|$])/g, '$1');

                resolve(processedText);
                element.remove();
            },
        });
    },
]);
