import angularModule from 'Editor/angularModule/scripts/editor_module';
import moment from 'moment-timezone';

angularModule.factory('LessonSaver', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const Lesson = $injector.get('Lesson');
        const sequence = $injector.get('sequence');
        const $q = $injector.get('$q');
        const guid = $injector.get('guid');
        const HttpQueue = $injector.get('HttpQueue');

        return SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'progressMessage', {
                get() {
                    return this.$$progressMessage;
                },
                set(val) {
                    if (val === this.$$progressMessage) {
                        return;
                    }

                    this.$$progressMessage = val;
                    if (this.logProgress) {
                        console.log(new Date(), val);
                    }
                },
            });

            Object.defineProperty(this.prototype, 'resultLines', {
                get() {
                    return this.$$resultLines;
                },
                set(val) {
                    if (val === this.$$resultLines) {
                        return;
                    }

                    this.$$resultLines = val;
                    if (this.logProgress) {
                        _.forEach(val, line => {
                            console.log(line);
                        });
                    }
                },
            });

            return {
                initialize(updatePublishedOrWorking, filters, logProgress) {
                    this.id = guid.generate();
                    this.progressMessage = 'Not started';
                    this.savedLessonDetails = [];
                    this.saveErrorDetails = [];
                    this.logProgress = logProgress;
                    this.updatePublishedOrWorking = updatePublishedOrWorking; // 'published' or 'working'
                    if (!_.includes(['published', 'working'], this.updatePublishedOrWorking)) {
                        throw new Error('Invalid value for updatePublishedOrWorking.');
                    }
                    this.filters = filters || {};
                },

                exec() {
                    const self = this;
                    return this._loadLessonIds()
                        .then(() => self._saveMoreLessons())
                        .then(() => {
                            self.complete = true;
                            let resultLines = [`Saved ${self.savedLessonDetails.length} lessons`];

                            // Print info about all the lessons that saved successfully
                            resultLines = resultLines.concat(
                                _.map(
                                    self.savedLessonDetails,
                                    details => ` - Saved ${details.id} / ${details.versionId} : ${details.title}`,
                                ),
                            );

                            if (_.some(self.lessonIds)) {
                                resultLines.push('');
                                resultLines.push('');
                                resultLines.push('');
                            }

                            // Each lessonId that is still left in the array is one that
                            // we failed to load.  Print a warning for each of those
                            resultLines = resultLines.concat(
                                _.map(self.lessonIds, id => {
                                    let message;
                                    if (self.updatePublishedOrWorking === 'working') {
                                        message = 'Probably because an editor is working on it.';
                                    } else {
                                        message = 'This is unexpected when updating published lessosn.';
                                    }
                                    return ` ******** Failed to save lesson '${id}\. ${message}`;
                                }),
                            );

                            if (_.some(self.saveErrorDetails)) {
                                resultLines.push('');
                                resultLines.push('');
                                resultLines.push('');
                            }

                            // Print warnings for lessons that errored when we tried to save them (usually
                            // validation errors)
                            resultLines = resultLines.concat(
                                _.chain(self.saveErrorDetails)
                                    .map(details => {
                                        let status = null;
                                        let message = null;
                                        try {
                                            status = details.response.status;
                                        } catch (e) {}
                                        try {
                                            message = details.response.data.message;
                                        } catch (e) {}

                                        return [
                                            ` ******** Error saving lesson ${details.id} / ${details.versionId} : ${details.title}.`,
                                            `           Status code: ${status}`,
                                            `               Message: ${message}`,
                                        ];
                                    })
                                    .flattenDeep()
                                    .value(),
                            );

                            if (_.some(self.lessonIds) || _.some(self.saveErrorDetails)) {
                                resultLines.push('');
                                resultLines.push('');
                                resultLines.push('');
                                resultLines.push(' ************** NOT ALL LESSONS WERE SAVED.  NOTE FAILURES ABOVE.');
                            }

                            self.resultLines = resultLines;
                        });
                },

                _loadLessonIds() {
                    const self = this;
                    this.progressMessage = 'Loading lesson list';

                    // We respect the filters passed in, but published is special, since we
                    // handle saving working versions and saving published ones differently.
                    const filters = _.extend(this.filters, {
                        published: this.updatePublishedOrWorking === 'published',
                    });

                    return Lesson.index(
                        {
                            filters,
                            'fields[]': ['id'],
                        },
                        {
                            // this request could take a long time. Do not time it out
                            timeout: undefined,
                        },
                    ).then(response => {
                        self.lessonIds = _.map(response.result, 'id');
                        self.lessonCount = self.lessonIds.length;
                        self.progressMessage = `${self.lessonCount} lessons loaded with filters: ${JSON.stringify(
                            filters,
                        )}`;
                    });
                },

                _loadLessons(ids) {
                    const oneHourAgo = moment().subtract(1, 'hour').toDate().getTime() / 1000;
                    const filters = {
                        published: this.updatePublishedOrWorking === 'published',
                        id: ids,
                    };

                    // when migrating working versions, only migrate ones
                    // that have not been updated in at least an hour to prevent
                    // conflicts with human editors
                    if (this.updatePublishedOrWorking === 'working') {
                        filters.updated_before = oneHourAgo;
                    }
                    return Lesson.index(
                        {
                            filters,
                            fields: 'ALL',
                            'except[]': ['lesson_progress', 'version_history', 'practice_frames'],
                        },
                        {
                            // this request could take a long time. Do not time it out
                            timeout: undefined,
                        },
                    ).then(response => response.result);
                },

                _saveMoreLessons() {
                    const self = this;

                    const targetIds = self.lessonIds.slice(0, 10);

                    // Because of the updated_before filter used when
                    // migrating working versions, we might not
                    // update all the lessons we intend to.  We only
                    // remove the ones we've updated from the list
                    let loadedIds = [];
                    if (!_.some(targetIds)) {
                        return $q.when();
                    }

                    return self
                        ._loadLessons(targetIds)
                        .then(lessons => {
                            loadedIds = _.map(lessons, 'id');
                            return self._saveLessonBatch(lessons);
                        })
                        .then(() => {
                            // remove the ids of any lessons that were updated
                            // and move ones that we failed to update to the
                            // end of the list so that we don't attempt to update them
                            // right away
                            self.lessonIds = _.chain(self.lessonIds)
                                .difference(loadedIds)
                                .sortBy(id => (_.includes(targetIds, id) ? 1 : 0))
                                .value();

                            // If we have failed to updated any lessons then we
                            // stop and report in the output the lessons we failed to save
                            if (_.some(loadedIds)) {
                                return self._saveMoreLessons();
                            }
                        });
                },

                _saveLessonBatch(lessons) {
                    const self = this;
                    return sequence(lessons, lesson =>
                        self._saveLesson(lesson).then(
                            () => {
                                self.savedLessonDetails.push({
                                    title: lesson.title,
                                    id: lesson.id,
                                    versionId: lesson.version_id,
                                });
                                self.progressMessage = `${self.savedLessonDetails.length} of ${self.lessonCount} lessons saved. (Last lesson id/versionId: ${lesson.id}/${lesson.version_id})`;
                            },
                            response => {
                                const status = response && response.status;
                                const message = response && response.data && response.data.message;
                                self.saveErrorDetails.push({
                                    title: lesson.title,
                                    id: lesson.id,
                                    versionId: lesson.version_id,
                                    response,
                                });
                                self.progressMessage = `Error saving lesson with id/versionId: ${lesson.id}/${lesson.version_id}. Status=${status}, message=${message}`;
                                HttpQueue.unfreezeAfterError(response.config);
                            },
                        ),
                    );
                },

                _validateNoUnexpectedChanges(origLessonJson, lesson) {
                    let newLessonJson = lesson.asJson();

                    _.forEach(origLessonJson.frames, (origFrameJson, i) => {
                        let newFrameJson = newLessonJson.frames[i];

                        _.forEach(origFrameJson.components, (origComponentJson, j) => {
                            origComponentJson = _.omit(origComponentJson, 'formatted_text');
                            let newComponentJson = _.omit(newFrameJson.components[j], 'formatted_text');

                            // Ignore cases where message_ids has been instantiated to an empty array
                            if (
                                newComponentJson.message_ids &&
                                newComponentJson.message_ids.length === 0 &&
                                !origComponentJson.message_ids
                            ) {
                                delete lesson.frames[i].components[j].message_ids;
                                newLessonJson = lesson.asJson();
                                newFrameJson = newLessonJson.frames[i];
                                newComponentJson = _.omit(newFrameJson.components[j], 'formatted_text');
                            }

                            if (!_.isEqual(origComponentJson, newComponentJson)) {
                                console.error('Orig component', origComponentJson);
                                console.error('New component', newComponentJson);
                                throw new Error(`Unexpected changes to lesson ${lesson.id} frame ${i} component ${j}`);
                            }
                        });
                    });
                },

                _saveLesson(lesson) {
                    const self = this;
                    const origLessonJson = lesson.asJson();

                    const textEditorViewModels = _.chain(lesson.frames)
                        .invokeMap('reify')
                        .map('components')
                        .flattenDeep()
                        .filter({
                            type: 'TextModel',
                        })
                        .map('editorViewModel')
                        .value();

                    return $q
                        .all(_.invokeMap(textEditorViewModels, 'formatText'))
                        .then(() => self._validateNoUnexpectedChanges(origLessonJson, lesson))
                        .then(() => {
                            const opts = {};
                            if (self.updatePublishedOrWorking === 'published') {
                                opts.update_version = lesson.version_id;
                            }
                            return lesson.save(opts, {
                                'FrontRoyal.ApiErrorHandler': {
                                    skip: true,
                                },
                            });
                        });
                },
            };
        });
    },
]);
