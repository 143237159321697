import angularModule from 'Editor/angularModule/scripts/editor_module';
import 'ExtensionMethods/array';
import template from 'Editor/angularModule/views/lesson_types/frame_list/grade_frame_list.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('gradeFrameList', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lesson: '<',
                grader: '<?',
            },
            link(scope) {
                scope.issueLists = [];

                scope.$watch('lesson', lesson => {
                    if (!lesson) {
                        return;
                    }
                    if (scope.grader) {
                        return;
                    }
                    lesson.grade().then(grader => {
                        scope.grader = grader;
                    });
                });

                // in real life, we can just set these on the grade() callback,
                // but it's easier to test if each have their own watcher
                scope.$watchCollection('grader.criticalIssues', criticalIssues => {
                    scope.issueLists.forEach(list => {
                        if (list.identifier === 'critical_issues') {
                            Array.remove(scope.issueLists, list);
                        }
                    });
                    if (criticalIssues && criticalIssues.length > 0) {
                        scope.issueLists.push({
                            identifier: 'critical_issues',
                            title: 'Critical Issues',
                            issues: criticalIssues,
                            message:
                                'Critical Issues result in an automatic F-. Revise or justify your decision not to fix in the Version Description field.',
                        });
                    }
                });

                scope.$watchCollection('grader.potentialProblems', potentialProblems => {
                    scope.issueLists.forEach(list => {
                        if (list.identifier === 'potential_problems') {
                            Array.remove(scope.issueLists, list);
                        }
                    });
                    if (potentialProblems && potentialProblems.length > 0) {
                        scope.issueLists.push({
                            identifier: 'potential_problems',
                            title: 'Potential Problems',
                            issues: potentialProblems,
                            message:
                                'Potential Problems drop your score one letter grade for each occurrence. Revise to up your score.',
                        });
                    }
                });

                scope.$watchCollection('grader.notes', notes => {
                    scope.issueLists.forEach(list => {
                        if (list.identifier === 'notes') {
                            Array.remove(scope.issueLists, list);
                        }
                    });
                    if (notes && notes.length > 0) {
                        scope.issueLists.push({
                            identifier: 'notes',
                            title: 'Notes',
                            issues: notes,
                            message:
                                'Notes give you helpful tips to improve your lesson. They do not lower your score.',
                        });
                    }
                });

                Object.defineProperty(scope, 'passingGrade', {
                    get() {
                        return ['A+', 'A', 'A-', 'B'].includes(scope.grader.letterGrade);
                    },
                });

                Object.defineProperty(scope, 'generalMessage', {
                    get() {
                        let goodness;
                        if (scope.grader.letterGrade === 'A+') {
                            goodness = 'great';
                        } else if (['A', 'A-', 'B'].includes(scope.grader.letterGrade)) {
                            goodness = 'good';
                        } else {
                            goodness = 'bad';
                        }
                        return {
                            great: "You're practically perfect in every way.",
                            good: 'Good job. Try to eliminate potential problems to get to an A+!',
                            bad: 'Almost there! Eliminate critical issues and potential problems to reach B or higher before pinning.',
                        }[goodness];
                    },
                });
            },
        };
    },
]);
