import angularModule from 'Editor/angularModule/scripts/editor_module';
import EditorConstants from 'Editor/angularModule/scripts/constants';
import template from 'Editor/angularModule/views/frame_annotations_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('frameAnnotationsEditor', [
    '$injector',
    $injector => ({
        restrict: 'E',
        scope: {
            frameViewModel: '<',
        },
        templateUrl,
        link(scope) {
            const DialogModal = $injector.get('DialogModal');

            scope.frameAnnotationTypes = EditorConstants.frameAnnotationTypes;

            const frameAnnotations = scope.frameViewModel.frame.annotations;
            scope.annotationsConfig = {};

            scope.annotationIsSelected = annotationType => Object.keys(frameAnnotations).includes(annotationType);

            scope.frameAnnotationTypes.forEach(annotation => {
                scope.annotationsConfig[annotation.type] = {};

                Object.defineProperty(scope.annotationsConfig[annotation.type], 'selected', {
                    get() {
                        return scope.annotationIsSelected(annotation.type);
                    },
                    set(val) {
                        if (val) {
                            frameAnnotations[annotation.type] = {};
                        } else {
                            // If the user is unselecting an annotation, we should
                            // show them a DialogModal mentioning that it will also
                            // clear out any existing notes.
                            DialogModal.confirm({
                                text: `Please confirm that you'd like to unmark this frame as "${annotation.label}". This will also clear out any existing notes.`,
                                confirmCallback: () => {
                                    delete frameAnnotations[annotation.type];
                                },
                            });
                        }
                    },
                });

                Object.defineProperty(scope.annotationsConfig[annotation.type], 'notes', {
                    get() {
                        return frameAnnotations[annotation.type].notes;
                    },
                    set(val) {
                        if (val) {
                            frameAnnotations[annotation.type].notes = val;
                        } else {
                            delete frameAnnotations[annotation.type].notes;
                        }
                    },
                });
            });
        },
    }),
]);
