import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/diff/lesson_diff.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('lessonDiff', [
    '$injector',

    function factory($injector) {
        const Lesson = $injector.get('Lesson');
        const $q = $injector.get('$q');
        const $timeout = $injector.get('$timeout');
        const $window = $injector.get('$window');
        const LessonDiff = $injector.get('LessonDiff');

        return {
            templateUrl,
            scope: {
                lesson: '<?',
                versionIds: '<?',
                versionId1: '@',
                versionId2: '@',
                lessonId: '@',
            },
            restrict: 'E',
            link(scope, elem) {
                let canvas;
                let ctx;

                // set up marker arrow URL for proper display of move arrows in jsondiffpatch SVGs
                scope.markerArrowURL = `${$window.location}#markerArrow`;

                scope.options = {
                    showNewFrames: true,
                    showComments: true,
                    showTextChanges: true,
                    showImageChanges: true,
                    showOtherChanges: true,
                    showDeletedFrames: false,
                    showCheckedOffComments: false,
                    showJsonDiff: false,
                    showAnnotations: true,
                    // showUnchangedComponents: false,
                    // showUnchangedMetadata: false
                };

                Object.defineProperty(scope, 'versions', {
                    get() {
                        if (!scope.versionIds) {
                            return [];
                        }

                        return _.chain(scope.versionIds)
                            .map(id => scope.loadedVersions[id])
                            .sortBy('updated_at')
                            .value();
                    },
                });

                function filterChangedFrames() {
                    if (!scope.lessonDiff) {
                        return;
                    }

                    const options = scope.options;
                    scope.changedFrames = _.filter(scope.lessonDiff.frameDiffs, frameDiff => {
                        if (!options.showDeletedFrames && frameDiff.removed) {
                            return false;
                        }

                        if (options.showTextChanges && frameDiff.textDiffs.length > 0) {
                            return true;
                        }

                        if (options.showOtherChanges && frameDiff.otherDiffs.length > 0) {
                            return true;
                        }

                        if (options.showImageChanges && frameDiff.imageChanges.length > 0) {
                            return true;
                        }

                        if (options.showComments && frameDiff.authorCommentsDiff.hasNewComments) {
                            return true;
                        }

                        if (options.showComments && frameDiff.authorCommentsDiff.hasRemovedComments) {
                            return true;
                        }

                        if (options.showCheckedOffComments && frameDiff.authorCommentsDiff.hasCheckedOffComments) {
                            return true;
                        }

                        if (options.showAnnotations && frameDiff.annotationsDiff.changes.length > 0) {
                            return true;
                        }

                        if (options.showJsonDiff && frameDiff.componentsDiff.edited) {
                            return true;
                        }
                    });
                }

                function loadVersion(versionId) {
                    if (scope.loadedVersions[versionId]) {
                        return $q.when(scope.versions[versionId]);
                    }
                    return Lesson.show(scope.lesson.id, {
                        filters: {
                            version_id: versionId,
                        },
                        'except[]': ['lesson_progress'],
                    }).then(response => {
                        const version = response.result;
                        scope.loadedVersions[version.version_id] = version;
                        return version;
                    });
                }

                //-------------------------
                // Drawing
                //-------------------------

                function clearCanvas() {
                    if (!canvas) {
                        return;
                    }
                    $(canvas).hide();
                    canvas.width = scope.getCanvasWidth();
                    canvas.height = 100;
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#FF4D63'; // $COLOR_V3_CORAL
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }

                function drawLine(i, j) {
                    const frameElem1 = elem.find('.frames-list:eq(0) .frame').eq(i);
                    const frameElem2 = elem.find('.frames-list:eq(1) .frame').eq(j);

                    const yStart = frameElem1.position().top + frameElem1.height();
                    const yFinish = frameElem2.position().top;
                    const xStart = frameElem1.position().left + frameElem1.width() / 2;
                    const xFinish = frameElem2.position().left + frameElem2.width() / 2;

                    ctx.beginPath();
                    ctx.moveTo(xStart, yStart);

                    ctx.bezierCurveTo(xStart, yStart + 8, xFinish, yFinish - 8, xFinish, yFinish);
                    ctx.stroke();
                }

                scope.getCanvasWidth = () => {
                    if (!scope.lessonDiff) {
                        return 0;
                    }
                    const numFrames = Math.max(
                        scope.lessonDiff.oldVersion.frames.length,
                        scope.lessonDiff.newVersion.frames.length,
                    );
                    return numFrames * (30 + 14) + 10;
                };

                function drawLinesFromFrame(fromFrame) {
                    const fromIndex = fromFrame.index();
                    const toFrame = scope.versions[1].frameForId(fromFrame.id, true);

                    if (toFrame) {
                        const toIndex = toFrame.index();
                        drawLine(fromIndex, toIndex);
                    }
                }

                function drawLines() {
                    canvas = elem.find('canvas')[0];
                    ctx = canvas.getContext('2d');
                    clearCanvas();
                    scope.versions[0].frames.forEach(frame => {
                        drawLinesFromFrame(frame);
                    });
                    $(canvas).show();
                }

                //-------------------------
                // Diffing
                //-------------------------

                function generateLessonDiff() {
                    if (!scope.versions || scope.versions.length < 2) {
                        return;
                    }

                    scope.lessonDiff = new LessonDiff(scope.versions[0], scope.versions[1], scope.options);

                    // wait a beat for canvas to show up
                    $timeout(0).then(drawLines);
                }

                //-------------------------
                // Resizes
                //-------------------------

                let windowResizeTimeout;
                $($window).on('resize.lesson_diff', () => {
                    // debounce it
                    if (windowResizeTimeout) {
                        $timeout.cancel(windowResizeTimeout);
                    } else {
                        clearCanvas();
                    }
                    windowResizeTimeout = $timeout(() => {
                        drawLines();
                        windowResizeTimeout = undefined;
                    }, 500);
                });
                scope.$on('$destroy', () => {
                    $($window).off('resize.lesson_diff');
                    $timeout.cancel(windowResizeTimeout);
                });

                //-------------------------
                // Watches
                //-------------------------

                scope.$watch('lesson', lesson => {
                    if (lesson) {
                        if (scope.versionId1 && scope.versionId2) {
                            scope.versionIds = [scope.versionId1, scope.versionId2];
                        }
                        scope.loadedVersions = {};
                        scope.loadedVersions[lesson.version_id] = lesson;
                        scope.lessonDiff = undefined;
                    }
                });

                scope.$watch('lessonId', lessonId => {
                    if (lessonId) {
                        Lesson.show(lessonId, {
                            filters: {
                                published: false,
                            },
                            'except[]': ['lesson_progress'],
                        }).then(response => {
                            scope.lesson = response.result;
                        });
                    }
                });

                scope.$watchCollection('versionIds', versionIds => {
                    if (!versionIds) {
                        return;
                    }

                    scope.selectedVersions = {};
                    scope.selectedVersions[versionIds[0]] = true;
                    scope.selectedVersions[versionIds[1]] = true;

                    loadVersion(versionIds[0])
                        .then(loadVersion.bind(undefined, versionIds[1]))
                        .then(generateLessonDiff);
                });

                scope.$watch('options.showComments', value => {
                    if (!value) {
                        scope.options.showCheckedOffComments = false;
                    }
                });

                scope.$watchCollection('options', filterChangedFrames);
                scope.$watch('lessonDiff', filterChangedFrames);
            },
        };
    },
]);
