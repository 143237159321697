import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/list_lessons.html';
import customTitleTemplate from 'Editor/angularModule/views/lesson/list_lessons_custom_title.html';
import customActionsTemplate from 'Editor/angularModule/views/lesson/list_lessons_custom_actions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listLessons', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const RecentlyEditedLessons = $injector.get('RecentlyEditedLessons');
        const ngToast = $injector.get('ngToast');
        const DialogModal = $injector.get('DialogModal');
        const ClientStorage = $injector.get('ClientStorage');
        const SiteMetadata = $injector.get('SiteMetadata');
        const scopeTimeout = $injector.get('scopeTimeout');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const Lesson = $injector.get('Lesson');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.searchType = 'content';
                scope.Locale = $injector.get('Locale');
                scope.apiFields = Lesson.fieldsForEditorList;

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //-------------------------
                // Recents
                //-------------------------

                scope.recentlyEditedLessons = new RecentlyEditedLessons();
                scope.showRecent = false;

                scope.toggleRecents = () => {
                    scope.showRecent = !scope.showRecent;
                    ClientStorage.setItem('listLessonsShowRecent', scope.showRecent);
                };

                if (ClientStorage.getItem('listLessonsShowRecent') === 'true') {
                    scopeTimeout(scope, () => {
                        scope.toggleRecents();
                    });
                }

                //-------------------------
                // Editing
                //-------------------------

                scope.editLesson = lesson => {
                    if (!lesson.editorAbilities.canOpenEditor) {
                        return;
                    }
                    const path = `/editor/lesson/${lesson.id}/edit`;
                    $location.url(path);
                };

                scope.editPracticeLesson = lesson => {
                    lesson.openPracticeEditor(false);
                };

                scope.deleteLesson = (lesson, onDestroyCallback) => {
                    const confirmed = $window.confirm(
                        `Are you sure you want to delete "${lesson.title}"?  This cannot be undone.`,
                    );
                    if (confirmed) {
                        lesson.destroy().then(() => {
                            ngToast.create({
                                content: 'Lesson deleted successfully.',
                                className: 'success',
                            });

                            if (onDestroyCallback) {
                                onDestroyCallback(lesson);
                            }
                        });
                    }
                };

                scope.createCallback = lesson => {
                    // now that we have the server-generated lesson id, embed it in the seo url and save
                    const seoCanonicalUrl = SiteMetadata.seoCanonicalUrlFromTitle('lesson', lesson.title, lesson.id);
                    lesson.entity_metadata.canonical_url = seoCanonicalUrl;
                    lesson.entity_metadata.save().then(() => {
                        scope.editLesson(lesson);
                    });
                };

                //-------------------------
                // Lesson Archive
                //-------------------------

                function archiveLesson(lesson) {
                    if (lesson.stream_titles.length === 0) {
                        lesson.archived = true;
                        lesson.save().then(
                            () => {
                                ngToast.create({
                                    content: 'Lesson archived successfully.',
                                    className: 'success',
                                });
                            },
                            () => {
                                // if the save failed, undo the change:
                                lesson.archived = false;
                            },
                        );
                    } else {
                        DialogModal.alert({
                            content: '<archive-lesson lesson="lesson"></archive-lesson>',
                            title: `Archive "${lesson.title}"`,
                            scope: {
                                lesson,
                            },
                        });
                    }
                }

                function unarchiveLesson(lesson) {
                    lesson.archived = false;
                    lesson.save().then(() => {
                        ngToast.create(
                            {
                                content: 'Lesson un-archived successfully.',
                                className: 'success',
                            },
                            () => {
                                // if the save failed, undo the change:
                                lesson.archived = true;
                            },
                        );
                    });
                }

                scope.toggleArchiveLesson = lesson => {
                    if (lesson.archived) {
                        unarchiveLesson(lesson);
                    } else {
                        archiveLesson(lesson);
                    }
                };

                scope.toggleArchiveList = () => {
                    scope.archived = !scope.archived;
                    scope.updateFilters();
                };

                //-------------------------
                // Columns
                //-------------------------

                scope.displayColumns = [
                    {
                        prop: 'tag',
                        type: 'text',
                        label: 'Tag',
                    },
                    {
                        prop: 'title',
                        type: 'custom',
                        label: 'Title',
                        template: customTitleTemplate,
                        callbacks: {
                            editCallback: scope.editLesson,
                        },
                    },
                    {
                        prop: 'authorName',
                        type: 'text',
                        label: 'Author',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'sortablePublishedAt',
                        type: 'time',
                        label: 'Published',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'sortableModifiedAt',
                        type: 'time',
                        label: 'Modified',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'streamTitlesToS',
                        type: 'text',
                        label: 'Course(s)',
                        classes: ['hidden-xs', 'hidden-sm', 'hidden-md'],
                    },
                    {
                        prop: 'editorAbilities',
                        type: 'custom',
                        label: 'Actions',
                        template: customActionsTemplate,
                        callbacks: {
                            editCallback: scope.editLesson,
                            editPracticeCallback: scope.editPracticeLesson,
                            deleteCallback: scope.deleteLesson,
                            toggleArchiveCallback: scope.toggleArchiveLesson,
                        },
                    },
                ];

                //-------------------------
                // Filtering
                //-------------------------

                scope.quickFilterProperties = ['tag', 'title', 'authorName', 'streamTitlesToS'];
                const booleanProperties = ['modifiedAfterPublished', 'hasPublishedVersion'];

                const defaultFilters = [
                    {
                        server: true,
                        default: true,
                        value: {
                            published: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            archived: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            in_users_locale_or_en: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            locale: 'en',
                        },
                    },
                ];

                scope.filtersForRecents = [
                    {
                        recentlyViewed: true,
                    },
                ];

                scope.modifiedAfterPublishedOptions = [
                    {
                        label: 'Modified after published',
                        value: 'true',
                    },
                    {
                        label: 'Not modified after published',
                        value: 'false',
                    },
                ];

                scope.hasPublishedVersionOptions = [
                    {
                        label: 'Published',
                        value: 'true',
                    },
                    {
                        label: 'Not published',
                        value: 'false',
                    },
                ];

                // Custom filtering function
                function customFilterCallback(filters) {
                    // fulltext search
                    if (scope.searchText) {
                        const key = scope.searchType === 'comments' ? 'comments_search' : 'content_search';
                        const val = {};
                        val[key] = scope.searchText;
                        filters.push({
                            type: 'hidden',
                            server: true,
                            default: false,
                            value: val,
                        });
                    }

                    // archive filtering
                    // eslint-disable-next-line no-prototype-builtins
                    const archivedFilter = _.find(filters, filter => filter.value.hasOwnProperty('archived'));
                    archivedFilter.default = !scope.archived;
                    archivedFilter.value.archived = !!scope.archived;
                    return filters;
                }

                // wire up filtering support
                editContentItemListMixin.onLink(
                    scope,
                    'listLessons',
                    defaultFilters,
                    booleanProperties,
                    customFilterCallback,
                );
            },
        };
    },
]);
