import 'angular-selectize2/dist/angular-selectize';
import 'angular-utils-pagination';
import 'angular-bind-html-compile';

import 'AdminEditorReportsShared/angularModule';
import 'AspectRatioEnforcer/angularModule';
import 'ContentItemEditor/angularModule';
import 'DialogModal/angularModule';
import 'Dynamic-node/angularModule';
import 'EditableThingsList/angularModule';
import 'FileTypeIcon/angularModule';
import 'FormatsText/angularModule';
import 'FrontRoyalSpinner/angularModule';
import 'FrontRoyalUiBootstrap/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'Groups/angularModule';
import 'ItemGroupsEditor/angularModule';
import 'JqueryHelper/angularModule';
import 'JsonFileInput/angularModule';
import 'LazyLoadedPanelBody/angularModule';
import 'Lessons/angularModule';
import 'Navigation/angularModule';
import 'Pagination/angularModule';
import 'Playlists/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'Sequence/angularModule';
import 'Sortable/angularModule';
import 'SortableColumns/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';
import 'ZoomContainer/angularModule';
import 'ngStorableImage/angularModule';

import 'angular-diff-match-patch';

import 'Editor/angularModule/scripts/oo_directive/directive_base';
import 'Editor/angularModule/scripts/oo_directive/oo_directive';

import { react2Angular } from 'FrontRoyalReact2Angular';
import { PlaylistExamEvaluations } from 'PlaylistExamEvaluation';

// window.diff_match_patch = diff_match_patch; // needed for 'angular-diff-match-patch' (I think)

const angularModule = angular
    .module('FrontRoyal.Editor', [
        'angular-bind-html-compile',
        'Translation',
        'timeoutHelpers',
        'AspectRatioEnforcer',
        'scrollHelper',
        'ngStorableImage',
        'diff-match-patch',
        'DialogModal',
        'dynamicNode',
        'EditableThingsList',
        'FileTypeIcon',
        'FormatsText',
        'SiteMetadata',
        'FrontRoyal.AdminEditorReportsShared',
        'FrontRoyal.EntityMetadata',
        'FrontRoyal.GlobalMetadata',
        'FrontRoyal.Lessons',
        'FrontRoyal.Playlists',
        'FrontRoyalSpinner',
        'FrontRoyal.Groups',
        'FrontRoyal.Navigation',
        'FrontRoyal.UiBootstrap',
        'FrontRoyal.ContentItemEditor',
        'FrontRoyal.Upload',
        'ItemGroupsEditor',
        'JsonFileInput',
        'jQueryHelper',
        'lazyLoadedPanelBody',
        'ooDirective',
        'Pagination',
        'safeApply',
        'selectize',
        'sortable',
        'sortable',
        'SortableColumns',
        'zoomContainer',
        'Sequence',
        'ui.bootstrap.datetimepicker', // angular-bootstrap-datetimepicker
        'angularUtils.directives.dirPagination',
    ])

    .constant('LESSON_TRANSLATION_EXPORT_FORMATS', ['oneColumnTextOnly', 'oneColumnWithFrames'])

    .run([
        '$injector',
        $injector => {
            const $window = $injector.get('$window');
            if ($window.MathJax) {
                $window.MathJax.ErrorLogService = $injector.get('ErrorLogService');

                if (!window.RUNNING_IN_TEST_MODE) {
                    const MathJax = $window.MathJax;

                    MathJax.Ajax.config.path.arabic = '/assets/arabic-mathjax';

                    MathJax.Hub.Config({
                        jax: ['output/CommonHTML'],
                        tex2jax: {
                            inlineMath: [['%%', '%%']],
                        },
                        TeX: {
                            extensions: ['color.js', 'enclose.js', '[arabic]/arabic.js'],
                        },

                        CommonHTML: {
                            // observed that the switch in Mathjax fonts led to larger fonts; this seems to help
                            minScaleAdjust: 96,
                            scale: 96,
                            undefinedFamily: 'AmiriWeb', // for arabic math
                        },

                        // hide the "loading / processing" etc messages in bottom left
                        showProcessingMessages: false,
                        messageStyle: 'none',
                    });

                    // Unit command definitions for Arabic translations
                    MathJax.Hub.Config({
                        Arabic: {
                            dict: {
                                // Units
                                SecondsAr: ['second', 'Text', ['\\text{s}', 'ث']],
                                MinuteAr: ['minute', 'Text', ['\\text{min}', 'د']],
                                HourAr: ['hour', 'Text', ['\\text{h}', 'س']],
                                DayAr: ['day', 'Text', ['\\text{d}', 'يوم']],
                                YearAr: ['year', 'Text', ['\\text{yr}', 'سنة']],
                                MeterAr: ['meter', 'Text', ['\\text{m}', 'م']],
                                CentimeterAr: ['centimeter', 'Text', ['\\text{cm}', 'سم']],
                                MillimeterAr: ['millimeter', 'Text', ['\\text{mm}', 'مم']],
                                KilometerAr: ['kilometer', 'Text', ['\\text{km}', 'كم']],
                                NanometerAr: ['nanometer', 'Text', ['\\text{nm}', 'نم']],
                                GramAr: ['gram', 'Text', ['\\text{g}', 'غرام']],
                                KilogramAr: ['kilogram', 'Text', ['\\text{kg}', 'كغ']],
                                MilligramAr: ['milligram', 'Text', ['\\text{mg}', 'مغ']],
                                MicrogramAr: ['microgram', 'Text', ['\\text{μg}', 'ميكروغرام']],
                                LiterAr: ['liter', 'Text', ['\\text{L}', 'لتر']],
                                MilliliterAr: ['milliliter', 'Text', ['\\text{ml}', 'مل']],
                                Diameter: ['diameter', 'Text', ['d', 'ق']], // circle diameter
                                Circumfrence: ['circumfrence', 'Text', ['C', 'ح']], // circle circumference (legacy, incorrect spelling)
                                Circumference: ['circumference', 'Text', ['C', 'ح']], // circle circumference (correct spelling)
                                NewtonAr: ['newton', 'Text', ['\\text{N}', 'نيوتن']],
                                JouleAr: ['joule', 'Text', ['\\text{J}', 'جول']],
                                RadianAr: ['radian', 'Text', ['\\text{rad}', 'راد']],
                                HertzAr: ['hertz', 'Text', ['\\text{Hz}', 'هيرتز']],
                                CoulombAr: ['coulomb', 'Text', ['\\text{C}', 'كولوم']],
                                HenryAr: ['henry', 'Text', ['\\text{H}', 'هنري']],
                                WeberAr: ['weber', 'Text', ['\\text{Wb}', 'ويبر']],
                                WattbAr: ['watt', 'Text', ['\\text{W}', 'واط']],
                                KilowattbAr: ['kilowatt', 'Text', ['\\text{kW}', 'كيلوواط']],
                                FaradbAr: ['farad', 'Text', ['\\text{F}', 'فاراد']],
                                VoltAr: ['volt', 'Text', ['\\text{V}', 'فولت']],
                                AmpereAr: ['ampere', 'Text', ['\\text{A}', 'أمبير']],
                                OhmAr: ['ohm', 'Text', ['\\text{Ω}', 'أوم']],
                                TeslaAr: ['tesla', 'Text', ['\\text{T}', 'تسلا']],

                                // Variables
                                VoltageAr: ['voltage', 'Text', ['V', 'جـ']],
                                CurrentAr: ['current', 'Text', ['I', 'ت']],
                                ResistanceAr: ['resistance', 'Text', ['R', 'م']],
                                ChargeAr: ['charge', 'Text', ['Q', 'ش']],
                                MagfieldAr: ['magfield', 'Text', ['B', 'غ']],
                                ElecfieldAr: ['elecfield', 'Text', ['E', 'مـ']],
                                EnergyAr: ['energy', 'Text', ['E', 'ط']],
                                AreaAr: ['area', 'Text', ['A', 'م']],
                                VelocityAr: ['velocity', 'Text', ['v', 'ع']],
                                LengthAr: ['length', 'Text', ['L', 'ل']],
                                TimeAr: ['time', 'Text', ['t', 'ز']],
                                MomentumAr: ['momentum', 'Text', ['p', 'خ']],
                                PowerAr: ['power', 'Text', ['P', 'القدرة']],
                                DistanceAr: ['distance', 'Text', ['d', 'م']],
                                RdistanceAr: ['rdistance', 'Text', ['r', 'ف']],
                                WorkAr: ['work', 'Text', ['W', 'ش']],
                                CapacitanceAr: ['capacitance', 'Text', ['C', 'س']],
                                InductanceAr: ['inductance', 'Text', ['L', 'ح']],
                            },
                        },
                    });

                    // handle special case color-extension overrides due to our custom parsing
                    // see also: https://github.com/mathjax/MathJax/pull/1394#issuecomment-187109091
                    MathJax.Hub.Register.StartupHook('TeX color Ready', () => {
                        const MML = MathJax.ElementJax.mml;
                        const STACKITEM = MathJax.InputJax.TeX.Stack.Item;
                        const PUSH = STACKITEM.prototype.Push;

                        STACKITEM.Augment({
                            Push(mml) {
                                if (mml instanceof MML.mbase && (mml.mathcolor || mml.mathbackground)) {
                                    if (mml.class) {
                                        if (!mml.class.match(/mjx-extension-color/)) {
                                            mml.class += ' mjx-extension-color';
                                        }
                                    } else {
                                        mml.class = 'mjx-extension-color';
                                    }
                                }
                                // eslint-disable-next-line prefer-rest-params
                                return PUSH.apply(this, arguments);
                            },
                        });
                    });

                    // HACK: code to support \vec workaround fix from https://github.com/mathjax/MathJax/issues/1709
                    // See related code in mathjax.scss, where we define an inline font for the \vec arrow
                    MathJax.Hub.Register.StartupHook('CommonHTML Jax Ready', () => {
                        const MML = MathJax.ElementJax.mml;
                        const fixCombiningChar = MML.mo.prototype.CHTMLfixCombiningChar;
                        // eslint-disable-next-line new-cap
                        MML.mo.Augment({
                            CHTMLfixCombiningChar(node) {
                                if (node.textContent === '\u20D7') {
                                    //
                                    //  Safari makes combining characters into non-combining ones when
                                    //  they don't have anything to combine with; replace \vec arrow
                                    //  with a non-combining one so all browsers get the same thing
                                    //
                                    node = node.firstChild;
                                    node.firstChild.nodeValue = '\u2192';
                                    node.className = 'mjx-char MJX-VEC';
                                    node.style.marginLeft = '-.5em';
                                    node.style.paddingTop = '.45em';
                                } else {
                                    fixCombiningChar.call(this, node);
                                }
                            },
                        });
                    });

                    // overriding processError to add our logging
                    const origProcessError = MathJax.Hub.processError;
                    MathJax.Hub.processError = (err, state, type) => {
                        if (MathJax.ErrorLogService) {
                            const textBeingProcessed = $(state.scripts && state.scripts[0]).text();
                            const _err = new Error(`Math processing error: ${err.message}`);
                            MathJax.ErrorLogService.notify(_err, undefined, {
                                textBeingProcessed,
                            });
                        }
                        return origProcessError.apply(MathJax.Hub, [err, state, type]);
                    };

                    // Note: if you want to force a Math Processing Error, uncomment
                    // the following.  You may or may not have to type \crash inside
                    // the mathjax content
                    // MathJax.Hub.Register.StartupHook('TeX Jax Ready', function() {
                    //     MathJax.InputJax.TeX.Definitions.macros.crash = 'Crash';
                    // });

                    MathJax.Hub.setRenderer('CommonHTML');

                    MathJax.Hub.Configured();
                }
            }
        },
    ]);

angularModule.component(
    'playlistExamEvaluations',
    react2Angular(PlaylistExamEvaluations, ['playlistExamEvaluationId']),
);

export default angularModule;
