import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerEditorViewModel',

        AnswerEditorViewModel =>
            AnswerEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerNavigatorModel',
                );

                // the answer that this navigator is linked to directly.  Note that
                // the navigator will also trigger when an equivalent answer (i.e.
                // same text or image) is selected, validated, etc.
                Object.defineProperty(this.prototype, 'primaryAnswer', {
                    get() {
                        return this.model.answerMatcher.answer;
                    },
                });

                return {
                    directiveName: 'cf-selectable-answer-navigator-editor',

                    setup() {
                        return this;
                    },

                    initialize($super, model) {
                        $super(model);
                        // when the answer gets removed, the matcher will get removed, and this should be removed
                        this.model.on('set:answerMatcher', function (answerMatcher) {
                            if (!answerMatcher) {
                                this.remove();
                            }
                        });

                        this.model.on(
                            '.answerMatcher.answer:text_or_image_change',
                            this._onAnswerContentChanged.bind(this),
                        );
                    },

                    // If an answer is changed such that it has the same content
                    // as another one, then there is the possibility that two
                    // messages could apply to the same answer, the message that was
                    // initially added to it and the message that was added to
                    // another answer which is now equivalent.  Clean that sh up.
                    //
                    // Note: very similar functionality is copy-pasted in SelectAnswerNavigatorEditorViewModel
                    _onAnswerContentChanged() {
                        if (this._conflictingNavigatorExists()) {
                            this.model.remove();
                        }
                    },

                    _conflictingNavigatorExists() {
                        const messages = this.frame.componentsForType(this.model.constructor);

                        for (const message of messages) {
                            if (
                                message !== this.model &&
                                message.challenge === this.model.challenge &&
                                message.appliesToAnswer(this.primaryAnswer)
                            ) {
                                return true;
                            }
                        }

                        return false;
                    },
                };
            }),
    ],
);
