import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Mixins.IsMessageEditorViewModel',

        (ComponentEditorViewModel, IsMessageEditorViewModel) =>
            ComponentEditorViewModel.subclass(function () {
                this.include(IsMessageEditorViewModel);
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.UserInputMessage.UserInputMessageModel');

                return {
                    initialize($super, model) {
                        $super(model);
                        this.onInitializeMessageEditorViewModel();
                    },
                };
            }),
    ],
);
