import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/content_topic/list_content_topics.html';
import labelsTemplate from 'Editor/angularModule/views/content_topic/list_content_topics_custom_labels.html';
import streamTitlesTemplate from 'Editor/angularModule/views/content_topic/list_content_topics_custom_stream_titles.html';
import actionsTemplate from 'Editor/angularModule/views/content_topic/list_content_topics_custom_actions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listContentTopics', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.Locale = $injector.get('Locale');
                scope.clientFilters = {};

                //-------------------------
                // Editing
                //-------------------------

                function saveCallback(contentTopic) {
                    contentTopic.save().then(() => {
                        ngToast.create({
                            content: 'Content topic updated',
                            className: 'success',
                        });
                    });
                }

                function destroyCallback(contentTopic, editableThingsCallback) {
                    contentTopic.destroy().then(() => {
                        if (editableThingsCallback) {
                            editableThingsCallback(contentTopic);
                        }
                        ngToast.create({
                            content: 'Content topic deleted',
                            className: 'success',
                        });
                    });
                }

                //-------------------------
                // Columns
                //-------------------------

                scope.displayColumns = [
                    {
                        prop: 'localizedLabelsToS',
                        type: 'custom',
                        label: 'Localized Label',
                        template: labelsTemplate,
                    },
                    {
                        prop: 'streamTitles',
                        type: 'custom',
                        label: 'Courses',
                        classes: ['hidden-xs'],
                        template: streamTitlesTemplate,
                    },
                    {
                        prop: 'editorAbilities',
                        type: 'custom',
                        label: 'Actions',
                        template: actionsTemplate,
                        callbacks: {
                            saveCallback,
                            destroyCallback,
                        },
                    },
                ];

                //-------------------------
                // Filtering
                //-------------------------

                scope.quickFilterProperties = ['localizedLabelsToS', 'streamTitlesToS'];

                // wire up filtering support
                editContentItemListMixin.onLink(scope, 'listPlaylists', [], []);
            },
        };
    },
]);
