import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/user_defined_options.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfUserDefinedOptions', [
    () => ({
        restrict: 'E',

        scope: {
            editorViewModel: '<',
            options: '<',
        },

        templateUrl,

        link(scope, elem) {
            scope.$watch('options', options => {
                scope.optionOnValues = [];
                scope.dynamicProperties = {};

                // Loop through the options array to create entries in the dyanmicProperties object.
                // These entries will be used to drive the view defined in the associated template.
                // Essentially, we've created something of a DSL that lets individual frame templates
                // specify user defined editor options through a list of JS objects. These objects specify
                // things like the title, type of input, and provide getter/setters for the underlying data model.
                angular.forEach(options, (entry, i) => {
                    // toggleBehavior is a special case entry type that could probably be refactored to be consistent with the other types
                    if (entry.type === 'toggleBehavior') {
                        const val = scope.editorViewModel.model.behaviors[entry.behavior];
                        if (Object.prototype.hasOwnProperty.call(entry, 'defaultValue') && angular.isUndefined(val)) {
                            scope.editorViewModel.model.behaviors[entry.behavior] = entry.defaultValue;
                        }
                        scope.optionOnValues[i] = scope.editorViewModel.model.includesBehavior(entry.behavior);

                        // Check to make sure the entry type is one of the supported values
                    } else if (!['radioSetProperty', 'checkboxSetProperty', 'inputGetterSetter'].includes(entry.type)) {
                        throw new Error(`Unexpected type "${entry.type}"`);
                    }

                    // We allow entries to be specified with either a property name or a getter/setter/title trio.
                    // 1) If it's a getter/setter/title trio, we use getter/setter functions as the model for the input.
                    // 2) If it's a property name, we look for that property on the editorViewModel and bind it to the input
                    //   in the template by wrapping it in getter/setter functions.
                    // In either case, we pass these getter/setters to the view via a property on the scope, keyed on either
                    // the property name (case 1) or the title (case 2).
                    let property;

                    let getter;
                    let setter;

                    if (entry.title && entry.get && entry.set) {
                        property = entry.title.replace(/[^a-zA-Z0-9]/g, '');
                        entry.property = property;
                        getter = entry.get;
                        setter = entry.set;
                    } else if (entry.property) {
                        property = entry.property;
                        getter = () => scope.editorViewModel[property];
                        setter = value => {
                            scope.editorViewModel[property] = value;
                        };
                    } else if (entry.type !== 'toggleBehavior') {
                        throw new Error(`No property or title/getter/setter present for ${entry.type}`);
                    }

                    Object.defineProperty(scope.dynamicProperties, property, {
                        get: getter,
                        set: setter,
                    });
                });
            });

            scope.toggleBehavior = (optionIndex, entry) => {
                const input = elem.find('.option').eq(optionIndex).find('input');
                const on = input.prop('checked');

                if (on) {
                    scope.editorViewModel.model.behaviors[entry.behavior] = entry.valueWhenOn;
                } else {
                    // false is different from undefined.  false is an indication that
                    // the author has purposely turned this behavior off.  undefined means
                    // that the author has not turned it on.
                    //
                    // This is an important distinction for behaviors that get turned
                    // on by default in certain cases.  If the behavior is set to
                    // undefined, then we will turn it on.  If it is set to false, then
                    // that means the user has purposely turned the behavior off, and
                    // we should not follow the default and turn it on.
                    scope.editorViewModel.model.behaviors[entry.behavior] = false;
                }

                scope.optionOnValues[optionIndex] = on;
            };
        },
    }),
]);
