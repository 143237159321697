import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.SharedAnswerListMixin',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const AnswerListModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.AnswerList.AnswerListModel',
            );
            const ComponentOverlayModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',
            );
            const MultipleChoiceChallengeModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
            );
            const MatchingBoardModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.MatchingBoard.MatchingBoardModel',
            );
            const TilePromptBoardModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.TilePromptBoard.TilePromptBoardModel',
            );

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    ChallengesEditorViewModel.supportConfigOption('supportsSharedAnswerList');

                    // getter/setter for an answerList that is shared between all challenges.
                    // Only available when config.supportsSharedAnswerList is true, since
                    // it only applies in cases where the challenges are instances of
                    // MultipleChoiceChallengeModel
                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'sharedAnswerList', {
                        get() {
                            if (!this.config.supportsSharedAnswerList) {
                                return;
                            }

                            // do not pay attention to the target in the getter.  this is necessary
                            // to make sure we can move the answer list when switching
                            // config options.
                            // (see line below that says 'this.sharedAnswerList = this.sharedAnswerList;')

                            // target = overlayOnImage
                            if (
                                this.model.sharedContentForInteractiveImage &&
                                this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel) &&
                                this.model.sharedContentForInteractiveImage.overlayComponents[0] &&
                                this.model.sharedContentForInteractiveImage.overlayComponents[0].isA(AnswerListModel)
                            ) {
                                return this.model.sharedContentForInteractiveImage.overlayComponents[0];
                            }

                            // target = mainImageMatchingBoard
                            if (
                                this.model.sharedContentForInteractiveImage &&
                                this.model.sharedContentForInteractiveImage.isA(MatchingBoardModel)
                            ) {
                                return this.model.sharedContentForInteractiveImage.answerList;
                            }

                            // FIXME: Consolidate MatchingBoards into single target

                            // target = mainImageTilePromptBoard
                            if (
                                this.model.sharedContentForInteractiveImage &&
                                this.model.sharedContentForInteractiveImage.isA(TilePromptBoardModel)
                            ) {
                                return this.model.sharedContentForInteractiveImage.answerList;
                            }

                            // target = sharedContentForInteractive (default)
                            if (
                                this.model.sharedContentForInteractive &&
                                this.model.sharedContentForInteractive.isA(AnswerListModel)
                            ) {
                                return this.model.sharedContentForInteractive;
                            }

                            return undefined;
                        },
                        set(answerList) {
                            if (!this.config.supportsSharedAnswerList) {
                                throw new Error(
                                    'Cannot set sharedAnswerList without supportsSharedAnswerList config value.',
                                );
                            }
                            const previousValue = this.sharedAnswerList;

                            if (answerList && !answerList.isA(AnswerListModel)) {
                                throw new Error(
                                    'Cannot set sharedAnswerList to something that is not an AnswerListModel',
                                );
                            }

                            const target = this.config.supportsSharedAnswerList.target || 'sharedContentForInteractive';

                            let type;

                            // set the answer list in the correct place
                            if (target === 'overlayOnImage') {
                                if (
                                    !this.model.sharedContentForInteractiveImage ||
                                    !this.model.sharedContentForInteractiveImage.isA(ComponentOverlayModel)
                                ) {
                                    type = this.model.sharedContentForInteractiveImage
                                        ? this.model.sharedContentForInteractiveImage.type
                                        : 'undefined';
                                    throw new Error(
                                        `sharedContentForInteractiveImage must be a ComponentOverlay if target=overlayOnImage. Is ${type}`,
                                    );
                                }
                                this.model.sharedContentForInteractiveImage.overlayComponents = [];

                                this.model.sharedContentForInteractiveImage.overlayComponents = [];

                                if (answerList) {
                                    this.model.sharedContentForInteractiveImage.overlayComponents.push(answerList);
                                    angular.extend(this.model.sharedContentForInteractiveImage.optionsFor(answerList), {
                                        x: 0,
                                        y: 0,
                                        units: '%',
                                        width: 100,
                                        height: 100,
                                        disablePositioning: true,
                                    });
                                }
                            } else if (target === 'mainImageMatchingBoard') {
                                // FIXME: Consolidate MatchingBoards into single target
                                if (
                                    !this.model.sharedContentForInteractiveImage ||
                                    !this.model.sharedContentForInteractiveImage.isA(MatchingBoardModel)
                                ) {
                                    type = this.model.sharedContentForInteractiveImage
                                        ? this.model.sharedContentForInteractiveImage.type
                                        : 'undefined';
                                    throw new Error(
                                        `sharedContentForInteractiveImage must be a MatchingBoard if target=mainImageMatchingBoard. Is ${type}`,
                                    );
                                }
                                this.model.sharedContentForInteractiveImage.answerList = answerList;
                            } else if (target === 'mainImageTilePromptBoard') {
                                if (
                                    !this.model.sharedContentForInteractiveImage ||
                                    !this.model.sharedContentForInteractiveImage.isA(TilePromptBoardModel)
                                ) {
                                    type = this.model.sharedContentForInteractiveImage
                                        ? this.model.sharedContentForInteractiveImage.type
                                        : 'undefined';
                                    throw new Error(
                                        `sharedContentForInteractiveImage must be a TilePromptBoard if target=mainImageTilePromptBoard. Is ${type}`,
                                    );
                                }
                                this.model.sharedContentForInteractiveImage.answerList = answerList;
                            } else if (target === 'sharedContentForInteractive') {
                                this.model.sharedContentForInteractive = answerList;
                            } else {
                                throw new Error(`unexpected target "${target}"`);
                            }

                            this._applySharedAnswerListToChallenges(answerList, previousValue);

                            // return shared answer list
                            return answerList;
                        },
                    });

                    ChallengesEditorViewModel.onConfigChange('supportsSharedAnswerList', function (configValue) {
                        if (configValue) {
                            this.model.challenges.forEach(challenge => {
                                if (!challenge.isA(MultipleChoiceChallengeModel)) {
                                    throw new Error(
                                        `Cannot turn on supportsSharedAnswerList because there is a challenge which is not a MultipleChoiceChallengeModel.  It is ${challenge.type}`,
                                    );
                                }
                            });

                            this._applySharedAnswerListToChallenges = function (sharedAnswerList, previousValue) {
                                if (this._sharedAnswerListListener) {
                                    this._sharedAnswerListListener.cancel();
                                    this._sharedAnswerListListener = undefined;
                                }

                                this._sharedAnswerListListener = this.model.challenges.on('childAdded', challenge => {
                                    if (sharedAnswerList) {
                                        challenge.answerList = sharedAnswerList;
                                        challenge.contentForInteractive = undefined;
                                    } else {
                                        if (challenge.answerList === previousValue) {
                                            challenge.answerList = undefined;
                                        }

                                        if (!challenge.answerList) {
                                            challenge.editorViewModel.addAnswerList();
                                        }
                                        challenge.contentForInteractive = challenge.answerList;
                                    }

                                    // remove any correctAnswers that are not in the answerList
                                    const correctAnswers = [];
                                    challenge.editorViewModel.correctAnswers.forEach(answer => {
                                        if (challenge.answerList.answers.includes(answer)) {
                                            correctAnswers.push(answer);
                                        }
                                    });
                                    challenge.editorViewModel.correctAnswers = correctAnswers;
                                });
                            };

                            this._applySharedAnswerListToChallenges(this.sharedAnswerList, undefined);

                            // this looks pointless, but it will move the shared answerList between
                            // contentForInteractive and the main content of the overlay component in
                            // sharedContentForInteractiveImage, if necessary, based on the value of config.target
                            this.sharedAnswerList = this.sharedAnswerList;
                        } else if (this._sharedAnswerListListener) {
                            this._sharedAnswerListListener.cancel();
                            this._sharedAnswerListListener = undefined;
                        }
                    });
                },
            });
        },
    ],
);
