import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/context_image/context_image_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfContextImageEditor', [
    () => ({
        restrict: 'E',

        scope: {
            frameViewModel: '<',
            setContextImageOn: '<',
            allowContextImageSizing: '<',
            title: '<',
            allowBottomImage: '<?',
            allowTopImage: '<?',
        },

        templateUrl,

        link(scope) {
            scope.title = scope.title || 'Context Images';

            scope.capitalize = str => str.substring(0, 1).toUpperCase() + str.substring(1);

            Object.defineProperty(scope, 'frame', {
                get() {
                    return this.frameViewModel.frame;
                },
            });

            Object.defineProperty(scope, 'firstContextImage', {
                get() {
                    return scope.setContextImageOn.firstContextImage;
                },
                set(val) {
                    scope.setContextImageOn.firstContextImage = val;
                },
            });

            Object.defineProperty(scope, 'secondContextImage', {
                get() {
                    return scope.setContextImageOn.secondContextImage;
                },
                set(val) {
                    scope.setContextImageOn.secondContextImage = val;
                },
            });

            Object.defineProperty(scope, 'validImageSizes', {
                get() {
                    return ['tall', 'short'];
                },
            });

            Object.defineProperty(scope, 'firstContextImageSize', {
                get() {
                    return scope.setContextImageOn && scope.setContextImageOn.model
                        ? scope.setContextImageOn.model.context_image_size
                        : this.validImageSizes[0];
                },
                set(val) {
                    if (val) {
                        scope.setContextImageOn.model.context_image_size = val;
                    } else {
                        scope.setContextImageOn.model.context_image_size = this.validImageSizes[0];
                    }
                },
            });

            Object.defineProperty(scope, 'secondContextImageSize', {
                get() {
                    return scope.setContextImageOn && scope.setContextImageOn.model
                        ? scope.setContextImageOn.model.context_image_2_size
                        : this.validImageSizes[0];
                },
                set(val) {
                    if (val) {
                        scope.setContextImageOn.model.context_image_2_size = val;
                    } else {
                        scope.setContextImageOn.model.context_image_2_size = this.validImageSizes[0];
                    }
                },
            });

            //---------------------------
            // Watches
            //---------------------------

            scope.$watchGroup(['allowContextImageSizing', 'setContextImageOn'], () => {
                if (scope.setContextImageOn && scope.setContextImageOn.model) {
                    // If sizing not allowed, set to short size
                    if (!scope.allowContextImageSizing) {
                        scope.setContextImageOn.model.context_image_size = scope.validImageSizes[1]; // short
                        scope.setContextImageOn.model.context_image_2_size = scope.validImageSizes[1]; // short
                    } else {
                        // Default to tall if no size specified and sizing is allowed
                        if (!scope.setContextImageOn.model.context_image_size) {
                            scope.setContextImageOn.model.context_image_size = scope.validImageSizes[0]; // tall
                        }
                        if (!scope.setContextImageOn.model.context_image_2_size) {
                            scope.setContextImageOn.model.context_image_2_size = scope.validImageSizes[0]; // tall
                        }
                    }
                }
            });
        },
    }),
]);
