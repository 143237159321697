import angularModule from 'Editor/angularModule/scripts/editor_module';
import inlineresources from 'inlineresources';
import getFilePathsFromManifest from 'WebpackManifestHelper';

angularModule.factory('TranslatableLessonExport', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const SuperModel = $injector.get('SuperModel');
        const $compile = $injector.get('$compile');
        const $rootScope = $injector.get('$rootScope');
        const DialogModal = $injector.get('DialogModal');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $q = $injector.get('$q');
        const Frame = $injector.get('Lesson.FrameList.Frame');

        const TranslatableLessonExport = SuperModel.subclass(function () {
            this.extend({
                version: 3,
            });

            // text is a getter/setter so we don't waste time with
            // encodeURI if we're never going to request the text
            Object.defineProperty(this.prototype, 'text', {
                get() {
                    if (!this.content) {
                        throw new Error('content is not yet prepared');
                    }
                    if (!this.$$text) {
                        this.$$text = encodeURI(`data:text/html;charset=utf-8,${this.content}`);
                    }
                    return this.$$text;
                },
            });

            // blob is a getter/setter so we don't waste time with
            // creating it if we're never going to request the blob
            Object.defineProperty(this.prototype, 'blob', {
                get() {
                    if (!this.content) {
                        throw new Error('content is not yet prepared');
                    }
                    if (!this.$$blob) {
                        this.$$blob = new Blob([this.content], {
                            type: 'data:application/octet-stream;charset=utf-8',
                        });
                    }
                    return this.$$blob;
                },
            });

            Object.defineProperty(this.prototype, 'singleColumn', {
                get() {
                    return _.includes(['copyright', 'oneColumnTextOnly', 'oneColumnWithFrames'], this.format);
                },
            });

            Object.defineProperty(this.prototype, 'showImagesInTable', {
                get() {
                    return _.includes(['copyright'], this.format);
                },
            });

            Object.defineProperty(this.prototype, 'showFrameContent', {
                get() {
                    return _.includes(['copyright', 'twoColumnWithFrames', 'oneColumnWithFrames'], this.format);
                },
            });

            return {
                initialize(lesson, format) {
                    this.lesson = lesson;

                    // format is needed for export, but not for import
                    this.format = format;
                },

                getPlainText() {
                    // include title, description, all text in frames
                    let all = [this.lesson.title].concat(this.lesson.description);

                    this.lesson.frames.forEach(frame => {
                        const textComponents = frame.componentsForType('Text.TextModel');
                        const texts = textComponents.map(element => element.text);
                        all = all.concat(texts);
                    });

                    return all.join(' ') || '';
                },

                import(translatableLessonExportEl) {
                    const self = this;
                    const warnings = {};
                    let successCount = 0;
                    let descriptionReset = false;
                    const shadowFrames = {};
                    const textarea = document.createElement('textarea');
                    const importVersion = parseInt(translatableLessonExportEl.attr('version'), 10);

                    translatableLessonExportEl.find('[needs-translation]').each(function () {
                        const el = $(this);
                        const parts = el.attr('needs-translation').split('.');

                        // quick and dirty way of decoding html-encoded entities.
                        // see also: http://stackoverflow.com/questions/3700326/decode-amp-back-to-in-javascript
                        textarea.innerHTML = el.html();
                        const val = textarea.value;

                        // warn if any HTML content is encountered
                        if (/<[a-z][\s\S]*>/i.test(val)) {
                            warnings[`HTML elements encountered in translation value: ${val.slice(0, 50)}`] = true;
                        }

                        const itemType = parts[0];
                        if (itemType === 'lesson') {
                            const key = parts[1];

                            if (key === 'description') {
                                const index = parseInt(parts[2], 10);
                                if (!val) {
                                    warnings[`No new text found for description bullet #${index}`] = true;
                                    return;
                                }
                                if (!descriptionReset) {
                                    self.lesson.description = [];
                                    descriptionReset = true;
                                }
                                self.lesson.description[index] = val;
                                successCount += 1;
                            } else {
                                if (!val) {
                                    warnings[`No new text found for lesson ${key}`] = true;
                                    return;
                                }
                                self.lesson[key] = val;
                                successCount += 1;
                            }
                        } else if (itemType === 'component') {
                            const frameId = parts[1];
                            const componentId = parts[2];

                            // We have to work on the frame json rather than the frame itself.
                            // Otherwise, various dynamic stuff setup by editor view models might
                            // try to be helpful and mess things up.  See https://trello.com/c/Wk0wfzrG/340-translated-file-import-removed-answers
                            if (!shadowFrames[frameId]) {
                                const frame = self.lesson.frameForId(frameId, true);
                                if (!frame) {
                                    warnings[`Frame ${frameId} not found.`] = true;
                                    return;
                                }
                                shadowFrames[frameId] = frame.asJson();
                            }
                            const frameJson = shadowFrames[frameId];

                            const componentJson = _.find(frameJson.components, {
                                id: componentId,
                            });
                            if (!componentJson) {
                                warnings[`Component ${componentId} not found.`] = true;
                                return;
                            }

                            let propToReplace;
                            if (componentJson.component_type === 'ComponentizedFrame.Text') {
                                propToReplace = 'text';
                            } else if (componentJson.component_type === 'ComponentizedFrame.MatchesExpectedText') {
                                propToReplace = 'expectedText';
                            } else {
                                warnings[
                                    `Component ${componentId} is not of an expected type.  It is a ${componentJson.component_type}`
                                ] = true;
                            }

                            if (propToReplace) {
                                if (val) {
                                    componentJson[propToReplace] = val;
                                    successCount += 1;
                                } else {
                                    warnings[
                                        `No new text found for "${componentJson.text.slice(
                                            0,
                                            50,
                                        )}..." (Component ${componentId})`
                                    ] = true;
                                }
                            }
                        }
                    });

                    _.forEach(shadowFrames, (frameJson, id) => {
                        const oldFrame = self.lesson.frameForId(id);
                        const index = oldFrame.index();
                        // eslint-disable-next-line no-multi-assign
                        const newFrame = (self.lesson.frames[index] = Frame.new(frameJson));
                        newFrame.$$embeddedIn = self.lesson;

                        newFrame.componentsForType('Text.TextModel').forEach(textModel => {
                            if (
                                textModel.includesBehavior('ProcessesChallengeBlanks') &&
                                textModel.frame().editor_template === 'compose_blanks'
                            ) {
                                /*
                                        In the case where challenge in a compose_blanks frame
                                        is not unlinked (i.e. the normal case), then we have to
                                        update the expectedText on the answer matcher to be
                                        whatever is in the blank in the text.
                                    */
                                textModel.challengesComponent.challenges.forEach((challenge, i) => {
                                    if (!challenge.unlink_blank_from_answer) {
                                        const answerMatchers = challenge.validator.expectedAnswerMatchers;
                                        const count = answerMatchers ? answerMatchers.length : 0;
                                        if (count !== 1) {
                                            throw new Error('Expected exactly 1 answer matcher');
                                        }
                                        const answerMatcher = answerMatchers[0];
                                        if (!answerMatcher.expectedText) {
                                            throw new Error('expectedText not defined');
                                        }
                                        answerMatcher.expectedText = textModel.editorViewModel.blanks[i];
                                    }
                                });
                            }
                        });

                        // It seems like we might need to also update expectedText on challenges from
                        // compose_blanks_on_image frames where unlink_blank_from_answer=false, but for some
                        // reason those get taken care of automagically by something else, so leaving it for now.

                        if (importVersion <= 2) {
                            newFrame
                                .componentsForType('Challenge.UserInputChallenge.UserInputChallengeModel')
                                .forEach(challenge => {
                                    if (challenge.unlink_blank_from_answer) {
                                        const warning = `Frame ${newFrame.displayIndex()} has an untranslated answer "${
                                            challenge.editorViewModel.correctAnswerText
                                        }" because this import is an old version that did not support translating unlinked answers.`;
                                        warnings[warning] = true;
                                    }
                                });
                        }
                    });

                    return $q(resolve => {
                        const promises = _.invokeMap(self.lesson.frames, 'formatAllText', true); // force enabled
                        $q.all(promises).then(resolve);
                    }).then(() => {
                        let title = 'Import successful';
                        let content = `<div>${successCount} texts were successfully imported.</div>`;
                        const warningKeys = Object.keys(warnings);
                        if (warningKeys.length > 0) {
                            const warningListItems = warningKeys.map(warning => `<li>${warning}</li>`).join('');

                            content = `${content}<ul>${warningListItems}</ul>`;
                            title = `${title}, but with warnings`;
                        }
                        DialogModal.alert({
                            content,
                            title,
                            size: 'normal',
                        });
                    });
                },

                prepare() {
                    if (
                        ![
                            'copyright',
                            'twoColumnTextOnly',
                            'twoColumnWithFrames',
                            'oneColumnTextOnly',
                            'oneColumnWithFrames',
                        ].includes(this.format)
                    ) {
                        throw new Error(`Unexpected format "${this.format}"`);
                    }
                    const self = this;
                    self.$$text = undefined;
                    self.$$base64 = undefined;
                    return this._getHtmlContent().then(content => {
                        self.content = content;
                        // self.data = encodeURI('data:application/octet-stream;charset=utf-8,' + content);
                        self.filename = self._getFilename();
                    });
                },

                _getFilename() {
                    const lesson = this.lesson;
                    const formatPart = {
                        copyright: 'copyright',
                        oneColumnTextOnly: 'text',
                        twoColumnTextOnly: 'text',
                        oneColumnWithFrames: 'context',
                        twoColumnWithFrames: 'context',
                    }[this.format];

                    if (!formatPart) {
                        throw new Error(`Cannot build filename for "${this.format}"`);
                    }
                    const filename = `${[lesson.parameterizedTag, formatPart, lesson.id].join('_')}.html`;
                    return filename;
                },

                _getHtmlContent() {
                    const self = this;

                    const scope = $rootScope.$new();
                    scope.translatableLessonExport = self;
                    const el = $('<translatable-lesson-export>');
                    el.attr('translatable-lesson-export', 'translatableLessonExport');
                    el.attr('version', TranslatableLessonExport.version);

                    const stage = $('<div class="public">');
                    stage.css({
                        position: 'absolute',
                        top: '9999px',
                        // background: 'white',
                        // top: 0
                    });
                    stage.append(el);
                    $('body').append(stage);

                    /*
                            Some frames take an indeterminate amount of time to render.
                            Once all frames are done, translatableLessonExport:rendered
                            will fire.
                        */
                    const promise = $q(resolve => {
                        scope.$on('translatableLessonExport:rendered', () => {
                            let htmlResult = self._buildHtmlDoc(el);
                            el.remove();
                            stage.remove();
                            scope.$destroy();

                            // HACK: work around local testing by requesting images without touching Cloudflare
                            htmlResult = htmlResult.replace(/uploads\.smart\.ly/g, 's3.amazonaws.com/uploads.smart.ly');

                            const parser = new DOMParser();
                            const doc = parser.parseFromString(htmlResult, 'text/html');
                            const allErrors = [];
                            const loadOpts = {
                                baseUrl: $window.ENDPOINT_ROOT,
                            };

                            inlineresources.loadAndInlineStyles(doc, loadOpts).then(styleErrors => {
                                allErrors.concat(styleErrors);

                                inlineresources.loadAndInlineCssLinks(doc, loadOpts).then(cssErrors => {
                                    allErrors.concat(cssErrors);

                                    inlineresources.loadAndInlineScript(doc, loadOpts).then(jsErrors => {
                                        allErrors.concat(jsErrors);

                                        // these are generally non-fatal, so just notify when we hit them
                                        if (allErrors.length > 0) {
                                            ErrorLogService.notify(
                                                `Lesson export of${
                                                    self.lesson.title
                                                }encountered some errors:${allErrors.join(', ')}`,
                                            );
                                        }

                                        // Remove cruft that gets messed up by the entity decoding sometimes
                                        $(doc).find('[data-mathml]').removeAttr('data-mathml');
                                        $(doc).find('.MJX_Assistive_MathML').remove();

                                        // fix bad style attributes that contain double quote instead of single
                                        $(doc)
                                            .find('.button_image')
                                            .each(function () {
                                                let styleAttr = $(this).attr('style');
                                                if (styleAttr) {
                                                    styleAttr = styleAttr.replace(/"/g, "'");
                                                    $(this).attr('style', styleAttr);
                                                }
                                            });

                                        let finalHTML = doc.documentElement.innerHTML;

                                        const beginningScripts =
                                            '<script type="text/javascript">function postLoadScript() { document.getElementsByTagName("html")[0].classList.remove("borderimage"); }</script>\n';

                                        finalHTML = finalHTML.replace('<head>', `<head>${beginningScripts}`);
                                        resolve(finalHTML);
                                    });
                                });
                            });
                        });
                    });

                    $compile(el)(scope);

                    return promise;
                },

                _buildHtmlDoc(el) {
                    const html = el[0].outerHTML;
                    let doc = '';
                    doc = `${doc}<html>\n`;
                    doc = `${doc}<head>\n`;
                    doc = `${doc}<meta charset="utf-8" />\n`;
                    doc = `${doc}<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.js"></script>\n`;

                    getFilePathsFromManifest('front-royal', 'css').forEach(path => {
                        doc = `${doc}<link rel="stylesheet" type="text/css" href="${$window.ENDPOINT_ROOT}/${path}" />\n`;
                    });

                    getFilePathsFromManifest('translatable_export', 'css').forEach(path => {
                        doc = `${doc}<link rel="stylesheet" type="text/css" href="${$window.ENDPOINT_ROOT}/${path}" />\n`;
                    });

                    doc = `${doc}</head>\n`;
                    doc = `${doc}<body class="public" onload="postLoadScript()">${html}</body>\n`;
                    doc = `${doc}</html>`;
                    return doc;
                },
            };
        });

        return TranslatableLessonExport;
    },
]);
