import angularModule from 'Editor/angularModule/scripts/editor_module';
import jsondiffpatch from 'FrontRoyalJsonDiffPatch';

angularModule.factory('AuthorCommentsDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');

        // create jsondiffpatch objects
        const jsonDiff = jsondiffpatch.create({
            objectHash(obj) {
                // author comments can be identified by timestamp
                return obj.timestamp;
            },
            arrays: {
                detectMove: true,
                includeValueOnMove: false,
            },
        });

        const AuthorCommentsDiff = SuperModel.subclass(() => ({
            initialize(oldFrame, newFrame, options) {
                const self = this;
                this.oldFrame = oldFrame;
                this.newFrame = newFrame;
                this.options = options;
                this.hasNewComments = false;
                this.hasRemovedComments = false;
                this.hasCheckedOffComments = false;

                const oldComments = oldFrame && oldFrame.author_comments;
                const newComments = newFrame && newFrame.author_comments;

                if (!oldComments && !newComments) {
                    this.diff = undefined;
                } else {
                    this.diff = jsonDiff.diff(oldComments || [], newComments || []);
                }

                this.changes = [];

                // remove the '_t' prop, which just indicates
                // that this is an array
                _.chain(this.diff)
                    .omit('_t')
                    .forEach((change, i) => {
                        // we don't particularly care if a comment was
                        // expanded/collapsed, so filter that out.
                        if (angular.equals(_.keys(change), ['$expanded'])) {
                            return;
                        }

                        if (Object.keys(change).includes('$expanded')) {
                            delete change.$expanded;
                        }

                        // if the only change is that the comment was marked
                        // as completed, handle that specially
                        if (angular.equals(_.keys(change), ['completed'])) {
                            self.hasCheckedOffComments = true;
                            self.changes.push({
                                type: 'completed',
                                comment: self.newFrame.author_comments[i],
                            });
                        }

                        // removed comment
                        else if (change[1] === 0) {
                            self.hasRemovedComments = true;
                            self.changes.push({
                                type: 'removed',
                                comment: change[0],
                            });
                        }

                        // added comment
                        else {
                            self.hasNewComments = true;
                            // tricky: if the new comment is already marked completed, give it a special status
                            if (change[0].completed === true) {
                                self.changes.push({
                                    type: 'added_and_completed',
                                    comment: change[0],
                                });
                            } else {
                                self.changes.push({
                                    type: 'added',
                                    comment: change[0],
                                });
                            }
                        }
                    })
                    .value();

                this.size = this.changes.length;
            },
        }));

        return AuthorCommentsDiff;
    },
]);
