import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/diff/frame_diff.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('frameDiff', [
    '$injector',

    function factory() {
        return {
            templateUrl,
            scope: {
                frameDiff: '<',
                options: '<',
            },
            restrict: 'E',
            link(scope) {
                function filterCommentChanges() {
                    if (!scope.options.showComments) {
                        scope.authorCommentsChanges = [];
                        return;
                    }
                    scope.authorCommentsChanges = _.filter(scope.frameDiff.authorCommentsDiff.changes, change => {
                        if (
                            !scope.options.showCheckedOffComments &&
                            (change.type === 'completed' || change.type === 'added_and_completed')
                        ) {
                            return false;
                        }
                        return true;
                    });
                }

                scope.$watchGroup(['options.showComments', 'options.showCheckedOffComments'], filterCommentChanges);
            },
        };
    },
]);
