import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.directive('frameInteractionTypeSelector', [
    '$injector',
    $injector => {
        const ChallengesEditorViewModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel',
        );
        const DialogModal = $injector.get('DialogModal');
        const availableFrameInteractionTypeConfigs =
            $injector.get('frameInteractionTypes').availableFrameInteractionTypeConfigs;

        return {
            restrict: 'E',
            scope: {
                frameViewModel: '<',
            },
            template:
                '<select ng-model="selectedFrameInteractionTypeConfig" name="interaction_type" ng-options="item as item.title for item in availableFrameInteractionTypeConfigs"></select>',

            link(scope) {
                function changeContentType(frameInteractionTypeConfig, frameViewModel) {
                    frameViewModel.playerViewModel.activeFrame = undefined;
                    const newFrame = scope.lesson.replaceFrame(frameViewModel.frame, frameInteractionTypeConfig);
                    frameViewModel.playerViewModel.activeFrame = newFrame;
                    newFrame.expandExtraPanelsInitially();
                }

                Object.defineProperty(scope, 'frame', {
                    get() {
                        return this.frameViewModel.frame;
                    },
                });

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return this.frame.lesson();
                    },
                });

                Object.defineProperty(scope, 'selectedFrameInteractionTypeConfig', {
                    get() {
                        return (
                            this.availableFrameInteractionTypeConfigs?.find(config =>
                                config.matchesFrame(this.frame),
                            ) || null
                        );
                    },
                    set(frameInteractionTypeConfig) {
                        if (
                            this.frameViewModel.frame.components.filter(
                                component =>
                                    component.component_type === 'ComponentizedFrame.SelectableAnswer' &&
                                    (component.image?.hasImage || component.text?.text),
                            ).length > 0
                        ) {
                            DialogModal.confirm({
                                text: `Are you sure you want to change the screen type? The current answer options will be lost with this change.`,
                                confirmCallback: () => {
                                    changeContentType(frameInteractionTypeConfig, this.frameViewModel);
                                },
                            });
                        } else {
                            changeContentType(frameInteractionTypeConfig, this.frameViewModel);
                        }
                    },
                });

                scope.$watch('frameViewModel', frameViewModel => {
                    if (!frameViewModel) {
                        return;
                    }

                    let _availableFrameInteractionTypeConfigs = availableFrameInteractionTypeConfigs;

                    // since we don't decorate_frame_json in practice lessons, we disallow polling.  We could
                    // bring it back, but seemed like we wouldn't want it
                    if (frameViewModel.lesson.isPractice) {
                        _availableFrameInteractionTypeConfigs = availableFrameInteractionTypeConfigs.filter(
                            type => type.editorTemplate !== ChallengesEditorViewModel.templates.multiple_choice_poll,
                        );
                    }

                    scope.availableFrameInteractionTypeConfigs = _availableFrameInteractionTypeConfigs;
                });
            },
        };
    },
]);
