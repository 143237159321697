/* eslint-disable prefer-regex-literals */
import angularModule from 'Editor/angularModule/scripts/editor_module';
import 'ExtensionMethods/array';

angularModule.constant('MaxTextLengthConfig', {
    DEFAULT: 450,
    TEXT_WITHOUT_IMAGE: 450,
    TEXT_WITH_IMAGE: 450,
    ANSWER: 150,
    BLANK: 75,
    MESSAGE: 250,
    MODAL: 325,
});

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',

    [
        '$injector',

        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const ProcessesChallengeBlanksEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesChallengeBlanksEditor',
            );
            const ProcessesModalsEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesModalsEditor',
            );
            const ProcessesMarkdownEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesMarkdownEditor',
            );
            const ProcessesInlineImagesEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesInlineImagesEditor',
            );
            const ProcessesMathjaxEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesMathjaxEditor',
            );
            const ProcessesStorableImagesEditor = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesStorableImagesEditor',
            );
            const MaxTextLengthConfig = $injector.get('MaxTextLengthConfig');
            const SuperModel = $injector.get('SuperModel');
            const LineBreakPrevention = $injector.get('LineBreakPrevention');
            const $q = $injector.get('$q');
            const FormatsText = $injector.get('FormatsText');
            const ClientStorage = $injector.get('ClientStorage');

            const Formatters = SuperModel.subclass(function () {
                Object.defineProperty(this.prototype, '_orderedFormatters', {
                    get() {
                        if (!this.__orderedFormatters) {
                            const indexMap = {};
                            this.formatterOrder.forEach((identifier, i) => {
                                indexMap[identifier] = i;
                            });
                            this.__orderedFormatters = this._formatters.sort((a, b) =>
                                indexMap[a.identifier] > indexMap[b.identifier] ? 1 : -1,
                            );
                        }

                        return this.__orderedFormatters;
                    },
                });

                return {
                    formatterOrder: [
                        'removeMathJax', // mathjax must be removed when markdown processing happens
                        'inlineImages', // must come before markdown
                        'markdown',
                        'modals',
                        'replaceMathJax',
                        /*
                        Challenge blanks must come after replaceMathJax,
                        since it processes blanks within the mathjax blocks.  It must
                        also come before processMathjax, sine it prepares blanks to
                        be handled by our custom Mathjax \Blank extension
                    */
                        'challengeBlanks',
                        'preventLineBreaks',
                        'processMathjax',
                        'inlineImagesPostMarkdown',
                    ],

                    initialize(editorViewModel) {
                        this._formatters = [];
                        this.editorViewModel = editorViewModel;
                        this.__orderedFormatters = undefined;

                        // Hack: rather than migrate all content, add preventLineBreaks by default
                        function preventLineBreaks(text) {
                            return LineBreakPrevention.preventLineBreaks(text);
                        }

                        this.add('preventLineBreaks', preventLineBreaks);
                    },

                    process(text) {
                        if (angular.isUndefined(text)) {
                            return $q.when('');
                        }

                        const context = {};

                        // a promise to start off the chain of formatters
                        let promise = $q(resolve => {
                            resolve(text);
                        });

                        // add each formatter to the chain
                        this._orderedFormatters.forEach(formatter => {
                            promise = promise.then(formattedText => {
                                const textOrPromise = formatter.func.apply(this.editorViewModel, [
                                    formattedText,
                                    context,
                                ]);
                                if (typeof textOrPromise === 'string') {
                                    // return an already resolved promise
                                    return $q(resolve => {
                                        resolve(textOrPromise);
                                    });
                                }
                                return textOrPromise;
                            });
                        });
                        return promise;
                    },

                    runFormatter(identifier, text) {
                        const formatter = _.find(this._formatters, {
                            identifier,
                        });
                        return formatter.func(text);
                    },

                    runFormatters(identifiers, text) {
                        const context = {};

                        let formattedText = text;
                        identifiers.forEach(identifier => {
                            const formatter = _.find(this._formatters, {
                                identifier,
                            });
                            if (!formatter) {
                                throw new Error(`No formatter found for "${identifier}"`);
                            }
                            formattedText = formatter.func.apply(this.editorViewModel, [formattedText, context]);
                        });

                        return formattedText;
                    },

                    add(identifier, func) {
                        if (this.editorViewModel.$$throwThisErrorIfAnyFormattersGetAdded) {
                            // It is not safe to just go ahead and reformat the text now, because we may have
                            // already reported to some script that the text is all formatted and good to go.
                            // eslint-disable-next-line no-console
                            console.error(
                                'Stack trace from when the text was set: ',
                                this.editorViewModel.$$throwThisErrorIfAnyFormattersGetAdded,
                            );
                            throw new Error(
                                `Cannot add formatter ${identifier} because text has already been formatted.`,
                            );
                        }

                        const newFormatter = {
                            identifier,
                            func,
                        };
                        this._formatters.push(newFormatter);
                        this.__orderedFormatters = undefined;

                        // this used to return an object that had ordering
                        // methods on it, but it became unwieldy so we just do the
                        // ordering globally above
                        return {};
                    },

                    remove(identifier) {
                        if (this.editorViewModel.$$throwThisErrorIfAnyFormattersGetAdded) {
                            // It is not safe to just go ahead and reformat the text now, because we may have
                            // already reported to some script that the text is all formatted and good to go.
                            // eslint-disable-next-line no-console
                            console.error(
                                'Stack trace from when the text was set: ',
                                this.editorViewModel.$$throwThisErrorIfAnyFormattersGetAdded,
                            );
                            throw new Error(
                                `Cannot remove formatter ${identifier} because text has already been formatted.`,
                            );
                        }

                        const formattersToRemove = [];
                        this._formatters.forEach(formatter => {
                            if (formatter.identifier === identifier) {
                                formattersToRemove.push(formatter);
                            }
                        });
                        formattersToRemove.forEach(formatter => {
                            Array.remove(this._formatters, formatter);
                        });
                        this.__orderedFormatters = undefined;
                    },
                };
            });

            return ComponentEditorViewModel.subclass(function () {
                const TextEditorViewModel = this;
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');

                this.supportConfigOption('showFontStyleEditor');
                this.supportConfigOption('hideFontSizeEditor');
                this.supportConfigOption('maxRecommendedTextLength');

                this.supportConfigOption('showTextToolbar');
                this.supportConfigOption('excludeToolbarButtonTypes');
                this.supportConfigOption('autoRenderKey');

                this.include(ProcessesModalsEditor);
                this.include(ProcessesMarkdownEditor);
                this.include(ProcessesMathjaxEditor);
                this.include(ProcessesInlineImagesEditor);
                this.include(ProcessesStorableImagesEditor);
                this.include(ProcessesChallengeBlanksEditor); // has to come after some of the other ones (see integration specs)

                Object.defineProperty(this.prototype, 'modalTexts', {
                    get() {
                        if (!this.model.modals) return [];
                        return this.model.modals.map(modal => modal.text);
                    },
                    set(modalTexts) {
                        this.model.modals = modalTexts.map(
                            modalText =>
                                TextEditorViewModel.addComponentTo(this.frame, {
                                    text: modalText,
                                }).setup().model,
                        );
                    },
                });

                return {
                    directiveName: 'cf-text-editor',
                    initialize($super, model) {
                        this.formatters = new Formatters(this);

                        $super(model);
                        this.setConfig({
                            maxRecommendedTextLength: MaxTextLengthConfig.DEFAULT,
                        });

                        this.model.on('.modals:childAdded', modal => {
                            modal.editorViewModel.setConfig({
                                maxRecommendedTextLength: MaxTextLengthConfig.MODAL,
                                showTextToolbar: true,
                                excludeToolbarButtonTypes: ['modal'],
                            });
                        });

                        this.model.on('set:text', this.formatText.bind(this, false));
                    },

                    renderAutomatically() {
                        if (!this.config.autoRenderKey) {
                            return true;
                        }

                        return ClientStorage.getItem(this.config.autoRenderKey) !== 'false';
                    },

                    toggleRenderAutomatically() {
                        if (this.config.autoRenderKey) {
                            ClientStorage.setItem(this.config.autoRenderKey, !this.renderAutomatically());
                        }
                    },

                    formatText(forcefullyRender) {
                        if (!this.renderAutomatically() && !forcefullyRender) {
                            return $q.when();
                        }

                        // create an error here so we can throw it later if any formatters get added.  This
                        // will let us view the stack trace to see who tried to format the text too soon.
                        if (this.model.text !== '') {
                            this.$$throwThisErrorIfAnyFormattersGetAdded = new Error(
                                'formatText called before all formatters setup.',
                            );
                        }

                        // if the text has not changed, do not format again, just return
                        // the previously created promise
                        if (this.$$_formattingPromise && this.$$_formattingPromise.text === this.model.text) {
                            return this.$$_formattingPromise;
                        }
                        const text = this.model.text;
                        this.$$_formattingPromise = this.formatters
                            .process(this.model.text, this)
                            .then(formattedText => {
                                // $$recentFormats is just used for logging in
                                // case something goes wrong
                                this.$$recentFormats = this.$$recentFormats || [];
                                this.$$recentFormats.push({
                                    text,
                                    formattedText,
                                });
                                while (this.$$recentFormats.length > 5) {
                                    this.$$recentFormats.shift();
                                }
                                this.model.formatted_text = formattedText;
                            });

                        this.$$_formattingPromise.text = this.model.text;

                        const saveBlock = this.lesson.blockSave();
                        return this.$$_formattingPromise.then(() => {
                            saveBlock.unblock();
                        });
                    },

                    /**
                     * Add \transn{...} around decimals and comma-delimited numbers for Arabic localization purposes
                     *
                     * Example cases:
                     *   %% 5.55 %% ---> %% \transn{5.55} %%
                     *   %% 3,000,000 %% ---> %% \transn{3,000,000} %%
                     *   %% Blank[5.55] %% --> %% Blank[\transn{5.55}] %%
                     *   %% Blank[3,000,000] %% --> %% Blank[\transn{3,000,000}] %%
                     *   "this is a bare number outside of mathjax: 3.55" --> "this is a bare number outside of mathjax: %% \transn{3.55} %%"
                     *   "this is a bare number outside of mathjax: 3,000,000" --> "this is a bare number outside of mathjax: %% \transn{3,000,000} %%"
                     *
                     * Note that for the Blank[...] cases, we also fix up the resulting expectedText in the associated MatchesExpectedText model.
                     * See componentized.js#addTransN for details.
                     *
                     * Cases that should *not* be transformed in compose* modes:
                     *   "... this is a blank outside of mathjax: [5.55]"
                     *   "... this is a blank outside of mathjax: [3,000,000]"
                     *
                     * However, in fill in the blanks* modes, we do want to transform:
                     *
                     *   [5.55]
                     *   [3,000,000]
                     *
                     * ...since the user doesn't have to the type these answers.
                     *
                     *  @param convertNonMathjax - whether or not to convert instances outside of MathJax
                     */
                    addTransN(convertNonMathjax) {
                        let text = this.model.text;

                        if (convertNonMathjax) {
                            // in compose modes (the default regexes here), we want to prevent conversion of blanks [...] and inline images ![...],
                            let commaAndDecimalRegex = new RegExp(
                                '(?<!\\d+|\\!\\[-?|[^\\[]\\[-?|^\\[-?)(-?)(\\d+\\,[\\d,]+\\.\\d+)',
                                'g',
                            );
                            let commaRegex = new RegExp(
                                '(?<!\\d+|\\!\\[-?|[^\\[]\\[-?|^\\[-?|[\\d,]+-?|transn{-?)(-?)(\\d+\\,\\d+[\\d,]+)',
                                'g',
                            );
                            let decimalRegex = new RegExp(
                                '(?<!\\d+|\\!\\[-?|[^\\[]\\[-?|^\\[-?|[\\d,]+-?|transn{-?)(-?)(\\d*\\.\\d+)',
                                'g',
                            );

                            if (!this.frame.mainUiComponent) {
                                throw new Error(
                                    'text model processing has not been updated to support frame types other than flexible componentizeed.',
                                );
                            }

                            // in non-compose modes, we relax the restrictions on blanks [...] to allow replacement
                            // we still want to prevent things like inline images from being converted, though
                            if (
                                !_.includes(
                                    ['compose_blanks_on_image', 'compose_blanks'],
                                    this.frame.mainUiComponent.editor_template,
                                )
                            ) {
                                commaAndDecimalRegex = new RegExp('(?<!\\d+|\\!\\[-?)(-?)(\\d+\\,[\\d,]+\\.\\d+)', 'g');
                                commaRegex = new RegExp(
                                    '(?<!\\d+|\\!\\[-?|[\\d,]+-?|transn{)(-?)(\\d+\\,\\d+[\\d,]+)',
                                    'g',
                                );
                                decimalRegex = new RegExp('(?<!\\d+|\\!\\[-?|[\\d,]+-?|transn{)(-?)(\\d*\\.\\d+)', 'g');
                            }

                            // temporarily remove MathJax...
                            text = FormatsText.removeMathjaxBeforeMarkdown(text, this);

                            // ...and then replace all decimals with MathJax + transn wrapped commas+decimal numbers
                            // Note: we use a fake delimiter here so as to not confuse the addMathjaxAfterMarkdown() call later
                            text = text.replace(commaAndDecimalRegex, '__@@@__ $1\\transn{$2} __@@@__');

                            // ...and then replace all comma-delimited numbers with MathJax + transn wrapped numbers
                            text = text.replace(commaRegex, '__@@@__ $1\\transn{$2} __@@@__');

                            // ...and then replace all decimals with MathJax + transn wrapped decimals
                            text = text.replace(decimalRegex, '__@@@__ $1\\transn{$2} __@@@__');

                            // ...and then re-instate MathJax
                            text = FormatsText.addMathjaxAfterMarkdown(text, this);

                            // finally, replace fake delimiters
                            text = text.replace(/__@@@__/gm, '%%');
                        } else {
                            const decimalRegex = new RegExp(
                                '(?<!transn{-?|space{-?|text{-?|kern-?|\\d+)(-?)(\\d*\\.\\d+)',
                                'g',
                            );
                            const commaRegex = new RegExp(
                                '(?<!transn{-?|space{-?|text{-?|kern-?|\\d+|,)(-?)(\\d+\\,\\d+[\\d,]+)',
                                'g',
                            );

                            // search within MathJax and wrap all decimals
                            text = FormatsText.findMathjaxAndReplace(text, originalMathJax =>
                                originalMathJax.replace(decimalRegex, '$1\\transn{$2}'),
                            );

                            // search within MathJax and wrap all comma-delimited numbers
                            text = FormatsText.findMathjaxAndReplace(text, originalMathJax =>
                                originalMathJax.replace(commaRegex, '$1\\transn{$2}'),
                            );
                        }

                        // This is a little dangerous, but necessary. When this method is called, we should be guaranteed that we're not adding or removing blanks.
                        // But, since we might be updating multiple blanks at the same time, if we allow the standard blanks update callback logic to run, it can
                        // result in answer choices being lost for blanks whose correct answer text changed as a result of the transn update. Since we know the
                        // number of blanks won't be changed by this logic, we can therefore safely disable that destructive (but in normal editing scenarios,
                        // required) behavior and just directly update the text.
                        this.$$suppressBlanksUpdate = true;
                        this.model.text = text;
                        this.$$suppressBlanksUpdate = false;
                    },

                    /**
                     * Convert bare "\transn{...}" text nodes into MathJax wrapped versions.
                     * Example:
                     *
                     *  "\transn{...}" --> "%% \transn{...} %%"
                     */
                    wrapTransN() {
                        let text = this.model.text;

                        const transnRegex = new RegExp('^\\\\transn{.*?}$', 'g');
                        if (text.match(transnRegex)) {
                            text = `%% ${text} %%`;
                        }

                        this.model.text = text;
                    },

                    /**
                     * Convert mixed fractions in MathJax to use the \tmfrac and \tmdfrac helpers for Arabic localization purposes
                     *
                     * Examples:
                     *   %% 1\frac{2}{3} %% --> %% \tmfrac{1}{2}{3} %%
                     *   %% 1\frac23 %% --> %% \tmfrac{1}{2}{3} %%
                     *   %% 1\dfrac{2}{3} %% --> %% \tmdfrac{1}{2}{3} %%
                     *   %% 1\dfrac23 %% --> %% \tmdfrac{1}{2}{3} %%
                     *   %% 1,000\dfrac{2,000}{3,000} %% --> %% \tmdfrac{1,000}{2,000}{3,000} %%
                     *
                     * Note that we don't ever expect to have mixed fractions plus decimals, e.g.: 1.5\frac{2}{3}
                     */
                    transformMixedFractions() {
                        let text = this.model.text;

                        // search within MathJax and replace basic fractions
                        text = FormatsText.findMathjaxAndReplace(text, originalMathJax =>
                            originalMathJax.replace(
                                /(\d+|\d+,\d+[\d,]+) *\\(d?)frac\{(.+?)\}\{(.+?)\}/g,
                                '\\tm$2frac{$1}{$3}{$4}',
                            ),
                        );

                        // search within MathJax and replace alternative fraction forms
                        text = FormatsText.findMathjaxAndReplace(text, originalMathJax =>
                            originalMathJax.replace(
                                /(\d+|\d+,\d+[\d,]+) *\\(d?)frac(\d)(\d)/g,
                                '\\tm$2frac{$1}{$3}{$4}',
                            ),
                        );

                        this.model.text = text;
                    },

                    setup() {
                        this.model.behaviors = this.model.behaviors || {};
                        angular.extend(this.model.behaviors, {
                            ProcessesMarkdown: {},
                            ProcessesInlineImages: {},
                            ProcessesModals: {},
                            ProcessesMathjax: {},
                        });
                        this.model.text = this.model.text || '';
                        return this;
                    },

                    maxRecommendedTextLength() {
                        const getter = this.config.maxRecommendedTextLength;
                        if (!getter) {
                            return undefined;
                        }
                        if (typeof getter === 'function') {
                            return getter();
                        }
                        return getter;
                    },

                    validateTextLength() {
                        // NOTE: it's important that these be undefined in the case where formatted_text
                        // is not set. this conveys information to frame_list_grader and text_editor dirs
                        // indicating that the component is not ready for validation of text length
                        let html;

                        let measuredText;

                        let textLength = 0;

                        if (this.model.formatted_text && this.model.formatted_text !== this.model.text) {
                            html = $('<div></div>').html(this.model.formatted_text);
                        }

                        if (html) {
                            // prevent double-counting of Mathjax
                            html.find('script').remove();
                            html.find('.MJX_Assistive_MathML').remove();

                            // trim unnecessary whitespace
                            measuredText = html.text().replace(/ +/g, ' ');
                            textLength = measuredText.length;
                        }

                        const result = {
                            valid: textLength <= this.model.editorViewModel.maxRecommendedTextLength(),
                            textLength,
                            maxTextLength: this.model.editorViewModel.maxRecommendedTextLength(),
                            measuredText,
                            unformattedLength: this.model.text ? this.model.text.length : 0,
                        };
                        return result;
                    },
                };
            });
        },
    ],
);
