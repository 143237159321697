import angularModule from 'Editor/angularModule/scripts/editor_module';
import jsondiffpatch from 'FrontRoyalJsonDiffPatch';
import EditorConstants from 'Editor/angularModule/scripts/constants';

angularModule.factory('AnnotationsDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const JsonDiff = $injector.get('JsonDiff');

        const AnnotationsDiff = SuperModel.subclass(() => ({
            initialize(oldFrame, newFrame, options) {
                this.oldFrame = oldFrame;
                this.newFrame = newFrame;
                this.options = options;

                const oldAnnotations = oldFrame?.annotations || {};
                const newAnnotations = newFrame?.annotations || {};

                if (Object.keys(oldAnnotations).length === 0 && Object.keys(newAnnotations).length === 0) {
                    this.diff = undefined;
                } else {
                    this.diff = jsondiffpatch.diff(oldAnnotations, newAnnotations);
                }

                this.changes = [];

                if (this.diff) {
                    Object.entries(this.diff).forEach(([annotationType, changes]) => {
                        const annotationConfig = EditorConstants.frameAnnotationTypes.find(
                            i => i.type === annotationType,
                        );
                        const changeConfig = {
                            label: annotationConfig.label,
                        };

                        let oldNotesValue;
                        let newNotesValue;
                        let oldSelectedValue;
                        let newSelectedValue;

                        if (Array.isArray(changes)) {
                            // If `changes` is an array with a single item, then a new annotation was added.
                            if (changes.length === 1) {
                                newSelectedValue = true;
                                newNotesValue = changes[0].notes ?? '';
                            } else if (changes.length === 3) {
                                // If `changes` has three items, an annotation was deleted.
                                oldSelectedValue = true;
                                oldNotesValue = changes[0].notes ?? '';
                                newSelectedValue = false;
                                newNotesValue = '';
                            }
                        } else {
                            // If `changes` isn't an array, then it's an object with keys of the updated
                            // annotations and values of the specific properties that were updated.
                            const { notes } = changes;

                            if (notes.length === 1) {
                                // If the `notes` array only contains one item, then a note was added to an already existing annotaiton.
                                newNotesValue = notes[0];
                            } else if (notes.length === 2) {
                                // If the `notes` array contains two items, then a note was edited.
                                oldNotesValue = notes[0];
                                newNotesValue = notes[1];
                            } else if (notes.length === 3) {
                                // If the `notes` array contains three items, then a note was removed.
                                oldNotesValue = notes[0];
                            }
                        }

                        const selectedDidntChange = oldSelectedValue === undefined && newSelectedValue === undefined;

                        const notesWereEdited =
                            selectedDidntChange && oldNotesValue && newNotesValue && oldNotesValue !== newNotesValue;
                        const notesWereAdded = selectedDidntChange && !oldNotesValue && newNotesValue;
                        const notesWereRemoved = selectedDidntChange && oldNotesValue && !newNotesValue;

                        const annotationWasAdded = !oldSelectedValue && newSelectedValue;
                        const annotationWasRemoved = oldSelectedValue && !newSelectedValue;

                        if (notesWereEdited) {
                            this.changes.push({
                                ...changeConfig,
                                type: 'notes_edited',
                                notes_diff: new JsonDiff(oldNotesValue, newNotesValue),
                            });
                        } else if (notesWereAdded) {
                            this.changes.push({
                                ...changeConfig,
                                type: 'notes_added',
                                new_notes: newNotesValue,
                            });
                        } else if (notesWereRemoved) {
                            this.changes.push({
                                ...changeConfig,
                                type: 'notes_removed',
                                old_notes: oldNotesValue,
                            });
                        } else if (annotationWasAdded) {
                            this.changes.push({
                                ...changeConfig,
                                type: 'added',
                                new_notes: newNotesValue,
                            });
                        } else if (annotationWasRemoved) {
                            this.changes.push({
                                ...changeConfig,
                                type: 'removed',
                                old_notes: oldNotesValue,
                            });
                        }
                    });
                }

                this.size = this.changes.length;
            },
        }));

        return AnnotationsDiff;
    },
]);
