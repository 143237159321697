import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame_author_comments.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('frameAuthorComments', [
    '$injector',

    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        return {
            restrict: 'E',
            templateUrl,

            scope: {
                frame: '<',
                currentUser: '<',
            },

            link(scope) {
                //----------------------------
                // Comment CRUD
                //----------------------------

                scope.addComment = (frame, currentUser) => {
                    frame.addComment(currentUser);
                    scope.saveComment();
                };

                scope.deleteComment = (frame, index) => {
                    DialogModal.confirm({
                        text: `Are you sure you want to delete this comment?`,
                        confirmCallback: () => {
                            frame.deleteComment(index);
                            scope.saveComment();
                        },
                    });
                };

                scope.saveComment = () => {
                    scope.$emit('saveLesson');
                };

                //----------------------------
                // Edit Toggling
                //----------------------------

                scope.editMode = false;

                scope.toggleEditComments = () => {
                    scope.editMode = !scope.editMode;
                };
            },
        };
    },
]);
