/* eslint-disable func-names */
import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    ***************** Notes on blanks frame types

    see fill_in_the_blanks_editor_template.js


*/

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.BlanksOnImage', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const ChallengesEditorTemplateHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
        );
        const MultipleChoiceChallengeModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
        );

        const sequentialOrConsumableOption = {
            type: 'radioSetProperty',
            property: 'sequentialOrConsumable',
            radios: [
                ['sequential', 'Each blank has its own set of answers.'],
                ['consumable', 'Blanks share one set of answers.'],
            ],
        };

        const selectionModeOption = editorViewModel => ({
            type: 'checkboxSetProperty',
            title: 'Check Many',
            get: () => editorViewModel.selectionMode === 'check_many_selection_mode',
            set: val => {
                editorViewModel.selectionMode = val ? 'check_many_selection_mode' : 'check_one_selection_mode';
            },
            disabled: () => editorViewModel.consumable,
        });

        return new AModuleAbove({
            included(ChallengesEditorViewModel) {
                const applyTemplate = function () {
                    const templateHelper = new ChallengesEditorTemplateHelper(this);

                    // cfInteractiveCards directive handles the progression through challenges,
                    // so we want to turn off the default behavior
                    this.model.behaviors.GotoNextOnChallengeComplete = undefined;

                    /*
                            This really sucks, but the order of config settings matters.

                            newChallengeType needs to come before supportsSharedAnswerList, in
                            case we are switching from compose_blanks, and the challenges do not
                            support answerLists.

                            supportsSharedAnswerList needs to come before supportsSequentialAndConsumable
                            so that supportsSequentialAndConsumable can determine whether this is initially
                            sequential or consumable
                        */
                    this.setConfig({
                        newChallengeType: MultipleChoiceChallengeModel,
                    });

                    this.setConfig({
                        supportsSharedAnswerList: true,
                    });

                    this.setConfig({
                        allowSetContextImages: true,
                        supportsSequentialAndConsumable: true,
                        userDefinedOptions: [sequentialOrConsumableOption],
                    });

                    templateHelper.setChallengeTemplate('blanks_on_image');

                    // setup shared text
                    templateHelper.setupSharedText();

                    const interactiveCardsEditorViewModel = templateHelper.setupSharedInteractiveCards();
                    templateHelper.setupSharedImageOverlayBlanks();
                    templateHelper.setupAtLeastOneChallenge(
                        interactiveCardsEditorViewModel.model.overlays[0].editorViewModel,
                    );

                    // setup shared answer list
                    if (this.consumable) {
                        templateHelper.setupSharedAnswerList('buttons', 2);
                    }

                    // make sure any answerLists, whether shared or not, show the randomize
                    // answer order option
                    this.model.on('.challenges[]:set:answerList', answerList => {
                        if (answerList) {
                            answerList.editorViewModel.setUserDefinedOptions(
                                'RandomizeAnswerOrder',
                                'forceSingleColumn',
                            );

                            answerList.skin = 'buttons';
                        }
                    });

                    // whenever a challenge is added, add a blank to it
                    this.model.challenges.on('childAdded', challenge => {
                        templateHelper.setupBlankAndChallenge(this.model, challenge);
                    });
                };

                ChallengesEditorViewModel.addMainUiComponentTemplate('blanks_on_image', function () {
                    applyTemplate.apply(this);
                });

                ChallengesEditorViewModel.addMainUiComponentTemplate('multiple_card_multiple_choice', function () {
                    applyTemplate.apply(this);

                    // whenever a blank is added, make it invisible
                    this.model.on(
                        '.sharedContentForInteractiveImage.overlays[].overlayComponents:childAdded',
                        blank => {
                            blank.invisible = true;
                            blank.text.allowBlank = true;
                        },
                    );

                    this.setConfig({
                        disallowAddAndReorderChallenges: true,
                        userDefinedOptions: [sequentialOrConsumableOption, selectionModeOption(this)],
                        supportsCheckMany: {
                            answerListSkin: {
                                check_one_selection_mode: 'buttons',
                                check_many_selection_mode: 'checkboxes',
                            },
                            challengeEditorTemplates: ['blanks_on_image_check_many', 'blanks_on_image'],
                        },
                    });

                    const editorViewModel = this;
                    this.model.on('set:sequentialOrConsumable', val => {
                        if (val === 'consumable') {
                            editorViewModel.selectionMode = 'check_one_selection_mode';
                        }
                    });

                    // Also look in ChallengeOverlayBlankEditorViewModel.  There is some special
                    // handling for this editor_template that unlinks all blanks from
                    // their answers.
                });
            },
        });
    },
]);
