import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.ThisOrThat',
    [
        '$injector',

        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');

            return new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    MultipleChoiceChallengeEditorViewModel.addTemplate('this_or_that', function () {
                        angular.forEach(
                            {
                                CompleteOnCorrect: {},
                                ClearMessagesOnAnswerSelect: {},
                                DisallowMultipleSelect: {},
                                FlashIncorrectStyling: {},
                                ImmediateValidation: {},
                                ShowCorrectStyling: {},
                                ResetAnswersOnActivated: {},
                            },
                            (val, key) => {
                                this.model.behaviors[key] = val;
                            },
                        );

                        this.model.validator.behaviors.HasAllExpectedAnswers = {};
                        this.model.validator.behaviors.HasNoUnexpectedAnswers = {};
                    });
                },
            });
        },
    ],
);
