import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    NoIncorrectAnswers gets turned on and off at the Challenges level, but this mixin
    is mostly just responsible for delegating to the individual challenges, which handle
    all the nittygritty.
*/

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.NoIncorrectAnswersMixin',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    // Since this is not persisted in the database in any way, and is only
                    // set in the editor template, we do not pull the initial value from the
                    // challenges, as we do with noIncorrectAnswers
                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'supportsNoIncorrectAnswers', {
                        get() {
                            return this._supportsNoIncorrectAnswers || false;
                        },
                        set(val) {
                            this._supportsNoIncorrectAnswers = val;
                            this.model.challenges.forEach(challengeModel => {
                                challengeModel.editorViewModel.supportsNoIncorrectAnswers = val;
                            });
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'noIncorrectAnswers', {
                        get() {
                            // assume that all the challenges have the same value
                            if (!this.supportsNoIncorrectAnswers) {
                                return false;
                            }
                            return this.model.challenges[0]
                                ? this.model.challenges[0].editorViewModel.noIncorrectAnswers
                                : false;
                        },
                        set(val) {
                            if (!this.supportsNoIncorrectAnswers) {
                                throw new Error('noIncorrectAnswers not supported');
                            }
                            this.model.challenges.forEach(challengeModel => {
                                challengeModel.editorViewModel.noIncorrectAnswers = val;
                            });

                            if (!val && this.supportsHasBranching) {
                                this.hasBranching = false;
                            }

                            if (!this.supportsNoIncorrectAnswers.ImmediateValidation) {
                                this.frame.continueButton.show_continue_when_ready_to_validate = val || undefined;
                                this.model.behaviors.GotoNextFrameOnComplete = val ? {} : undefined;
                            } else {
                                this.frame.continueButton.show_continue_when_ready_to_validate = undefined;
                                this.model.behaviors.GotoNextFrameOnComplete = undefined;
                            }
                        },
                    });

                    ChallengesEditorViewModel.setCallback('after', 'initialize', function () {
                        // With supportsNoIncorrectAnswers, the challengesEditorViewModel
                        // is the original source of the value (which is set in the editor template),
                        // so we want to run this right away and we do not want to pass false as the
                        // last argument here.
                        this.model.challenges.on('childAdded', challenge => {
                            challenge.editorViewModel.supportsNoIncorrectAnswers = this.supportsNoIncorrectAnswers;
                        });

                        // With noIncorrectAnswers, the challenge models are the original source of
                        // the value, since it must be persisted, so we do not want to run this against
                        // existing challenges, and we pass false as the last argument.
                        this.model.challenges.on(
                            'childAdded',
                            challenge => {
                                if (this.supportsNoIncorrectAnswers) {
                                    challenge.editorViewModel.noIncorrectAnswers = this.noIncorrectAnswers;
                                }
                            },
                            false,
                        );
                    });
                },
            });
        },
    ],
);
