import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.AnswerMatcherEditorViewModel',

        AnswerMatcherEditorViewModel =>
            AnswerMatcherEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.AnswerMatcher.MatchesExpectedText.MatchesExpectedTextModel',
                );

                return {
                    removeTransN() {
                        let text = this.model.expectedText;

                        // The editor has a tool to auto-wrap Mathjax decimals and comma-delimited numbers in \transn{...} to aid in
                        // Arabic translation. However, this can have the unwanted side effect in compose frames of creating expectedText
                        // values that look like "\transn{1.2345}". This method will search for cases like this and undo them, restoring the
                        // original intent of the compose blank.
                        text = text.replace(/\\transn{(.*?)}/g, '$1');

                        this.model.expectedText = text;
                    },
                };
            }),
    ],
);
