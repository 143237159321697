import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonEditorViewModel',

    [
        '$injector',
        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
            const MaxTextLengthConfig = $injector.get('MaxTextLengthConfig');
            const HasTextOrImageEditorViewModelMixin = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',
            );

            return ComponentEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.MatchingChallengeButton.MatchingChallengeButtonModel',
                );
                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    directiveName: 'cf-matching-challenge-button-editor',

                    setup() {
                        const textHelper = TextModel.EditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;

                        return this;
                    },

                    initialize($super, model) {
                        $super(model);

                        // if the challenge is removed, remove the blank
                        model.on('set:challenge', function (challenge) {
                            if (!challenge) {
                                this.remove();
                            }
                        });

                        model.on('set:text', this._initializeText.bind(this), true);
                    },

                    _initializeText() {
                        if (this.model.text) {
                            this.model.text.editorViewModel.setConfig({
                                showFontStyleEditor: false,
                                maxRecommendedTextLength: MaxTextLengthConfig.ANSWER,
                            });
                        }
                    },
                };
            });
        },
    ],
);
