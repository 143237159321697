import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/edit_lesson_copyright_tools.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editLessonCopyrightTools', [
    '$injector',

    function factory($injector) {
        const TranslatableLessonExportSet = $injector.get('TranslatableLessonExportSet');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                lesson: '<',
            },
            link(scope) {
                scope.exportTranslatableArtifact = () => {
                    scope.translatableLessonExportSet = new TranslatableLessonExportSet([scope.lesson], ['copyright']);
                };
            },
        };
    },
]);
