import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/answer_list/answer_list_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfAnswerListEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                Object.defineProperty(scope, 'currentChallengeEditorViewModel', {
                    get() {
                        const currentChallengeViewModel = this.viewModel.currentChallengeViewModel;
                        return this.editorViewModel.editorViewModelFor(
                            currentChallengeViewModel && currentChallengeViewModel.model,
                        );
                    },
                });

                const firstAnswer = scope.editorViewModel.model.answers[0];
                scope.activeAnswerHelper = firstAnswer && firstAnswer.editorViewModel;

                scope.addAnswer = function () {
                    const answerHelper = this.editorViewModel.addAnswer();
                    this.activeAnswerHelper = answerHelper;
                };

                scope.setActiveAnswer = answerHelper => {
                    if (scope.activeAnswerHelper === answerHelper) {
                        scope.activeAnswerHelper = undefined;
                    } else {
                        scope.activeAnswerHelper = answerHelper;
                    }
                };

                scope.answerChecked = function (answerHelper) {
                    return (
                        this.currentChallengeEditorViewModel &&
                        this.currentChallengeEditorViewModel.answerChecked(answerHelper.model)
                    );
                };

                scope.isCorrectAnswerForActiveChallenge = function (answer) {
                    const correctAnswers = this.currentChallengeEditorViewModel
                        ? this.currentChallengeEditorViewModel.correctAnswers
                        : [];
                    return correctAnswers.includes(answer);
                };
            },
        }),
]);
