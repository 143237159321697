import angularModule from 'Editor/angularModule/scripts/editor_module';
// FIXME: does fill_in_the_blanks really need it's own editor template?  What's the right level here?
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.FillInTheBlanks',
    [
        'AModuleAbove',
        'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.EditorTemplates.BlanksMixin',

        (AModuleAbove, BlanksMixin) =>
            new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    MultipleChoiceChallengeEditorViewModel.addTemplate('fill_in_the_blanks', function () {
                        BlanksMixin.applyToEditorTemplate(this);
                    });
                },
            }),
    ],
);
