import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/translatable_lesson_export/translatable_lesson_export.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('translatableLessonExport', [
    '$injector',

    function factory($injector) {
        const $timeout = $injector.get('$timeout');

        return {
            templateUrl,
            restrict: 'E',
            scope: {
                translatableLessonExport: '<',
            },
            link(scope) {
                scope.showWordCount = true; // todo: maybe we want to hide this at some point in the future?

                Object.defineProperty(scope, 'lesson', {
                    get() {
                        return scope.translatableLessonExport.lesson;
                    },
                });

                scope.$watch('translatableLessonExport', () => {
                    scope.wordCount = scope.translatableLessonExport.getPlainText().split(/\s+\b/).length;
                });

                scope.$watch('translatableLessonExport.format', format => {
                    if (
                        !_.includes(
                            [
                                'copyright',
                                'twoColumnTextOnly',
                                'twoColumnWithFrames',
                                'oneColumnTextOnly',
                                'oneColumnWithFrames',
                            ],
                            format,
                        )
                    ) {
                        throw new Error(`Unexpected format "${format}"`);
                    }
                });

                let firedRendering = false;
                const waitingForRenders = {};
                scope.$on('frame:rendering', (evt, scopeId) => {
                    if (!firedRendering) {
                        scope.$emit('translatableLessonExport:rendering', scope.$id);
                        firedRendering = true;
                    }
                    waitingForRenders[scopeId] = true;
                });

                function fireRenderedIfNotWaiting() {
                    if (!_.some(waitingForRenders)) {
                        scope.$emit('translatableLessonExport:rendered');
                    }
                }

                scope.$on('frame:rendered', (evt, scopeId) => {
                    delete waitingForRenders[scopeId];
                    fireRenderedIfNotWaiting();
                });

                $timeout(fireRenderedIfNotWaiting);
            },
        };
    },
]);
