import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/stream/list_streams_create.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listStreamsCreate', [
    '$injector',

    function factory($injector) {
        const ngToast = $injector.get('ngToast');
        const Stream = $injector.get('Lesson.Stream');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                stream: '=thing',
                goBack: '&',
                created: '&',
            },
            link(scope) {
                scope.save = () => {
                    Stream.create({
                        title: scope.newStreamTitle,
                        chapters: [
                            {
                                title: 'Default Chapter Title',
                                lesson_hashes: [],
                            },
                        ],
                    }).then(response => {
                        scope.created({
                            $thing: response.result,
                        });

                        ngToast.create({
                            content: 'Course saved',
                            className: 'success',
                        });
                    });
                };
            },
        };
    },
]);
