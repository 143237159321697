import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankEditorViewModel',

    [
        '$injector',
        $injector => {
            const ComponentEditorViewModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
            );
            const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
            const MaxTextLengthConfig = $injector.get('MaxTextLengthConfig');
            const MultipleChoiceChallengeModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeModel',
            );
            const UserInputChallengeModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
            );
            const $rootScope = $injector.get('$rootScope');
            const HasTextOrImageEditorViewModelMixin = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',
            );

            return ComponentEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ChallengeOverlayBlank.ChallengeOverlayBlankModel',
                );

                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    directiveName: 'cf-challenge-overlay-blank-editor',

                    setup() {
                        const textHelper = TextModel.EditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;

                        return this;
                    },

                    initialize($super, model) {
                        $super(model);

                        this._challengeListeners = [];

                        // In order to prevent an infinite loop, we need
                        // to delay initializing other editor view models
                        $rootScope.$evalAsync(() => {
                            model.on(
                                'set:challenge',
                                challenge => {
                                    this._clearChallengeListeners();

                                    if (!challenge) {
                                        // if the challenge is removed, remove the blank
                                        this.remove();
                                    } else if (challenge.isA(MultipleChoiceChallengeModel)) {
                                        this._watchMultipleChoiceChallenge();
                                    } else if (challenge.isA(UserInputChallengeModel)) {
                                        this._watchUserInputChallenge();
                                    } else {
                                        throw new Error(`Unexpected challenge type: ${challenge.type}`);
                                    }

                                    // in multiple_card_multiple_choice, challenges are always unlinked from
                                    // answers.  I would have liked to do this in the editor template, but
                                    // I could not get it working there for some reason.
                                    if (
                                        challenge &&
                                        this.frame.mainUiComponent.editor_template === 'multiple_card_multiple_choice'
                                    ) {
                                        challenge.unlink_blank_from_answer = true;
                                    }
                                },
                                true,
                            );

                            model.on('set:text', this._initializeText.bind(this), true);
                            model.on('.text:set:text', this._onTextChange.bind(this), true);
                            model.on('.challenge:set:unlink_blank_from_answer', this._onUnlinkChange.bind(this), true);
                        }, true);
                    },

                    _initializeText() {
                        if (this.model.text && !this.model.text.fontSize) {
                            this.model.text.fontSize = 14;
                        }

                        if (this.model.text) {
                            this.model.text.editorViewModel.setConfig({
                                showFontStyleEditor: true,
                                maxRecommendedTextLength: MaxTextLengthConfig.BLANK,
                            });
                        }
                    },

                    // If unlink_blank_from_answer is ever turned OFF (i.e.
                    // the user wants the blank and the answer to be linked once
                    // again, the we refresh the linking)
                    _onUnlinkChange(val) {
                        if (!val) {
                            if (this.model.image) {
                                this._onImageChange(this.model.image);
                            } else if (this.model.text) {
                                this._onTextChange(this.model.text.text);
                            }
                        }
                    },

                    _watchMultipleChoiceChallenge() {
                        this.model.on('set:image', this._onImageChange.bind(this), true);
                    },

                    _watchUserInputChallenge() {
                        // nothing special to do here
                    },

                    _onTextChange(text) {
                        if (!this.model.challenge.unlink_blank_from_answer) {
                            this.model.challenge.editorViewModel.correctAnswerText = text;
                        }
                        this._setRelatedBlankLabelOnCorrectAnswer();
                    },

                    _onImageChange(image) {
                        if (!this.model.challenge.unlink_blank_from_answer) {
                            this.model.challenge.editorViewModel.correctAnswerImage = image;
                        }
                        this._setRelatedBlankLabelOnCorrectAnswer();
                    },

                    _setRelatedBlankLabelOnCorrectAnswer() {
                        const correctAnswers = this.model.challenge.editorViewModel.correctAnswers;
                        // UserInputChallenges have no value for correctAnswers
                        if (correctAnswers) {
                            correctAnswers.forEach(answer => {
                                answer.editorViewModel.relatedBlankLabel = this.model.label;
                                answer.editorViewModel.relatedBlankChallenge = this.model.challenge;
                            });
                        }
                    },

                    _clearChallengeListeners() {
                        this._challengeListeners.forEach(listener => {
                            listener.cancel();
                        });
                    },
                };
            });
        },
    ],
);
