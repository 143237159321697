import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',

        ComponentEditorViewModel =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.ContinueButton.ChallengesContinueButton.ChallengesContinueButtonModel',
                );

                return {};
            }),
    ],
);
