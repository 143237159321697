import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.ComponentEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.ChallengeValidator.ChallengeValidatorEditorViewModel',

        (ComponentEditorViewModel, ChallengeValidatorEditorViewModel) =>
            ComponentEditorViewModel.subclass(function () {
                this.setModel('Lesson.FrameList.Frame.Componentized.Component.Challenge.ChallengeModel');

                // ProcessesChallengeBlanks sets the text for a challenge, but does not know
                // enough about the internal structure of a challenge to know exactly what needs
                // to be set.
                Object.defineProperty(this.prototype, 'correctAnswerText', {
                    get() {
                        return undefined;
                    },
                    set() {
                        throw new Error(
                            `Subclasses of ChallengeEditorViewModel can define correctAnswerText=. ${this.type} does not.`,
                        );
                    },
                    configurable: true, // overriden in tests
                });

                // blanks on image editor template sets the image for a challenge, but does not know
                // enough about the internal structure of a challenge to know exactly what needs
                // to be set.
                Object.defineProperty(this.prototype, 'correctAnswerImage', {
                    get() {
                        return undefined;
                    },
                    set() {
                        throw new Error(
                            `Subclasses of ChallengeEditorViewModel can define correctAnswerImage=. ${this.type} does not.`,
                        );
                    },
                    configurable: true, // overriden in tests
                });

                Object.defineProperty(this.prototype, 'noIncorrectAnswers', {
                    get() {
                        return undefined;
                    },
                    set() {
                        throw new Error(
                            `Subclasses of ChallengeEditorViewModel can define noIncorrectAnswers=. ${this.type} does not.`,
                        );
                    },
                    configurable: true, // overriden in tests
                });

                Object.defineProperty(this.prototype, 'label', {
                    get() {
                        const editorTemplate = this.frame.mainUiComponent.editor_template;
                        let label;
                        if (editorTemplate === 'multiple_card_multiple_choice') {
                            const card = this.frame.mainUiComponent.sharedContentForInteractiveImage.cardForChallenge(
                                this.model,
                            );
                            label = card.label;
                        } else {
                            _.map(this.frame.components, 'editorViewModel');
                            label = this.proxyEditorConfig && this.proxyEditorConfig.editorViewModel.model.label;
                        }

                        return label;
                    },
                });

                return {
                    setup() {
                        this.addValidator();
                        return this;
                    },

                    addValidator() {
                        const validatorHelper = ChallengeValidatorEditorViewModel.addComponentTo(this.frame).setup();
                        this.model.validator = validatorHelper.model;
                        this.model.validator.challenge = this.model;
                    },
                };
            }),
    ],
);
