import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
    ***************** Notes on blanks frame types

    see fill_in_the_blanks_editor_template.js


*/

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ComposeBlanksOnImage',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');
            const ChallengesEditorTemplateHelper = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenges.EditorTemplates.ChallengesEditorTemplateHelper',
            );
            const UserInputChallengeModel = $injector.get(
                'Lesson.FrameList.Frame.Componentized.Component.Challenge.UserInputChallenge.UserInputChallengeModel',
            );

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    ChallengesEditorViewModel.addMainUiComponentTemplate('compose_blanks_on_image', function () {
                        const templateHelper = new ChallengesEditorTemplateHelper(this);

                        // cfInteractiveCards directive handles the progression through challenges,
                        // so we want to turn off the default behavior
                        this.model.behaviors.GotoNextOnChallengeComplete = undefined;
                        this.model.behaviors.GotoNextOnChallengeComplete = undefined;

                        // Set up the config
                        this.setConfig({
                            newChallengeType: UserInputChallengeModel,
                            allowSetContextImages: true,
                            userDefinedOptions: templateHelper.getComposeBlanksUserOptions(),
                        });

                        // Set up all the shared components...
                        templateHelper.setChallengeTemplate('basic_user_input');
                        templateHelper.setupSharedText();

                        const interactiveCardsEditorViewModel = templateHelper.setupSharedInteractiveCards();
                        templateHelper.setupSharedImageOverlayBlanks();

                        this.model.challenges.on('childAdded', challenge => {
                            templateHelper.setupBlankAndChallenge(this.model, challenge);
                        });

                        // Setup at least once challenge
                        templateHelper.setupAtLeastOneChallenge(
                            interactiveCardsEditorViewModel.model.overlays[0].editorViewModel,
                        );
                    });
                },
            });
        },
    ],
);
