import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/editor_widgets/image_list_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfImageListEditor', [
    '$injector',

    $injector => {
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            scope: {
                frameViewModel: '<',
            },
            templateUrl,
            link(scope) {
                const activeUploads = {};

                //---------------------------
                // Scope convenience methods
                //---------------------------

                Object.defineProperty(scope, 'frame', {
                    get() {
                        return this.frameViewModel.frame;
                    },
                });

                scope.onImageUploadStarted = function (fileName, dataUrl) {
                    // using hasOwnProperty() because activeUploads[filename] can return false positives
                    // when the fileName happens to be a JS property on the object prototype (such as
                    // "constructor" or "hasOwnProperty").
                    if (Object.prototype.hasOwnProperty.call(activeUploads, fileName)) {
                        // content-item-image-upload should have prevented this from happening
                        throw new Error('Cannot have two active uploads with the same filename.');
                    }
                    this.alertedBadFilenameError = false;
                    const image = ImageModel.EditorViewModel.addComponentTo(this.frame, {
                        label: fileName,
                    }).model;
                    image.$$dataUrl = dataUrl;
                    activeUploads[fileName] = {
                        saveBlock: scope.frameViewModel.frame.lesson().blockSave(),
                        model: image,
                    };
                };

                scope.onImageUploaded = function (fileName, s3Asset) {
                    /*
                        We try our best in content_item_image_upload_dir to
                        make any changes to the filename that the server will make.
                        However, if we've missed something, and the file that
                        comes back has a different filename than what we
                        expected, then we won't know what to do
                        with it.  In that case, we ask the user to try a different
                        filename.
                    */
                    if (Object.prototype.hasOwnProperty.call(activeUploads, fileName)) {
                        activeUploads[fileName].model.replaceDataUrlWithImage(s3Asset);
                        activeUploads[fileName].saveBlock.unblock();
                        delete activeUploads[fileName];
                    } else {
                        if (!this.alertedBadFilenameError) {
                            $window.alert(
                                'Cannot recognize image filename.  Could be because it has non-standard characters.  Please try changing the filename and trying again.',
                            );
                        }
                        scope.onUploadError();
                        this.alertedBadFilenameError = true;
                    }
                };

                scope.onUploadError = () => {
                    angular.forEach(activeUploads, val => {
                        val.model.remove();
                        val.saveBlock.unblock();
                    });
                    scope.activeUploads = {};
                };
            },
        };
    },
]);
