import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('ComponentDiff', [
    '$injector',

    function factory($injector) {
        const SuperModel = $injector.get('SuperModel');
        const JsonDiff = $injector.get('JsonDiff');
        const TextListDiff = $injector.get('TextListDiff');
        const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
        const ImageModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Image.ImageModel');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ComponentOverlayModel = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.ComponentOverlay.ComponentOverlayModel',
        );

        const ComponentDiff = SuperModel.subclass(() => ({
            initialize(oldComponent, newComponent) {
                this.oldComponent = oldComponent;
                this.newComponent = newComponent;

                this.klass = (this.oldComponent && this.oldComponent.constructor) || this.newComponent.constructor;
                this.componentDisplayName = (oldComponent && oldComponent.displayName) || newComponent.displayName;

                this._setTextAndImageDiffs();
                this._setOtherDiffs();
            },

            _setOtherDiffs() {
                this.otherDiffs = [];

                this._setExpectedTextDiffs();
                this._setPositionDiffs();
                this._setChallengeListDiffs();
                this._setAnswerListDiffs();
            },

            _setExpectedTextDiffs() {
                let diff;

                // expectedText
                const oldExpectedText = this.oldComponent && this.oldComponent.expectedText;
                const newExpectedText = this.newComponent && this.newComponent.expectedText;
                if (oldExpectedText !== newExpectedText) {
                    diff = new JsonDiff(oldExpectedText, newExpectedText);
                    diff.usage = 'Answer Text';
                    this.otherDiffs.push(diff);
                }
            },

            _setPositionDiffs() {
                let diff;

                // x, y, height, width
                let oldDimensions =
                    this.oldComponent && _.chain(this.oldComponent).pick('x', 'y', 'height', 'width').value();
                let newDimensions =
                    this.newComponent && _.chain(this.newComponent).pick('x', 'y', 'height', 'width').value();

                // the position of a blank is stored in the ComponentOverlay component
                if (this.newComponent && this.newComponent.type === 'ChallengeOverlayBlankModel') {
                    oldDimensions = this._getOverlayOptions(this.oldComponent);
                    newDimensions = this._getOverlayOptions(this.newComponent);
                }

                if (oldDimensions && newDimensions && !angular.equals(oldDimensions, newDimensions)) {
                    diff = new JsonDiff(oldDimensions, newDimensions);

                    if (this.newComponent.type === 'SelectableAnswerModel') {
                        diff.usage = `Image Hotspot "${this.newComponent.label}"" position`;
                    } else if (this.newComponent.type === 'ChallengeOverlayBlankModel') {
                        diff.usage = `Blank "${this.newComponent.label}"`;
                    } else {
                        ErrorLogService.notify(`Unexpected type for position changes: "${this.newComponent.type}" `);
                        diff.usage = this.newComponent.type;
                    }
                    this.otherDiffs.push(diff);
                }
            },

            _setChallengeListDiffs() {
                const oldChallengeIds = this.oldComponent && this.oldComponent.challenge_ids;
                const newChallengeIds = this.newComponent && this.newComponent.challenge_ids;

                if (!oldChallengeIds || !newChallengeIds) {
                    return;
                }

                if (!angular.equals(oldChallengeIds, newChallengeIds)) {
                    this._addTextListDiff(
                        _.chain(this.oldComponent.challenges).map('editorViewModel').map('label').value(),
                        _.chain(this.newComponent.challenges).map('editorViewModel').map('label').value(),
                        'Challenge List',
                    );
                }
            },

            _setAnswerListDiffs() {
                const oldAnswerIds = this.oldComponent && this.oldComponent.answer_ids;
                const newAnswerIds = this.newComponent && this.newComponent.answer_ids;

                if (!oldAnswerIds || !newAnswerIds) {
                    return;
                }

                const oldLabels = _.chain(this.oldComponent.answers).map('label').value();
                const newLabels = _.chain(this.newComponent.answers).map('label').value();

                // In the case of blank image hotspot answers, a change in order
                // might not result in a change in the list of labels.  Show nothing
                // in that case
                if (!angular.equals(oldLabels, newLabels)) {
                    this._addTextListDiff(oldLabels, newLabels, 'Answer List');
                }
            },

            _addTextListDiff(oldList, newList, usage) {
                const diff = new TextListDiff(oldList, newList);
                diff.usage = usage;
                this.otherDiffs.push(diff);
            },

            /*
                    This method gets the position of a challengeOverlayBlank from
                    the ComponentOverlay component where it is stored.  A
                    ComponentOverlay component looks like, where the keys of
                    the overlayOptions object are ids of ChallengeOverlayBlank
                    components.

                    {
                    "id": "6f044b24-f2e6-4ff5-c102-247a4bd5a321",
                    "behaviors": {},
                    "component_type": "ComponentizedFrame.ComponentOverlay",
                    "image_id": "20e8e623-f6bb-4a5d-cebc-bf47112f4c65",
                    "overlayOptions": {
                      "eaa9d30e-2130-47a7-a534-5a8b403ad48b": {
                        "x": 69.75477,
                        "y": 72.72727,
                        "units": "%",
                        "width": 19.61853,
                        "height": 7.27273
                      },
                      "5bea23d9-ed98-4f89-ffe2-f023b2ba3d51": {
                        "x": 0,
                        "y": 0,
                        "units": "%",
                        "width": 66.48492,
                        "height": 22.18184
                      }
                    },
                */
            _getOverlayOptions(challengeOverlayBlankModel) {
                if (!challengeOverlayBlankModel) {
                    return;
                }

                const componentOverlayModels = challengeOverlayBlankModel
                    .frame()
                    .componentsForType(ComponentOverlayModel);
                return _.chain(componentOverlayModels)
                    .map('overlayOptions')
                    .map(overlayOptions => overlayOptions[challengeOverlayBlankModel.id])
                    .compact()
                    .first()
                    .pick('x', 'y', 'height', 'width')
                    .value();
            },

            _setTextAndImageDiffs() {
                const self = this;
                this.imageChanges = [];
                this.textDiffs = [];

                function addSpecialDiff(key, oldReferencedComponent, newReferencedComponent) {
                    if (
                        (oldReferencedComponent && oldReferencedComponent.isA(TextModel)) ||
                        (newReferencedComponent && newReferencedComponent.isA(TextModel))
                    ) {
                        self._addTextDiff(key, oldReferencedComponent, newReferencedComponent);
                    }

                    if (
                        (oldReferencedComponent && oldReferencedComponent.isA(ImageModel)) ||
                        (newReferencedComponent && newReferencedComponent.isA(ImageModel))
                    ) {
                        self._addImageChange(key, oldReferencedComponent, newReferencedComponent);
                    }
                }

                this.klass.referenceKeys().forEach(key => {
                    const oldReferencedComponent = self.oldComponent && self.oldComponent[key];
                    const newReferencedComponent = self.newComponent && self.newComponent[key];

                    // We do not support text or image components stored in
                    // arrays.  We could, but it is complex and as far
                    // as I know it never happens
                    if (_.isArray(oldReferencedComponent) || _.isArray(newReferencedComponent)) {
                        // special case for modals. we don't care about "reordering"
                        if (key === 'modals') {
                            const componentMap = {};
                            if (oldReferencedComponent) {
                                oldReferencedComponent.forEach(oldModalText => {
                                    const modalId = oldModalText.id;
                                    componentMap[modalId] = [oldModalText, undefined];
                                });
                            }
                            if (newReferencedComponent) {
                                newReferencedComponent.forEach(newModalText => {
                                    const modalId = newModalText.id;
                                    const components = componentMap[modalId] || [undefined, undefined];
                                    components[1] = newModalText;
                                    componentMap[modalId] = components;
                                });
                            }
                            angular.forEach(componentMap, value => {
                                addSpecialDiff(key, value[0], value[1]);
                            });
                        }
                        return;
                    }

                    addSpecialDiff(key, oldReferencedComponent, newReferencedComponent);
                });
            },

            _addTextDiff(key, oldComponent, newComponent) {
                const oldMainTextComponent = oldComponent && oldComponent.frame().mainTextComponent;
                const newMainTextComponent = newComponent && newComponent.frame().mainTextComponent;
                let usage;

                const oldText = oldComponent && oldComponent.text;
                const newText = newComponent && newComponent.text;

                if (oldText === newText) {
                    return;
                }

                if (
                    (oldMainTextComponent && oldMainTextComponent === oldComponent) ||
                    (newMainTextComponent && newMainTextComponent === newComponent)
                ) {
                    usage = 'Main Text';
                } else if (key === 'modals') {
                    usage = 'Modal';
                } else {
                    usage = this.componentDisplayName;
                }

                const textDiff = new JsonDiff(oldText, newText);
                textDiff.usage = usage;
                this.textDiffs.push(textDiff);
            },

            // _setImageChanges: function() {
            //     var self = this;
            //     var imageChanges = [];
            //     // Loop through all keys on the component that can hold
            //     // references to other components, and look for references
            //     // to images
            //     this.klass.referenceKeys().forEach(function(key) {
            //         // This is a hack.  We assume that only keys with the word
            //         // 'image' in them will reference components that are images.
            //         // This is true for now and greatly simplifies the code below
            //         // because we don't have to determine the type of the components
            //         // referenced in arrays
            //         if (!key.match(/image/)) {
            //             return;
            //         }
            //         // Grab the referenced component, if one exists
            //         var oldReferencedImage = self.oldComponent && self.oldComponent[key];
            //         var newReferencedImage = self.newComponent && self.newComponent[key];
            //         // For now, we have no cases where there is an array of images.  We could
            //         // support this if we needed to, but it would complicate the code,
            //         // so just throw if this ever happens.
            //         if (
            //             (_.isArray(oldReferencedImage) && oldReferencedImage.length > 0) ||
            //             (_.isArray(newReferencedImage) && newReferencedImage.length > 0)
            //         ) {
            //             throw new Error('Arrays of images not supported.');
            //         }
            //         // If we have an image, get the url from it
            //         var oldUrl = oldReferencedImage && oldReferencedImage.urlForFormat('293x115');
            //         var newUrl = newReferencedImage && newReferencedImage.urlForFormat('293x115');
            //         // If the url has changed, add an entry to the
            //         // imageChanges array
            //         if (oldUrl !== newUrl) {
            //             imageChanges.push({
            //                 oldLabel: oldReferencedImage && oldReferencedImage.label,
            //                 newLabel: newReferencedImage && newReferencedImage.label,
            //                 oldUrl: oldUrl,
            //                 newUrl: newUrl,
            //                 usage: key === 'image' ? self.componentDisplayName : key
            //             });
            //         }
            //     });
            //     this.imageChanges = imageChanges;
            // }
            _addImageChange(key, oldComponent, newComponent) {
                // If we have an image, get the url from it
                const oldUrl =
                    oldComponent &&
                    oldComponent.image &&
                    oldComponent.image.formats &&
                    oldComponent.image.formats['293x115'] &&
                    oldComponent.urlForFormat('293x115');
                const newUrl =
                    newComponent &&
                    newComponent.image &&
                    newComponent.image.formats &&
                    newComponent.image.formats['293x115'] &&
                    newComponent.urlForFormat('293x115');
                let usage;

                if (key === 'image') {
                    usage = this.componentDisplayName;
                } else if (key.match(/contentforfirstimage/i)) {
                    usage = 'Top Image';
                } else if (key.match(/contentforsecondimage/i)) {
                    usage = 'Bottom Image';
                } else {
                    usage = key;
                }

                // If the url has changed, add an entry to the
                // imageChanges array
                if (oldUrl !== newUrl) {
                    this.imageChanges.push({
                        oldLabel: oldComponent && oldComponent.label,
                        newLabel: newComponent && newComponent.label,
                        oldUrl,
                        newUrl,
                        usage,
                    });
                }
            },
        }));

        return ComponentDiff;
    },
]);
