import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson/preview_lesson.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('previewLesson', [
    '$injector',

    function factory($injector) {
        const $window = $injector.get('$window');
        const safeApply = $injector.get('safeApply');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                if (!$rootScope.sharedPreviewScope) {
                    throw new Error('Cannot initialize lesson preview without a shared preview scope');
                }

                // see also: validation_responser
                const stopWatchingSharedPreviewScope = $rootScope.sharedPreviewScope.$watch(() => {
                    safeApply(scope);
                });

                Object.defineProperty(scope, 'playerViewModel', {
                    get() {
                        return $rootScope.sharedPreviewScope
                            ? $rootScope.sharedPreviewScope.playerViewModel
                            : undefined;
                    },
                });

                // We have to do this explicitly because when the containing
                // iframe is removed things will not get cleaned up otherwise.
                // This is probably the wrong place to do it, but it works.
                $($window).on('unload', () => {
                    try {
                        $rootScope.$destroy();
                    } catch (err) {
                        // https://sentry.io/organizations/pedago/issues/1542830300/events/latest/?environment=Production+-+Client&project=1491374&query=is%3Aunresolved
                        if (!err.message.match('nextSibling')) {
                            throw err;
                        }
                    }

                    if ($rootScope.sharedPreviewScope) {
                        $rootScope.sharedPreviewScope.onPreviewDigest();
                    }
                });

                // Make sure the sharedPreviewScope gets digested whenever this one is.
                scope.$watch(() => {
                    if ($rootScope.sharedPreviewScope) {
                        $rootScope.sharedPreviewScope.onPreviewDigest();
                    }
                });

                scope.$on('$destroy', () => {
                    stopWatchingSharedPreviewScope();
                });
            },
        };
    },
]);
