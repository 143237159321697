import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenge.MultipleChoiceChallenge.MultipleChoiceChallengeEditorViewModel.NoIncorrectAnswers',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');

            return new AModuleAbove({
                included(MultipleChoiceChallengeEditorViewModel) {
                    Object.defineProperty(
                        MultipleChoiceChallengeEditorViewModel.prototype,
                        'supportsNoIncorrectAnswers',
                        {
                            get() {
                                return this._supportsNoIncorrectAnswers || false;
                            },
                            set(val) {
                                this._supportsNoIncorrectAnswers = val;
                                if (val) {
                                    this._onNoIncorrectAnswersChange();
                                }
                            },
                        },
                    );

                    Object.defineProperty(MultipleChoiceChallengeEditorViewModel.prototype, 'noIncorrectAnswers', {
                        get() {
                            if (!this.supportsNoIncorrectAnswers) {
                                return false;
                            }
                            return this.model.no_incorrect_answers;
                        },
                        set(val) {
                            if (!this.supportsNoIncorrectAnswers) {
                                throw new Error('does not support noIncorrectAnswers');
                            }

                            this.model.no_incorrect_answers = val;
                            this._onNoIncorrectAnswersChange();
                        },
                        configurable: true, // overridden in tests
                    });
                },

                _onNoIncorrectAnswersChange() {
                    /*
                        When NoIncorrectAnswers is on,
                            - ImmediateValidation and ShowCorrectStyling are turned off
                            - PlayScalingSoundOnSelected is turned on each answer as added
                            - ReadyToValidateWhenAnswerIsSelected is turned on
                            - all validations and answerMatchers are removed from the validator
                            - messages are attached to the 'selected' event instead of 'validated'

                            So, when an answer is selected, no validation will happen since ImmediateValidation
                            is off, but since ReadyToValidateWhenAnswerIsSelected is on, the continue button will
                            change so that when it is clicked validate() will be called on the challenge.  Validate()
                            will always be successful, since all validations have been removed, at that point the
                            challenge will be complete and the Challenges component will take over handling
                            what happens next.
                    */
                    const val = this.model.no_incorrect_answers;

                    if (val) {
                        if (this.supportsNoIncorrectAnswers.ImmediateValidation) {
                            this.model.behaviors.ImmediateValidation = {};
                            this.model.behaviors.ReadyToValidateWhenAnswerIsSelected = undefined;
                            this.messageEvent = 'validated';
                        } else {
                            this.model.behaviors.ImmediateValidation = undefined;
                            this.model.behaviors.ReadyToValidateWhenAnswerIsSelected = {};
                            this.messageEvent = 'selected';
                        }

                        this.model.behaviors.PlayScalingSoundOnSelected = {};
                        this.model.behaviors.ShowCorrectStyling = undefined;
                        this.model.validator.behaviors.HasAllExpectedAnswers = undefined;
                        this.model.validator.behaviors.HasNoUnexpectedAnswers = undefined;
                        this.model.validator.expectedAnswerMatchers = [];
                    } else {
                        this.model.behaviors.ImmediateValidation = {};
                        this.model.behaviors.ShowCorrectStyling = {};
                        this.model.behaviors.ReadyToValidateWhenAnswerIsSelected = undefined;
                        this.model.behaviors.PlayScalingSoundOnSelected = undefined;
                        this.model.validator.behaviors.HasAllExpectedAnswers = {};
                        this.model.validator.behaviors.HasNoUnexpectedAnswers = {};
                        this.messageEvent = 'validated';
                    }

                    // warning, this will cause issues if config.disableCorrectAnswerSelect might
                    // be set to true by something else (which happens in blanks).  So, if
                    // we use this with blanks, we need to do something fancy.
                    // See above for where this is set initially
                    this.config.disableCorrectAnswerSelect = val;
                },
            });
        },
    ],
);
