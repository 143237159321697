import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/edit_lesson_translation_tools.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editLessonTranslationTools', [
    '$injector',

    function factory($injector) {
        const TranslatableLessonExportSet = $injector.get('TranslatableLessonExportSet');
        const LESSON_TRANSLATION_EXPORT_FORMATS = $injector.get('LESSON_TRANSLATION_EXPORT_FORMATS');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                playerViewModel: '<',
            },
            link(scope) {
                scope.exportTranslatableArtifact = () => {
                    scope.translatableLessonExportSet = new TranslatableLessonExportSet(
                        [scope.playerViewModel.lesson],
                        LESSON_TRANSLATION_EXPORT_FORMATS,
                    );
                };
            },
        };
    },
]);
