import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/challenge/user_input_challenge/user_input_challenge_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfUserInputChallengeEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',

    EditorDirHelper =>
        EditorDirHelper.getOptions({
            scope: {
                overlayEditorViewModel: '<',
                // Copy-pasted from EditorDirHelper...should make it do deep merge maybe?
                editorViewModel: '<',
                frameViewModel: '<',
                options: '<?',

                // skin cannot go in options because it needs to be
                // accessible to templateUrl, which is called before
                // the scope is built
                skin: '@',
            },
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);
            },
        }),
]);
