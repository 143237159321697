import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerEditorViewModel',

    [
        'Lesson.FrameList.Frame.Componentized.Component.Answer.AnswerEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Text.TextEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Image.ImageEditorViewModel',
        'Lesson.FrameList.Frame.Componentized.Component.Mixins.HasTextOrImageEditorViewModelMixin',
        'MaxTextLengthConfig',

        (
            AnswerEditorViewModel,
            TextEditorViewModel,
            ImageEditorViewModel,
            HasTextOrImageEditorViewModelMixin,
            MaxTextLengthConfig,
        ) =>
            AnswerEditorViewModel.subclass(function () {
                this.setModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Answer.SelectableAnswer.SelectableAnswerModel',
                );

                this.supportConfigOption('supportHasBackground');
                this.supportConfigOption('maxRecommendedTextLength');
                this.supportConfigOption('hideFontSizeEditor');

                this.onConfigChange('supportHasBackground', function (val) {
                    if (!val) {
                        this.model.hasBackground = undefined;
                    }
                });

                // Support overriding selectable_answer's text's hideFontSizeEditor configuration from
                // any components that contain selectable answers (see image_hotspot_editor_template).
                this.onConfigChange('hideFontSizeEditor', function (val) {
                    if (this.model.text) {
                        this.model.text.editorViewModel.config.hideFontSizeEditor = val;
                    }
                });

                this.include(HasTextOrImageEditorViewModelMixin);

                return {
                    directiveName: 'cf-selectable-answer-editor',

                    setup() {
                        const textHelper = TextEditorViewModel.addComponentTo(this.frame).setup();
                        this.model.text = textHelper.model;

                        return this;
                    },

                    initialize($super, model) {
                        $super(model);

                        // It seems like we should be able to just set "relatedBlank"
                        // rather than having to separate properties, but the blank
                        // is sometimes a ChallengeOverlayBlankModel and sometimes just
                        // a chunk of text processed by Text.Behaviors.ProcessesChallengeBlanks,
                        // so each property is set differently in the two cases
                        this.relatedBlankLabel = undefined;
                        this.relatedBlankChallenge = undefined;

                        this._setRecommendedMaximumTextLength();
                    },

                    _setRecommendedMaximumTextLength() {
                        this.model.on(
                            'set:text',
                            textComponent => {
                                if (textComponent) {
                                    textComponent.editorViewModel.setConfig({
                                        maxRecommendedTextLength: () =>
                                            this.config.maxRecommendedTextLength || MaxTextLengthConfig.ANSWER,
                                    });
                                    textComponent.editorViewModel.setConfig({
                                        showFontStyleEditor: true,
                                        hideFontSizeEditor: true,
                                    });
                                }
                            },
                            true,
                        );
                    },

                    wrapTransN() {
                        if (this.model.text) {
                            this.model.text.editorViewModel.wrapTransN();
                        }
                    },
                };
            }),
    ],
);
