import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/editor_index.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editorIndex', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const $rootScope = $injector.get('$rootScope');
        const isMobileMixin = $injector.get('isMobileMixin');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const $route = $injector.get('$route');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                isMobileMixin.onLink(scope);
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;

                scope.mobileState = {
                    expanded: false,
                };

                // We hide the app header on mobile on this screen
                scope.$watch('isMobile', () => {
                    AppHeaderViewModel.toggleVisibility(!scope.isMobile);
                });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                //--------------------------
                // Navigation Helpers
                //--------------------------
                Object.defineProperty(scope, '$route', {
                    get() {
                        return $route;
                    },
                });

                Object.defineProperty(scope, 'searchParamId', {
                    get() {
                        return $location.search().id || null;
                    },
                });

                scope.$watch('$route.current.$$route.site', (newVal, oldVal) => {
                    if (oldVal === 'editor' && newVal !== 'editor') {
                        // bust lesson cache when navigating away from editor
                        LearnerContentCache.bustCache();
                    }
                });

                scope.gotoSection = section => {
                    scope.section = section;
                    $location.search('section', section);
                    // Exams is the only section where the individual items are rendered within the editor-index directive.
                    // In that case, if we unset the search param, it becomes impossible to share a link to a particular item
                    if (section !== 'exams') {
                        $location.search('id', undefined);
                    }

                    scope.mobileState.expanded = false;
                };

                scope.isSectionActive = section =>
                    section === scope.section || (section === 'lessons' && !scope.section);

                Object.defineProperty(scope, 'sectionNavigationTitle', {
                    get() {
                        if (!scope.section) {
                            return 'LESSONS';
                        }
                        if (scope.section === 'contentTopics') {
                            return 'CONTENT TOPICS';
                        }
                        return scope.section.toUpperCase();
                    },
                });

                const initialSection = $location.search().section;
                if (initialSection) {
                    scope.gotoSection(initialSection);
                }
            },
        };
    },
]);
