import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('UnsavedChangesWarning', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');

        /*
            This is a little bit nuts, and arguably wrong and stupid,
            but here's what we're doing:

            We have some cases where a component, on initialization,
            initializes and array or an object.  So something like:

            this.model.messages = this.model.messages || [];

            In those cases, we've just dirtied the record.  But for the
            purposes of an unsaved error message, we want to ignore those.
            So here we re-implement equals, but tell it to consider two
            blank things as "equal".  So undefined is equal to [] or {}.
        */
        const isArray = angular.isArray;
        const isDate = angular.isDate;
        const isFunction = angular.isFunction;

        function isRegExp(value) {
            return $window.toString.call(value) === '[object RegExp]';
        }

        function isScope(obj) {
            return obj && obj.$evalAsync && obj.$watch;
        }

        function isWindow(obj) {
            return obj && obj.window === obj;
        }

        function isBlank(obj) {
            if (angular.isUndefined(obj)) {
                return true;
            }

            if (isArray(obj) && obj.length === 0) {
                return true;
            }

            if (obj && typeof obj === 'object' && Object.keys(obj).length === 0) {
                return true;
            }

            return false;
        }

        function equals(o1, o2) {
            if (isBlank(o1) && isBlank(o2)) {
                return true;
            }

            // everything below here is identical to angular.equals

            if (o1 === o2) return true;
            if (o1 === null || o2 === null) return false;
            if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
            const t1 = typeof o1;
            const t2 = typeof o2;
            let length;
            let key;
            let keySet;
            if (t1 == t2) {
                if (t1 == 'object') {
                    if (isArray(o1)) {
                        if (!isArray(o2)) return false;
                        if ((length = o1.length) == o2.length) {
                            for (key = 0; key < length; key++) {
                                if (!equals(o1[key], o2[key])) return false;
                            }
                            return true;
                        }
                    } else if (isDate(o1)) {
                        if (!isDate(o2)) return false;
                        return equals(o1.getTime(), o2.getTime());
                    } else if (isRegExp(o1)) {
                        return isRegExp(o2) ? o1.toString() == o2.toString() : false;
                    } else {
                        if (
                            isScope(o1) ||
                            isScope(o2) ||
                            isWindow(o1) ||
                            isWindow(o2) ||
                            isArray(o2) ||
                            isDate(o2) ||
                            isRegExp(o2)
                        )
                            return false;
                        keySet = {};
                        for (key in o1) {
                            if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
                            if (!equals(o1[key], o2[key])) return false;
                            keySet[key] = true;
                        }
                        for (key in o2) {
                            if (
                                !keySet.hasOwnProperty(key) &&
                                key.charAt(0) !== '$' &&
                                o2[key] !== undefined &&
                                !isFunction(o2[key])
                            )
                                return false;
                        }
                        return true;
                    }
                }
            }
            return false;
        }

        function logDiffs(o1, o2) {
            if (isBlank(o1) && isBlank(o2)) {
                return true;
            }

            if (o1 === o2) return true;
            if (o1 === null || o2 === null) {
                console.log(o1, '!=', o2, '(a)');
                return false;
            }
            if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
            const t1 = typeof o1;
            const t2 = typeof o2;
            let length;
            let key;
            let keySet;
            if (t1 == t2) {
                if (t1 == 'object') {
                    if (isArray(o1)) {
                        if (!isArray(o2)) {
                            console.log(o1, '!=', o2, '(b)');
                            return false;
                        }
                        if ((length = o1.length) == o2.length) {
                            for (key = 0; key < length; key++) {
                                if (!logDiffs(o1[key], o2[key])) {
                                    // console.log('VALUES FOR ', key, ' ARE NOT EQUAL (1)');
                                    return false;
                                }
                            }
                            return true;
                        }
                    } else if (isDate(o1)) {
                        if (!isDate(o2)) {
                            // console.log(o1, '!=', o2, '(c)');
                            return false;
                        }
                        return logDiffs(o1.getTime(), o2.getTime());
                    } else if (isRegExp(o1)) {
                        const r = isRegExp(o2) ? o1.toString() == o2.toString() : false;
                        if (!r) {
                            // console.log(o1, '!=', o2, '(d)');
                            return false;
                        }
                        return true;
                    } else {
                        if (
                            isScope(o1) ||
                            isScope(o2) ||
                            isWindow(o1) ||
                            isWindow(o2) ||
                            isArray(o2) ||
                            isDate(o2) ||
                            isRegExp(o2)
                        ) {
                            console.log(o1, '!=', o2, '(e)');
                            return false;
                        }
                        keySet = {};
                        for (key in o1) {
                            if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
                            if (!logDiffs(o1[key], o2[key])) {
                                console.log('VALUES FOR ', key, ' ARE NOT EQUAL (2)');
                                return false;
                            }
                            keySet[key] = true;
                        }
                        for (key in o2) {
                            if (
                                !keySet.hasOwnProperty(key) &&
                                key.charAt(0) !== '$' &&
                                o2[key] !== undefined &&
                                !isFunction(o2[key])
                            ) {
                                console.log('VALUES FOR ', key, ' ARE NOT EQUAL (3)');
                                return false;
                            }
                        }
                        return true;
                    }
                }
            }
            console.log(o1, '!=', o2, '(f)');
            return false;
        }

        return {
            warnOnUnsavedChanges(scope, prop) {
                // the unload handler kills the karma watcher
                if (window.RUNNING_IN_TEST_MODE) {
                    return;
                }
                const origWindowUnload = $window.onbeforeunload;
                let origJson;
                let lastWarningAt;
                Object.defineProperty(scope, 'hasUnsavedChanges', {
                    get() {
                        if (origJson && !equals(scope[prop].asJson(), origJson)) {
                            // call equals again with logging
                            logDiffs(scope[prop].asJson(), origJson, true);
                            return true;
                        }
                        return false;
                    },
                });

                Object.defineProperty(scope, 'warningSilenced', {
                    get() {
                        return lastWarningAt && new Date() - lastWarningAt < 5000;
                    },
                });

                scope.$watch(prop, record => {
                    if (record) {
                        origJson = record.asJson();
                    }
                });
                scope.$watch(`${prop}.$$saving`, (newValue, oldValue) => {
                    if (oldValue && !newValue) {
                        origJson = scope[prop].asJson();
                    }
                });
                $window.onbeforeunload = () => {
                    if (scope.hasUnsavedChanges && !scope.warningSilenced) {
                        lastWarningAt = new Date();
                        return 'You have unsaved changes';
                    }
                };
                scope.$on('$locationChangeStart', (evt, next, prev) => {
                    const changingMoreThanJustQueryParams = next.split('?')[0] !== prev.split('?')[0];
                    if (changingMoreThanJustQueryParams && scope.hasUnsavedChanges && !scope.warningSilenced) {
                        if (!$window.confirm('You have unsaved changes.  Are you sure you want to leave this page?')) {
                            lastWarningAt = new Date();
                            evt.preventDefault();
                        } else {
                            lastWarningAt = new Date();
                        }
                    }
                });
                scope.$on('$destroyed', () => {
                    $window.onbeforeunload = origWindowUnload;
                });
            },
        };
    },
]);
