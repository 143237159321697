import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/lesson_types/frame_list/frame/componentized/component/text/text_editor.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cfTextEditor', [
    'Lesson.FrameList.Frame.Componentized.Component.EditorDirHelper',
    '$injector',
    (EditorDirHelper) /* $injector */ =>
        EditorDirHelper.getOptions({
            templateUrl,
            link(scope, element) {
                EditorDirHelper.link(scope, element);

                scope.options = scope.options || {};

                Object.defineProperty(scope, 'textareaRows', {
                    get() {
                        return this.skin === 'inline' ? 1 : 4;
                    },
                });

                Object.defineProperty(scope, 'activeModal', {
                    get() {
                        if (
                            scope._activeModal &&
                            scope.model.editorViewModel.$$modalKeys.includes(scope._activeModal)
                        ) {
                            return scope._activeModal;
                        }
                        return scope.model.editorViewModel.$$modalKeys[0];
                    },
                    set(activeModal) {
                        scope._activeModal = activeModal;
                    },
                });

                Object.defineProperty(scope, 'activeModalIndex', {
                    get() {
                        if (scope.activeModal && scope.model.editorViewModel.$$modalKeys.includes(scope.activeModal)) {
                            return scope.model.editorViewModel.$$modalKeys.indexOf(scope.activeModal);
                        }
                        return -1;
                    },
                });

                scope.getModalLabel = modalKey => {
                    const modalKeyJson = JSON.parse(modalKey);
                    const hasDuplicateKeys = scope.model.editorViewModel.$$modalKeys.some(key => {
                        const json = JSON.parse(key);

                        return json.label === modalKeyJson.label && json.index !== modalKeyJson.index;
                    });

                    if (hasDuplicateKeys) {
                        const index =
                            scope.model.editorViewModel.$$modalKeys
                                .filter(key => JSON.parse(key).label === modalKeyJson.label)
                                .indexOf(modalKey) + 1;

                        return `${modalKeyJson.label} (${index})`;
                    }

                    return modalKeyJson.label;
                };

                const softMaxLengthWarningPercent = 0.75;
                const DEFAULT_MAX_LENGTH = 9999;

                scope.checkTextLength = () => {
                    // Make sure that this is specific enough not to catch the
                    // modal editors inside of here
                    const counter = element.find('> .cf-text-editor > .counter-and-render-toggle > .tlm-counter');

                    const maxLength =
                        +scope.editorViewModel.maxRecommendedTextLength() > 0
                            ? +scope.editorViewModel.maxRecommendedTextLength()
                            : DEFAULT_MAX_LENGTH;
                    const yellowThreshold = maxLength * softMaxLengthWarningPercent;
                    const validation = scope.editorViewModel.validateTextLength();
                    const textLength = validation.textLength;
                    const unformattedLength = validation.unformattedLength;

                    if (textLength === 0 && unformattedLength > 0) {
                        // If the text is being formatted, do not show anything
                        counter.text('Calculating ...');
                    } else {
                        counter.text(`${maxLength - textLength} / ${maxLength} remaining`);
                    }

                    const elemClassList = element.get(0).classList;
                    const counterClassList = counter.get(0).classList;

                    if (textLength >= maxLength) {
                        elemClassList.add('tlm-over');
                        elemClassList.remove('tlm-warning');
                        counterClassList.add('tlm-over');
                        counterClassList.remove('tlm-warning');
                    } else if (textLength >= yellowThreshold) {
                        elemClassList.add('tlm-warning');
                        elemClassList.remove('tlm-over');
                        counterClassList.add('tlm-warning');
                        counterClassList.remove('tlm-over');
                    } else {
                        elemClassList.remove('tlm-warning');
                        elemClassList.remove('tlm-over');
                        counterClassList.remove('tlm-warning');
                        counterClassList.remove('tlm-over');
                    }
                };

                // Potential future improvement: figure out how to make it so the programatic
                // text area updates below make it into the undo/redo stack, which would allow
                // users to CTRL+Z / CTRL+SHIFT+Z their updates.

                scope.onToolbarButtonClick = option => {
                    // Retrieve the textarea element.
                    const textAreaElement = element.find('.cf-text-area').get(0);

                    // Retrieve the current selected/highlighted text if there is any - otherwise,
                    // this will be the current cursor position.
                    const [start, end] = [textAreaElement.selectionStart, textAreaElement.selectionEnd];

                    // Check to see if the user has any text selected/highlighted.
                    // Defaults to an empty string if no text is currently selected.
                    const selectedText = textAreaElement.value.substring(start, end);

                    // Create new string with the prepend and append strings.
                    const updatedText = `${option.prepend}${selectedText}${option.append}`;

                    // Update the text area with the new string.
                    textAreaElement.focus();
                    textAreaElement.setRangeText(updatedText, start, end);

                    // Update the selection area to either maintain the selection
                    // from before the text update, or place the cursor in between the
                    // prepend and append strings.
                    textAreaElement.selectionStart = start + option.prepend.length;
                    textAreaElement.selectionEnd = end + option.prepend.length;

                    // Update model to trigger formatting.
                    scope.model.text = textAreaElement.value;
                };

                scope.formatOptionTypeToTooltipString = optionType => {
                    const words = optionType.split(/-| /);
                    return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
                };

                scope.textToolbarOptions = _.reject(
                    [
                        {
                            type: 'italic',
                            buttonText: '✱', // u2731 unicode used for properly positioned asterisk
                            prepend: '*',
                            append: '*',
                            styles: {
                                fontSize: '10px', // u2731 is a bit bigger than other chars at the same font size, so lowering it a bit here
                            },
                        },
                        {
                            type: 'bold',
                            buttonText: '✱✱', // u2731 unicode used for properly positioned asterisk
                            prepend: '**',
                            append: '**',
                            styles: {
                                fontSize: '10px', // u2731 is a bit bigger than other chars at the same font size, so lowering it a bit here
                            },
                        },
                        {
                            type: 'link',
                            buttonText: '[ ]( )',
                            prepend: '[',
                            append: ']()',
                        },
                        {
                            type: 'modal',
                            buttonText: '[[ ]]',
                            prepend: '[[',
                            append: ']]',
                        },
                        {
                            type: 'mathjax',
                            buttonText: '%%',
                            prepend: '%%',
                            append: '%%',
                        },
                        {
                            type: 'inline-image',
                            buttonText: '![ ]',
                            prepend: '![',
                            append: ']',
                        },
                        {
                            type: 'display-image',
                            buttonText: '!![ ]',
                            prepend: '!![',
                            append: ']',
                        },
                        {
                            type: 'code',
                            buttonText: '``',
                            prepend: '`',
                            append: '`',
                            styles: {
                                fontSize: '16px', // backticks are hard to see, so make them a bit larger
                            },
                        },
                    ],
                    item => scope.model.editorViewModel.config?.excludeToolbarButtonTypes?.includes(item.type),
                );

                // detect outside changes to softMaxLength
                scope.$watch('softMaxLength', scope.checkTextLength);
                scope.$watch('model.formatted_text', scope.checkTextLength);

                // Even if a user has `renderAutomatically` set to false,
                // we should still format the text upon un-focusing the text area.
                scope.onBlur = () => {
                    if (!scope.model.editorViewModel.renderAutomatically()) {
                        const forcefullyRender = true;
                        scope.model.editorViewModel.formatText(forcefullyRender);
                    }
                };
            },
        }),
]);
