import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesMarkdownEditor', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const FormatsText = $injector.get('FormatsText');

        return new AModuleAbove({
            included(TextEditorViewModel) {
                function defendAgainstModalLinkConfusion(text) {
                    // `invisibleChar` looks like an empty string, but it is
                    // not.  There is an invisible character there.
                    // Use the arrow keys to go back and forth over it
                    // and you can tell.
                    //
                    // We have to do this so that markdown does not think
                    // that a parenthesis after a modal is a link.
                    // See https://trello.com/c/TG8P4Dxj/500-bug-parenthesis-after-bracket-causes-error
                    const invisibleChar = '​';
                    // b is ']]'
                    // c is (maybe) some whitespace
                    // d is '('
                    return text.replace(/(\]\])(\s*)(\()/g, (a, b, c, d) => b + c + invisibleChar + d);
                }

                // set up a callback to be run whenever a TextModel is initialized
                TextEditorViewModel.setCallback('after', 'initialize', function () {
                    const editorViewModel = this;

                    this.model.on('behavior_added:ProcessesMarkdown', () => {
                        editorViewModel.formatters.add('markdown', text => {
                            text = defendAgainstModalLinkConfusion(text);
                            return FormatsText.processMarkdown(text);
                        });
                    });
                });
            },
        });
    },
]);
