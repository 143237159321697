import angularModule from 'Editor/angularModule/scripts/editor_module';
import template from 'Editor/angularModule/views/stream/list_streams.html';
import customTitleTemplate from 'Editor/angularModule/views/stream/list_streams_custom_title.html';
import customActionsTemplate from 'Editor/angularModule/views/stream/list_streams_custom_actions.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listStreams', [
    '$injector',

    function factory($injector) {
        const $location = $injector.get('$location');
        const SiteMetadata = $injector.get('SiteMetadata');
        const editContentItemListMixin = $injector.get('editContentItemListMixin');
        const Stream = $injector.get('Lesson.Stream');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.Locale = $injector.get('Locale');
                scope.customTip = '<i class="fa fa-star"></i> = Available to SMARTER users';
                scope.apiFields = Stream.fieldsForEditorList;

                //-------------------------
                // Editing
                //-------------------------

                scope.editStream = stream => {
                    $location.url(`/editor/course/${stream.id}/edit`);
                };

                scope.createCallback = stream => {
                    // now that we have the server-generated stream id, embed it in the seo url and save
                    const seoCanonicalUrl = SiteMetadata.seoCanonicalUrlFromTitle('course', stream.title, stream.id);
                    stream.entity_metadata.canonical_url = seoCanonicalUrl;
                    stream.entity_metadata.save().then(() => {
                        scope.editStream(stream);
                    });
                };

                //-------------------------
                // Columns
                //-------------------------

                scope.displayColumns = [
                    {
                        prop: 'tag',
                        type: 'text',
                        label: 'Tag',
                    },
                    {
                        prop: 'title',
                        type: 'custom',
                        label: 'Title',
                        template: customTitleTemplate,
                        callbacks: {
                            editCallback: scope.editStream,
                        },
                    },
                    {
                        prop: 'authorName',
                        type: 'text',
                        label: 'Author',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'chapterCount',
                        type: 'number',
                        label: '# Chapter(s)',
                        classes: ['hidden-xs', 'hidden-sm', 'hidden-md'],
                    },
                    {
                        prop: 'lessonCount',
                        type: 'number',
                        label: '# Lesson(s)',
                        classes: ['hidden-xs', 'hidden-sm', 'hidden-md'],
                    },
                    {
                        prop: 'sortablePublishedAt',
                        type: 'time',
                        label: 'Published',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'sortableModifiedAt',
                        type: 'time',
                        label: 'Modified',
                        classes: ['hidden-xs'],
                    },
                    {
                        prop: 'editorAbilities',
                        type: 'custom',
                        label: 'Actions',
                        template: customActionsTemplate,
                        callbacks: {
                            editCallback: scope.editStream,
                        },
                    },
                ];

                //-------------------------
                // Filtering
                //-------------------------

                scope.quickFilterProperties = ['tag', 'title', 'authorName'];
                const booleanProperties = ['modifiedAfterPublished', 'hasPublishedVersion'];

                const defaultFilters = [
                    {
                        server: true,
                        default: true,
                        value: {
                            published: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            in_users_locale_or_en: false,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            user_can_see: null,
                        },
                    },
                    {
                        server: true,
                        default: true,
                        value: {
                            locale: 'en',
                        },
                    },
                ];

                scope.modifiedAfterPublishedOptions = [
                    {
                        label: 'Modified after published',
                        value: 'true',
                    },
                    {
                        label: 'Not modified after published',
                        value: 'false',
                    },
                ];

                scope.hasPublishedVersionOptions = [
                    {
                        label: 'Published',
                        value: 'true',
                    },
                    {
                        label: 'Not published',
                        value: 'false',
                    },
                ];

                // wire up filtering support
                editContentItemListMixin.onLink(scope, 'listStreams', defaultFilters, booleanProperties);
            },
        };
    },
]);
