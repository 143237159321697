import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Challenges.ChallengesEditorViewModel.CheckManyMixin',
    [
        '$injector',
        $injector => {
            const AModuleAbove = $injector.get('AModuleAbove');

            /*
                This function uses the `challengeEditorTemplates` property set up in the
                `supportsCheckMany` config to determine which editor template to use for
                each challenge.

                The possibilities for `challengeEditorTemplates` are
                    * ['check_many', 'basic_multiple_choice']
                    * ['blanks_on_image_check_many', 'blanks_on_image']

                In each case, the first entry is the editor template to use when
                selectionMode value is 'check_many_selection_mode' or 'check_all_selection_mode' and the second is the one we use when selectionMode value is 'check_one_selection_mode'

                NOTE: the check_many option has to match /check_many/. See componentized.js#getMultipleChoiceInstructions
            */
            function getEditorTemplate(supportsCheckManyConfig, isMultipleAnswerMode) {
                const index = isMultipleAnswerMode ? 0 : 1;
                return supportsCheckManyConfig.challengeEditorTemplates[index];
            }

            const multipleAnswerModes = ['check_many_selection_mode', 'check_all_selection_mode'];

            return new AModuleAbove({
                included(ChallengesEditorViewModel) {
                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'selectionMode', {
                        get() {
                            if (this.usesMultipleAnswersEditorTemplate && this.checkAll) {
                                return 'check_all_selection_mode';
                            }
                            if (this.usesMultipleAnswersEditorTemplate) {
                                return 'check_many_selection_mode';
                            }
                            return 'check_one_selection_mode';
                        },
                        set(value) {
                            const prevValue = this.selectionMode;

                            if (value === prevValue) {
                                return;
                            }

                            const isMultipleAnswerMode = multipleAnswerModes.includes(value);

                            const newEditorTemplate = getEditorTemplate(
                                this.config.supportsCheckMany,
                                isMultipleAnswerMode,
                            );

                            this._lastCorrectAnswersForSelectionModes = this._lastCorrectAnswersForSelectionModes || {};

                            // update the individual challenges
                            this.model.challenges.forEach(challenge => {
                                // memoize the correct answers for the previous selection mode in case we switch back
                                this._lastCorrectAnswersForSelectionModes[challenge.id] = {
                                    ...this._lastCorrectAnswersForSelectionModes[challenge.id],
                                    [prevValue]: challenge.editorViewModel.correctAnswers?.concat(),
                                };

                                // setting this on challenges[0] causes the selectionMode getter to return a new value
                                challenge.selection_mode = value;

                                challenge.editorViewModel.applyTemplate(newEditorTemplate);

                                // restore correct answers from the memoized correct answers
                                challenge.editorViewModel.correctAnswers = this._lastCorrectAnswersForSelectionModes[
                                    challenge.id
                                ]?.[value] || [challenge.answers?.[0]];
                            });

                            // can't be both branching and multiple answers at the same time
                            if (isMultipleAnswerMode && this.supportsHasBranching) {
                                this.hasBranching = false;
                            }

                            if (value === 'check_all_selection_mode' && this.supportsNoIncorrectAnswers) {
                                // although we reproduce some of the noIncorrectAnswers behavior in _onChange()
                                this.noIncorrectAnswers = false;
                            }

                            this._onChange();
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'answerLists', {
                        get() {
                            /*
                                In the "Multiple Choice Multiple Cards" case, we will have different
                                answer lists for each challenge, and there will be no sharedAnswerList.

                                In other cases, we will have sharedAnswerList.
                            */
                            return this.sharedAnswerList
                                ? [this.sharedAnswerList]
                                : this.model.challenges.map(c => c.answerList);
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'checkAll', {
                        get() {
                            return this.firstChallenge?.selection_mode === 'check_all_selection_mode';
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'firstChallenge', {
                        get() {
                            return this.model.challenges?.[0];
                        },
                    });

                    Object.defineProperty(ChallengesEditorViewModel.prototype, 'usesMultipleAnswersEditorTemplate', {
                        get() {
                            if (!this.config.supportsCheckMany) {
                                return false;
                            }
                            return (
                                this.firstChallenge?.editor_template ===
                                getEditorTemplate(this.config.supportsCheckMany, true)
                            );
                        },
                    });

                    ChallengesEditorViewModel.supportConfigOption('supportsCheckMany');

                    ChallengesEditorViewModel.onConfigChange('supportsCheckMany', function () {
                        this._onChange();
                    });

                    ChallengesEditorViewModel.prototype._onChange = function () {
                        if (this.supportsNoIncorrectAnswers) {
                            // This triggers the `MultipleChoiceChallengeEditorViewModel.NoIncorrectAnswersMixin` setter to run,
                            // which resets the appropriate behaviors/validators before overriding below
                            // eslint-disable-next-line no-self-assign
                            this.noIncorrectAnswers = this.noIncorrectAnswers;
                        }

                        // reset listeners
                        this._checkManyListener?.cancel();
                        this._checkManyListener = null;
                        this._answerListListeners?.forEach(l => l.cancel());
                        this._answerListListeners = [];

                        if (!this.config.supportsCheckMany) {
                            return;
                        }

                        const isMultipleAnswerMode = multipleAnswerModes.includes(this.selectionMode);

                        // when checkMany is set, loop through existing challenges and update them
                        // start listening for challenges to be added
                        //  - when a challenge is added, check the current value of checkMany and apply to the challenge
                        this._checkManyListener = this.model.challenges.on('childAdded', challenge => {
                            challenge.selection_mode = this.selectionMode;
                            const challengeEditorViewModel = challenge.editorViewModel;

                            challengeEditorViewModel.applyTemplate(
                                getEditorTemplate(this.config.supportsCheckMany, isMultipleAnswerMode),
                            );

                            const setAnswerListSkin = () => {
                                if (!challenge.answerList) {
                                    return;
                                }
                                challenge.answerList.skin =
                                    this.config.supportsCheckMany.answerListSkin[this.selectionMode];
                            };

                            setAnswerListSkin();
                            const answerListSkinListener = challenge.on('set:answerList', setAnswerListSkin);
                            this._answerListListeners.push(answerListSkinListener);

                            // Set behaviors and listeners for the selectionMode 'check_all_selection_mode'
                            if (this.checkAll) {
                                const setAllAnswersCorrect = () => {
                                    challenge.editorViewModel.correctAnswers = challenge.answers.clone();
                                };

                                setAllAnswersCorrect();

                                // Ensure any newly added answers are marked correct
                                const checkAllListener = challenge.answers.on('childAdded', setAllAnswersCorrect);
                                this._answerListListeners.push(checkAllListener);

                                // Modify challenge behaviors and editor config
                                challenge.behaviors.DisallowMultipleSelect = undefined;
                                challenge.behaviors.ReadyToValidateWhenAnswerIsSelected = undefined;
                                challenge.behaviors.ShowCorrectStyling = undefined;
                                challenge.behaviors.ImmediateValidation = {};
                                challenge.editorViewModel.messageEvent = 'selected';
                                challenge.behaviors.PlayScalingSoundOnSelected = {};
                                challenge.validator.behaviors.HasAllExpectedAnswers = {};
                                challenge.validator.behaviors.HasNoUnexpectedAnswers = {};
                                challenge.editorViewModel.config.disableCorrectAnswerSelect = true;
                            }
                        });
                    };
                },
            });
        },
    ],
);
