import angularModule from 'Editor/angularModule/scripts/editor_module';
/*
In the editor, this behavior needs to detect blanks in the text and make sure that challenges are created to go along with them
*/

/*
    In the player, this behavior needs to replace blanks in the text with the cf-challenge-blank
    directive.  This, in turn, can happen in two different ways:

    1. Outside of mathjax blocks, the blank (which must be wrapped
        in brackets, like here is a [blank]) is directly
        replaced with a <cf-challenge-blank> element
    2. Inside of mathjax blocks, the blank (which must be prefixed
        with 'Blank' and wrapped in brackets, like e = mBlank[c^2])
        is replaced with the mathjax extension: \Blank[INDEX]{content}.
        We have added the \Blank extension to mathjax, which will
        add the cf-challenge-blank directive as part of the mathjax
        processing (see ProcessesMathjax for that extension)
*/

angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesChallengeBlanksEditorHelper',
    [
        '$injector',

        $injector => {
            const regularBlanksRegex = /([^![]|\\|^)\[([^[\]]*)\]/g;

            return {
                regularBlanksRegex,

                // This function is used by processBlanks (in the player)
                // and by getBlanksFromText (in the editor) to determine which
                // blanks exist in the text, and, in the player only, to replace
                // them with the cf-challenge-blank directive.
                replaceBlanks(text, isMarkdownFormatted, fn) {
                    let currentIndex = 0;
                    const splitOnSpecialBlock = $injector.get(
                        'Lesson.FrameList.Frame.Componentized.Component.Text.TextHelpers',
                    ).splitOnSpecialBlock;

                    // Split the text up into special and regular blocks.
                    // In regular blocks, blanks are indicated by brackets, i.e. [SOME_CONTENT]
                    // In special blocks, blanks are indicated by the word
                    //    'Blank' and the brackets, i.e. Blank[SOME_CONTENT]
                    return splitOnSpecialBlock(text, isMarkdownFormatted, (textBlock, inSpecialBlock) => {
                        const regularRegex = regularBlanksRegex;

                        // the (.|\s) is there so we can capture the prefix like we do
                        // in the regularBlanksRegex, just for consistency.  We don't need
                        // the checks inside of it because there is no markdown processing
                        // or images inside of special blocks.
                        const specialBlockRegex = /(.|\s)Blank\[([^[\]]*)\]/g;
                        const regex = inSpecialBlock ? specialBlockRegex : regularRegex;

                        // If you want brackets inside of a mathjax or code blank, you escape them, like
                        // `Blank[array\[0\]]`.  In order to make this work, we just remove any
                        // escaped blanks right here and then put them back in farther down.  We
                        // ALSO need to be careful, though, not to break the ability to put blanks next to
                        // each other with syntax like `[blank1]\\[blank2]`, so we only look
                        // for cases where there is an escaped bracket that does not come right
                        // after another slash or bracket.  See the specs flagged with BRACKET_SUPPORT
                        // NOTE: we do not support brackets outside of special blocks, but we could. We
                        // just didn't want to do the extra work until there was a specific request

                        // match for an escaped opening bracket that does not have
                        //  1. a backslash beforehand (so we don't match the unmarkdowned 2-blanks in a row syntax)
                        //  2. a closing bracket beforehand (so we don't match the markdowned 2-blanks in a row syntax)
                        const openingBracketRegex = /([^\]\\])(\\\[)/g;

                        // match for an escaped closing bracket, regardless of what is in front (but match
                        // the preceding character just for consistency with above)
                        const closingBracketRegex = /(.)(\\\])/g;

                        // match very specifically for an escaped closing bracket followed by and escaped opening bracket
                        // and grab the preceding character for consistency
                        const consecutiveOpenAndClosedBrackets = /(.)(\\\]\\\[)/g;

                        // In the scenario where we are inside of a code block blank
                        // and we want to show brackets side by side i.e `Blank[someNestedArray\[0\]\[0\]]`
                        // we need to go ahead and remove those before the other replaces below incorrectly alter the string
                        // so leave this replace first
                        textBlock = textBlock
                            .replace(
                                consecutiveOpenAndClosedBrackets,
                                (_, prefix) => `${prefix}**ESCAPED_OPEN_AND_CLOSE_BRACKET**`,
                            )
                            .replace(openingBracketRegex, (_, prefix) => `${prefix}**ESCAPED_OPENING_BRACKET**`)
                            .replace(closingBracketRegex, (_, prefix) => `${prefix}**ESCAPED_CLOSING_BRACKET**`);

                        // for each blank in the text, pass along
                        // 1. the prefix (whatever came just before the blank)
                        // 2. the content inside the blank
                        // 3. a boolean indicating whether we are within a special block or not
                        // 4. the index of the blank in the full text
                        //
                        // These things are passed into the provided fn, which will
                        // return new content for the blank.
                        const formattedBlock = textBlock.replace(regex, (...args) => {
                            const prefix = args[1];
                            const blankContent = args[2]
                                .replace(/\*\*ESCAPED_OPEN_AND_CLOSE_BRACKET\*\*/g, '][')
                                .replace(/\*\*ESCAPED_OPENING_BRACKET\*\*/g, '[')
                                .replace(/\*\*ESCAPED_CLOSING_BRACKET\*\*/g, ']');
                            const result = fn(prefix, blankContent, inSpecialBlock, currentIndex);
                            currentIndex += 1;
                            return result;
                        });

                        return formattedBlock
                            .replace(/\*\*ESCAPED_OPENING_BRACKET\*\*/g, '[')
                            .replace(/\*\*ESCAPED_CLOSING_BRACKET\*\*/g, ']');
                    });
                },
            };
        },
    ],
);

angularModule.factory('Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesChallengeBlanksEditor', [
    '$injector',
    $injector => {
        const ProcessesChallengeBlanksEditorHelper = $injector.get(
            'Lesson.FrameList.Frame.Componentized.Component.Text.Behaviors.ProcessesChallengeBlanksEditorHelper',
        );
        const AModuleAbove = $injector.get('AModuleAbove');

        function getBracketedBlockTypes(editorViewModel, text) {
            const model = editorViewModel.model;

            if (editorViewModel.type !== 'TextEditorViewModel') {
                throw new Error('Asshole');
            }
            if (text && model && model.includesBehavior('ProcessesMarkdown')) {
                const bracketedBlockTypes = [];

                //  do markdown processing
                let markdownFormattedText = editorViewModel.formatters.runFormatters(
                    ['removeMathJax', 'markdown', 'replaceMathJax'],
                    text,
                );

                // replace any blanks that are left with the string '____BLANK'
                markdownFormattedText = markdownFormattedText.replace(
                    ProcessesChallengeBlanksEditorHelper.regularBlanksRegex,
                    '____BLANK',
                );

                // replace any <a> tag in the formatted text with the string '____LINK'
                markdownFormattedText = markdownFormattedText.replace(/<a/g, '____LINK');

                // match on either ____BLANK or ____LINK and build up an array
                // that stores whether each instance of [asdaas] is a blank or a link
                let i = 0;
                markdownFormattedText.replace(/(____BLANK|____LINK)/g, str => {
                    bracketedBlockTypes[i] = str === '____LINK' ? 'link' : 'blank';
                    i += 1;
                });
                return bracketedBlockTypes;
            }
            return undefined;
        }

        function getBlanksFromText(editorViewModel, text) {
            const blanks = [];

            // when we see [asdas], it might be a blank or a link, since markdown
            // links look like [link](http://google.com).  bracketedBlockTypes stores
            // whether each instance of [asdas] is a blank or a link.
            const bracketedBlockTypes = getBracketedBlockTypes(editorViewModel, text);
            if (text) {
                let bracketedBlockCount = 0;
                ProcessesChallengeBlanksEditorHelper.replaceBlanks(
                    text,
                    false,
                    (prefix, formattedContents, inSpecialBlock, currentIndex) => {
                        if (!bracketedBlockTypes || bracketedBlockTypes[currentIndex] === 'blank') {
                            blanks.push(formattedContents);
                        }
                        bracketedBlockCount = currentIndex + 1;
                    },
                );
                if (bracketedBlockTypes && bracketedBlockCount !== bracketedBlockTypes.length) {
                    // We used to throw here, but there are ways to get into this state by entering
                    // ridiculous content, like ' a [[[link](http://google.com) and a [blank]'.
                    // We are stull in the editor, so the author should have the opportunity to
                    // recognize that things are messed up and fix them.  No need to throw.
                    // ProcessesChallengeBlanks will throw in the player if bad content makes it there.
                }
            }
            return blanks;
        }

        function SingleChangeError(message) {
            this.name = 'SingleChangeError';
            this.message = message || '';
        }
        SingleChangeError.prototype = Error.prototype;

        //---------------------------------
        // BEGIN: Blank Formatting
        //---------------------------------

        function processMathjaxBlank(index, formattedContents) {
            return `\\Blank[${index}]{${formattedContents}}`;
        }

        function processRegularBlank(index, formattedContents) {
            // (Note this functionality is duplicated in ProcessesMathjax#HTMLcreateSpan,
            // which does the same thing for blanks inside of mathjax.  Changes here may need to
            // be implemented there as well.)
            const str =
                // set earlier blanks at a higher z-index, in case their click targets
                // (large invisible areas that make them easier to click on mobile) overlap.
                // set contents
                // close it up
                `<cf-challenge-blank id="blank_${index}" inline view-model="viewModel.challengesComponentViewModel.challengesViewModels[${index}]" ng-style="{zIndex: 100-${index}}">${
                    // set contents
                    formattedContents
                }</cf-challenge-blank>`;

            return str;
        }

        // This is the function that runs in the player to add the
        // cf-challenge-blank directive (in the non-mathjax case) or the
        // \Blank mathjax extension (in the mathjax case) to every blank
        // in the text
        function processBlanks(text) {
            const challengesComponent = this.model.challengesComponent;
            if (!challengesComponent) {
                throw new Error('Cannot process challenge blanks because there is no challenges component.');
            }
            const isMarkdownFormatted = true; // really we should check if we include the markdown behavior here, but since we always do, just assume true
            text = ProcessesChallengeBlanksEditorHelper.replaceBlanks(
                text,
                isMarkdownFormatted,
                (prefix, formattedContents, inSpecialBlock, currentIndex) => {
                    let str = prefix; // the character before the blank started is in the prefix

                    // special case: if the prefix is '\\', then the author is explicitly escaping for a blank that is
                    // directly preceding this blank. Leave out this cruft from the processed output.
                    if (str === '\\') {
                        str = '';
                    }

                    const challenge = challengesComponent.challenges[currentIndex];
                    if (!challenge) {
                        // NOTE: We don't actually need this because this code has moved solely into the editor
                        //       We're maintaining it here to keep merge conflicts less confusing while pre_rendered_formatting exists

                        /*
                        var $route = $injector.get('$route');
                        // Only throw this error in the player.  If we are in the editor, just leave things
                        // broken and give editors a chance to fix it.  You can get into this state with
                        // ridiculous content like ' a [[[link](http://google.com) and a [blank]'.
                        if (!$route.current || !_.includes(['edit-lesson', 'preview-lesson'], $route.current.directive)) {
                            throw new Error('No challenge for index=' + currentIndex + ' while processing blanks.');
                        }

                        */
                        return prefix + formattedContents;
                    }

                    const processedBlank =
                        inSpecialBlock === 'mathjax'
                            ? processMathjaxBlank(currentIndex, formattedContents)
                            : processRegularBlank(currentIndex, formattedContents);

                    str += processedBlank;

                    return str;
                },
            );

            return text;
        }

        //---------------------------------
        // END: Blank Formatting
        //---------------------------------

        return new AModuleAbove({
            included(TextEditorViewModel) {
                // set up a callback to be run whenever a TextModel is initialized
                TextEditorViewModel.setCallback('after', 'initialize', function fn() {
                    const editorViewModel = this;
                    let setTextListener;
                    let setUnlinkListener;

                    this.model.on('behavior_added:ProcessesChallengeBlanks', () => {
                        if (!editorViewModel.model.challengesComponent) {
                            throw new Error('ProcessesChallengeBlanks requires that challengesComponent be set.');
                        }

                        let updatingBlanks = false;
                        editorViewModel.formatters.add('challengeBlanks', text => {
                            if (updatingBlanks) {
                                throw new Error('Must finish updateBlanks() before you call processBlanks()');
                            }
                            return processBlanks.apply(this, [text]);
                        });

                        editorViewModel._initializeBlanks();

                        // Listen for changes to the text and re-run updateBlanks
                        setTextListener = editorViewModel.model.on('set:text', text => {
                            if (editorViewModel.$$suppressBlanksUpdate) {
                                return;
                            }
                            updatingBlanks = true;
                            const newBlanks = getBlanksFromText(editorViewModel, text);
                            editorViewModel.updateBlanks(newBlanks);
                            updatingBlanks = false;
                        });

                        // If unlink_blank_from_answer is ever turned OFF (i.e.
                        // the user wants the blank and the answer to be linked once
                        // again, the we run updateRelatedBlankOnCorrectAnswers
                        // in order to refresh the linking)
                        setUnlinkListener = editorViewModel.model.on(
                            '.challengesComponent.challenges[]:set:unlink_blank_from_answer',
                            val => {
                                if (!val) {
                                    editorViewModel.updateAllCorrectAnswerTexts();
                                }
                            },
                        );
                    });

                    this.model.on('behavior_removed:ProcessesChallengeBlanks', () => {
                        if (setTextListener) {
                            setTextListener.cancel();
                            setTextListener = undefined;
                        }
                        if (setUnlinkListener) {
                            setUnlinkListener.cancel();
                            setUnlinkListener = undefined;
                        }
                        editorViewModel.model.challengesComponent = undefined;

                        editorViewModel.formatters.remove('challengeBlanks');
                    });
                });
            },

            _initializeBlanks() {
                const editorViewModel = this;
                /*
                                    There will be no challenges if we are switching between compose_blanks
                                    and fill_in_the_blanks, since switching challenge types (from MultipleChoiceChallenge
                                    to UserInputChallenge) deletes all of the challenges.  There might already
                                    be blanks in the text, though, so we need to run updateBlanks.
                                */
                if (editorViewModel.model.challengesComponent.challenges.length === 0) {
                    this.blanks = []; // BD: THIS IS NEW
                    const newBlanks = getBlanksFromText(editorViewModel, this.model.text);
                    editorViewModel.updateBlanks(newBlanks);
                } else {
                    /*
                                    If there are any challenges, then we will assume that they are already
                                    in sync with the blanks in the text.
                                */
                    editorViewModel.blanks = getBlanksFromText(editorViewModel, editorViewModel.model.text);
                }

                // Run updateRelatedBlankOnCorrectAnswers once now.  subsequently,
                // it will be run inside of updateBlanks
                // if  the blanks have changed.
                editorViewModel.updateRelatedBlankOnCorrectAnswers();
            },

            updateBlanks(newBlanks, oldChallengesMap = {}) {
                const origBlanks = this.blanks.slice(0); // clone blanks array
                if (!_.isEqual(newBlanks, origBlanks)) {
                    // this._saveDataForBlanks();

                    // In most cases, only one blank will change at a time, either an
                    // addition, a removal, or a change.  In those cases, we can be smart
                    // about preserving the existing confusers, messages, etc.
                    try {
                        // we can add a single blank
                        if (newBlanks.length > origBlanks.length) {
                            this._addSingleBlank(newBlanks, origBlanks, oldChallengesMap);
                        }

                        // we can remove a single blank
                        else if (newBlanks.length < origBlanks.length) {
                            this._removeSingleBlank(newBlanks, origBlanks);
                        }

                        // we can change a single blank
                        else {
                            this._changeSingleBlank(newBlanks, origBlanks);
                        }

                        // If there is more than once change, then an error will be thrown. In
                        // that case we have to blow away existing questions entirely and
                        // start over from scratch.
                    } catch (e) {
                        if (e.name === 'SingleChangeError') {
                            this.updateMultipleBlanks(origBlanks, newBlanks);
                        } else {
                            throw e;
                        }
                    }

                    this.blanks = newBlanks.slice(0);
                    this.updateRelatedBlankOnCorrectAnswers();
                }
            },

            updateMultipleBlanks(origBlanks, newBlanks) {
                this.blanks = [];

                // store the original challenges, keyed by the blank text and
                // the index of that text in the list (in case multiple blanks have
                // the same text)
                const oldChallengesMap = {};
                const blankTextCounts = {};
                const challenges = this.model.challengesComponent.challenges.clone();
                origBlanks.forEach((blankText, i) => {
                    const challenge = this.model.challengesComponent.challenges[i];
                    if (!blankTextCounts[blankText]) {
                        blankTextCounts[blankText] = 0;
                    }
                    const blankTextIndex = blankTextCounts[blankText];
                    blankTextCounts[blankText] += 1;
                    const key = `${blankText}-------${blankTextIndex}`;
                    oldChallengesMap[key] = challenge;
                });

                this.model.challengesComponent.challenges = [];
                const oneByOne = [];
                angular.forEach(newBlanks, blank => {
                    oneByOne.push(blank);

                    // when we call updateBlanks, pass in the oldChallengesMap so
                    // that, as we add blanks, we can try to line them up with
                    // existing challenges and not lose existing confusers, etc.
                    this.updateBlanks(oneByOne, oldChallengesMap);
                });

                // make sure that any challenges that are no longer being used get removed
                // so that any references are cleaned up.  If we don't do this, we end up with unreferenced
                // errors
                challenges.forEach(challenge => {
                    if (!this.model.challengesComponent.challenges.includes(challenge)) {
                        challenge.remove();
                    }
                });
            },

            updateRelatedBlankOnCorrectAnswers() {
                // give the correct answers some information about the blanks that
                // they are the correct answers for
                this.model.challengesComponent.challenges.forEach((challenge, i) => {
                    const correctAnswer = challenge.editorViewModel.correctAnswer;
                    if (correctAnswer) {
                        correctAnswer.editorViewModel.relatedBlankLabel = this.blanks[i];
                        correctAnswer.editorViewModel.relatedBlankChallenge = challenge;
                    }
                });
            },

            updateAllCorrectAnswerTexts() {
                this.model.challengesComponent.challenges.forEach((challenge, i) => {
                    this._updateCorrectAnswerText(i, this.blanks[i]);
                });
            },

            _addSingleBlank(newBlanks, origBlanks, oldChallengesMap) {
                const additions = [];
                let origIndex = 0;

                const blankTextCounts = {};

                // if we're calling this after capturing a SingleChangeError, then
                // oldChallengesMap will be set
                oldChallengesMap = oldChallengesMap || {};

                angular.forEach(newBlanks, (newBlank, i) => {
                    // the oldChallengesMap is has old challenges keyed by
                    // the blank text and the position of that text (in case
                    // multiple blanks have the same text)
                    if (!blankTextCounts[newBlank]) {
                        blankTextCounts[newBlank] = 0;
                    }
                    const blankTextIndex = blankTextCounts[newBlank];
                    blankTextCounts[newBlank] += 1;
                    const oldChallengeKey = `${newBlank}-------${blankTextIndex}`;
                    const challenge = oldChallengesMap[oldChallengeKey];

                    // use origIndex to follow along in the original array,
                    // checking that each newBlank matches up with the existing blank
                    if (origBlanks[origIndex] !== newBlank) {
                        // when we find one that doesn't match up, we assume it needs
                        // to be added, and we step ahead by one in the original array
                        additions.push({
                            index: i,
                            text: newBlank,
                            challenge,
                        });
                    } else {
                        origIndex += 1;
                    }
                });

                if (additions.length !== 1) {
                    throw new SingleChangeError('Expecting to add exactly one blank');
                }
                if (additions[0].challenge) {
                    this.model.challengesComponent.challenges.splice(additions[0].index, 0, additions[0].challenge);
                } else {
                    this.model.challengesComponent.editorViewModel.addChallenge(additions[0].index);
                }

                this._updateCorrectAnswerText(additions[0].index, additions[0].text);
            },

            _removeSingleBlank(newBlanks, origBlanks) {
                const removals = [];
                let newIndex = 0;
                angular.forEach(origBlanks, (origBlank, i) => {
                    // use newIndex to follow along in the new array,
                    // checking that each origBlank matches up with the new blank
                    if (newBlanks[newIndex] !== origBlank) {
                        // when we find one that doesn't match up, we assume it needs
                        // to be removed, and we do not step ahead our counter in the new array
                        removals.push({
                            index: i,
                        });
                    } else {
                        newIndex += 1;
                    }
                });
                if (removals.length !== 1) {
                    throw new SingleChangeError('Expecting to remove exactly one blank');
                }
                const challenges = this.model.challengesComponent.challenges;
                const challenge = challenges[removals[0].index];
                challenge.remove();
            },

            _changeSingleBlank(newBlanks, origBlanks) {
                const changes = [];
                angular.forEach(origBlanks, (origBlank, i) => {
                    const newBlank = newBlanks[i];
                    if (newBlank !== origBlank) {
                        changes.push({
                            text: newBlank,
                            index: i,
                        });
                    }
                });
                if (changes.length !== 1) {
                    throw new SingleChangeError('Expecting to change exactly one blank');
                }

                this._updateCorrectAnswerText(changes[0].index, changes[0].text);
            },

            _updateCorrectAnswerText(index, text) {
                const challengeModel = this.model.challengesComponent.challenges[index];
                if (!challengeModel.unlink_blank_from_answer) {
                    challengeModel.editorViewModel.correctAnswerText = text;
                }
            },
        });
    },
]);
