angular.module('ooDirective', ['AClassAbove']).factory('DirectiveBase', [
    'Prototype.Class',
    Class => {
        const DirectiveBase = {
            subclass(options) {
                return angular.extend({}, this, options);
            },

            generateOptions() {
                const options = {};
                angular.forEach(
                    [
                        'name',
                        'priority',
                        'terminal',
                        'scope',
                        'require',
                        'controllerAs',
                        'restrict',
                        'template',
                        'templateUrl',
                        'replace',
                        'transclude',
                        'link',
                        'compile',
                    ],
                    prop => {
                        if (prop === 'link') {
                            const link = this.link;

                            options.link = link
                                ? function (...args) {
                                      return link.apply(this, args);
                                  }
                                : link;
                        } else {
                            options[prop] = this[prop];
                        }
                    },
                );

                // handle 'compile' and 'link'

                if (this.controller) {
                    // I can't figure out how to support arbitrary injectables here
                    // because of the difficulty of making sure the locals get passed
                    // through the inheritance chain.  So we're just hardcoding the locals.
                    // That should be okay, though, since
                    // any injectables can be injected into the directive and used in
                    // the controller anyway.
                    if (Object.prototype.toString.call(this.controller) === '[object Array]') {
                        throw new Error(
                            'DirectiveBase does not support bracket notation for controllers.  All controllers will be passed the following locals: $scope, $element, $attrs, $transclude.  The controller should be a function.',
                        );
                    }
                    options.controller = ['$scope', '$element', '$attrs', '$transclude', this.controller];
                }
                return options;
            },

            controller: Class.create({
                initialize(scope, element, attrs) {
                    this.scope = scope;
                    this.element = element;
                    this.attrs = attrs;
                },
            }),

            extendController(options) {
                const superController = this.controller;
                if (!superController) {
                    throw new Error('Cannot extend controller because there is no controller');
                }

                return Class.create(superController, options);
            },
        };

        return DirectiveBase;
    },
]);
