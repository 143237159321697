import angularModule from 'Editor/angularModule/scripts/editor_module';

angularModule.directive('cfComponentEditor', [
    () => ({
        restrict: 'E',

        // we need replace false here so that the view-model attr is not overridden when
        // passing it down
        replace: false,

        scope: {
            editorViewModel: '<',
            frameViewModel: '<',
            disabled: '<',
            skin: '@',
            options: '<',
        },

        template:
            '<div \
               observe="editorViewModel.model.id"\
               dynamic-node="editorViewModel.directiveName"\
               editor-view-model="editorViewModel"\
               frame-view-model="frameViewModel"\
               skin="{{skin}}"\
               options="options"\
               disabled="disabled"\
               component-id="{{editorViewModel.model.id}}"\
               ></div>',
    }),
]);
